
import React from "react";
// react component for creating dynamic tables
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';

// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import Image from "@material-ui/icons/Image";
import Button from "../../components/CustomButtons/Button";
import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import {AlbumImages, AlbumId, USER_TYPE} from "../../helper/Cookies";
import {UploadImageAction, HideMsg} from "../../actions/GalleryAlbumAction";
import {connect} from "react-redux";
import Snackbar from "../../components/Snackbar/Snackbar";

class GalleryImages extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            checked: [24, 22],
            modules: ["Circulars", "Assessments", "HomeWorks", "Attendance","Gallery","Calendar","Feed","Chart","Fee","Transport" ],
            simpleSelect: "",
            multipleSelect: [],
            file: null,
            fileName:"No file Chosen",
            fileType:"",
            imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
            currentImage: 0
        };
        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
    }
    openLightbox(event, obj) {
        this.setState({
            currentImage: obj.index,
            lightboxIsOpen: true,
        });
    }
/*
    openLightbox( obj) {
        this.setState({
            currentImage: obj,
            lightboxIsOpen: true,
        });
    }
*/

    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }
    gotoPrevious() {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }
    gotoNext() {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }

    handleCreate=()=>{
        //alert();
        this.setState({
            isCreated: !this.state.isCreated,
        });
       // this.props.history.push('/Gallery');
    };
    handleFileClick() {
        var input = document.createElement("input");
        input.type = "file";
        input.onchange = this.handleImageChange;
        input.click();
    }
    handleImageChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                fileType:file.type,
                file: file,
                fileName:file.name,
                imagePreviewUrl: reader.result
            });

        };

        reader.readAsDataURL(file);
        const {UploadImage,}=this.props;
        const{fileType}=this.state;
        UploadImage(AlbumId(),file,file.type);
//console.log("state",this.state);
        // this.props.history.push('/Gallery');
    }
    timeInfo = () => {
        const {HideMsg} = this.props;
        setTimeout(() => {
            HideMsg();
            this.handleCreate();
        }, 3000)
    }

    render(){
        const { isFailed,msg,msgColor ,HideMsg} = this.props;

        if (isFailed === true && msg !== null) {
            if(msgColor === "success"){
                this.timeInfo();
            }else{
                setTimeout(() => {
                    HideMsg();
                }, 2000)
            }
        }
         let ImagesList = JSON.parse(AlbumImages());
        const userType=parseInt(USER_TYPE());
        let ListImages=[];
      //  console.log("image.length",ImagesList.length);
       if(ImagesList.length>0){
           ImagesList.forEach(r=>{
               const{url}=r;
               ListImages.push({
                   src:url,
                   width:1,
                   height: 1
               })
           })
       }

        return (
            <GridContainer justify={"center"}>
                <GridContainer>
                    <ItemGrid xs={12} sm={6} md={4}>
                        <Snackbar
                            open={isFailed}
                            place={"tc"}
                            color={msgColor}
                            message={msg}
                        />
                    </ItemGrid>
                </GridContainer>
                    <ItemGrid xs={12}>
                        <IconCard
                            icon={Image}
                           // iconColor={"rose"}
                            title={
                                <div>

                                    <Button
                                        onClick={this.handleFileClick}
                                        // round color="white"
                                        color="primary" right={true}>Upload Image
                                    </Button>
                                    <Button
                                    onClick={this.props.history.goBack}
                                    // round color="white"
                                    color="danger" right={true} >Back To Albums
                                </Button>

                                </div>
                            }
                            content={
                                <GridContainer >
                                    <ItemGrid xs={12}>
                                        <div>

                                            <Gallery columns={6} photos={ListImages} onClick={this.openLightbox} direction={'column'}/>
                                            <Lightbox images={ListImages}
                                                      onClose={this.closeLightbox}
                                                      onClickPrev={this.gotoPrevious}
                                                      onClickNext={this.gotoNext}
                                                      currentImage={this.state.currentImage}
                                                      isOpen={this.state.lightboxIsOpen}
                                            />
                                        </div>
                                    </ItemGrid>
                                </GridContainer>
                            }
                        />
                    </ItemGrid>
            </GridContainer>
        );
    }
}
const mapStateToProps = state =>{
    const{isFailed,msg,msgColor}=state.GalleryAlbum;
    return{isFailed,msg,msgColor}
}

export default connect(mapStateToProps,{
    UploadImage:UploadImageAction,HideMsg,
}) (GalleryImages);
