import React from "react";
import PropTypes from "prop-types";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

// @material-ui/icons
import IconCard from "components/Cards/IconCard.jsx";



// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import {schoolCatagory, userList} from "../../variables/menus";
import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import {School} from "@material-ui/icons";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "material-ui/Select";
import MenuItem from "material-ui/Menu/MenuItem";
import FormControl from "material-ui/Form/FormControl";
import {connect} from "react-redux";
import Snackbar from "../../components/Snackbar/Snackbar";
import {HideMsg, RegisterSchoolAction,} from "../../actions/RegisterschoolAction";


class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tittle: "Register School",
      schoolName:null,
      schoolCode:null,
      cType: "",
      schoolContactName:null,
      schoolMobile:null,
      schoolEmail:null,
        menus:[1,2,3,4,5,6,7,8,9,10,11],
        RefCode:null,
      isCreated: false,
    };
    // this.handleToggle = this.handleToggle.bind(this);
  }
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onChangeValue = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    })
  };
  handleCreate=()=>{
    //alert();
    this.setState({
      isCreated: !this.state.isCreated,
    });
      const {history} = this.props;
      history.push("/Login")
  };
    schoolRegister = () => {
        const{RegisterSchool}=this.props;
        const{schoolName, schoolCode, schoolEmail, schoolMobile,schoolContactName, menus, RefCode,cType}=this.state;
        RegisterSchool(schoolName, schoolCode, schoolEmail, schoolMobile,schoolContactName, menus,RefCode,cType);
    };

    timeInfo = () => {
        setTimeout(() => {
            this.handleCreate();
        }, 3000)
    }
  render() {
    const { classes,isFailed,msgColor ,msg,HideMsg } = this.props;

      if (isFailed === true && msg !== null) {
          if(msgColor==="success"){
              this.timeInfo();
          }else{
              setTimeout(() => {
                  HideMsg();
              }, 2000)
          }
      }
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
            <GridContainer>
                <ItemGrid xs={12} sm={6} md={4}>
                    <Snackbar
                        open={isFailed}
                        place={"tc"}
                        color={msgColor}
                        message={msg}
                    />
                </ItemGrid>
            </GridContainer>
          <ItemGrid xs={12} sm={12} md={10}>
                <GridContainer justify="center">

                  <ItemGrid xs={12} sm={12}>
                    <IconCard
                        icon={School}
                        title={this.state.tittle}
                        iconColor="rose"
                        content={
                          <form>
                            <GridContainer justify="center">
                              <ItemGrid xs={12} sm={6}>
                            <CustomInput
                                labelText="School Name"
                                id="schoolName"
                                onChange={this.onChangeValue}
                                formControlProps={{
                                  fullWidth: true
                                }}
                                inputProps={{
                                  // value:schoolName
                                }}
                            />
                              </ItemGrid>
                              <ItemGrid xs={12} sm={6}>
                            <CustomInput
                                labelText="School Code"
                                id="schoolCode"
                                onChange={this.onChangeValue}
                                formControlProps={{
                                  fullWidth: true
                                }}
                                inputProps={{
                                  // disabled:isDisabled,
                                  // value:schoolCode
                                }}
                            />
                              </ItemGrid>
                            </GridContainer>
                            <GridContainer justify="center">

                              <ItemGrid xs={12} sm={6}>
                                <CustomInput
                                    labelText="Mobile No"
                                    id="schoolMobile"
                                    onChange={this.onChangeValue}
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      // value:schoolMobile
                                    }}
                                />


                              </ItemGrid>
                              <ItemGrid xs={12} sm={6}>
                                <CustomInput
                                    labelText="Conatct Name"
                                    id="schoolContactName"
                                    onChange={this.onChangeValue}
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      // value:schoolLandline
                                    }}
                                />

                              </ItemGrid>

                            </GridContainer>
                            <GridContainer justify="center">
                              <ItemGrid xs={12} sm={6}>
                            <CustomInput
                                labelText="Email Id"
                                id="schoolEmail"
                                onChange={this.onChangeValue}
                                formControlProps={{
                                  fullWidth: true
                                }}
                                inputProps={{
                                  // value:schoolEmail
                                }}
                            />
                              </ItemGrid>
                              <ItemGrid xs={12} sm={6}>

                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                >
                                  <InputLabel
                                      htmlFor="simple-select"
                                      className={classes.selectLabel}
                                  >
                                    Select Catagory
                                  </InputLabel>
                                  <Select
                                      MenuProps={{
                                        className: classes.selectMenu
                                      }}
                                      classes={{
                                        select: classes.select
                                      }}
                                      value={this.state.cType}
                                      onChange={this.handleSimple}
                                      inputProps={{
                                        name: "cType",
                                        id: "cType"
                                      }}
                                  >
                                    <MenuItem
                                        disabled
                                        classes={{
                                          root: classes.selectMenuItem
                                        }}
                                    >
                                      Select Catagory
                                    </MenuItem>
                                    {schoolCatagory !== null ?
                                        schoolCatagory.map((data) =>
                                            <MenuItem
                                                key={data.catagoryId}
                                                classes={{
                                                  root: classes.selectMenuItem,
                                                  selected: classes.selectMenuItemSelected
                                                }}
                                                value={data.catagoryName}
                                            >
                                              {data.catagoryName}
                                            </MenuItem>
                                        )
                                        : null}
                                  </Select>
                                </FormControl>

                              </ItemGrid>
                            </GridContainer>
                            <GridContainer>
                              <ItemGrid xs={12} sm={6}>
                                <CustomInput
                                    labelText="Referal Code"
                                    id="RefCode"
                                    onChange={this.onChangeValue}
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      // value:schoolWebsite
                                    }}
                                />

                              </ItemGrid>
                            </GridContainer>

                            <GridContainer justify="center">
                                  <ItemGrid>
                                    <br/>
                                    <Button color="success" onClick={this.schoolRegister}>Save</Button>
                                    <br/>
                                  </ItemGrid>

                              <ItemGrid>
                                <br/>
                                <Button color="rose" onClick={this.handleCreate}>Cancel</Button>
                                <br/>
                              </ItemGrid>

                            </GridContainer>
                          </form>

                        }
                    />
                  </ItemGrid>
                </GridContainer>
          </ItemGrid>
        </GridContainer>
      </div>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps= state => {
    const {isFailed,msg,msgColor}=state.RegSchool;
    return{isFailed,msg,msgColor};
}

export default connect(mapStateToProps,{
    RegisterSchool:RegisterSchoolAction,HideMsg,
})( withStyles(registerPageStyle)(RegisterPage));
