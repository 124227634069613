
import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Amount from "@material-ui/icons/AccountBalance";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import { dataTable } from "variables/general.jsx";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import withStyles from "material-ui/styles/withStyles";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "material-ui/Select";
import MenuItem from "material-ui/Menu/MenuItem";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
class FeeType extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            headers:["Code","Class","Date","Subject","Homework"],

            data: dataTable.dataRows.map((prop,key) => {
                return ({
                    id: key,
                    name: prop[0],
                    position: prop[1],
                    office: prop[2],
                    age: prop[3],
                    actions: (
                        // we've added some custom button actions
                        <div className="actions-right">

                            <IconButton
                                onClick={() => {
                                    var data = this.state.data;
                                    data.find((o,i) => {
                                        if(o.id === key){
                                            // here you should add some custom code so you can delete the data
                                            // from this component and from your server as well
                                            data.splice(i,1);
                                            return true;
                                        }
                                        return false;
                                    });
                                    this.setState({data: data});
                                }}
                                color="dangerNoBackground"
                                customClass="remove">
                                <Close />
                            </IconButton>
                        </div>

                    )
                })
            }),
            selectHead: "",
            selectMode:"",
            fileName:"No file Chosen",
            file:null,
            mode_list: ["School Fee", "Enrichment Courses", "MISC" ],
            head_list: ["Main Head", "Sub Header" ],

        };
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
    }
    handleCreate=()=>{
        //alert();
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
    };
    handleSimple = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    handleFileClick() {
        var input = document.createElement("input");
        input.type = "file";
        input.onchange = this.handleFileChange;
        input.click();
    }
    handleFileChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                fileName:file.name
            });
        };
        reader.readAsDataURL(file);
    }

    render(){
        const { classes } = this.props;
        return (
            <GridContainer justify="center">
                {this.state.isCreated ?
                    <ItemGrid xs={12} sm={12} >
                        <IconCard
                            icon={Amount}
                            title="Create Fee Type"
                            iconColor="rose"
                            content={
                                <form>

                                    <GridContainer justify="center">
                                        <ItemGrid xs={12} sm={6}>
                                          {/*  <InputLabel className={classes.label}>
                                                Fee Head
                                            </InputLabel>*/}
                                            <CustomInput
                                                 labelText="Fee Head"
                                                id="circular"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                  //  multiline: true,
                                                   // rows: 3,
                                                    // placeholder: "Type Description Here"
                                                }}
                                            />
                                        </ItemGrid>

                                        <ItemGrid xs={12} sm={6}>
                                           {/* <InputLabel className={classes.label}>
                                                Fee Code
                                            </InputLabel>*/}
                                            <CustomInput
                                                 labelText=" Fee Code"
                                                id="circular"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    //  multiline: true,
                                                    // rows: 3,
                                                    // placeholder: "Type Description Here"
                                                }}
                                            />

                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={12}>
                                            <FormControl
                                                fullWidth
                                                className={classes.selectFormControl}
                                            >
                                                <InputLabel className={classes.label}>
                                                    Parent Head
                                                </InputLabel>

                                                <Select
                                                    MenuProps={{
                                                        className: classes.selectMenu
                                                    }}
                                                    classes={{
                                                        select: classes.select
                                                    }}
                                                    value={this.state.selectMode}
                                                    onChange={this.handleSimple}
                                                    inputProps={{
                                                        name: "selectMode",
                                                        id: "select-class"
                                                    }}
                                                >
                                                    <MenuItem
                                                        disabled
                                                        classes={{
                                                            root: classes.selectMenuItem
                                                        }}
                                                    >
                                                        Select Parent Head
                                                    </MenuItem>
                                                    {this.state.mode_list.map((prop, key) => {
                                                        return(
                                                            <MenuItem
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                    selected: classes.selectMenuItemSelected
                                                                }}
                                                                value={key}
                                                            >
                                                                {prop.toString()}
                                                            </MenuItem>

                                                        )
                                                    })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </ItemGrid>
                                    </GridContainer>



                                    <GridContainer justify="center">

                                        <ItemGrid>
                                            <br/>
                                            <Button color="success" onClick={this.handleCreate}>Save</Button>
                                            <br/>
                                        </ItemGrid>
                                        <ItemGrid>
                                            <br/>
                                            <Button color="rose" onClick={this.handleCreate}  >Cancel</Button>
                                            <br/>
                                        </ItemGrid>

                                    </GridContainer>
                                </form>

                            }
                        />
                    </ItemGrid> :
                    <ItemGrid xs={12}>

                        <IconCard
                            icon={Amount}
                            iconColor={"rose"}
                            title={
                                <div>
                                    Fee Types
                                    <Button
                                        onClick={this.handleCreate}
                                        // round color="white"
                                        color="rose" right={true}> Add Fee Type
                                    </Button>
                                </div>
                            }
                            content={
                                <GridContainer >
                                  {/*  <ItemGrid xs={12} sm={11}>

                                    </ItemGrid>
                                    <ItemGrid xs={1} sm={1}>

                                        <IconButton
                                            onClick={this.handleCreate}
                                            // round color="white"
                                            color="rose">
                                            <AddIcon />
                                        </IconButton>
                                    </ItemGrid>*/}
                                    <ItemGrid xs={12}>
                                        {/*  <ExportToExcel posts={this.state.data}/>*/}
                                        <ReactTable
                                            data={this.state.data}
                                            filterable
                                            style={{color: "#00acc1"}}
                                            columns={[
                                                {
                                                    Header: "Fee Code",
                                                    accessor: "name",
                                                    style: {color: "#000", textAlign: "left"}

                                                },
                                                {
                                                    Header: "Fee Head",
                                                    accessor: "office",
                                                    style: {color: "#000", textAlign: "left"}

                                                },

                                                {
                                                    Header: "Parent Head",
                                                    accessor: "position",
                                                    style: {color: "#000", textAlign: "left"}

                                                },
                                                {
                                                    Header: "Actions",
                                                    accessor: "actions",
                                                    sortable: false,
                                                    filterable: false,
                                                }
                                            ]}
                                            defaultPageSize={5}
                                            showPaginationBottom={true}
                                            className="-striped -highlight"
                                        >

                                        </ReactTable>
                                    </ItemGrid>
                                </GridContainer>

                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
}
const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#AAAAAA",
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    ...customSelectStyle
};
export default withStyles(extendedFormsStyle)(FeeType);
