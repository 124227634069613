// import {ApolloClient} from "apollo-boost";
// import {ApolloClient} from "apollo-client";
// import {HttpLink} from "apollo-link-http";
// import {InMemoryCache} from "apollo-cache-inmemory";
//
// const cache = new InMemoryCache();
// const link = new HttpLink({
//     uri: "https://graphql.kidzlog.com/graphql"
// });
//
// export const gqlClient = new ApolloClient({
//     cache,
//     link
// });


import {ApolloClient} from "apollo-client";
import {HttpLink} from "apollo-link-http";
import {ApolloLink} from 'apollo-boost';
import {InMemoryCache} from "apollo-cache-inmemory";
import {getAuth, getAuthString,AUTH} from "../../helper/Cookies";

const cache = new InMemoryCache();
const httpLink = new HttpLink({
    uri: "https://kidzql.spiolabs.com/graphql"
});

const authLink = new ApolloLink( (operation, forward) => {
    const token = AUTH();
    operation.setContext({
        headers: {
            // 'Access-Control-Allow-Origin':"http://127.0.0.1:3030",
            auth: token
        }
    })
    return forward(operation);
});

// const authLink = setContext(async (_, {headers}) => {
//     const token = await getToken();
//     console.log(token);
//     return {
//         ...headers,
//         auth: token
//     }
// });

export const gqlClient = new ApolloClient({
    cache,
    link: authLink.concat(httpLink)
});