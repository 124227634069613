import {gql} from "apollo-boost";


export const createTeacherMutation = gql `mutation createTeacher($input: CreateTeacherInput!){
createTeacher(teacher: $input){
    userId
    schoolId
    name
    classTeacher
    classes{
      classId
      subjectId
    }
    contact
    emailId
    activeStatus
    attFor
    createdDateTime
  }
}`;