import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import {Switch, Route, Redirect} from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import {dashRoutes, profileRoutes, headerRoutes} from "../routes/dashboard.jsx";
// import{menuList,profileRoutes, headerRoutes} from "../variables/menus";
//import dashboardRoutes from "../routes/dashboard";

import appStyle from "../assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx";

import image from "assets/img/sidebar-2.jpg";
import logo from "assets/img/KIDZ.png";

import {connect} from "react-redux";
import {USER_TYPE} from "../helper/Cookies";


const switchRoutes = (routes) => (

        <Switch>
        {routes.map((prop, key) => {
            if (prop.redirect)
                return <Redirect from={prop.path} to={prop.pathTo} key={key}/>;
            if (prop.collapse)
                return prop.views.map((prop, key) => {
                    return (
                        <Route path={prop.path} component={prop.component} key={key}/>
                    );
                });
            return <Route path={prop.path} component={prop.component} key={key}/>;
        })
        }
    </Switch>
);

var ps;

class Dashboard extends React.Component {
    state = {
        mobileOpen: false,
        miniActive: false
    };
    handleDrawerToggle = () => {
        this.setState({mobileOpen: !this.state.mobileOpen});
    };

    getRoute() {
        if ((this.props.location.pathname === "/Dashboard/MyProfile") || (this.props.location.pathname === "/Dashboard/Schools") || (this.props.location.pathname === "/Dashboard/Users") || (this.props.location.pathname === "/Dashboard/Configurations"))
            return profileRoutes;
        else if ((this.props.location.pathname === "/Dashboard/ClassRooms") || (this.props.location.pathname === "/Dashboard/Subjects") || (this.props.location.pathname === "/Dashboard/Students") || (this.props.location.pathname === "/Dashboard/Teachers"))
            return headerRoutes;
        else
            return dashRoutes;
    }
componentWillReceiveProps(nextProps, nextContext) {

    const{isLoggedIn,history}=nextProps;
    if (!isLoggedIn) {
        history.push("/");
    }
}

    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            // eslint-disable-next-line
            ps = new PerfectScrollbar(this.refs.mainPanel, {
                suppressScrollX: true,
                suppressScrollY: false
            });
        }
    }

    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
    }

    componentDidUpdate(e) {
        if (e.history.location.pathname !== e.location.pathname) {
            this.refs.mainPanel.scrollTop = 0;
        }
    }

    sidebarMinimize() {
        this.setState({miniActive: !this.state.miniActive});
    }

    handleLogout = () => {
        this.props.history.push('/Logout');
    };

    handleProfile = () => {
        this.props.history.push('/Dashboard/reports');
    };

    render() {
        const {classes, ...rest} = this.props;
        const mainPanel =
            classes.mainPanel +
            " " +
            cx({
                [classes.mainPanelSidebarMini]: this.state.miniActive,
                [classes.mainPanelWithPerfectScrollbar]:
                navigator.platform.indexOf("Win") > -1
            });

        return (
            <div className={classes.wrapper}>
                {USER_TYPE() === 1 && USER_TYPE() === 2 ?
                <Sidebar
                    routes={dashRoutes}
                    logoText={"kidzlog"}
                    logo={logo}
                    image={image}
                    handleDrawerToggle={this.handleDrawerToggle}
                    open={this.state.mobileOpen}
                    color="blue"
                    bgColor="black"
                    miniActive={this.state.miniActive}
                    {...rest}
                />
                    :<Sidebar
                        routes={dashRoutes}
                        logoText={"kidzlog"}
                        logo={logo}
                        image={image}
                        handleDrawerToggle={this.handleDrawerToggle}
                        open={this.state.mobileOpen}
                        color="logoblue"
                        bgColor="white"
                        miniActive={this.state.miniActive}
                        {...rest}
                    />}
                <div className={mainPanel} ref="mainPanel">
                    <Header
                        sidebarMinimize={this.sidebarMinimize.bind(this)}
                        miniActive={this.state.miniActive}
                        routes={headerRoutes}
                        handleDrawerToggle={this.handleDrawerToggle}
                        handleProfile={this.handleProfile}
                        {...rest}
                    />

                    {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}


                    <div className={classes.content}>
                        <div className={classes.container}>{
                            switchRoutes(this.getRoute())
                        }</div>
                    </div>

                    {/*{this.getRoute() ? (
                        <div className={classes.content}>
                            <div className={classes.container}>{switchRoutes(menuList)}</div>
                        </div>
                    ) : (
                        <div className={classes.content}>{switchRoutes(profileRoutes)}</div>
                    )}*/}
                    {/*
                    {this.getRoute() ? <Footer fluid/> : null}
*/}

                    <Footer fluid/>
                </div>
            </div>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapPropsToState = state => {
    // console.log(state);
    return {isLoggedIn: state.LoginAuth.isLoggedIn};
};

export default connect(mapPropsToState, null)(withStyles(appStyle)(Dashboard));
