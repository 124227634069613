import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import Tooltip from "material-ui/Tooltip";

// @material-ui/icons
import School from "@material-ui/icons/School";
import AndroidIcon from "@material-ui/icons/Android";
import DateRange from "@material-ui/icons/DateRange";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import PhoneIphone from "@material-ui/icons/PhoneIphone";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";
import StatsCard from "components/Cards/StatsCard.jsx";
import ChartCard from "components/Cards/ChartCard.jsx";
import IconCard from "../../components/Cards/IconCard.jsx";
import {connect} from "react-redux";
import {
    GetDashboardData,
    GetMobileRegisterData,
    GetCirularData,
    GetAssessmentsData,
    GetHomeWorksData
} from "../../actions/DashboardAction";

import {emailsSubscriptionChart} from "variables/charts";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import CircularIcon from "@material-ui/icons/BlurCircular";
import Assessment from "@material-ui/icons/Assessment";
import AttendanceIcon from "@material-ui/icons/Assignment";
import ContentCopy from "@material-ui/icons/ContentCopy";
import BigCalendar from "react-big-calendar";
import {events} from "../../variables/general";
import ReactEcharts from '../../components/ReactECharts';
import CHARTCONFIG from '../../components/ReactECharts/ChartConfig';
import linetheme, {bartheme,barColor} from "../../components/ReactECharts/ChartThemes";
import ReactTable from "react-table";
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";

const line2 = {};

class Dashboard extends React.Component {
    state = {
        value: 0,
        events: events,
    };
    handleChange = (event, value) => {
        this.setState({value});
    };
    handleChangeIndex = index => {
        this.setState({value: index});
    };

    eventColors(event, start, end, isSelected) {
        var backgroundColor = "event-";
        event.color
            ? (backgroundColor = backgroundColor + event.color)
            : (backgroundColor = backgroundColor + "default");
        return {
            className: backgroundColor
        };
    }

    componentDidMount() {
        const {getSchoolData, getDeviceData, getCirculars, getAssessments, getHomeWorks} = this.props;
        getSchoolData();
        getDeviceData();
        getCirculars();
        getAssessments();
        getHomeWorks(moment().format('DD-MM-YYYY'));
    }

    render() {
        const {classes, isFailed, msg, schoolData, DeviceData, Circulars, Assessments, HomeWorks} = this.props;

        var widget1 = schoolData.widgetOne;
        var widget2 = schoolData.widgetTwo;

        let Works = [];
        HomeWorks.forEach(r => {
            Works.push([
                moment().format('DD-MM-YYYY'),
                r.name,
                r.subjects.toString()
            ])
        });
        let Assess = [];
        Assessments.forEach(r => {
            Assess.push([
                    r.name,
                    r.assessCount
                ]
            )
        });
        let xAxisData=[];
        xAxisData.push("Schools");
        xAxisData.push("Our School");
        xAxisData.push("Android");
        xAxisData.push("IOS");

        let yAxisData=[];
      /*  yAxisData.push({
            data:[widget1 !== undefined ? widget1.totalStudetns : 0,0,0],
            type: 'bar',
            barGap:0,
            barWidth:20,          //  barGap:0,
          //  barMaxWidth:50
        });
        yAxisData.push({
            data:[0,widget2 !== undefined ? widget2.totalStudents :0,0,0],
            type: 'bar',
            barWidth:30,
            barGap:0,
            //  barGap:0,
          //  barMaxWidth:100
        });
        yAxisData.push({
            data:[0,0,DeviceData !== undefined ? DeviceData.androidCount: 0,0],
            type: 'bar',
            barWidth:30,
            barGap:0,
            barMaxWidth:100
        });
        yAxisData.push({
            data:[0,0,0,DeviceData !== undefined ? DeviceData.iosCount: 0],
            type: 'bar',
            barWidth:30,
            barGap:0,
            barMaxWidth:100
        });
*/
        yAxisData.push(widget1 !== undefined ? widget1.totalStudetns : 0);
        yAxisData.push(widget2 !== undefined ? widget2.totalStudents :0);
        yAxisData.push(DeviceData !== undefined ? DeviceData.androidCount: 0);
        yAxisData.push(DeviceData !== undefined ? DeviceData.iosCount: 0);
        return (
            <GridContainer>
                <ItemGrid xs={12} sm={12} md={6}>
                    <IconCard
                        icon={School}
                        iconColor={"liteBlue"}
                        title=" Registrations"
                        content={
                            <GridContainer style={{height: 300}}>
                                <ItemGrid xs={12} sm={12} md={12} style={{fontWeight: 300,fontSize:"16px"}}>

                                        <ReactEcharts
                                           // color={barColor}
                                        option={{
                                            tooltip: {
                                                trigger: 'axis',
                                                axisPointer: {
                                                    type: 'shadow'
                                                }
                                            },
                                            xAxis: {
                                                name:'Registrations',
                                                nameLocation:'center',
                                                type: 'category',
                                                nameTextStyle:{
                                                    fontWeight:400,
                                                    fontSize:14,
                                                },
                                              //  axisTick: {show: false},
                                                nameGap:25,
                                                axisLabel: {
                                                    textStyle: {
                                                        color: CHARTCONFIG.color.text
                                                    }
                                                },
                                                splitLine: {
                                                    lineStyle: {
                                                        color: CHARTCONFIG.color.splitLine
                                                    }
                                                },
                                                data:xAxisData
                                            },
                                            yAxis: {
                                              //  name:'Registrations',
                                                // nameLocation:'center',
                                                nameTextStyle:{
                                                    fontWeight:400,
                                                    fontSize:14
                                                },
                                                nameGap:25,
                                                type: 'value',
                                                axisLabel: {
                                                    textStyle: {
                                                        color: CHARTCONFIG.color.text
                                                    }
                                                },
                                                splitLine: {
                                                    lineStyle: {
                                                        color: CHARTCONFIG.color.splitLine
                                                    }
                                                },
                                                splitArea: {
                                                    show: true,
                                                    areaStyle: {
                                                        color: CHARTCONFIG.color.splitArea
                                                    }
                                                }
                                            },
                                            series:[{
                                            data:yAxisData,
                                            type: 'bar',
                                           /* barGap:0,
                                            barWidth:20, */         //  barGap:0,
                                              barMaxWidth:50
                                        }]
                                        }}
                                        theme={bartheme}
                                        showLoading={false}/>

                                   {/* Total Registrations In {widget1 !== undefined ? widget1.schoolCount + " Schools : " : " "}  <span style={{fontWeight:400,fontSize:'18px'}}>{widget1 !== undefined ? widget1.totalRegs + "/" + (widget1 !== undefined ? widget1.totalStudetns : 0) : 0}</span>
                               <br/>
                               <br/>

                                    Total Registrations In Our School  : <span style={{fontWeight:400,fontSize:'18px'}}>{widget2 !== undefined ? widget2.totalRegs + "/" + (widget2 !== undefined ? widget2.totalStudents : 0) :0}</span>

                                    <br/>
                                    <br/>

                                    Android Registrations In Our School :  <span style={{fontWeight:400,fontSize:'18px'}}>{DeviceData !== undefined ? DeviceData.androidCount: 0}</span>
                                    <br/>
                                    <br/>

                                    IOS Registrations In Our School : <span style={{fontWeight:400,fontSize:'18px'}}>{DeviceData !== undefined ? DeviceData.iosCount: 0}</span>
*/}
                                </ItemGrid>

                            </GridContainer>
                        }
                    />
                </ItemGrid>

              {/*  <ItemGrid xs={12} sm={6} md={6} lg={3}>
                    <StatsCard
                        icon={School}
                        iconColor="red"
                        title={widget1 !== undefined ? widget1.schoolCount + " schools" : 0}
                        description={widget1 !== undefined ? widget1.totalRegs + "/" + (widget1 !== undefined ? widget1.totalStudetns : 0) : 0}
                        // small="Registrations"
                        statIcon={Update}
                        // statIconColor="danger"
                        statText={widget1 !== undefined ? " Total Registrations " + widget1.totalStudetns : 0}
                        style={{height: "200px"}}
                        //  statLink={{ text: "Registrations & School Name", href: "#pablo" }}
                    />
                </ItemGrid>
                <ItemGrid xs={12} sm={6} md={6} lg={3}>
                    <StatsCard
                        icon={School}
                        iconColor="orange"
                        title={widget2 !== undefined ? widget2.schoolName : 0}
                        description={widget2 !== undefined ? widget2.totalRegs + "/" + (widget2 !== undefined ? widget2.totalStudents : 0) : 0}
                        // small="Registrations"
                        statIcon={Update}
                        // statIconColor="danger"
                        statText={widget2 !== undefined ? widget2.totalRegs + " Registrations In " + (widget2 !== undefined ? widget2.schoolName : 0) : 0}
                        style={{height: "200px"}}
                        //  statLink={{ text: "Registrations & School Name", href: "#pablo" }}
                    />
                </ItemGrid>
                <ItemGrid xs={12} sm={6} md={6} lg={3}>
                    <StatsCard
                        icon={AndroidIcon}
                        iconColor="green"
                        title="Android Registrations"
                        description={DeviceData.androidCount}
                        small={<br/>}
                        statIcon={Update}
                        statText={widget2 !== undefined ? DeviceData.androidCount + " in " + widget2.schoolName : 0}
                    />
                    <br/>
                </ItemGrid>
                <ItemGrid xs={12} sm={6} md={6} lg={3}>
                    <StatsCard
                        icon={PhoneIphone}
                        iconColor="blue"
                        title="IOS Registrations"
                        description={DeviceData.iosCount}
                        small={<br/>}
                        statIcon={Update}
                        statText={widget2 !== undefined ? DeviceData.iosCount + " in " + widget2.schoolName : 0}
                    />
                    <br/>
                </ItemGrid>*/}
                <ItemGrid xs={12} sm={12} md={6}>
                    <IconCard
                        icon={CircularIcon}
                        iconColor={"primary"}
                        title=" Circulars"
                        content={
                            <GridContainer style={{height: 300, overflowY: 'auto', overflowScrolling: "touch"}}>
                                <ItemGrid xs={12} sm={12} md={12}>

                                    {Circulars.map((object, i) => {
                                        return (
                                            <GridContainer>
                                                <ItemGrid xs={12} md={12}>
                                                    <legend align="right" style={{
                                                        fontSize: "14px",
                                                        fontFamily: "bold",
                                                        color: "#00acc1"
                                                    }}>{moment(object.dateOfCircular).format('DD/MM/YYYY')}
                                                    </legend>
                                                </ItemGrid>
                                                <ItemGrid xs={12} md={12}>
                                                    <legend style={{fontSize: "14px"}}>{object.description}
                                                    </legend>
                                                    <ItemGrid xs={12} style={{
                                                        height: 1,
                                                        backgroundColor: "#ddd",
                                                        marginTop: "15px"
                                                    }}/>

                                                </ItemGrid>
                                            </GridContainer>
                                        );
                                    })}
                                </ItemGrid>
                            </GridContainer>
                        }
                    />
                </ItemGrid>
                <ItemGrid xs={12} sm={12} md={6}>
                    <IconCard
                        icon={Assessment}
                        title="Assessments"
                        iconColor="primary"
                        content={
                            <div style={{height: 300, overflowY: 'auto', overflowScrolling: "touch"}}>
                                <Table
                                    tableHead={["Class", "No Of Assessments"]}
                                    tableData={Assess}
                                />
                            </div>

                        }
                    />

                </ItemGrid>
                <ItemGrid xs={12} sm={12} md={6}>
                    <IconCard
                        icon={ContentCopy}
                        title="Home Works"
                        iconColor="liteBlue"
                        content={
                            <div style={{height: 300, overflowY: 'auto', overflowScrolling: "touch"}}>
                                {/*<ReactTable*/}
                                {/*    data={Works}*/}
                                {/*    style={{color: "#00acc1"}}*/}
                                {/*    columns={HomeWorkColumns}*/}
                                {/*    defaultPageSize={5}*/}
                                {/*    showPaginationBottom={false}*/}
                                {/*    className="-striped -highlight"*/}
                                {/*/>*/}
                                <Table
                                    tableHead={["Date", "Class", "Subjects"]}
                                    tableData={Works}
                                />
                            </div>

                        }
                    />

                </ItemGrid>
            </GridContainer>

        );
    }
}

line2.options = {
    tooltip: {
        trigger: 'axis'
    },
    calculable: true,

    xAxis: [
        {
            type: 'category',
            boundaryGap: false,
            data: ['Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
            axisLabel: {
                textStyle: {
                    color: CHARTCONFIG.color.text
                }
            },
            splitLine: {
                lineStyle: {
                    color: CHARTCONFIG.color.splitLine
                }
            }
        }
    ],
    yAxis: [
        {
            type: 'value',
            axisLabel: {
                textStyle: {
                    color: CHARTCONFIG.color.text
                }
            },
            splitLine: {
                lineStyle: {
                    color: CHARTCONFIG.color.splitLine
                }
            },
            splitArea: {
                show: true,
                areaStyle: {
                    color: CHARTCONFIG.color.splitArea
                }
            }
        }
    ],
    series: [
        {
            name: 'Class A',
            type: 'line',
            stack: 'Sum',
            data: [3, 5, 4, 5, 4, 4]
        },
        {
            name: 'Class B',
            type: 'line',
            stack: 'Sum',
            data: [2, 3, 1, 3, 2, 2]
        },
        {
            name: 'Class C',
            type: 'line',
            stack: 'Sum',
            data: [4, 2, 0, 2, 4, 2]
        }

    ]
};

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {

    const {isFailed, msg, schoolData, DeviceData, Circulars, Assessments, HomeWorks} = state.Dashboard;
    return {isFailed, msg, schoolData, DeviceData, Circulars, Assessments, HomeWorks};
}


export default connect(mapStateToProps, {
    getSchoolData: GetDashboardData,
    getDeviceData: GetMobileRegisterData,
    getCirculars: GetCirularData,
    getAssessments: GetAssessmentsData,
    getHomeWorks: GetHomeWorksData,
})
(withStyles(dashboardStyle)(Dashboard))
