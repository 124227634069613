import {
    CREATE_SUBJECT_FAIL,
    CREATE_SUBJECT_SUCCESS, SUBJECT_LIST,
    UPDATE_SUBJECT_FAIL,
    UPDATE_SUBJECT_SUCCESS
} from "../actions/types";


const INIT_STATE = {isFailed: false, msg: null, msgColor: "danger", dataTable: []};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CREATE_SUBJECT_SUCCESS:
            return{...state,isFailed: true,msg:action.payLoad,msgColor: "success"};
        case CREATE_SUBJECT_FAIL:
            return{...state,isFailed:true,msg:action.payLoad};
        case UPDATE_SUBJECT_SUCCESS:
            return{...state,isFailed: true,msg:action.payLoad,msgColor: "success"};
        case UPDATE_SUBJECT_FAIL:
            return{...state,isFailed:true,msg:action.payLoad};
        case SUBJECT_LIST:
            return {...state, dataTable: action.payLoad};
        default:
            return INIT_STATE;

    }
}