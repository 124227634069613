import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";

// @material-ui/icons

import Close from "@material-ui/icons/Close";

import Print from "@material-ui/icons/Print";
import Amount from "@material-ui/icons/AccountBalance";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";

import {dataTable} from "variables/general.jsx";
import Button from "../../components/CustomButtons/Button";
import withStyles from "material-ui/styles/withStyles";
import InputLabel from "material-ui/Input/InputLabel";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import TextField from "@material-ui/core/TextField/TextField";
import SelectTemp from 'react-select';
import {DisplayClassList} from "../../actions/ClassRoomAction";
import {GetChildList} from "../../actions/AssessmentAction";
import {GetFees, GetReceipts, CreateFeeReceipt, HideMsg} from "../../actions/FeeReceipt"
import {connect} from "react-redux";
import {liteBlue} from "../../assets/jss/material-dashboard-pro-react";
import RegularCard from "../../components/Cards/RegularCard";
import CustomInput from "../../components/CustomInput/CustomInput";
import Snackbar from "../../components/Snackbar/Snackbar";


class FeeReceipt extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectClass: "",
            selectChild: "",
            showFeeDeatils: false,
        }

    }

    handleCreate = () => {
        //alert();
        this.setState({
            selectClass: "",
            selectChild: "",
            showFeeDeatils: false
        });
       window.location.reload();


    };
    onChangeValue = (event) => {

        this.setState({[event.target.id]: event.target.value});

    };
    handleSimple = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleChange(value) {
        this.setState({selectClass: value.value});
        const {ChildList} = this.props;
        let GetChildArray = [];
        GetChildArray.push(value.value);
        ChildList(GetChildArray);
    }

    handleStudentChange(value) {

        this.setState({selectChild: value.value, showFeeDeatils: true})
        const {getFeeReceipts,getFeeData} = this.props;
        getFeeReceipts(value.value,"");
        getFeeData(this.state.selectClass);

    }


    CreateFeeReceipt = (data, i) => {
        const {selectChild} = this.state;
        const {FeeReceiptCreate} = this.props;
        const termDetails = data.find(a => a.term === i);
        let termName = termDetails.termName;
        let term = i + 1;
        const items = [];
        var durAmount = 0, total = 0;
        termDetails.fee.forEach(f => {
            total = total + f.value;
            items.push({
                name: f.title,
                amount: f.value,
            })
        });
        durAmount = total - termDetails.paidAmount;
        if (this.state["dueAmount" + (i + 1)] === undefined) {
            var amount = durAmount;
        } else {
            var amount = this.state["dueAmount" + (i + 1)];
        }
       // console.log("terrm", termDetails, i + 1, selectChild, parseInt(amount), items, termName);
        FeeReceiptCreate(selectChild, term, termName, parseInt(amount), items)
    }

    componentDidMount() {
        const {ClassList} = this.props;
        ClassList();
    }

    getTheme = (theme) => ({
        ...theme,
        borderRadius: '4px',
        height: "20px",
        colors: {
            ...theme.colors,
            //  primary25: '#00ABDC',
            primary: liteBlue,
            //   primary50:"#00ABDC"
        },
    });
    timeInfo = () => {
        const {HideMsg, ClassList} = this.props;
        setTimeout(() => {
            HideMsg();
            ClassList();
            this.handleCreate();
        }, 3000)
    };

    render() {
        const {classes, classList, classWiseList, FeeList, FeeReceiptData, msg, msgColor, isFailed, HideMsg} = this.props;
        if (isFailed === true && msg !== null) {
            if (msgColor === "success") {
                this.timeInfo();
            } else {
                setTimeout(() => {
                    HideMsg();
                }, 2000)
            }
        }
        let ClassNames = [{value:"",label:"select..."}], childNames = [{value:"",label:"select..."}];
        const data = [];

        if (FeeList !== null && FeeList.length > 0) {
            const feeData = FeeList[0];
            Array.from({length: feeData.terms}).forEach((_, index) => {
                let termF = {term: index, fee: [], paidAmount: 0 ,dueAmt:0};
                var tot = 0;
                feeData.fee.forEach(f => {
                    tot = tot + f.value[index];
                    termF.fee.push({
                        title: f.head,
                        value: f.value[index],

                    });
                });
                termF.totAmount = tot;
                termF.lastDate = feeData.lastDates[index];
                termF.termName = feeData.fee[0].termName;
                var amt = 0;
                FeeReceiptData.forEach(prop => {
                    if (index + 1 === prop.term) {
                        amt = amt + prop.amount
                    }
                });
                termF.paidAmount = amt;
                termF.dueAmt = termF.totAmount - termF.paidAmount;
                data.push(termF);
            });
        }


        if (classList !== null) {
            classList.forEach(r => {
                const {name, classId} = r;
                ClassNames.push({
                    value: classId,
                    label: name
                })
            })
        }
        if (classWiseList !== null) {
            classWiseList.forEach(r => {
                const {name, childId} = r;
                childNames.push({
                    value: childId,
                    label: name
                })
            })

        }
        return (
            <GridContainer justify="center">
                <GridContainer>
                    <ItemGrid xs={12} sm={6} md={4}>
                        <Snackbar
                            open={isFailed}
                            place={"tc"}
                            color={msgColor}
                            message={msg}
                        />
                    </ItemGrid>
                </GridContainer>
                <ItemGrid xs={12} sm={12}>
                    <IconCard
                        icon={Amount}
                        title="Generate Fee Receipt"
                        iconColor="rose"
                        content={
                            <form>
                                <GridContainer justify=" center">

                                    <ItemGrid xs={12} sm={6}>
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={6}>
                                                <InputLabel className={classes.label}>
                                                    Select Class *
                                                </InputLabel>
                                                <SelectTemp
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                   // value={this.state.selectClass}
                                                    // defaultValue={options[0]}
                                                    name="selectClass"
                                                    options={ClassNames}
                                                    onChange={this.handleChange.bind(this)}
                                                    theme={(theme) => this.getTheme(theme)}
                                                />
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>
                                                <InputLabel className={classes.label}>
                                                    Select Child
                                                </InputLabel>
                                                <SelectTemp
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    // defaultValue={options[0]}
                                                    name="selectChild"
                                                    options={childNames}
                                                    onChange={this.handleStudentChange.bind(this)}
                                                    // onChange={(option)=>{
                                                    //     this.setState({selectChild:option.value})
                                                    // }}
                                                    theme={(theme) => this.getTheme(theme)}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                </GridContainer>
                                {data.length > 0 && data!=="" && data !== undefined && this.state.showFeeDeatils ?
                                    data.map((prop, i) => {
                                        return (
                                            <ItemGrid>
                                                <RegularCard
                                                    cardTitle={"Term" + (i + 1)}
                                                    content={
                                                        <GridContainer justify=" center">
                                                            {prop.fee.map(r => {
                                                                return (
                                                                    <ItemGrid xs={12} sm={12}>
                                                                        <GridContainer>
                                                                            <ItemGrid xs={3} sm={3}>
                                                                                {r.title}
                                                                            </ItemGrid>
                                                                            <ItemGrid xs={3} sm={3}>
                                                                                {" : " + r.value}
                                                                            </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid>
                                                                )
                                                            })}

                                                            <ItemGrid xs={12} sm={12}>
                                                                <GridContainer>
                                                                    <ItemGrid xs={12} sm={12}>
                                                                        <GridContainer>
                                                                    <ItemGrid xs={3} sm={3}>
                                                                        <InputLabel>
                                                                            Total Amount
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={3} sm={3} >
                                                                        <InputLabel>
                                                                            {" : " + prop.totAmount}
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={12}>
                                                                        <GridContainer>
                                                                    <ItemGrid xs={3} sm={3} >
                                                                        <InputLabel>
                                                                            Paid Amount
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                    <ItemGrid  xs={3} sm={3}>
                                                                        <InputLabel>
                                                                            {" : " + prop.paidAmount}
                                                                        </InputLabel>
                                                                    </ItemGrid>
                                                                        </GridContainer>
                                                                    </ItemGrid>
                                                                    <ItemGrid xs={12} sm={12} >
                                                                    <GridContainer style={{marginLeft:"0px"}}>
                                                                    <ItemGrid xs={3} sm={3}>
                                                                        {prop.totAmount === prop.paidAmount ?
                                                                            null
                                                                            : <GridContainer>
                                                                                <CustomInput
                                                                                    labelText="Due Amount"
                                                                                    id={"dueAmount" + (i + 1)}
                                                                                    onChange={this.onChangeValue}
                                                                                    formControlProps={{
                                                                                        fullWidth: true
                                                                                    }}
                                                                                    defValue={prop.dueAmt}
                                                                                    inputProps={{
                                                                                        type: "number",
                                                                                        value: this.state["dueAmount" + (i + 1)]
                                                                                    }}
                                                                                />
                                                                            </GridContainer>}
                                                                    </ItemGrid>
                                                                    {prop.paidAmount === prop.totAmount ?
                                                                        <ItemGrid >
                                                                            <InputLabel>
                                                                                Status : Paid
                                                                            </InputLabel>
                                                                        </ItemGrid> :
                                                                        <ItemGrid >
                                                                            <Button color="success"
                                                                                    onClick={() => {
                                                                                        this.CreateFeeReceipt(data, i)
                                                                                    }}>Pay</Button>
                                                                        </ItemGrid>}
                                                                    </GridContainer>
                                                                    </ItemGrid>
                                                                </GridContainer>
                                                            </ItemGrid>
                                                        </GridContainer>
                                                    }
                                                />
                                            </ItemGrid>
                                        )

                                    })
                                    : null}
                            </form>

                        }
                    />
                </ItemGrid>
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#AAAAAA",
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    ...customSelectStyle
};

const mapStateToProps = state => {
    const {msg, msgColor, isFailed, classList, classWiseList, FeeList, FeeReceiptData} = state.FeeReceipt;
    return {msg, msgColor, isFailed, classList, classWiseList, FeeList, FeeReceiptData}
};

export default connect(mapStateToProps, {
    FeeReceiptCreate: CreateFeeReceipt, HideMsg,
    ChildList: GetChildList,
    ClassList: DisplayClassList,
    getFeeData: GetFees,
    getFeeReceipts: GetReceipts
})(withStyles(extendedFormsStyle)(FeeReceipt));
