
import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

import Close from "@material-ui/icons/Close";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import People from "@material-ui/icons/PersonAdd";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import {schoolCatagoryNew, userList, userListNew} from "../../variables/menus";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import withStyles from "material-ui/styles/withStyles";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "material-ui/Select";
import MenuItem from "material-ui/Menu/MenuItem";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import {CreateUserAction,HideMsg,DisplayUserList} from "../../actions/UserAction";
import {connect} from "react-redux";
import Snackbar from "../../components/Snackbar/Snackbar";
import {USER_TYPE} from "../../helper/Cookies";
import SelectTemp from "react-select";
import {liteBlue} from "../../assets/jss/material-dashboard-pro-react";

class Users extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            name:null,
            uName:null,
            contact:null,
            uEmail:null,
            uType: "",
            file: null,
            fileName:"No file Chosen",
            imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
            isCreated: false,
            columns:[]
        }
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
    }
    handleCreate=()=>{
        //alert();
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
    };
    handleSimple = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    handleFileClick() {
        var input = document.createElement("input");
        input.type = "file";
        input.onchange = this.handleImageChange;
        input.click();
    }
    handleImageChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                fileName:file.name,
                imagePreviewUrl: reader.result
            });
        };
        reader.readAsDataURL(file);
    }
    onChangeValue = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        })
    };

    userCreate = () => {
        const {CreateUser} = this.props;
        const {name,uName,contact,uEmail,uType,file} = this.state;
        console.log("state",this.state);
        CreateUser(name,contact,uEmail,uName,uType,file);

    };
    timeInfo = () => {
        const {HideMsg,StudentList} = this.props;
        setTimeout(() => {
            HideMsg();
            this.handleCreate();
        }, 3000)
    }
    componentDidMount() {
        const{UsersList}=this.props;
        UsersList();
    }
    getTheme=(theme) => ({
        ...theme,
        borderRadius: '4px',
        height: "20px",
        colors: {
            ...theme.colors,
            //  primary25: '#00ABDC',
            primary:liteBlue,
            //   primary50:"#00ABDC"
        },
    });

    render(){
        const { classes,isFailed,msg,msgColor,userTable } = this.props;
        if (isFailed === true && msg !== null) {
            if(msgColor === "success"){
                this.timeInfo();
            }else{
                setTimeout(() => {
                    HideMsg();
                }, 2000)
            }
        }
        console.log("sadik",userTable);
        let ListUsers = [];
        let coulmn=[];
        const userType=USER_TYPE();
        if (userTable.length > 0) {
            if(userType===5){
                coulmn=[
                    {
                        Header: "Name",
                        accessor: "name",
                        style: {color: "#000", textAlign: "left"}

                    },
                    {
                        Header: "User Name",
                        accessor: "uname",
                        style: {color: "#000", textAlign: "left"}

                    },

                    {
                        Header: "Contact No",
                        accessor: "contact",
                        style: {color: "#000", textAlign: "left"}

                    },
                    {
                        Header: "Email",
                        accessor: "email",
                        style: {color: "#000", textAlign: "left"}

                    },
                    {
                        Header: "Role",
                        accessor: "type",
                        style: {color: "#000", textAlign: "left"}

                    },

                ]
            }else{
                coulmn=[
                    {
                        Header: "Name",
                        accessor: "name",
                        style: {color: "#000", textAlign: "left"}

                    },
                    {
                        Header: "User Name",
                        accessor: "uname",
                        style: {color: "#000", textAlign: "left"}

                    },

                    {
                        Header: "Contact No",
                        accessor: "contact",
                        style: {color: "#000", textAlign: "left"}

                    },
                    {
                        Header: "Email",
                        accessor: "email",
                        style: {color: "#000", textAlign: "left"}

                    },
                    {
                        Header: "Role",
                        accessor: "type",
                        style: {color: "#000", textAlign: "left"}

                    },
                    {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                    }
                ]
            }
            userTable.forEach(r => {
                const {name,uname,contact,email,type} = r;
                    ListUsers.push({
                        name: name,
                        uname: uname,
                        contact: contact,
                        email: email,
                        type: type,
                        actions: (
                            <div className="actions-right">
                                <IconButton
                                    onClick={() => {
                                       //  this.handleUpdateSchool(schoolId,name,code,address,city,country,contact,phone,email,website,socialLinks,logoUrl,menus);
                                    }}
                                    color="primaryNoBackground"
                                    customClass="edit">
                                    <Edit/>
                                </IconButton>{" "}
                                <IconButton
                                    onClick={() => {

                                    }}
                                    color="dangerNoBackground"
                                    customClass="remove">
                                    <Close/>
                                </IconButton>
                            </div>
                        )
                    })

            })
        }
        return (
            <GridContainer justify="center">
                <GridContainer>
                    <ItemGrid xs={12} sm={6} md={4}>
                        <Snackbar
                            open={isFailed}
                            place={"tc"}
                            color={msgColor}
                            message={msg}
                        />
                    </ItemGrid>
                </GridContainer>
                {this.state.isCreated ?
                    <ItemGrid xs={12} sm={12} >
                        <IconCard
                            icon={People}
                            title="Create User"
                          //  iconColor="rose"
                            content={
                                <form>
                                    <CustomInput
                                        labelText="Name*"
                                        id="name"
                                        onChange={this.onChangeValue}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                    <GridContainer>
                                        <ItemGrid xm={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText=" User Name*"
                                                id="uName"
                                                onChange={this.onChangeValue}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                            />

                                        </ItemGrid>
                                        <ItemGrid xm={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="Contact"
                                                id="contact"
                                                onChange={this.onChangeValue}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                            />

                                        </ItemGrid>
                                    </GridContainer>
                                    <CustomInput
                                        labelText="Email Id"
                                        id="uEmail"
                                        onChange={this.onChangeValue}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                    <InputLabel
                                        htmlFor="simple-select"
                                        className={classes.label}
                                    >
                                        Select Role
                                    </InputLabel>

                                    <SelectTemp
                                        className="basic-single"
                                        classNamePrefix="select"
                                        // defaultValue={options[0]}
                                        name="uType"
                                        options={userListNew}
                                        onChange={(option)=>{
                                            this.setState({uType:option.value})
                                        }}
                                        theme={(theme) =>this.getTheme(theme)}
                                    />


{/*
                                    <FormControl
                                        fullWidth
                                        className={classes.selectFormControl}
                                    >
                                        <InputLabel
                                            htmlFor="simple-select"
                                            className={classes.selectLabel}
                                        >
                                            Select Role
                                        </InputLabel>
                                        <Select
                                            MenuProps={{
                                                className: classes.selectMenu
                                            }}
                                            classes={{
                                                select: classes.select
                                            }}
                                            value={this.state.uType}
                                            onChange={this.handleSimple}
                                            inputProps={{
                                                name: "uType",
                                                id: "uType"
                                            }}
                                        >
                                            <MenuItem
                                                disabled
                                                classes={{
                                                    root: classes.selectMenuItem
                                                }}
                                            >
                                                Select Role
                                            </MenuItem>
                                            {userList !== null ?
                                                userList.map((data) =>
                                                    <MenuItem
                                                        key={data.userId}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelected
                                                        }}
                                                        value={data.userId}
                                                    >
                                                        {data.userName}
                                                    </MenuItem>
                                                )
                                                : null}
                                        </Select>
                                    </FormControl>
*/}
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={6}>
                                            <CustomInput
                                                labelText="Profile Pic"
                                                id="profile_pic"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    disabled: true,
                                                    value:""+this.state.fileName
                                                }}
                                            />
                                        </ItemGrid>
                                        <ItemGrid>
                                            <Button  onClick={this.handleFileClick}>Choose File</Button>
                                        </ItemGrid>


                                    </GridContainer>

                                    <GridContainer justify="center">

                                        <ItemGrid>
                                            <br/>
                                            <Button color="primary" onClick={this.userCreate}>Save</Button>
                                            <br/>
                                        </ItemGrid>
                                        <ItemGrid>
                                            <br/>
                                            <Button color="danger" onClick={this.handleCreate}  >Cancel</Button>
                                            <br/>
                                        </ItemGrid>

                                    </GridContainer>
                                </form>

                            }
                        />
                    </ItemGrid> :
                    <ItemGrid xs={12}>
                        <IconCard
                            icon={People}
                         //   iconColor={"rose"}
                            title={
                                <div>
                                    Users
                                    {userType!==5?
                                    <Button
                                        onClick={this.handleCreate}
                                        // round color="white"
                                        color="primary" right={true}> Add User
                                    </Button>
                                        :null}
                                </div>
                            }
                            content={
                                <GridContainer >
                                  {/*  <ItemGrid xs={12} sm={11}>

                                        <legend>   </legend>
                                    </ItemGrid>
                                    <ItemGrid xs={1} sm={1}>

                                        <IconButton
                                            onClick={this.handleCreate}
                                            // round color="white"
                                            color="rose">
                                            <AddIcon />
                                        </IconButton>
                                    </ItemGrid>*/}
                                    <ItemGrid xs={12}>
                                        <ReactTable
                                            data={ListUsers}
                                            filterable
                                            style={{color: "#00acc1"}}
                                            columns={coulmn}
                                            defaultPageSize={5}
                                            showPaginationBottom={true}
                                            className="-striped -highlight"
                                        />
                                    </ItemGrid>
                                </GridContainer>

                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
}
const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color:liteBlue,
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex"
    },
    ...customSelectStyle
};
const  mapStateToProps =state=>{
    const{isFailed,msg,msgColor,userTable} = state.User;
    return{isFailed,msg,msgColor,userTable}
}
export default connect(mapStateToProps,{
    CreateUser:CreateUserAction,HideMsg,
    UsersList:DisplayUserList,
}) (withStyles(extendedFormsStyle)(Users));
