import {
    CLASS_LSIT,
    CLASSWISE_STUDENT_LIST, DELETE_FAILED, DELETE_SUCCESS,
    LIST_MESSAGES,
    SEND_MESSAGE_FAIL,
    SEND_MESSAGE_SUCCESS
} from "../actions/types";

const INIT_STATE = {isFailed: false, msg: null, msgColor: "danger", MessageTable: [],classList:[],classWiseList:[],deleteSuccess:false};

export default (state=INIT_STATE,action)=>{
    switch (action.type) {
        case CLASS_LSIT:
            return {...state, classList: action.payLoad};
        case CLASSWISE_STUDENT_LIST:
            return{...state,classWiseList:action.payLoad};
        case SEND_MESSAGE_SUCCESS:
            return {...state,isFailed: true,msg: action.payLoad, msgColor: "success"};
        case SEND_MESSAGE_FAIL:
            return {...state, isFailed: true, msg: action.payLoad};
        case LIST_MESSAGES:
            return{...state,MessageTable:action.payLoad};
        case DELETE_SUCCESS:
            return {...state,isFailed: true,msg: action.payLoad, msgColor: "success",deleteSuccess:true};
        case DELETE_FAILED:
            return {...state,isFailed: true,msg: action.payLoad};
        default:
            return INIT_STATE
    }
}
