import schoolAPi from "kidzwapi";
import {
    DELETE_FAILED,
    DELETE_SUCCESS,
    HIDE_INFO_MSG,
    LIST_MARKS,
    UPLOAD_MARKS_FAIL,
    UPLOAD_MARKS_SUCCESS
} from "../actions/types";
import {AUTH, SCHOOL_ID, USER_ID} from "../helper/Cookies";

function UploadMarksSuccess(){
    return{
        type:UPLOAD_MARKS_SUCCESS,
        payLoad:"Marks Uploaded Successfully"
    }
}

function UploadMarksFailed(msg){
    return{
        type:UPLOAD_MARKS_FAIL,
        payLoad: msg
    }
}
function DeleteExamSuccess(){
    return{
        type:DELETE_SUCCESS,
        payLoad:"Exam Deleted Successfully"
    }
}

function DeleteExamFailed(msg){
    return{
        type:DELETE_FAILED,
        payLoad:msg
    }
}

function hideMsg(){
    return{
        type:HIDE_INFO_MSG,
        payLoad:null
    }
}
function ListExams(json){
    return{
        type:LIST_MARKS,
        payLoad:json
    }
}
export function HideMsg(){
    return function(dispatch){
        dispatch(hideMsg())
    }
}
export function UploadMarksAction(classId,examTittle,maxMarks,minMarks,file,fileType){
    return function (dispatch){
        if((fileType==="application/vnd.ms-excel" || fileType==="text/csv") && file!==null){
            if(classId!==0 && examTittle!==null && maxMarks!==null && minMarks!==null){
                return schoolAPi.uploadMarks(parseInt(SCHOOL_ID()),classId,file,examTittle,maxMarks,minMarks,USER_ID(),AUTH())
                    .then(resp=>{
                        if(resp.status){
                            dispatch(UploadMarksSuccess());
                        }else{
                            dispatch(UploadMarksFailed(resp.msg));
                        }
                    })
            }else{
                dispatch(UploadMarksFailed("Enter All fileds"))
            }
        }else{
            dispatch(UploadMarksFailed("Please Upload Csv File Only"))
        }
    }
}

export function DisplayMarks(classId){
    return function(dispatch){
        return schoolAPi.uploadMarksList(parseInt(SCHOOL_ID()),classId,USER_ID(),AUTH())
            .then(resp=>{
                if(resp.status){
                    dispatch(ListExams(resp.marks));
                }
            })
    }
}

export function DeleteMarks(examId,marksId){
    return function(dispatch){
        return schoolAPi.deleteMarks(examId,marksId,USER_ID(),AUTH())
            .then(resp=>{
                if(resp.status){
                    dispatch(DeleteExamSuccess());
                }else{
                    dispatch(DeleteExamFailed(resp.msg))
                }
            })
    }
}
