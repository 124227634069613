import schoolAPi from "kidzwapi";
import {
    CREATE_ASSESSMENT_FAIL,
    CREATE_ASSESSMENT_SUCCESS,
    HIDE_INFO_MSG,
    CLASSWISE_STUDENT_LIST,
    ASSESSMENT_LIST, DELETE_SUCCESS,
} from "./types";
import {AUTH, SCHOOL_ID, USER_ID} from "../helper/Cookies";

function CreateAssessmentSuccess(){
    return{
        type:CREATE_ASSESSMENT_SUCCESS,
        payLoad:"Assessment Created Successfully"
    }
}

function CreateAssessmentFailed(msg){
    return{
        type:CREATE_ASSESSMENT_FAIL,
        payLoad: msg
    }
}

function DeleteAssessmentSuccess(){
    return{
        type:DELETE_SUCCESS,
        payLoad:"Assessment Deleted Successfully"
    }
}
function DeleteAssessmentFailed(msg){
    return{
        type:DELETE_SUCCESS,
        payLoad:msg
    }
}

function hideMsg(){
    return{
        type:HIDE_INFO_MSG,
        payLoad:null
    }
}
function getStudents(json){
    return{
        type:CLASSWISE_STUDENT_LIST,
        payLoad:json
    }
}
function AssessmentList(json){
    return{
        type:ASSESSMENT_LIST,
        payLoad:json
    }
}

export function HideMsg() {
    return function(dispatch){
        dispatch(hideMsg());
    }

}

export function GetChildList(classId){
    return function (dispatch){
        return schoolAPi.classWiseStudents(parseInt(SCHOOL_ID()),classId, USER_ID(), AUTH())
            .then(resp=>{
                if(resp.status){
                    dispatch(getStudents(resp.students));
                }
            })

    }
}

export function CreateAssessmentAction(childId,classId,aName,aDate,file,fileType){
    return function (dispatch){
        if(childId!==0 && classId!==0 && file !==null ){
            if(fileType === "application/pdf"){
                return schoolAPi.createAssessment(parseInt(SCHOOL_ID()),childId,classId,aName,aDate,file,USER_ID(), AUTH())
                    .then(resp=>{
                        if(resp.status){
                            dispatch(CreateAssessmentSuccess());
                        }else{
                            dispatch(CreateAssessmentFailed(resp.msg));
                        }
                    })
            }else{
                dispatch(CreateAssessmentFailed("Please select Pdf file Only"))
            }
        }else{
            dispatch(CreateAssessmentFailed("Enter All Fields"))
        }
    }
}

export function DisplayAssessmentList(FromDate,ToDate,classId){
    return function (dispatch){
        return schoolAPi.listAssessment(parseInt(SCHOOL_ID()),classId, USER_ID(),FromDate,ToDate, AUTH())
            .then(resp=>{
                if(resp.status){
                    dispatch(AssessmentList(resp.assessments));
                }
            })

    }
}
export function DeleteAssessment(assessmentId){
    console.log("assess req",assessmentId, USER_ID(), AUTH())
    return function (dispatch){
        return schoolAPi.deleteAssessment(assessmentId, USER_ID(), AUTH())
            .then(resp=>{
                if(resp.status){
                    console.log("assess resssss",resp);
                    dispatch(DeleteAssessmentSuccess());
                }else{
                    dispatch(DeleteAssessmentFailed(resp.msg));

                }
            })

    }
}
