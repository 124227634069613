import {gql} from "apollo-boost";

// export const getFeeQuery = gql ``;
export const getFee = gql `query fees($schoolId: Int!, $classId: Int){
  fees(schoolId: $schoolId, classId: $classId){
    _id
    schoolId
    classId
    terms
    lastDates
    fee{
      head
      termName
      value
      total
    }
    validTo
    validFrom
    createdAt
    class{
    name
    }
  }
}`;
