import React from "react";

// material-ui components

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";

import PictureUpload from "../../components/CustomUpload/PictureUpload";
import Button from "../../components/CustomButtons/Button";
import {USER_ID} from "../../helper/Cookies";
import Popup from"../HeaderPages/PopUp";
import {connect} from "react-redux";
import {DisplayMyProfile,CreateMyProfilePassword,HideMsg} from"../../actions/MyProfileAction";
import withStyles from "material-ui/styles/withStyles";
// import {CreateStudentPassword, DisplayStudentList, HideMsg} from "../../actions/StudentAction";
import Snackbar from "../../components/Snackbar/Snackbar";
import InputLabel from "material-ui/Input/InputLabel";
import {liteBlue} from "../../assets/jss/material-dashboard-pro-react";

class UserProfile extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      npass:"",
      cpass:"",
      showPopup:false,

    }
  }
    handleCreate=()=>{
        this.setState({
            npass:"",
            cpass:"",
            showPopup: false,
        });
    };
   togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup
    });
  }
  onChangeValue = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    })
  };
  CreatePassword() {
    const{npass,cpass}=this.state;
    const{UserPassword}=this.props;
    UserPassword(npass,cpass);
  }
    timeInfo = () => {
        const {HideMsg} = this.props;
        setTimeout(() => {
            HideMsg();
            this.handleCreate();
        }, 3000)
    }

  //
  componentDidMount() {
    const {getMyProfile} = this.props;
    getMyProfile();
  }
  render(){

      const{isFailed,msgColor ,msg,HideMsg,myProfileData,classes}=this.props;
console.log("sadaik",myProfileData)
      if (isFailed === true && msg !== null) {
          if(msgColor === "success"){
              this.timeInfo();
          }else{
              setTimeout(() => {
                  HideMsg();
              }, 2000)
          }
      }

  return (
      <div>
        <GridContainer justify="center">
            <GridContainer>
                {msg !== null?
                    <ItemGrid xs={12} sm={6} md={4}>
                        <Snackbar
                            open={isFailed}
                            place={"tc"}
                            color={msgColor}
                            message={msg}
                        />
                    </ItemGrid>
                :null}
            </GridContainer>
          <ItemGrid xs={12} sm={12} md={12}>
            {this.state.showPopup ?
                <Popup
                    closePopup={this.togglePopup.bind(this)}
                    onClickCreate={this.CreatePassword.bind(this)}
                    handleInput={this.onChangeValue}
                />

                : null
            }
            <IconCard
                icon={PermIdentity}
              //  iconColor="rose"
                // title="Tania Andrew"
                // category=" Edit your profile"
                title={

                  <div>
                    {/*{this.state.tittle}*/}
                    {/*{console.log("childdddddd",childId)}*/}
                    {(USER_ID() !== null) ?
                        <Button
                            onClick={this.togglePopup.bind(this)}
                            // round color="white"
                            color="primary" right={true}> Change Password
                        </Button>
                        : null}
                  </div>
                }
                content={
                  <form>
                    <GridContainer>

                        <ItemGrid xs={12} sm={12} md={8}>
                            <InputLabel className={classes.label}>
                                School Name
                            </InputLabel>

                        <CustomInput
                           // id="company-disabled"
                            inputProps={{
                                disabled: true,
                                value:myProfileData.name,
                            }}

                            formControlProps={{
                              fullWidth: true
                            }}
                        />

                      </ItemGrid>

                        <ItemGrid xs={12} sm={12} md={8}>
                            <InputLabel className={classes.label}>
                                Email Address
                            </InputLabel>

                            <CustomInput
                                // labelText="Email address"
                                id="email-address"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    disabled: true,
                                    value:myProfileData.email
                                }}
                            />

                        </ItemGrid>

                        <ItemGrid xs={12} sm={12} md={4}>
                        <PictureUpload  imgUrl={myProfileData.logoUrl} disable={true}/>
                      </ItemGrid>
                    </GridContainer>
                    <GridContainer>
                      <ItemGrid xs={12} sm={12} md={6}>
                          <InputLabel className={classes.label}>
                              First Name
                          </InputLabel>

                          <CustomInput
                            // labelText="First Name"
                            id="first-name"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                                disabled: true,
                                value:myProfileData.contactPerson,
                            }}
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={6}>
                          <InputLabel className={classes.label}>
                              Last Name
                          </InputLabel>

                          <CustomInput
                            // labelText="Last Name"
                            id="last-name"
                            formControlProps={{
                              fullWidth: true
                            }}

                        />
                      </ItemGrid>
                    </GridContainer>
                    <GridContainer>
                      <ItemGrid xs={12} sm={12} md={4}>
                          <InputLabel className={classes.label}>
                              City
                          </InputLabel>

                          <CustomInput
                            // labelText="City"
                            id="city"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                                disabled: true,
                                value:myProfileData.city,
                            }}
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={4}>
                          <InputLabel className={classes.label}>
                              Country
                          </InputLabel>

                          <CustomInput
                            // labelText="Country"
                            id="country"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                                disabled: true,
                                value:myProfileData.country,
                            }}
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={4}>
                          <InputLabel className={classes.label}>Postal Code
                          </InputLabel>

                          <CustomInput
                            // labelText="Postal Code"
                            id="postal-code"
                            formControlProps={{
                              fullWidth: true
                            }}
                            // inputProps={{
                            //     disabled: true,
                            //     value:myProfileData.country,
                            // }}
                        />
                      </ItemGrid>
                    </GridContainer>
                    <GridContainer>
                      <ItemGrid xs={12} sm={12} md={12}>
                          <InputLabel className={classes.label}>
                              Contact No
                          </InputLabel>
                        <CustomInput
                            // labelText="Contact No"
                            id="about-me"
                            //value={""}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                                disabled: true,
                                value:myProfileData.contact,
                            }}
                        />
                      </ItemGrid>
                    </GridContainer>
                    <Clearfix/>
                  </form>
                }
            />
          </ItemGrid>
          {/* <ItemGrid xs={12} sm={12} md={4}>
          <ProfileCard
            avatar={avatar}
            subtitle="CEO / CO-FOUNDER"
            title="Alec Thompson"
            description="Don't be scared of the truth because we need to restart the human foundation in truth And I love you like Kanye loves Kanye I love Rick Owens’ bed design but the back is..."
            content={
              <Button color="rose" round>
                Follow
              </Button>
            }
          />
        </ItemGrid>*/}
        </GridContainer>
      </div>
  );
}
}
const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color:liteBlue,
        fontSize: "14px",
        lineHeight: "1.0001",
        fontWeight: "400",
        display: "inline-flex"
    },

  ...customSelectStyle
};

const mapStateToProps = state =>{
  const{isFailed,msg,msgColor,myProfileData}=state.MyProfile;
  return{isFailed,msg,msgColor,myProfileData}
}

export default connect(mapStateToProps,{
  getMyProfile: DisplayMyProfile,
    UserPassword:CreateMyProfilePassword,HideMsg,
})(withStyles(extendedFormsStyle)(UserProfile));
