import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import {NavLink} from "react-router-dom";
import cx from "classnames";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import Drawer from "material-ui/Drawer";
import List from "material-ui/List";
import ListItem from "material-ui/List/ListItem";
import ListItemIcon from "material-ui/List/ListItemIcon";
import ListItemText from "material-ui/List/ListItemText";
import Hidden from "material-ui/Hidden";
import Collapse from "material-ui/transitions/Collapse";
import Dashboard from "@material-ui/icons/Dashboard"
// core components
import HeaderLinks from "components/Header/HeaderLinks.jsx";

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";
import avatar from "assets/img/faces/avatar.jpg";
// import default_avatar from "assets/img/default-avatar.png";
import default_avatar from "assets/img/user.png";

import {dispName, SCHOOL_ID, USER_TYPE} from "../../helper/Cookies";
import UserProfile from "../../views/Profile/UserProfile";
import Users from "../../views/Profile/Users";
import SubSchools from "../../views/Profile/SubSchools";
var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
                suppressScrollX: true,
                suppressScrollY: false
            });
        }
    }

    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
    }

    render() {


        const {className, user, headerLinks, links} = this.props;
        return (
            <div className={className} ref="sidebarWrapper">
                {user}
                {headerLinks}
                {links}
            </div>
        );
    }
}

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openAvatar: false,
            openDashboard: this.activeRoute("/Dashboard"),
            openCirculars: this.activeRoute("/Circulars"),
            openAssessments: this.activeRoute("/Assessments"),
            openHomeWork: this.activeRoute("/HomeWorks"),
            openGallery: this.activeRoute("/Gallery"),
            openAttendance: this.activeRoute("/Attendance"),
            openCalendar: this.activeRoute("/Calendar"),
            openFee: this.activeRoute("/Fee"),
            openTransport: this.activeRoute("/Transport"),
            miniActive: true
        };
        this.activeRoute.bind(this);
    }

    // verifies if routeName is the one active (in browser input)
    activeRoute(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
    }

    openCollapse(collapse) {
        var st = {};
        st[collapse] = !this.state[collapse];
        this.setState(st);
    }

    render() {
        var profileRoutes=[];

        if(parseInt(USER_TYPE())===2){
            profileRoutes = [
                {
                    path: "/Dashboard/MyProfile",
                    name: "MyProfile",
                    component: UserProfile,
                    state:"openMyProfile"
                    //  isHide:true
                },
            ];
        }else if(parseInt(USER_TYPE())===3 && parseInt(SCHOOL_ID())===1){

            // console.log("userType",parseInt(USER_TYPE()));
            profileRoutes = [
                {
                    path: "/Dashboard/MyProfile",
                    name: "MyProfile",
                    state:"openMyProfile",
                    component: UserProfile
                    //  isHide:true
                },
                {
                    path: "/Dashboard/Users",
                    name: "Users",
                    state: "openUser",
                    component:Users
                },

                {
                    path: "/Dashboard/Schools",
                    name: "Schools",
                    state: "openSchools",
                    component:SubSchools
                },
                // {
                //     path: "/Dashboard/Configurations",
                //     name: "Configurations",
                //     state: "openConfigurations",
                //     component:Configurations
                // },
                /*  { redirect: true, path: "/", pathTo: "/Dashboard", name: "Dashboard" }*/

            ];
        }else{
            profileRoutes = [
                {
                    path: "/Dashboard/MyProfile",
                    name: "MyProfile",
                    component: UserProfile,
                    state:"openMyProfile"
                    //  isHide:true
                },
                {
                    path: "/Dashboard/Users",
                    name: "Users",
                    state: "openUser",
                    component:Users
                },
                // {
                //     path: "/Dashboard/Configurations",
                //     name: "Configurations",
                //     state: "openConfigurations",
                //     component:Configurations
                // },
                /*  { redirect: true, path: "/", pathTo: "/Dashboard", name: "Dashboard" }*/

            ];
        }
        const {
            classes,
            color,
            logo,
            image,
            logoText,
            routes,
            bgColor,
            rtlActive
        } = this.props;
        const itemText =
            classes.itemText +
            " " +
            cx({
                [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
                [classes.itemTextMiniRTL]:
                rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.itemTextRTL]: rtlActive
            });
        const collapseItemText =
            classes.collapseItemText +
            " " +
            cx({
                [classes.collapseItemTextMini]:
                this.props.miniActive && this.state.miniActive,
                [classes.collapseItemTextMiniRTL]:
                rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.collapseItemTextRTL]: rtlActive,
                    [classes.whiteAfter]: bgColor === "white"
            });
        const userWrapperClass =
            classes.user +
            " " +
            cx({
                [classes.whiteAfter]: bgColor === "white"
            });
        const caret =
            classes.caret +
            " " +
            cx({
                [classes.caretRTL]: rtlActive
            });
        /* const collapseItemMini =
             classes.collapseItemMini +
             " " +
             cx({
                 [classes.collapseItemMiniRTL]: rtlActive
             });*/
        const photo =
            classes.photo +
            " " +
            cx({
                [classes.photoRTL]: rtlActive
            });
        var user = (
            <div className={userWrapperClass}>
                <div className={photo}>
                    <img src={default_avatar} className={classes.avatarImg} alt="..."/>
                </div>
                <List className={classes.list}>
                    <ListItem className={classes.item + " " + classes.userItem}>
                        <NavLink
                            to={"#"}
                            className={classes.itemLink + " " + classes.userCollapseButton}
                            onClick={() => this.openCollapse("openAvatar")}
                        >
                            <ListItemText
                                primary={rtlActive ? "تانيا أندرو" : dispName()}
                                secondary={
                                    <b
                                        className={
                                            caret + " " + classes.userCaret +
                                            " " +
                                            (this.state.openAvatar ? classes.caretActive : "")
                                        }
                                    />
                                }
                                disableTypography={true}
                                className={itemText + " " + classes.userItemText}
                            />
                        </NavLink>
                        <Collapse in={this.state.openAvatar} unmountOnExit>
                            <List className={classes.list + " " + classes.collapseList}>
                                {profileRoutes.map((prop, key) => {
                                    if (prop.redirect) {
                                        return null;
                                    }
                                    /*
                                                                        const navLinkClasses =
                                                                            classes.collapseItemLink +
                                                                            " " +
                                                                            cx({
                                                                                [" " + classes[color]]: this.activeRoute(prop.path)
                                                                            });
                                    */
                                    const collapseItemMini =
                                        classes.collapseItemMini +
                                        " " +
                                        cx({
                                            [classes.collapseItemMiniRTL]: rtlActive
                                        });
                                    return (


                                        <ListItem key={key} className={classes.collapseItem}>
                                            <NavLink to={prop.path} className={
                                                classes.itemLink + " " + classes.userCollapseLinks
                                            }>
                            <span className={collapseItemMini}>
                              {prop.mini}
                            </span>
                                                <ListItemText
                                                    primary={prop.name}
                                                    disableTypography={true}
                                                    className={collapseItemText}
                                                />
                                            </NavLink>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Collapse>
                    </ListItem>
                </List>
            </div>
        );

        var links = (

            <List className={classes.list}>


                <ListItem className={classes.item}>
                    <NavLink to="/Dashboard" onClick={() => this.openCollapse("openDashboard")}
                             className={classes.itemLink + " " + cx({[" " + classes[color]]: this.activeRoute("/Dashboard")})}>
                        <ListItemIcon className={classes.itemIcon}>
                            <Dashboard/>
                        </ListItemIcon>
                        <ListItemText
                            primary="Dashboard"
                            disableTypography={true}
                            className={classes.itemText}
                        />
                    </NavLink>
                </ListItem>


                {routes.map((prop, key) => {
                    if (prop.redirect) {
                        return null;
                    }
                    if (prop.isHide) {
                        return null;
                    }
                    if (prop.collapse) {
                        const navLinkClasses =
                            classes.itemLink +
                            " " +
                            cx({
                                [" " + classes.collapseActive]: this.activeRoute(prop.path)
                            });
                        const itemText =
                            classes.itemText +
                            " " +
                            cx({
                                [classes.itemTextMini]:
                                this.props.miniActive && this.state.miniActive,
                                [classes.itemTextMiniRTL]:
                                rtlActive && this.props.miniActive && this.state.miniActive,
                                [classes.itemTextRTL]: rtlActive
                            });
                        const collapseItemText =
                            classes.collapseItemText +
                            " " +
                            cx({
                                [classes.collapseItemTextMini]:
                                this.props.miniActive && this.state.miniActive,
                                [classes.collapseItemTextMiniRTL]:
                                rtlActive && this.props.miniActive && this.state.miniActive,
                                [classes.collapseItemTextRTL]: rtlActive
                            });

                        const itemIcon =
                            classes.itemIcon +
                            " " +
                            cx({
                                [classes.itemIconRTL]: rtlActive
                            });
                        const caret =
                            classes.caret +
                            " " +
                            cx({
                                [classes.caretRTL]: rtlActive
                            });
                        return (
                            <ListItem key={key} className={classes.item}>
                                <NavLink
                                    to={"#"}
                                    className={navLinkClasses}
                                    onClick={() => this.openCollapse(prop.state)}
                                >
                                    <ListItemIcon className={itemIcon}>
                                        <prop.icon/>
                                        {/*
                                        <img src={prop.icon} alt="icon" />
*/}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={prop.name}
                                        secondary={
                                            <b
                                                className={
                                                    caret +
                                                    " " +
                                                    (this.state[prop.state] ? classes.caretActive : "")
                                                }
                                            />
                                        }
                                        disableTypography={true}
                                        className={itemText}
                                    />
                                </NavLink>
                                <Collapse in={this.state[prop.state]} unmountOnExit>
                                    <List className={classes.list + " " + classes.collapseList}>
                                        {prop.views.map((prop, key) => {
                                            if (prop.redirect) {
                                                return null;
                                            }
                                            const navLinkClasses =
                                                classes.collapseItemLink +
                                                " " +
                                                cx({
                                                    [" " + classes[color]]: this.activeRoute(prop.path)
                                                });
                                            const collapseItemMini =
                                                classes.collapseItemMini +
                                                " " +
                                                cx({
                                                    [classes.collapseItemMiniRTL]: rtlActive
                                                });
                                            return (
                                                <ListItem key={key} className={classes.collapseItem}>
                                                    <NavLink to={prop.path} className={navLinkClasses}>
                            <span className={collapseItemMini}>
                              {prop.mini}
                            </span>
                                                        <ListItemText
                                                            primary={prop.name}
                                                            disableTypography={true}
                                                            className={collapseItemText}
                                                        />
                                                    </NavLink>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </Collapse>
                            </ListItem>
                        );
                    }
                    const navLinkClasses =
                        classes.itemLink +
                        " " +
                        cx({
                            [" " + classes[color]]: this.activeRoute(prop.path)
                        });
                    const itemText =
                        classes.itemText +
                        " " +
                        cx({
                            [classes.itemTextMini]:
                            this.props.miniActive && this.state.miniActive,
                            [classes.itemTextMiniRTL]:
                            rtlActive && this.props.miniActive && this.state.miniActive,
                            [classes.itemTextRTL]: rtlActive
                        });
                    const itemIcon =
                        classes.itemIcon +
                        " " +
                        cx({
                            [classes.itemIconRTL]: rtlActive
                        });
                    return (
                        <ListItem key={key} className={classes.item}>
                            <NavLink to={prop.path} className={navLinkClasses}>
                                <ListItemIcon className={itemIcon}>
                                    <prop.icon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={prop.name}
                                    disableTypography={true}
                                    className={itemText}
                                />
                            </NavLink>
                        </ListItem>
                    );
                })}
            </List>
        );

        const logoNormal =
            classes.logoNormal +
            " " +
            cx({
                [classes.logoNormalSidebarMini]:
                this.props.miniActive && this.state.miniActive,
                [classes.logoNormalSidebarMiniRTL]:
                rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.logoNormalRTL]: rtlActive
            });
        const logoMini =
            classes.logoMini +
            " " +
            cx({
                [classes.logoMiniRTL]: rtlActive
            });
        const logoClasses =
            classes.logo +
            " " +
            cx({
                [classes.whiteAfter]: bgColor === "white"
            });
        var brand = (
            <div className={logoClasses}>
                <a href="/#" className={logoNormal}>
                    <img src={logo} width={220} height={80} alt="logo" />
                </a>
{/*
                <a href="/#" className={logoNormal}>
                    {logoText}
                </a>
*/}
            </div>
        );
        const drawerPaper =
            classes.drawerPaper +
            " " +
            cx({
                [classes.drawerPaperMini]:
                this.props.miniActive && this.state.miniActive,
                [classes.drawerPaperRTL]: rtlActive
            });
        const sidebarWrapper =
            classes.sidebarWrapper +
            " " +
            cx({
                [classes.drawerPaperMini]:
                this.props.miniActive && this.state.miniActive,
                [classes.sidebarWrapperWithPerfectScrollbar]:
                navigator.platform.indexOf("Win") > -1
            });
        return (
            <div ref="mainPanel">
                <Hidden mdUp>
                    <Drawer
                        variant="temporary"
                        anchor={rtlActive ? "left" : "right"}
                        open={this.props.open}
                        classes={{
                            paper: drawerPaper + " " + classes[bgColor + "Background"]
                        }}
                        onClose={this.props.handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true // Better open performance on mobile.
                        }}
                    >
                        {brand}
                        <SidebarWrapper
                            className={sidebarWrapper}
                            user={user}
                            headerLinks={<HeaderLinks rtlActive={rtlActive}/>}
                            links={links}
                        />
                        {image !== undefined ? (
                            <div
                                className={classes.background}
                                // style={{backgroundImage: "url(" + image + ")"}}
                            />
                        ) : null}
                    </Drawer>
                </Hidden>
                <Hidden smDown>
                    <Drawer
                        onMouseOver={() => this.setState({miniActive: false})}
                        onMouseOut={() => this.setState({miniActive: true})}
                        anchor={rtlActive ? "right" : "left"}
                        variant="permanent"
                        open
                        classes={{
                            paper: drawerPaper + " " + classes[bgColor + "Background"]
                        }}
                    >
                        {brand}
                        <SidebarWrapper
                            className={sidebarWrapper}
                            user={user}
                            links={links}
                        />
                        {image !== undefined ? (
                            <div
                                className={classes.background}
                                // style={{backgroundImage: "url(" + image + ")"}}
                            />
                        ) : null}
                    </Drawer>
                </Hidden>
            </div>
        );
    }
}


Sidebar.defaultProps = {
    bgColor: "blue"
};

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf(["white", "black", "blue","logoblue"]),
    rtlActive: PropTypes.bool,
    bgColor: PropTypes.oneOf(["white", "red", "orange", "green", "blue", "purple", "rose", "litegreen","logoblue"]),
    logo: PropTypes.string,
    logoText: PropTypes.string,
    image: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    profileRoutes: PropTypes.arrayOf(PropTypes.object)

};

export default withStyles(sidebarStyle)(Sidebar);
