
import React from "react";
// react component for creating dynamic tables

// @material-ui/icons


// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

import Button from "../../components/CustomButtons/Button";
import withStyles from "material-ui/styles/withStyles";
import PricingCard from "../../components/Cards/PricingCard";
import {Business, Weekend} from "@material-ui/icons";

//import pricingPageStyle from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.jsx";

import {
    container,
    defaultFont
} from "assets/jss/material-dashboard-pro-react.jsx";
class Configurations extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            simpleSelect: "",
            multipleSelect: [],

        }
    }
    handleCreate=()=>{
        //alert();
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
    };


    render(){
       /* const { classes } = this.props;*/
        return (
            <GridContainer justify="center">
                {this.state.isCreated ?
                    <ItemGrid>

                    </ItemGrid>
                    :<ItemGrid xs={12} sm={10} >
                        <div>
                            <GridContainer justify="center">
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <PricingCard
                                        //plain
                                        title="Pupilnerd"
                                        price={
                                            <h6 style={{fontSize:"24px"}}>FREE</h6>
                                        }
                                        description={

                                            <div>

                                                <GridContainer >
                                                    <ItemGrid justify="center" xs={12}>
                                                        <h4 style={{color:"black"}}>Includes :</h4>
                                                    </ItemGrid>
                                                    <ItemGrid  xs={12} style={{height: 1,backgroundColor:"#D2D2D2"}}/>
                                                    <ItemGrid justify="center" xs={12}>
                                                        <br/>
                                                        <legend>Circulars</legend>
{/*
                                                        <legend>Assessments</legend>
*/}
                                                        <legend>Attendance</legend>
                                                        <legend>Chat</legend>
                                                        <legend>Calendar</legend>
                                                        <legend>Assignments</legend>
                                                        <legend>Achievements</legend>
                                                        <legend>Achievements</legend>
                                                    </ItemGrid>
                                                    <ItemGrid  xs={12} style={{height: 1,backgroundColor:"#D2D2D2"}}/>
                                                    <ItemGrid justify="center" xs={12}>
                                                        <br/>
                                                        <legend>3 Staff member</legend>
                                                    </ItemGrid>
                                                    <ItemGrid  xs={12} style={{height: 1,backgroundColor:"#D2D2D2"}}/>
                                                    <ItemGrid justify="center" xs={12}>
                                                        <br/>
                                                        <legend>Up to 200 Students</legend>
                                                    </ItemGrid>
                                                    <ItemGrid  xs={12} style={{height: 1,backgroundColor:"#D2D2D2"}}/>
                                                    <ItemGrid justify="center" xs={12}>
                                                        <br/>
                                                        <legend>Online help center</legend>
                                                    </ItemGrid>

                                                </GridContainer>
                                            </div>
                                        }
                                        icon={Weekend}
                                        footer={
                                            <Button round color="black">
                                                Free
                                            </Button>
                                        }
                                    />
                                </ItemGrid>
                                <ItemGrid xs={12} sm={12} md={6}>
                                    <PricingCard
                                        title="Premium"
                                        price={
                                            <h5 style={{fontSize:"24px"}}>$1.5<span  style={{fontSize:"12px"}}>/user/month</span></h5>

                                        }
                                        description={
                                            <div>
                                                <GridContainer >
                                                    <ItemGrid justify="center" xs={12}>
                                                        <h4 style={{color:"#e91e63",fontStyle:"bold"}}>Everything in Pupilnerd, Plus</h4>
                                                    </ItemGrid>
                                                    <ItemGrid  xs={12} style={{height: 1,backgroundColor:"#D2D2D2"}}/>
                                                    <ItemGrid justify="center" xs={12}>
                                                        <br/>
                                                        <legend>Wall</legend>
                                                        <legend>Exams</legend>
                                                        <legend>School Menu</legend>
                                                        <legend>Temperature</legend>
                                                        <legend>Hand Book</legend>
                                                        <legend>Fee</legend>
                                                        <legend>Transport</legend>
                                                    </ItemGrid>
                                                    <ItemGrid  xs={12} style={{height: 1,backgroundColor:"#D2D2D2"}}/>
                                                        <ItemGrid justify="center" xs={12}>
                                                            <br/>
                                                            <legend>Unlimited Staff</legend>
                                                        </ItemGrid>
                                                        <ItemGrid  xs={12} style={{height: 1,backgroundColor:"#D2D2D2"}}/>
                                                        <ItemGrid justify="center" xs={12}>
                                                            <br/>
                                                            <legend>Unlimited Students</legend>
                                                        </ItemGrid>
                                                        <ItemGrid  xs={12} style={{height: 1,backgroundColor:"#D2D2D2"}}/>
                                                        <ItemGrid justify="center" xs={12}>
                                                            <br/>
                                                            <legend>Email/Chat/Phone Support</legend>
                                                        </ItemGrid>

                                                </GridContainer>

                                            </div>
                                        }
                                        icon={Business}
                                        iconColor="rose"
                                        footer={
                                            <Button round color="rose">
                                                Upgrade
                                            </Button>
                                        }
                                    />
                                </ItemGrid>
                            </GridContainer>

                        </div>

                    </ItemGrid>}
            </GridContainer>
        );
    }
}
const pricingPageStyle = {
    content: {
        minHeight: "calc(100vh - 80px)",
        position: "relative",
        zIndex: "4"
    },
    container: {
        ...container
    },
    title: {
        ...defaultFont,
        color: "#FFFFFF",
        marginTop: "13vh",
        marginBottom: "30px",
        textAlign: "center"
    },
    description: {
        fontSize: "18px",
        color: "#FFFFFF",
        textAlign: "center"
    },
    underline: {
        "&:before": {
            backgroundColor: "#D2D2D2",
            height: "1px !important"
        },
        "&:after": {
            backgroundColor: "#D2D2D2",
            transition: "0.3s ease all"
        }
    },
};

export default  withStyles(pricingPageStyle) (Configurations);
