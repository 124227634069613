import schoolAPi from "kidzwapi";
import {CREATE_USER_FAIL, CREATE_USER_SUCCESS, HIDE_INFO_MSG, USERS_LIST} from "./types";
import {USER_ID, AUTH,SCHOOL_ID} from "../helper/Cookies";

function CreateUserSuccess(){

    return{
        type:CREATE_USER_SUCCESS,
        payLoad:"User Created Successfully"

    }
}

function CreateUserFailed(msg){
    return{
        type:CREATE_USER_FAIL,
        payLoad: msg
    }
}
function hideMsg(){
    return{
        type:HIDE_INFO_MSG,
        payLoad:null
    }
}

function UsersList(json){
    return{
        type:USERS_LIST,
        payLoad:json
    }
}

export function HideMsg(){
    return function(dispatch){
        dispatch(hideMsg());
    }
}
 export function CreateUserAction(name, contact, email, uname, type, profilePic){
    return function (dispatch) {
        console.log("request",parseInt(SCHOOL_ID()),name, contact, email, uname, type, profilePic,USER_ID(),AUTH())
        if(parseInt(SCHOOL_ID())!==null && name!==null && uname!==null){
             return schoolAPi.createUser(parseInt(SCHOOL_ID()),name, contact, email, uname, type, profilePic,USER_ID(),AUTH())
                 .then(resp=>{
                     console.log("response",resp);
                     if(resp.status){
                         dispatch(CreateUserSuccess());
                     }else{
                         dispatch(CreateUserFailed(resp.msg));
                     }
                 })
        }else{
            dispatch(CreateUserFailed("Enter All Fields"));
        }
    }
 }

 export function DisplayUserList(){
    return function(dispatch){
        return schoolAPi.listUser(parseInt(SCHOOL_ID()),USER_ID(),AUTH())
            .then(resp=>{
                if(resp.status){
                    dispatch(UsersList(resp.users));
                }
            })
    }
 }
