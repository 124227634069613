import {
    CREATE_MYPROFILE_PASSWORD_SUCCESS,
    CREATE_MYPROFILE_PASSWORD_FAILED,
    GET_MYPROFILE_SUCCESS,
    GET_MYPROFILE_FAILED,
} from "../actions/types";

const INIT_STATE = {isFailed: false, msg: null, msgColor: "danger",myProfileData:{}};
export default (state=INIT_STATE,action)=> {
    switch (action.type) {
        case GET_MYPROFILE_SUCCESS:
            console.log("reducer",action.payLoad)
            return {...state,isFailed: true,myProfileData: action.payLoad};
        case GET_MYPROFILE_FAILED:
            return {...state, isFailed: true, myProfileData: action.payLoad};
        case CREATE_MYPROFILE_PASSWORD_SUCCESS:
            return {...state,isFailed: true,msg: action.payLoad, msgColor: "success"};
        case CREATE_MYPROFILE_PASSWORD_FAILED:
            return {...state, isFailed: true, msg: action.payLoad};
        default:
            return INIT_STATE
    }
}
