import {gql} from "apollo-boost";

// export const getFeeQuery = gql ``;
export const getFeeReceipt = gql `query getReceipts($schoolId: ID!, $childId: ID){
  getReceipts(schoolId: $schoolId, childId: $childId){
    _id
    schoolId
    childId
    term
    termName
    items{
      name
      amount
    }
    amount
    createdAt
    child{
    name
    }
  }
}`;
