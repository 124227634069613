import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import AppBar from "material-ui/AppBar";
import Toolbar from "material-ui/Toolbar";
import IconButton from "material-ui/IconButton";
import Button from "material-ui/Button";
import Hidden from "material-ui/Hidden";

// material-ui icons
import Menu from "@material-ui/icons/Menu";

/*import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";*/
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";


// core components
import HeaderLinks from "./HeaderLinks";
import CustomIconButton from "components/CustomButtons/IconButton.jsx";

import headerStyle from "../../assets/jss/material-dashboard-pro-react/components/headerStyle.jsx";

import {connect} from "react-redux";

function Header({...props}) {

    const {classes, color, rtlActive, handleProfile, isLoggedIn, history} = props;
    console.log(isLoggedIn);
    if (!isLoggedIn) {
        history.push("/");
    }
    const appBarClasses = cx({
        [" " + classes[color]]: color
    });
    const sidebarMinimize =
        classes.sidebarMinimize +
        " " +
        cx({
            [classes.sidebarMinimizeRTL]: rtlActive
        });
    return (
        <AppBar className={classes.appBar + appBarClasses}>
            <Toolbar className={classes.container}>
                <Hidden smDown>
                    <div className={sidebarMinimize}>
                        {props.miniActive ? (
                            <CustomIconButton color="white" onClick={props.sidebarMinimize}>
                                <KeyboardArrowRight className={classes.sidebarMiniIcon}/>
                            </CustomIconButton>
                        ) : (
                            <CustomIconButton color="white" onClick={props.sidebarMinimize}>
                                <KeyboardArrowLeft className={classes.sidebarMiniIcon}/>
                            </CustomIconButton>
                        )}
                    </div>
                </Hidden>
                <div className={classes.flex}>
                    {props.routes.map((prop, key) => {
                        return (
                            <Button key={prop.path} onClick={()=>{history.push(prop.path)}} className={classes.title} >
                                {prop.name}
                            </Button>
                        )
                    })
                    }
                </div>

                <Hidden smDown implementation="css">
                    <HeaderLinks rtlActive={rtlActive} handleProfile={handleProfile}/>
                </Hidden>


                <Hidden mdUp>
                    <IconButton
                        className={classes.appResponsive}
                        color="inherit"
                        aria-label="open drawer"
                        onClick={props.handleDrawerToggle}>
                        <Menu/>
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
    rtlActive: PropTypes.bool,

};
const mapPropsToState = state => {
    // console.log(state);
    return {isLoggedIn: state.LoginAuth.isLoggedIn};
};
export default connect(mapPropsToState, null)(withStyles(headerStyle)(Header));
