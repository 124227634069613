import {
    CREATE_CIRCULAR_SUCCESS,
    CREATE_CIRCULAR_FAIL, CIRCULAR_LIST, CLASS_LSIT, DELETE_SUCCESS, DELETE_FAILED
} from "../actions/types";

const INIT_STATE = {isFailed: false, msg: null, msgColor: "danger", circularTable: [],classList:[],isRequestSent:false,deleteSuccess:false};
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CLASS_LSIT:
            return{...state,classList:action.payLoad};
        case CREATE_CIRCULAR_SUCCESS:
            return {...state,isFailed: true,msg: action.payLoad, msgColor: "success"};
        case CREATE_CIRCULAR_FAIL:
            return {...state, isFailed: true, msg: action.payLoad};
        case CIRCULAR_LIST:
            return {...state, circularTable: action.payLoad,isRequestSent:true};
        case DELETE_SUCCESS:
            return {...state,isFailed:true, msg: action.payLoad,deleteSuccess:true,msgColor: "success"};
        case DELETE_FAILED:
            return {...state,isFailed:true, msg: action.payLoad,deleteSuccess:true};
        default:
            return INIT_STATE;
    }
}
