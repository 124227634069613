import {
    CREATE_CLASS_FAIL,
    CREATE_CLASS_SUCCESS,
    HIDE_INFO_MSG,
    UPDATE_CLASS_FAIL,
    UPDATE_CLASS_SUCESS,
    CLASS_LSIT
} from "../actions/types";

const INIT_STATE = {isFailed: false, msg: null, msgColor: "danger", dataTable: []};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CREATE_CLASS_SUCCESS:
            return {...state,isFailed: true,msg: action.payLoad, msgColor: "success"};
        case CREATE_CLASS_FAIL:
            return {...state, isFailed: true, msg: action.payLoad};
        case UPDATE_CLASS_SUCESS:
            return {...state,isFailed: true, msg: action.payLoad, msgColor: "success"};
        case UPDATE_CLASS_FAIL:
            return {...state, isFailed: true, msg: action.payLoad};
        case HIDE_INFO_MSG:
            return INIT_STATE;
        case CLASS_LSIT:
            return {...state, dataTable: action.payLoad};
        default:
            return INIT_STATE;
    }
}
