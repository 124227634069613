import React from "react";
import PropTypes from "prop-types";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import InputAdornment from "material-ui/Input/InputAdornment";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import LoginCard from "components/Cards/LoginCard.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import Snackbar from "../../components/Snackbar/Snackbar";
import{HideMsg,ValidateTokenAction,ChangePasswordAction} from "../../actions/ResetPasswordAction";
import {connect} from 'react-redux'

class ResetPasswordPage extends React.Component {
    constructor(props) {
        super(props);
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            // cardAnimaton: "cardHidden",
            userId: "",
            cpwd: "",
            npwd: "",
            token:"",
            msg:"",
            showError: false,
            isLoading: false
        };

        this.onChangeValues = this.onChangeValues.bind(this);
    }

    onChangeValues(event) {
        this.setState({
            [event.target.id]: event.target.value
        });

        // this.setState({
        //     isError: false
        // });
    }


    // setErrorState = (isError, ErrorMsg) => {
    //     this.setState({
    //         showError: isError,
    //         errorMsg: ErrorMsg,
    //         isLoading: false
    //     });
    //     this.timeOut();
    // };
    //
    // handleKeyPress = event => {
    //     if (event.key === 'Enter') {
    //         this.onLogin();
    //     }
    // };
    SavePassword = () => {
        const token=this.props.match.params.token;
        const { npwd,cpwd} = this.state;
            const {PasswordReset,userId} = this.props;
              PasswordReset(userId, npwd, cpwd,token);
    };

    goToHome = () => {
        setTimeout(() => {
            const {history} = this.props;
            history.push("/Login");
        }, 3000)

    };

    componentDidMount() {
        const token=this.props.match.params.token;
        const {tokenValidate} = this.props;
        tokenValidate(token);
    }

    render() {
        const {classes, msg, isFailed, msgColor,display,isRedirect} = this.props;

        if(isRedirect){
            this.goToHome();
        }
        const {cpwd, npwd} = this.state;
        return (
            <div className={classes.content} style={{backgroundColor:"#34495E"}}>
                <div className={classes.container}>

                    {this.props.msg!=="Valid Link." && this.props.msg!==null?
                    <GridContainer>
                        <ItemGrid xs={12} sm={6} md={4}>
                            <Snackbar
                                open={isFailed}
                                place={"tc"}
                                color={msgColor}
                                message={msg}
                            />
                        </ItemGrid>
                    </GridContainer>
                        :null}

                    {
                        this.props.display !== "none"?
                        <GridContainer justify="center">
                        <ItemGrid xs={12} sm={6} md={4}>
                        <form>
                        <LoginCard
                         customCardClass={classes[this.state.cardAnimaton]}
                        headerColor="rose"
                        cardTitle="Create Password"
                        // cardSubtitle="Or Be Classical"
                        footerAlign="center"
                        footer={
                        <div>
                            <Button color="roseNoBackground" wd size="lg" onClick={this.SavePassword}>
                                Change Password
                            </Button>
                        </div>
                    }
                        content={
                        <div>
                            <CustomInput
                                labelText="New Password"
                                id="npwd"
                                onChange={this.onChangeValues}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <LockOutline
                                                className={classes.inputAdornmentIcon}
                                            />
                                        </InputAdornment>
                                    ),
                                    value: npwd
                                }}
                            />
                            <CustomInput
                                labelText="Confirm Password"
                                id="cpwd"
                                iType={"password"}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                onChange={this.onChangeValues}
                                inputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <LockOutline
                                                className={classes.inputAdornmentIcon}
                                            />
                                        </InputAdornment>
                                    ),
                                    value: (cpwd)
                                }}
                            />
                        </div>
                    }
                        />
                        </form>
                        </ItemGrid>
                        </GridContainer>
                   :null }
                </div>
            </div>
        );
    }
}

ResetPasswordPage.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    const {isFailed, msg, msgColor,display,userId,isRedirect} = state.ResetPwd;
    return {isFailed, msg, msgColor,display,userId,isRedirect};
};

export default connect(mapStateToProps, {
    PasswordReset:ChangePasswordAction, HideMsg,
    tokenValidate:ValidateTokenAction
})(withStyles(loginPageStyle)(ResetPasswordPage));
