import React from "react";
import PropTypes from "prop-types";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import {ForgetPasswordAction,HideMsg} from "../../actions/ForgetPasswordAction";
import { Link } from 'react-router-dom';
import HeaderCard from "../../components/Cards/HeaderCard";
import {connect} from "react-redux";
import Snackbar from "../../components/Snackbar/Snackbar";


class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email_adress:''
        }
        // we use this to make the card to appear after the page has been rendered
    }

    onChangeValue = event => {
        this.setState({[event.target.id]: event.target.value});
    };
    PasswordCreate = () => {
        const {CreatePassword} = this.props;
        const {email_adress} = this.state;
        console.log("state::",this.state);
        CreatePassword(email_adress);
    };

    // componentDidMount() {
    //     // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    //     setTimeout(
    //         function () {
    //             this.setState({cardAnimaton: ""});
    //         }.bind(this),
    //         700
    //     );
    // }
    onLogin = () => {
        this.props.history.push('/');
    };

    timeInfo = () => {
        const {HideMsg} = this.props;
        setTimeout(() => {
            HideMsg();
            // this.handleCreate();
        }, 3000)
    }
    render() {
        const { classes,msg,isFailed,msgColor,HideMsg } = this.props;

        if (isFailed === true && msg !== null) {
            if(msgColor === "success"){
                this.timeInfo();
            }else{
                setTimeout(() => {
                    HideMsg();
                }, 2000)
            }
        }
        return (
            <div className={classes.content}>
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridContainer>
                            <ItemGrid xs={12} sm={6} md={4}>
                                <Snackbar
                                    open={isFailed}
                                    place={"tc"}
                                    color={msgColor}
                                    message={msg}
                                />
                            </ItemGrid>
                        </GridContainer>

                        <ItemGrid xs={12} sm={8} md={8}>
                            <HeaderCard
                                cardTitle="Forgot Password"
                                headerColor="rose"
                                content={
                                    <form>
                                        <br/>
                                        <CustomInput
                                            labelText="Email adress"
                                            id="email_adress"
                                            onChange={this.onChangeValue}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                type: "email"
                                            }}
                                        />
                                        <Link to="/" className="btn btn-link" >
                                            <span style={{color:"#00acc1"}}>Back To Login</span></Link>
                                        <br/>
                                        <GridContainer justify="center">
                                            <ItemGrid >
                                                <Button color="success" onClick={ this.PasswordCreate} >Submit</Button>
                                            </ItemGrid>
                                            <ItemGrid >
                                                <Button color="rose" onClick={  this.onLogin} >Cancel</Button>
                                            </ItemGrid>

                                        </GridContainer>
                                        <br/>
                                    </form>

                                }
                            />
                        </ItemGrid>
                    </GridContainer>
                </div>
            </div>
        );
    }
}

ForgotPassword.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state =>{
    const{isFailed,msg,msgColor,}=state.ForgetPsd;
    return{isFailed,msg,msgColor}
}
export default connect(mapStateToProps,{
    CreatePassword:ForgetPasswordAction,HideMsg,
})(withStyles(loginPageStyle)(ForgotPassword));
