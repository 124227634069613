import {REGISTER_SCHOOL_SUCCESS,
    REGISTER_SCHOOL_FAIL,
} from "../actions/types";

const INIT_STATE = {isFailed: false, msg: null, msgColor: "danger"};
export default (state=INIT_STATE,action)=> {
    switch (action.type) {
        case REGISTER_SCHOOL_SUCCESS:
            return {...state,isFailed: true,msg: action.payLoad, msgColor: "success"};
        case REGISTER_SCHOOL_FAIL:
            return {...state, isFailed: true, msg: action.payLoad};
        default:
            return INIT_STATE
    }
}
