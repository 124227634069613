import {}from "../actions/types";
import {CREATE_FEE_SUCCESS,CREATE_FEE_FAILED} from "../actions/types";
import {CLASS_LSIT} from "../actions/types";
import {GET_FEE_DATA} from "../actions/types";
import {DELETE_SUCCESS} from "../actions/types";
import {DELETE_FAILED} from "../actions/types";

const INIT_STATE = {isFailed: false, msg: null, msgColor: "danger",classList:[],FeeList:[],isRequestSent:false};
export default (state = INIT_STATE, action) => {
    switch(action.type) {
        case CREATE_FEE_SUCCESS:
            return{...state,isFailed: true,msg:action.payLoad,msgColor: "success"};
        case CREATE_FEE_FAILED:
            return {...state, isFailed: true, msg: action.payLoad};
        case DELETE_SUCCESS:
            return{...state,isFailed: true,msg:action.payLoad,msgColor: "success"};
        case DELETE_FAILED:
            return {...state, isFailed: true, msg: action.payLoad};
        case CLASS_LSIT:
            return{...state,classList:action.payLoad};
        case GET_FEE_DATA:
            return{...state,FeeList:action.payLoad,isRequestSent:true};
        default:
            return INIT_STATE;
    }

}
