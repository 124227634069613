
import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import PlaceIcon from "@material-ui/icons/Place";
import Edit from "@material-ui/icons/Edit";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import { dataTable } from "variables/general.jsx";
import Button from "../../components/CustomButtons/Button";
import withStyles from "material-ui/styles/withStyles";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "material-ui/Select";
import MenuItem from "material-ui/Menu/MenuItem";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import ExportToExcel from "../../components/ExportToExcel/ExportToExcel";
class ChildAllocation extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            headers:["Code","Class","Date","Subject","Homework"],

            data: dataTable.dataRows.map((prop,key) => {
                return ({
                    id: key,
                    name: prop[0],
                    position: prop[1],
                    office: prop[2],
                    age: prop[3],
                    actions: (
                        // we've added some custom button actions
                        <div className="actions-right">

                            <IconButton
                                onClick={() => {
                                   // let obj = this.state.data.find(o => o.id === key);
                                    this.handleCreate();
                                    /*
                                                                        alert(
                                                                            "You've clicked EDIT button on \n{ \nName: " + obj.name
                                                                            + ", \nposition: " + obj.position
                                                                            + ", \noffice: " + obj.office
                                                                            + ", \nage: " + obj.age + "\n}.");
                                    */
                                }}
                                color="infoNoBackground"
                                customClass="edit">
                                <Edit />
                            </IconButton>{" "}
                            <IconButton
                                onClick={() => {
                                    var data = this.state.data;
                                    data.find((o,i) => {
                                        if(o.id === key){
                                            // here you should add some custom code so you can delete the data
                                            // from this component and from your server as well
                                            data.splice(i,1);
                                            return true;
                                        }
                                        return false;
                                    });
                                    this.setState({data: data});
                                }}
                                color="dangerNoBackground"
                                customClass="remove">
                                <Close />
                            </IconButton>
                        </div>

                    )
                })
            }),
            selectClass: "",
            selectChild:"",
            selectRoute:"",
            selectStop:"",
            fileName:"No file Chosen",
            file:null,
            child_list: ["Child A", "Child B", "Child V", "Child C","Child D","Child E","Child F" ],
            classes_list: ["1st Standard", "2nd Standard", "3rd Standard", "4th Standard","5th Standard","6th Standard","7th Standard" ],
            routes_list:  ["Route A", "Route B", "Route V", "Route C","Route D","Route E","Route F" ],
            stops_list:  ["Stop A", "Stop B", "Stop V", "Stop C","Stop D","Stop E","Stop F" ]

        };
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
    }
    handleCreate=()=>{
        //alert();
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
    };
    handleSimple = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    handleFileClick() {
        var input = document.createElement("input");
        input.type = "file";
        input.onchange = this.handleFileChange;
        input.click();
    }
    handleFileChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                fileName:file.name
            });
        };
        reader.readAsDataURL(file);
    }

    render(){
        const { classes } = this.props;
        return (
            <GridContainer justify="center">
                {this.state.isCreated ?
                    <ItemGrid xs={12} sm={12} >
                        <IconCard
                            icon={PlaceIcon}
                            title="Child Allocation"
                            iconColor="rose"
                            content={
                                <form>

                                    <GridContainer justify="center">

                                        <ItemGrid xs={12} sm={6}>
                                            <FormControl
                                                fullWidth
                                                className={classes.selectFormControl}
                                            >
                                                <InputLabel className={classes.label}>
                                                    Class
                                                </InputLabel>

                                                <Select
                                                    MenuProps={{
                                                        className: classes.selectMenu
                                                    }}
                                                    classes={{
                                                        select: classes.select
                                                    }}
                                                    value={this.state.selectClass}
                                                    onChange={this.handleSimple}
                                                    inputProps={{
                                                        name: "selectClass",
                                                        id: "select-class"
                                                    }}
                                                >
                                                    <MenuItem
                                                        disabled
                                                        classes={{
                                                            root: classes.selectMenuItem
                                                        }}
                                                    >
                                                        Select Class
                                                    </MenuItem>
                                                    {this.state.classes_list.map((prop, key) => {
                                                        return(
                                                            <MenuItem
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                    selected: classes.selectMenuItemSelected
                                                                }}
                                                                value={key}
                                                            >
                                                                {prop.toString()}
                                                            </MenuItem>

                                                        )
                                                    })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={6}>

                                            <FormControl
                                                fullWidth
                                                className={classes.selectFormControl}
                                            >
                                                <InputLabel className={classes.label}>
                                                    Child
                                                </InputLabel>
                                                <Select
                                                    MenuProps={{
                                                        className: classes.selectMenu
                                                    }}
                                                    classes={{
                                                        select: classes.select
                                                    }}
                                                    value={this.state.selectChild}
                                                    onChange={this.handleSimple}
                                                    inputProps={{
                                                        name: "selectChild",
                                                        id: "select-class"
                                                    }}
                                                >
                                                    <MenuItem
                                                        disabled
                                                        classes={{
                                                            root: classes.selectMenuItem
                                                        }}
                                                    >
                                                        Select Child
                                                    </MenuItem>
                                                    {this.state.child_list.map((prop, key) => {
                                                        return(
                                                            <MenuItem
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                    selected: classes.selectMenuItemSelected
                                                                }}
                                                                value={key}
                                                            >
                                                                {prop.toString()}
                                                            </MenuItem>

                                                        )
                                                    })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={6}>
                                            <FormControl
                                                fullWidth
                                                className={classes.selectFormControl}
                                            >
                                                <InputLabel className={classes.label}>
                                                    Route
                                                </InputLabel>

                                                <Select
                                                    MenuProps={{
                                                        className: classes.selectMenu
                                                    }}
                                                    classes={{
                                                        select: classes.select
                                                    }}
                                                    value={this.state.selectRoute}
                                                    onChange={this.handleSimple}
                                                    inputProps={{
                                                        name: "selectRoute",
                                                        id: "select-class"
                                                    }}
                                                >
                                                    <MenuItem
                                                        disabled
                                                        classes={{
                                                            root: classes.selectMenuItem
                                                        }}
                                                    >
                                                        Select Route
                                                    </MenuItem>
                                                    {this.state.routes_list.map((prop, key) => {
                                                        return(
                                                            <MenuItem
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                    selected: classes.selectMenuItemSelected
                                                                }}
                                                                value={key}
                                                            >
                                                                {prop.toString()}
                                                            </MenuItem>

                                                        )
                                                    })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </ItemGrid>

                                        <ItemGrid xs={12} sm={6}>
                                            <FormControl
                                                fullWidth
                                                className={classes.selectFormControl}
                                            >
                                                <InputLabel className={classes.label}>
                                                    Stop
                                                </InputLabel>

                                                <Select
                                                    MenuProps={{
                                                        className: classes.selectMenu
                                                    }}
                                                    classes={{
                                                        select: classes.select
                                                    }}
                                                    value={this.state.selectStop}
                                                    onChange={this.handleSimple}
                                                    inputProps={{
                                                        name: "selectStop",
                                                        id: "select-class"
                                                    }}
                                                >
                                                    <MenuItem
                                                        disabled
                                                        classes={{
                                                            root: classes.selectMenuItem
                                                        }}
                                                    >
                                                        Select Class
                                                    </MenuItem>
                                                    {this.state.stops_list.map((prop, key) => {
                                                        return(
                                                            <MenuItem
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                    selected: classes.selectMenuItemSelected
                                                                }}
                                                                value={key}
                                                            >
                                                                {prop.toString()}
                                                            </MenuItem>

                                                        )
                                                    })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </ItemGrid>

                                    </GridContainer>



                                    <GridContainer justify="center">

                                        <ItemGrid>
                                            <br/>
                                            <Button color="success" onClick={this.handleCreate}>Save</Button>
                                            <br/>
                                        </ItemGrid>
                                        <ItemGrid>
                                            <br/>
                                            <Button color="rose" onClick={this.handleCreate}  >Cancel</Button>
                                            <br/>
                                        </ItemGrid>

                                    </GridContainer>
                                </form>

                            }
                        />
                    </ItemGrid> :
                    <ItemGrid xs={12}>

                        <IconCard
                            icon={PlaceIcon}
                            iconColor={"rose"}
                            title={
                                <div>
                                    Child Allocation
                                    <Button
                                        onClick={this.handleCreate}
                                        // round color="white"
                                        color="rose" right={true}> Allocate
                                    </Button>
                                </div>
                            }
                            content={
                                <GridContainer >
{/*
                                    <ItemGrid xs={12}>
                                        <GridContainer >
                                            <ItemGrid xs={11} sm={11}>

                                                <legend>   </legend>
                                            </ItemGrid>
                                            <ItemGrid xs={1} sm={1}>

                                                <IconButton
                                                    onClick={this.handleCreate}
                                                    // round color="white"
                                                    color="rose">
                                                    <AddIcon />
                                                </IconButton>
                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid >
*/}

                                    <ItemGrid xs={12}>
                                        <ReactTable
                                            data={this.state.data}
                                            filterable
                                            style={{color:"#00acc1"}}
                                            columns={[
                                                {
                                                    Header: "Code",
                                                    accessor: "name",
                                                    style:{color:"#000",textAlign:"left"}

                                                },

                                                {
                                                    Header: "Class",
                                                    accessor: "office",
                                                    style:{color:"#000",textAlign:"left"}

                                                },
                                                {
                                                    Header: "Child Name",
                                                    accessor: "office",
                                                    style:{color:"#000",textAlign:"left"}

                                                },
                                                {
                                                    Header: "Route Name",
                                                    accessor: "office",
                                                    style:{color:"#000",textAlign:"left"}

                                                },
                                                {
                                                    Header: "Stop",
                                                    accessor: "age",
                                                    style:{color:"#000",textAlign:"left"}

                                                },
                                                {
                                                    Header: "Actions",
                                                    accessor: "actions",

                                                    sortable: false,
                                                    filterable: false,
                                                }
                                            ]}
                                            defaultPageSize={5}
                                            showPaginationBottom={true}
                                            className="-striped -highlight"
                                        />

                                    </ItemGrid>
                                    <ItemGrid xs={12}>
                                        <GridContainer>
                                            <ItemGrid xs={12} md={10}>
                                            </ItemGrid>
                                            <ItemGrid xs={12} md={2}>
                                                <ExportToExcel
                                                    posts={this.state.data}
                                                    headings={this.state.headers}
                                                    fileName={"Fee Structures"} sheetName={"Fee Structure"}/>

                                            </ItemGrid>



                                        </GridContainer>
                                    </ItemGrid>

                                </GridContainer>

                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
}
const extendedFormsStyle=theme =>( {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#AAAAAA",
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    ...customSelectStyle
});
export default withStyles(extendedFormsStyle)(ChildAllocation);
