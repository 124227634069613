import schoolAPi from "kidzwapi";
import {USER_ID, AUTH, SCHOOL_ID, USER_TYPE, Classes} from "../helper/Cookies";
import {
    SUBJECT_LIST,
    CREATE_SUBJECT_FAIL,
    CREATE_SUBJECT_SUCCESS,
    HIDE_INFO_MSG,
    UPDATE_SUBJECT_FAIL,
    UPDATE_SUBJECT_SUCCESS,
} from "./types";

function CreateSubjectSuccess() {
    return {
        type: CREATE_SUBJECT_SUCCESS,
        payLoad: "Subject Created Successfully"
    }
}

function CreateSubjectFailed(msg) {
    return {
        type: CREATE_SUBJECT_FAIL,
        payLoad: msg
    }
}

function UpdateSubjectSuccess() {
    return {
        type: UPDATE_SUBJECT_SUCCESS,
        payLoad: "Subject Updated Successfully"
    }
}

function UpdateSubjectFailed(msg) {
    return {
        type: UPDATE_SUBJECT_FAIL,
        payLoad: msg
    }

}

function hideMsg() {
    return {
        type: HIDE_INFO_MSG,
        payload: null
    }
}

function SubjectList(json) {
    return {
        type: SUBJECT_LIST,
        payLoad: json
    }
}

export function HideMsg() {
    return function (dispatch) {
        dispatch(hideMsg());
    }
}

export function SubjectAction(subjectCode, subjectName) {
    return function (dispatch) {
        if (subjectCode !== null && subjectName !== null) {
            return schoolAPi.createSubject(parseInt(SCHOOL_ID()), subjectCode, subjectName, USER_ID(), AUTH())
                .then(resp => {
                    if (resp.status) {
                        dispatch(CreateSubjectSuccess());
                    } else {
                        dispatch(CreateSubjectFailed(resp.msg))
                    }
                })
        } else {
            dispatch(CreateSubjectFailed("Enter All Fields"))
        }

    }
}

export function UpdateSubject(subjectId, subjectCode, subjectName) {
    return function (dispatch) {
        if (subjectId !== null && subjectName !== null) {
            return schoolAPi.updateSubject(subjectId, parseInt(SCHOOL_ID()), subjectCode, subjectName, USER_ID(), AUTH())
                .then(resp => {
                    if (resp.status) {
                        dispatch(UpdateSubjectSuccess());

                    } else {
                        dispatch(UpdateSubjectFailed(resp.msg));
                    }
                })
        } else {
            dispatch(UpdateSubjectFailed("Enter All Fileds"));
        }
    }
}


export function DisplaySubjectList(classId) {
    return function (dispatch) {
        if (parseInt(USER_TYPE()) !== 2) {
            return schoolAPi.listSubject(parseInt(SCHOOL_ID()), USER_ID(), AUTH())
                .then(resp => {
                        if (resp.status) {
                            dispatch(SubjectList(resp.subjects));
                        }
                    }
                )
        } else {
            var TeacherClass = JSON.parse(Classes());
            console.log("Teacher class", TeacherClass);
            console.log("classId", classId);
            const tempClassId = Array.isArray(classId) ? classId[0] : classId;
            const classIndex = TeacherClass.findIndex(t => t._id === tempClassId);
            console.log("index", classIndex);
            if (classIndex > -1) {
                const subList = TeacherClass[classIndex].subjects.map(s => {
                   return {
                       subjectId: s._id,
                       name: s.name
                   }
                });
                console.log(subList);
                dispatch(SubjectList(subList));
            } else {
                dispatch(SubjectList([]));
            }

            // let subjects=[];
            // TeacherClass.forEach(r => {
            //     const {_id, name} = r;
            //     subjects.push({
            //         classId: _id,
            //         name: name
            //     })
            // });
            // if(subjects.length>0)  {
            //     dispatch(SubjectList(subjects));
            // }
        }
    }
}
