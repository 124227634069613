import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

import Edit from "@material-ui/icons/Edit";
import People from "@material-ui/icons/Person";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import withStyles from "material-ui/styles/withStyles";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import {CreateTeacherAction, HideMsg, DispalyTeacherList, UpdateTeacherAction,DeleteTeacher} from "../../actions/TeacherAction";
import {DisplayClassList} from "../../actions/ClassRoomAction";
import {DisplaySubjectList} from "../../actions/SubjectAction";
import {connect} from "react-redux";
import Snackbar from "../../components/Snackbar/Snackbar";
import {USER_TYPE} from "../../helper/Cookies";
import SelectTemp from 'react-select';
import Tooltip from "material-ui/Tooltip";
import {liteBlue} from "../../assets/jss/material-dashboard-pro-react";


class Teachers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tittle: "Create Teacher",
            hideButton: true,
            simpleSelect: "",
            teacherId: null,
            tName: null,
            tContact: null,
            tEmail: null,
            tUserName: null,
            tPassword: null,
            // tDob:moment().format('YYYY-MM-DD'),
            multipleClassTeacher: [],
            ClassSubjects: [{classId: null, subjectId: []}],
            isCreated: false,
            attendanceFor: []
        }
    }

    handleCreate = () => {
        //alert();
        this.setState({
            tittle: "Create Teacher",
            hideButton: true,
            tName: null,
            tContact: null,
            tEmail: null,
            multipleClassTeacher: [],
            ClassSubjects: [{classId: null, subjectId: []}],
            isCreated: !this.state.isCreated,
        });
    };
    handleUpdateTeacher = (teacherId, name, classTeacher, subjects, classes, contact, emailId) => {
        this.setState({
            tittle: "Update Teacher",
            buttonType: "Update",
            hideButton: false,
            teacherId: teacherId,
            tName: name,
            tContact: contact,
            tEmail: emailId,
            multipleClassTeacher: classTeacher,
            multipleSubjects: subjects,
            multipleTeachingClasses: classes,
            isCreated: !this.state.createId,
            listId: 0
        });
        // const {ClassList,SubjectList} = this.props;
        // ClassList();
        // // SubjectList();
    };
    handleSimple = event => {
        this.setState({[event.target.name]: event.target.value});
    };
    onChangeValue = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        })
    };

    handleMultipleClassTeacher(value) {
        let ClassArray = [];
        if (value !== null) {
            value.map((data) =>
                ClassArray.push(data.value)
            )
        }
        this.setState({multipleClassTeacher: ClassArray});
        // this.setState({ multipleClassTeacher: value.value });
    };

    handleAttendanceFor(value){
        let classArry = [];
        if (value !== null) {
           value.map(data => {
               classArry.push(data.value);
           })
        }
        console.log(classArry);
        this.setState({attendanceFor: classArry});
    }

    handleMultipleSubjects(i, value) {
        let SubjectArray = [];
        if (value !== null) {
            value.map((data) =>
                SubjectArray.push(data.value)
            )
        }
        let ClassSubjects = [...this.state.ClassSubjects];
        ClassSubjects[i] = {...ClassSubjects[i], subjectId: SubjectArray};
        this.setState({ClassSubjects});

    };

    handleMultipleTeachingClasses(i, e) {
        let ClassSubjects = [...this.state.ClassSubjects];
        ClassSubjects[i] = {...ClassSubjects[i], classId: e.value};
        this.setState({ClassSubjects});
    };

    teacherCreate = () => {

        const {CreateTeacher} = this.props;
        const {tName, multipleClassTeacher, tContact, tEmail, ClassSubjects, tUserName, tPassword, attendanceFor} = this.state;

        CreateTeacher(tUserName, tPassword, tName, multipleClassTeacher, ClassSubjects, tContact, tEmail, attendanceFor);

    };
    teacherUpdate = () => {

        const {UpdateTeacher} = this.props;
        const {teacherId, tName, multipleClassTeacher, multipleSubjects, multipleTeachingClasses, tContact, tEmail} = this.state;
        UpdateTeacher(teacherId, tName, multipleClassTeacher, multipleSubjects, multipleTeachingClasses, tContact, tEmail);
        const {ClassList, SubjectList} = this.props;
        ClassList();
        SubjectList();
    };
    timeInfo = () => {
        const {HideMsg, TeacherList, ClassList, SubjectList,deleteTeacher} = this.props;
        setTimeout(() => {
            HideMsg();
            TeacherList();
            ClassList();
            SubjectList();
            if(deleteTeacher!==true){
                this.handleCreate();
            }
        }, 3000)
    };


    createUI() {
        const {classes, subjectTable, teacherTable} = this.props;
        let SubjectOptions = [];
        if (subjectTable !== null) {
            subjectTable.forEach(r => {
                const {name, subjectId} = r;
                SubjectOptions.push({
                    value: subjectId,
                    label: name
                })
            })
        }

        let options = [];

        if (teacherTable !== null) {
            teacherTable.forEach(r => {
                const {name, classId} = r;
                options.push({
                    value: classId,
                    label: name
                })
            })
        }
        return this.state.ClassSubjects.map((el, i) => (
            <div key={i}>
                <GridContainer>
                    <ItemGrid xs={12} sm={6} md={4}>
                        <InputLabel className={classes.label}>
                            Teaching Class
                        </InputLabel>
                        <SelectTemp
                            className="basic-single"
                            classNamePrefix="select"
                            // defaultValue={options[0]}
                            name="multipleTeachingClasses"
                            options={options}
                            onChange={this.handleMultipleTeachingClasses.bind(this, i)}
                            theme={(theme) =>this.getTheme(theme)}
                        />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={6} md={4}>
                        <InputLabel className={classes.label}>
                            Subjects Teaching
                        </InputLabel>
                        <SelectTemp
                            isMulti
                            className="basic-multi-select"
                            classNamePrefix="select"
                            // defaultValue={options[0]}
                            name="multipleSubjects"
                            options={SubjectOptions}
                            onChange={this.handleMultipleSubjects.bind(this, i)}
                            theme={(theme) =>this.getTheme(theme)}
                        />
                    </ItemGrid>

                    <ItemGrid xs={12} sm={6} md={4}>
                        <Button color="primary" onClick={this.removeClick.bind(this, i)}>Remove</Button>
                    </ItemGrid>
                </GridContainer>
            </div>

        ))
    }

    addClick() {
        this.setState(prevState => ({
            ClassSubjects: [...prevState.ClassSubjects, {classId: [], subjectId: []}]
        }))
    }

    removeClick(i) {
        let ClassSubjects = [...this.state.ClassSubjects];
        ClassSubjects.splice(i, 1);
        this.setState({ClassSubjects});
    }
    deleteTeacher(teacherId){
        const {TeacherDelete}=this.props;
        TeacherDelete(teacherId);
    }
    componentDidMount() {
        const {ClassList, SubjectList, TeacherList} = this.props;
        ClassList();
        SubjectList();
        TeacherList();
    }
    getTheme=(theme) => ({
        ...theme,
        borderRadius: '4px',
        height: "20px",
        colors: {
            ...theme.colors,
            //  primary25: '#00ABDC',
            primary:liteBlue,
            //   primary50:"#00ABDC"
        },
    });


    render() {
        const {tName, tContact, tEmail, pwd, uname} = this.state;

        const {classes, teacherTable, subjectTable, dataTable, isFailed, msgColor, msg, HideMsg, ClassList, SubjectList,deleteTeacher} = this.props;

        if (isFailed === true && msg !== null) {
            if (msgColor === "success") {
                this.timeInfo();
            } else {
                setTimeout(() => {
                    HideMsg();
                    ClassList();
                    SubjectList();
                }, 2000)
            }
        }
        let SubjectOptions = [];

        if (subjectTable !== null) {
            subjectTable.forEach(r => {
                const {name, subjectId} = r;
                SubjectOptions.push({
                    value: subjectId,
                    label: name
                })
            })
        }

        let options = [];

        if (teacherTable !== null) {
            teacherTable.forEach(r => {
                const {name, classId} = r;
                options.push({
                    value: classId,
                    label: name
                })
            })
        }
        let teacherList = [];
        let columns = [];
        const userType = parseInt(USER_TYPE());
        // if (dataTable.length > 0) {
        if (userType === 2 || userType === 5) {
            columns = [
                {
                    Header: "Name",
                    accessor: "name",
                    style: {color: "#000", textAlign: "left"}

                },

                {
                    Header: "Contact No",
                    accessor: "contact",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Eamil",
                    accessor: "emailId",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Username",
                    accessor: "uname",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Password",
                    accessor: "pwd",
                    style: {color: "#000", textAlign: "left"}

                }
            ]
        } else {
            columns = [
                {
                    Header: "Name",
                    accessor: "name",
                    style: {color: "#000", textAlign: "left"}

                },

                {
                    Header: "Contact No",
                    accessor: "contact",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Eamil",
                    accessor: "emailId",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Username",
                    accessor: "uname",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Password",
                    accessor: "pwd",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                }
            ]
        }
        dataTable.forEach(r => {
            const {name, contact, emailId, teacherId, classTeacher, subjects, classes, uname, pwd} = r;
            teacherList.push({
                name: name,
                contact: contact,
                emailId: emailId,
                teacherId: teacherId,
                classTeacher: classTeacher,
                subjects: subjects,
                classes: classes,
                pwd: pwd,
                uname: uname,
                actions: (
                    <div className="actions-right">
                        {/*<IconButton*/}
                        {/*    onClick={() => {*/}
                        {/*        this.handleUpdateTeacher(teacherId,name,classTeacher,subjects,classes,contact,emailId);*/}
                        {/*    }}*/}
                        {/*    color="infoNoBackground"*/}
                        {/*    customClass="edit">*/}
                        {/*    <Edit/>*/}
                        {/*</IconButton>{" "}*/}
                        <Tooltip title="Delete">
                        <IconButton
                            onClick={() => {{if(window.confirm('Are you sure you want to Delete this item?')){this.deleteTeacher(teacherId)};}}}
                            color="dangerNoBackground"
                            customClass="remove">
                            <Close/>
                        </IconButton>
                        </Tooltip>
                    </div>
                )
            })
        })
        // }
        return (
            <GridContainer justify="center">
                <GridContainer>
                    <ItemGrid xs={12} sm={6} md={4}>
                        <Snackbar
                            open={isFailed}
                            place={"tc"}
                            color={msgColor}
                            message={msg}
                        />
                    </ItemGrid>
                </GridContainer>
                {this.state.isCreated ?
                    <ItemGrid xs={12} sm={12}>
                        <IconCard
                            icon={People}
                            title={this.state.tittle}
                           // iconColor="rose"
                            content={
                                <form>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={4}>
                                            <CustomInput
                                                labelText="Teacher Name"
                                                isMandatory={true}
                                                id="tName"
                                                onChange={this.onChangeValue}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    value: tName
                                                }}
                                            />
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={4}>
                                            <CustomInput
                                                labelText="Teacher UserName"
                                                id="tUserName"
                                                onChange={this.onChangeValue}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    value: uname
                                                }}
                                            />
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={4}>
                                            <CustomInput
                                                labelText="Teacher Password"
                                                id="tPassword"
                                                iType={"password"}
                                                onChange={this.onChangeValue}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    value: pwd
                                                }}
                                            />
                                        </ItemGrid>

                                        <ItemGrid xs={12} sm={4}>
                                            <CustomInput
                                                labelText="Contact No"
                                                isMandatory={true}
                                                id="tContact"
                                                onChange={this.onChangeValue}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    value: tContact
                                                }}
                                            />
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={4}>
                                            <CustomInput
                                                labelText="Email Id"
                                                id="tEmail"
                                                onChange={this.onChangeValue}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    type: "email",
                                                    value: tEmail
                                                }}
                                            />

                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={4}>

                                            <InputLabel className={classes.label}>
                                                Class Teacher For
                                            </InputLabel>
                                            <SelectTemp
                                                isMulti
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                id="multipleClassTeacher"
                                                // defaultValue={options[0]}
                                                name="multipleClassTeacher"
                                                options={options}
                                                onChange={this.handleMultipleClassTeacher.bind(this)}
                                                // value={this.state.multipleClassTeacher}
                                                theme={(theme) => this.getTheme(theme)}
                                            />
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={4}>

                                            <InputLabel className={classes.label}>
                                                Post Attendance For
                                            </InputLabel>
                                            <SelectTemp
                                                isMulti
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                id="multipleClassTeacher"
                                                // defaultValue={options[0]}
                                                name="multipleClassTeacher"
                                                options={options}
                                                onChange={this.handleAttendanceFor.bind(this)}
                                                // value={this.state.multipleClassTeacher}
                                                theme={(theme) => this.getTheme(theme)}
                                            />
                                        </ItemGrid>


                                    </GridContainer>
                                    {this.createUI()}
                                    

                                    <Button color="primary" onClick={this.addClick.bind(this)}>Add More</Button>
                                    <GridContainer justify="center">
                                        {this.state.hideButton === true ?
                                            <ItemGrid>
                                                <br/>
                                                <Button color="primary" onClick={this.teacherCreate}>Save</Button>
                                                <br/>
                                            </ItemGrid>
                                            :
                                            < ItemGrid>
                                                < br/>
                                                < Button color="primary" onClick={this.teacherUpdate}>Update</Button>
                                                <br/>
                                            </ItemGrid>
                                        }
                                        <ItemGrid>
                                            <br/>
                                            <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                            <br/>
                                        </ItemGrid>
                                    </GridContainer>
                                </form>

                            }
                        />
                    </ItemGrid> :
                    <ItemGrid xs={12}>
                        <IconCard
                            icon={People}
                           // iconColor={"rose"}
                            title={
                                <div>
                                    Teachers
                                    {userType !== 5 ?
                                        <Button
                                            onClick={this.handleCreate}
                                            // round color="white"
                                            color="primary" right={true}> Add Teacher
                                        </Button>
                                        : null}
                                </div>
                            }
                            content={
                                <GridContainer>
                                    <ItemGrid xs={12}>
                                        <ReactTable
                                            data={teacherList}
                                            filterable
                                            style={{color: "#00acc1"}}
                                            columns={columns}
                                            defaultPageSize={5}
                                            showPaginationBottom={true}
                                            className="-striped -highlight"
                                        />
                                    </ItemGrid>
                                </GridContainer>

                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#AAAAAA",
        fontSize: "12px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex"
    },
    ...customSelectStyle
};

const mapStateToProps = state => {
    const {isFailed, msg, msgColor, dataTable, classList, subjectList,deleteTeacher} = state.Teacher;
    return {teacherTable: classList, subjectTable: subjectList, isFailed, msg, msgColor, dataTable,deleteTeacher}
};

export default connect(mapStateToProps, {
    CreateTeacher: CreateTeacherAction, HideMsg,
    ClassList: DisplayClassList,
    SubjectList: DisplaySubjectList,
    TeacherList: DispalyTeacherList,
    UpdateTeacher: UpdateTeacherAction,
    TeacherDelete:DeleteTeacher,
})(withStyles(extendedFormsStyle)(Teachers));
