import schoolAPi from "kidzwapi";
import {
    CREATE_ATTENDANCE_SUCCESS,
    CREATE_ATTENDENCE_FAIL, DELETE_FAILED,
    DELETE_SUCCESS,
    HIDE_INFO_MSG,
    LIST_ATTENDANCE
} from "./types";
import {AUTH, SCHOOL_ID, USER_ID} from "../helper/Cookies";

function CreateAttendanceSuccess() {
    return {
        type: CREATE_ATTENDANCE_SUCCESS,
        payLoad: "Attendance Posted Successfully"
    }
}

function CreateAttendanceFailed(msg) {
    return {
        type: CREATE_ATTENDENCE_FAIL,
        payLoad: msg
    }
}
function DeleteAttendanceSuccess() {
    return {
        type: DELETE_SUCCESS,
        payLoad: "Attendance Deleted Successfully"
    }
}

function DeleteAttendanceFailed(msg) {
    return {
        type: DELETE_FAILED,
        payLoad: msg
    }
}

function ListAttendance(json){
    return{
        type:LIST_ATTENDANCE,
        payLoad:json
    }
}
function hideMsg() {
    return {
        type: HIDE_INFO_MSG,
        payLoad: null
    }
}

export function HideMsg() {
    return function (dispatch) {
        dispatch(hideMsg());
    }
}

export function SaveAttendanceAction(classId, attendanceDate, attendance) {
    return function (dispatch) {
        if (classId !== null && attendanceDate !== null && attendance !== null) {
            return schoolAPi.createAttendance(parseInt(SCHOOL_ID()),classId,attendanceDate,attendance,USER_ID(),AUTH())
                .then(resp=>{
                    if(resp.status){
                        dispatch(CreateAttendanceSuccess());
                    }else{
                        dispatch(CreateAttendanceFailed(resp.msg));
                    }
                })
        } else {
            dispatch(CreateAttendanceFailed("Please enter Mandatory"));
        }
    }
}

export function DisplayListAttendance(attendanceDate,classId,ToDate){
    return function(dispatch){
        return schoolAPi.listAttendance(parseInt(SCHOOL_ID()),classId,attendanceDate,ToDate,USER_ID(),AUTH())
            .then(resp=>{
                if(resp.status){
                    dispatch(ListAttendance(resp.attendance));
                }
            })
    }
}

export function DeleteAttendance(schoolId,classId,childId,attendanceDate){
    return function(dispatch){
        console.log("Attend req",schoolId,classId,childId,attendanceDate,USER_ID(),AUTH());
        return schoolAPi.deleteAttendance(schoolId,classId,childId,attendanceDate,USER_ID(),AUTH())
            .then(resp=>{
                if(resp.status){
                    console.log("atten resss",resp);
                    dispatch(DeleteAttendanceSuccess());
                }else{
                    dispatch(DeleteAttendanceFailed(resp.msg))
                }
            })
    }
}
