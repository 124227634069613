
import DashboardIcon from "@material-ui/icons/Dashboard";
import Place from "@material-ui/icons/Place";
import Image from "@material-ui/icons/Image";
import AttendanceIcon from "@material-ui/icons/Assignment";
import Amount from "@material-ui/icons/AccountBalance";
import DateRange from "@material-ui/icons/DateRange";
import ChatIcon from "@material-ui/icons/Chat";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ContentCopy from "@material-ui/icons/ContentCopy";
import CircularIcon from "@material-ui/icons/BlurCircular";
import Dashboard from "../views/Dashboard/Dashboard.jsx";
import Circulars from "../views/Circulars/Circulars";
import Assessments from "../views/Assessments/Assessments";
import Homework from "../views/HomeWorks/Homework";
import Attendance from "../views/Attendance/Attendance";
import Gallery from "../views/Gallery/Gallery";
import GalleryImages from "../views/Gallery/GalleryImages";
import Calendar from "../views/Calendar/Calendar";
import Chats from "../views/Chat/Chats";
import FeeType from "../views/Fee/FeeType";
import FeeScheme from "../views/Fee/FeeStructure";
import FeeInvoice from "../views/Fee/FeeInvoice";
import FeeReceipt from "../views/Fee/FeeReceipt";
import RouteMaster from "../views/Transport/RouteMaster";
import ChildAllocation from "../views/Transport/ChildAllocation";
import ChatConversation from "../views/Chat/ChatConversation";
import UserProfile from "../views/Profile/UserProfile";
import Users from "../views/Profile/Users";
import SubSchools from "../views/Profile/SubSchools";
import Configurations from "../views/Profile/Configurations";
import ClassRooms from "../views/HeaderPages/ClassRooms";
import Subjects from "../views/HeaderPages/Subjects";
import Students from "../views/HeaderPages/Students";
import Teachers from "../views/HeaderPages/Teachers";


// var today = new Date();
// var y = today.getFullYear();
// var m = today.getMonth();
// var d = today.getDate();


export var menuList = [
        {
            menuId:1,
            name:"Circulars",
            path: "/Circulars",
            state: "openCirculars",
            icon:CircularIcon,
            isHide:false,
            component:Circulars
        },
        {
            menuId:2,
            path: "/Assessments",
            name: "Assessments",
            state: "openAssessments",
            icon: AssessmentIcon,
            isHide:false,
            component:Assessments
        },

        {
            menuId:3,
            path: "/Home_Works1",
            name: "HomeWorks",
            state: "openHomeWork",
            icon: ContentCopy,
            isHide:false,
            component:Homework
        },
        {
            menuId:4,
            path: "/Attendance",
            name: "Attendance",
            state: "openAttendance",
            icon: AttendanceIcon,
            isHide:false,
            component:Attendance
        },
        {
            menuId:5,
            path: "/Gallery",
            name: "Gallery",
            state: "openGallery",
            icon: Image,
            isHide:false,
            component:Gallery,
        },


        {
            menuId:6,
            path: "/Calendar",
            name: "Calendar",
            state: "openCalendar",
            icon: DateRange,
            isHide:false,
            component:Calendar
        },


        {
            menuId:7,
            path: "/Chats",
            name: "Chats",
            state: "openChat",
            icon: ChatIcon,
            isHide:false,
            component:Chats
        },

        {
            menuId:8,
            collapse:true,
            path: "/Fee",
            name: "Fee",
            state: "openFee",
            icon: Amount,
            isHide:false,
            // component:''
            views: [
                {
                    path: "/Fee/FeeType",
                    name: "Fee Type",
                    mini: "FT",
                    component: FeeType
                },
                {
                    path: "/Fee/FeeScheme",
                    name: "Fee Structure",
                    mini: "FS",
                    component: FeeScheme
                },
                {
                    path: "/Fee/FeeInvoice",
                    name: "Fee Invoice",
                    mini: "FI",
                    component: FeeInvoice
                },
                { path: "/Fee/FeeReceipt", name: "Fee Receipt", mini: "FR", component: FeeReceipt }
            ]
        },

        {
            menuId:9,
            collapse:true,
            path: "/Transport",
            name: "Transport",
            state: "openTransport",
            icon: Place,
            isHide:false,
            // component:''
            views: [
                {
                    path: "/Transport/RouteMaster",
                    name: "Route Master",
                    mini: "RM",
                    component: RouteMaster
                },
                {
                    path: "/Transport/ChildAllocation",
                    name: "Child Allocation",
                    mini: "CA",
                    component: ChildAllocation
                }
            ]
        },
    {
        path: "/GalleryImages",
        name: "Images",
        state: "",
        icon: Image,
        isHide:true,
        component: GalleryImages
    },
    {
        path: "/ChatConversation",
        name: "ChatConversation",
        state: "",
        icon: Image,
        isHide:true,
        component: ChatConversation
    },
        {
            path: "/Dashboard",
            name: "DashBoard",
            icon: DashboardIcon,
            component: Dashboard,
            state:"openDashboard",
            isHide:true
        },
        {
            redirect: true,
            path: "/",
            pathTo: "/Login",
            name: "Dashboard",
            isHide:true
        }

];
export var profileRoutes = [
    {
        path: "/Dashboard/MyProfile",
        name: "MyProfile",
        component: UserProfile,
        state:"openMyProfile"
        //  isHide:true
    },
    {
        path: "/Dashboard/Users",
        name: "Users",
        state: "openUser",
        component:Users
    },

    {
        path: "/Dashboard/Schools",
        name: "Schools",
        state: "openSchools",
        component:SubSchools
    },
    {
        path: "/Dashboard/Configurations",
        name: "Configurations",
        state: "openConfigurations",
        component:Configurations
    },
    /*  { redirect: true, path: "/", pathTo: "/Dashboard", name: "Dashboard" }*/

];

export var headerRoutes = [
    {
        path: "/Dashboard/ClassRooms",
        name: "ClassRooms",
        component: ClassRooms,
        state:"openMyClassrooms"
        //  isHide:true
    },
    {
        path: "/Dashboard/Subjects",
        name: "Subjects",
        state: "openSubjects",
        component:Subjects
    },

    {
        path: "/Dashboard/Students",
        name: "Students",
        state: "openStudents",
        component:Students
    },
    {
        path: "/Dashboard/Teachers",
        name: "Teachers",
        state: "openTeachers",
        component:Teachers
    },
    /* { redirect: true, path: "/", pathTo: "/Dashboard", name: "Dashboard" }*/

];
export var userList=[
    {
        userId:4,
        userName:"Content Creater"
    },
    {
        userId:5,
        userName:"Content Viewer"
    },
];
export var userListNew=[
    {
        value:4,
        label:"Content Creater"
    },
    {
        value:5,
        label:"Content Viewer"
    },
];
export var schoolCatagoryNew=[
    {
        value:1,
        label:"0-200"
    },
    {
        value:2,
        label:"201-500"
    },
    {
        value:3,
        label:"501-1000"
    },
    {
        value:4,
        label:"1001-2000"
    },
    {
        value:5,
        label:">2000"
    },
];

  export var schoolCatagory=[
    {
        catagoryId:1,
        catagoryName:"0-200"
    },
    {
        catagoryId:2,
        catagoryName:"201-500"
    },
    {
        catagoryId:3,
        catagoryName:"501-1000"
    },
    {
        catagoryId:4,
        catagoryName:"1001-2000"
    },
    {
        catagoryId:5,
        catagoryName:">2000"
    },
];

export var  subjectList=[
    {
        subjectId:1,
        subjectName:"Telugu"
    },
    {
        subjectId:2,
        subjectName:"Hindi"
    },
    {
        subjectId:3,
        subjectName:"English"
    },
    {
        subjectId:4,
        subjectName:"Maths"
    },
    {
        subjectId:5,
        subjectName:"Physics"
    },
    {
        subjectId:6,
        subjectName:"Biology"
    },
    {
        subjectId:7,
        subjectName:"Social"
    },
];

export var HolidayType=[
    {
        type:"H",
        name:"Holiday"
    },
    {
        type:"E",
        name:"Event"
    },
]
export var HolidayType1=[
    {
        value:"H",
        label:"Holiday"
    },
    {
        value:"E",
        label:"Event"
    },
]

export var AttendanceType=[
    {    Id:1,
        isAbsent:0,
        value:0,
        label:"Present"
    },
    {   Id:2,
        isAbsent:1,
        value:1,
        label:"Absent"
    }
];

export var ChildDropLIst=[
    {
    Id:1,
        value:"Mother",
        label:"Mother"
    },
    {
        Id:2,
        value:"Father",
        label:"Father"
    },
    {
        Id:3,
        value:"Grand Mother",
        label:"Grand Mother"
    },
    {
        Id:4,
        value:"Grand Father",
        label:"Grand Father"
    },
    {
        Id:5,
        value:"Helper",
        label:"Helper"
    },
    {
        Id:6,
        value:"Driver",
        label:"Driver"
    },
    {
        Id:7,
        value:"School Bus",
        label:"School Bus"
    }

];

export var BloodGroup=[
    {
        value:1,
        label:"A+"
    },
    {
        value:2,
        label:"A-"
    },
    {
        value:3,
        label:"B+"
    },
    {
        value:4,
        label:"B-"
    },{
        value:5,
        label:"O+"
    },{
        value:6,
        label:"O-"
    },{
        value:7,
        label:"AB+"
    },{
        value:8,
        label:"AB-"
    }
];
