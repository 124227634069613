import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/icons
import ClassIcon from '@material-ui/icons/Class';
import Edit from "@material-ui/icons/Edit";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import withStyles from "material-ui/styles/withStyles";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import {connect} from "react-redux";
import {ClassRoomAction, HideMsg, UpdateClassRoomAction, DisplayClassList} from "../../actions/ClassRoomAction";
import Snackbar from "../../components/Snackbar/Snackbar";
import {USER_TYPE} from "../../helper/Cookies";
import Tooltip from "material-ui/Tooltip";

class ClassRooms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tittle: "Create Class",
            classId: '',
            className: null,
            order_no: null,
            hideButton: true,
            createId: false,
            listId: 1,
        };
        this.baseState = this.state;
        //  this.handleChange = this.handleChange.bind(this);
        //  this.handleChangeEnabled = this.handleChangeEnabled.bind(this);

    }

    handleCreate = () => {
        //alert();
        this.setState({
            tittle: "Create Class",
            hideButton: true,
            className: null,
            order_no: null,
            createId: !this.state.createId,
            listId: 0
        });
    };
    handleUpdateClass = (classId, name, orderNo) => {
        this.setState({
            tittle: "Update Class",
            buttonType: "Update",
            hideButton: false,
            className: name,
            order_no: orderNo,
            classId: classId,
            createId: !this.state.createId,
            listId: 0
        });
    };

    onChangeValue = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        })
    };

    saveClassRoom = () => {
        const {CreateClass} = this.props;
        const {className, order_no} = this.state;
        CreateClass(className, order_no);

    };
    updateClassRoom = () => {
        const {UpdateClass} = this.props;
        const {className, classId, order_no} = this.state;
        UpdateClass(className, classId, order_no);
    };

    timeInfo = () => {
        const {HideMsg, ClassList} = this.props;
        setTimeout(() => {
            HideMsg();
            ClassList();
            this.handleCreate();
        }, 3000)
    }

    componentDidMount() {
        const {ClassList} = this.props;
        ClassList();
    }

    render() {
        const {className, order_no} = this.state;
        const {isFailed, msg, msgColor, dataTable, HideMsg} = this.props;
        if (isFailed === true && msg !== null) {
            if (msgColor === "success") {
                this.timeInfo();
            } else {
                setTimeout(() => {
                    HideMsg();
                }, 2000)
            }
        }
        let classList = [];
        let columns = [];
        const userType = parseInt(USER_TYPE());
        // if (dataTable.length > 0) {
        if (userType === 5) {
            columns = [
                {
                    Header: "Class Name",
                    accessor: "name",
                    style: {color: "#000", textAlign: "left"}

                },

                {
                    Header: "Order No",
                    accessor: "orderNo",
                    style: {color: "#000", textAlign: "left"}

                }
            ]
        } else {
            columns = [
                {
                    Header: "Class Name",
                    accessor: "name",
                    style: {color: "#000", textAlign: "left"}

                },

                {
                    Header: "Order No",
                    accessor: "orderNo",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                }
            ]
        }
        dataTable.forEach(r => {
            const {classId, name, orderNo} = r;
            classList.push({
                id: classId,
                name: name,
                orderNo: orderNo,
                actions: (
                    <div className="actions-right">
                        <div>
                            <Tooltip title="Edit">
                                <IconButton
                                    onClick={() => {
                                        this.handleUpdateClass(classId, name, orderNo);
                                    }}
                                    color="infoNoBackground"
                                    customClass="edit">
                                    <Edit/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                )
            })
        })
        // }
        return (
            <GridContainer justify="center">
                <GridContainer>
                    <ItemGrid xs={12} sm={6} md={4}>
                        <Snackbar
                            open={isFailed}
                            place={"tc"}
                            color={msgColor}
                            message={msg}
                        />
                    </ItemGrid>
                </GridContainer>
                {this.state.createId ?
                    <IconCard
                        icon={ClassIcon}
                        title={this.state.tittle}
                      //  iconColor="rose"
                        content={
                            <form>
                                <CustomInput
                                    labelText="Class Name"
                                    id="className"
                                    onChange={this.onChangeValue}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        value: className
                                    }}
                                />
                                <CustomInput
                                    labelText="Order Number"
                                    id="order_no"
                                    onChange={this.onChangeValue}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        value: order_no
                                    }}
                                />

                                <GridContainer justify="center">

                                    {this.state.hideButton === true ?
                                        <ItemGrid>
                                            <br/>
                                            <Button color="primary" onClick={this.saveClassRoom}>Save</Button>
                                            <br/>
                                        </ItemGrid>
                                        :
                                        < ItemGrid>
                                            < br/>
                                            < Button color="primary" onClick={this.updateClassRoom}>Update</Button>
                                            <br/>
                                        </ItemGrid>
                                    }
                                    <ItemGrid>
                                        <br/>
                                        <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                        <br/>
                                    </ItemGrid>

                                </GridContainer>
                            </form>

                        }
                    />
                    :
                    <IconCard
                        onClick={this.handleCreate}
                        icon={ClassIcon}
                       // iconColor={"rose"}
                        title={
                            <div>
                                Class Rooms
                                {userType !== 5 ?
                                    <Button
                                        onClick={this.handleCreate}
                                        // round color="white"
                                        color="primary" right={true}> Add Class
                                    </Button>
                                    : null}
                            </div>
                        }
                        content={
                            <div id="classTable">
                                <GridContainer xs={12}>
                                    <ItemGrid xs={12}>
                                        <ReactTable
                                            data={classList}
                                            filterable
                                            style={{color: "#00acc1"}}
                                            columns={columns}
                                            defaultPageSize={5}
                                            showPaginationBottom={true}
                                            className="-striped -highlight"
                                        />

                                    </ItemGrid>
                                </GridContainer>
                            </div>
                        }
                    />
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#AAAAAA",
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex"
    },
    ...customSelectStyle
};

const mapStateToProps = state => {
    const {isFailed, msg, msgColor, dataTable} = state.ClassRoom;
    return {isFailed, msg, msgColor, dataTable};
};

export default connect(mapStateToProps, {
    CreateClass: ClassRoomAction,
    HideMsg,
    UpdateClass: UpdateClassRoomAction,
    ClassList: DisplayClassList,
})(withStyles(extendedFormsStyle)(ClassRooms));
