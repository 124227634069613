import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
import moment from "moment";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Assessment from "@material-ui/icons/Assessment";
import AttachmentIcon from "@material-ui/icons/AttachFile";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import withStyles from "material-ui/styles/withStyles";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "material-ui/Select";
import MenuItem from "material-ui/Menu/MenuItem";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import ExportToExcel from "../../components/ExportToExcel/ExportToExcel";
import {DisplayClassList} from "../../actions/ClassRoomAction";
import {
    GetChildList,
    CreateAssessmentAction,
    HideMsg,
    DisplayAssessmentList,
    DeleteAssessment
} from "../../actions/AssessmentAction";
import {connect} from "react-redux";
import Snackbar from "../../components/Snackbar/Snackbar";
import Edit from "@material-ui/icons/Edit";
import {USER_TYPE} from "../../helper/Cookies";
import SelectTemp from 'react-select';
import Tooltip from "material-ui/Tooltip";
import {liteBlue} from "../../assets/jss/material-dashboard-pro-react";


class Assessments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: ["Id", "Assessment", "Date", "AssessmentUrl"],
            selectClass: [],
            selectChild: [],
            aDate: moment().format('YYYY-MM-DD'),
            aName: "",
            fileName: "No file Chosen",
            file: null,
            fileType: "",
            FromDate: moment().subtract(30, 'd').format('YYYY-MM-DD'),
            ToDate: moment().format('YYYY-MM-DD'),
            tDate: moment().format('YYYY-MM-DD'),
        };
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
    }

    handleCreate = () => {
        //alert();
        this.setState({
            aName: "",
            fileName: "No file Chosen",
            file: null,
            fileType: "",
            selectClass: [],
            // selectChild:[],
            isCreated: !this.state.isCreated,
        });
        const {ClassList} = this.props;
        ClassList();
    };
    onChangeValue = event => {
        this.setState({[event.target.id]: event.target.value});
    };

    SelectClass(value) {
        this.setState({selectClass: value.value});
    };

    handleChange(value) {
        this.setState({selectClass: value.value});
        const {ChildList} = this.props;
        let GetChildArray = [];
        GetChildArray.push(value.value);
        ChildList(GetChildArray);
    }

    handleChild = event => {
        this.setState({selectChild: event.target.value});

    };
    dateChanged = (dateObj) => {
        if (typeof dateObj === 'object')
            this.setState({aDate: dateObj.format('DD-MM-YYYY')});
    };

    selectFromDate = (dateObj) => {
        if (typeof dateObj === 'object')
            this.setState({FromDate: dateObj.format('YYYY-MM-DD')});
    };
    selectToDate = (dateObj) => {
        if (typeof dateObj === 'object')
            this.setState({ToDate: dateObj.format('YYYY-MM-DD')});
    };

    AssessmentCreate = () => {
        const {CreateAssessment} = this.props;
        const {selectChild, selectClass, aName, aDate, file, fileType} = this.state;
        CreateAssessment(selectChild, selectClass, aName, aDate, file, fileType);
    };

    AssessmentGet = () => {
        const {FromDate, ToDate, selectClass} = this.state;
        const {AssessmentList} = this.props;
        AssessmentList(FromDate, ToDate, selectClass);
    }

    handleFileClick() {
        var input = document.createElement("input");
        input.type = "file";
        input.onchange = this.handleFileChange;
        input.click();
    }

    handleFileChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                fileName: file.name,
                fileType: file.type
            });
        };
        reader.readAsDataURL(file);
    }

    deleteAssessment(assessId) {
        const {AssessmentDelete} = this.props;
        AssessmentDelete(assessId);
    }

    componentDidMount() {
        const {ClassList} = this.props;
        // var oneWeekAgo = moment(this.state.FromDate).subtract(7, 'days');
        // var oneWeekAgoDate=oneWeekAgo.format("YYYY-MM-DD");
        ClassList();
        // AssessmentList(this.state.FromDate,oneWeekAgoDate);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {AssessmentList, AssessmentTable, classList, isRequestSent} = nextProps;
        if (AssessmentTable.length <= 0) {
            let ClassArrays = [];
            if (this.state.selectClass.length <= 0) {
                classList.forEach(r => {
                    const {classId} = r;
                    ClassArrays.push(classId)
                });
                this.setState({selectClass: ClassArrays});
            }
            // var oneWeekAgo = moment(this.state.tDate).subtract(30, 'days');
            // var oneWeekAgoDate = oneWeekAgo.format("YYYY-MM-DD");
            if (isRequestSent === false && ClassArrays.length > 0) {
                // {this.setState({tDate:oneWeekAgoDate})}
                AssessmentList(this.state.FromDate, this.state.ToDate, ClassArrays);
            }
        }
        return true;
    }


    handleAttachment(attachmentUrl) {
        if (attachmentUrl !== null && attachmentUrl !== undefined)
            window.open(attachmentUrl, "_blank");
        // window.location.assign(attachmentUrl);
        //window.location.hash(attachmentUrl);
    }

    timeInfo = () => {
        const {HideMsg, AssessmentList, ClassList, deleteSuccess} = this.props;
        setTimeout(() => {
            HideMsg();
            ClassList();
            if (deleteSuccess !== true) {
                this.handleCreate();
            }
            this.AssessmentGet();
        }, 3000)
    };
    getTheme=(theme) => ({
        ...theme,
        borderRadius: '4px',
        height: "20px",
        colors: {
            ...theme.colors,
            //  primary25: '#00ABDC',
            primary:liteBlue,
            //   primary50:"#00ABDC"
        },
    });

    render() {
        const {classes, classList, classWiseList, msg, isFailed, msgColor, HideMsg, AssessmentTable, ClassList} = this.props;
        if (isFailed === true && msg !== null) {
            if (msgColor === "success") {
                this.timeInfo();
            } else {
                setTimeout(() => {
                    HideMsg();
                    ClassList();
                }, 2000)
            }
        }

        let options = [{label: "All Classes", value: -1,}];

        if (classList !== null) {
            classList.forEach(r => {
                const {name, classId} = r;
                options.push({
                    value: classId,
                    label: name
                })
            })
        }

        let ClassNames = [],childNames=[];

        if (classList !== null) {
            classList.forEach(r => {
                const {name, classId} = r;
                ClassNames.push({
                    value: classId,
                    label: name
                })
            })
        }
        if(classWiseList!==null){
            classWiseList.forEach(r => {
                const {name, childId} = r;
                childNames.push({
                    value: childId,
                    label: name
                })
            })

        }
        let ListAssessment = [];
        let columns = [];
        const userType = parseInt(USER_TYPE());
        if (userType === 2 || userType === 5) {
            columns = [
                {
                    Header: "Date",
                    accessor: "assessDate",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Assessment Name",
                    accessor: "assessName",
                    style: {color: "#000", textAlign: "left"}

                },

                {
                    Header: "Attachment",
                    accessor: "attachment",
                    sortable: false,
                    filterable: false,
                }

            ]
        } else {
            columns = [
                {
                    Header: "Date",
                    accessor: "assessDate",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Assessment Name",
                    accessor: "assessName",
                    style: {color: "#000", textAlign: "left"}

                },

                {
                    Header: "Attachment",
                    accessor: "attachment",
                    sortable: false,
                    filterable: false,
                },
                {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                }
            ]
        }
        AssessmentTable.forEach(r => {
            const {assessName, assessDate, attachmentUrl, assessId} = r;
            ListAssessment.push({
                assessId: assessId,
                assessName: assessName,
                assessDate: moment(assessDate).format("DD-MM-YYYY"),
                attachmentUrl: attachmentUrl,
                attachment: (
                    <div>
                        <IconButton
                            onClick={() => {
                                this.handleAttachment(attachmentUrl);
                            }}
                            color="blackNoBackground"
                            customClass="edit">
                            <AttachmentIcon/>
                        </IconButton>{" "}
                    </div>
                ),
                actions: (
                    <div className="actions-right">
                        <Tooltip title="Delete">
                            <IconButton
                                onClick={() => {
                                    {
                                        if (window.confirm('Are you sure you want to Delete this Record?')) {
                                            this.deleteAssessment(assessId)
                                        }
                                        ;
                                    }
                                }}
                                color="dangerNoBackground"
                                customClass="remove">
                                <Close/>
                            </IconButton>
                        </Tooltip>
                    </div>
                )
            })
        })
        return (
            <GridContainer justify="center">
                <GridContainer>
                    <ItemGrid xs={12} sm={6} md={4}>
                        <Snackbar
                            open={isFailed}
                            place={"tc"}
                            color={msgColor}
                            message={msg}
                        />
                    </ItemGrid>
                </GridContainer>
                {this.state.isCreated ?
                    <IconCard
                        icon={Assessment}
                        title="Create Assessment"
                      //  iconColor="rose"
                        content={
                            <form>
                                <CustomInput
                                    labelText="Assessment Name"
                                    id="aName"
                                    onChange={this.onChangeValue}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                                <InputLabel className={classes.label}>
                                    Date
                                </InputLabel>
                                <FormControl fullWidth>
                                    <Datetime
                                        timeFormat={false}
                                        dateFormat="DD-MM-YYYY"
                                        onChange={this.dateChanged}
                                        inputProps={{
                                            id: "cDate",
                                            placeholder: "Select Date",
                                            // value: cDate
                                            // onChange: event => this.handleChangeValues(event, "interviewDate", "length", 0),
                                            // value: this.state.tDate
                                        }}
                                    />
                                </FormControl>
                                <GridContainer justify="center">

                                    <ItemGrid xs={12} sm={6}>
                                        <InputLabel className={classes.label}>
                                            Select Class *
                                        </InputLabel>
                                        <SelectTemp
                                            className="basic-single"
                                            classNamePrefix="select"
                                            // defaultValue={options[0]}
                                            name="selectClass"
                                            options={ClassNames}
                                            onChange={this.handleChange.bind(this)}
                                            theme={(theme) =>this.getTheme(theme)}
                                        />
                                    </ItemGrid>
                                        <ItemGrid xs={12} sm={6}>
                                            <InputLabel className={classes.label}>
                                                Select Child
                                            </InputLabel>
                                            <SelectTemp
                                                className="basic-single"
                                                classNamePrefix="select"
                                                // defaultValue={options[0]}
                                                name="selectChild"
                                                options={childNames}
                                                onChange={(option)=>{
                                                    this.setState({selectChild:option.value})
                                                }}
                                                theme={(theme) =>this.getTheme(theme)}
                                            />
                                        </ItemGrid>

                                </GridContainer>

                                <GridContainer>

                                    <ItemGrid xs={12} sm={6}>
                                        <CustomInput
                                            labelText=" Attachment"
                                            id="attachment"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                disabled: true,
                                                value: "" + this.state.fileName
                                            }}
                                        />
                                    </ItemGrid>
                                    <ItemGrid>
                                        <Button onClick={this.handleFileClick}>Choose File</Button>
                                    </ItemGrid>

                                </GridContainer>

                                <GridContainer justify="center">

                                    <ItemGrid>
                                        <br/>
                                        <Button color="primary" onClick={this.AssessmentCreate}>Save</Button>
                                        <br/>
                                    </ItemGrid>
                                    <ItemGrid>
                                        <br/>
                                        <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                        <br/>
                                    </ItemGrid>

                                </GridContainer>
                            </form>
                        }
                    />
                    :
                    <IconCard
                        icon={Assessment}
                       // iconColor={"rose"}
                        title={
                            <div>
                                Assessments
                                {userType !== 5 ?
                                    <Button
                                        onClick={this.handleCreate}
                                        // round color="white"
                                        color="primary" right={true}> Add Assessment
                                    </Button>
                                    : null}
                            </div>
                        }
                        content={
                            <GridContainer>
                                <ItemGrid xs={12} sm={12}>

                                    <GridContainer>
                                        <ItemGrid xs={12} sm={2} style={{marginTop: "-11px"}}>
                                            <InputLabel className={classes.label}>
                                                From Date
                                            </InputLabel>
                                            <FormControl fullWidth>
                                                <Datetime
                                                    timeFormat={false}
                                                    // defaultValue={ moment(this.state.tDate).subtract(30, 'days')}
                                                    dateFormat="DD-MM-YYYY"
                                                    onChange={this.selectFromDate}
                                                    inputProps={{
                                                        id: "FromDate",
                                                        placeholder: "Select Date",
                                                        value: this.state.FromDate
                                                    }}
                                                />
                                            </FormControl>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2} style={{marginTop: "-11px"}}>
                                            <InputLabel className={classes.label}>
                                                To Date
                                            </InputLabel>
                                            <FormControl fullWidth>
                                                <Datetime
                                                    timeFormat={false}
                                                    dateFormat="DD-MM-YYYY"
                                                    // defaultValue={this.state.tDate}
                                                    onChange={this.selectToDate}
                                                    inputProps={{
                                                        id: "ToDate",
                                                        placeholder: "Select Date",
                                                        value: this.state.ToDate
                                                    }}
                                                />
                                            </FormControl>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={3} style={{marginTop: "-11px"}}>
                                            <InputLabel className={classes.label}>
                                                Class
                                            </InputLabel>
                                            <SelectTemp
                                                className="basic-single"
                                                classNamePrefix="select"
                                                defaultValue={options[0]}
                                                name="selectClass"
                                                options={options}
                                                onChange={this.SelectClass.bind(this)}
                                                theme={(theme) =>this.getTheme(theme)}
                                            />
                                        </ItemGrid>

                                        <ItemGrid xs={12} sm={2}>
                                            <Button color="primary" onClick={this.AssessmentGet}> Search </Button>
                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12}>
                                    <ItemGrid xs={12}
                                              style={{height: 1, backgroundColor: "#00acc1", marginTop: "15px"}}/>
                                    <ReactTable
                                        data={ListAssessment}
                                        filterable
                                        style={{color: "#00acc1"}}
                                        columns={columns}
                                        defaultPageSize={5}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                    />
                                    <ItemGrid xs={12}>
                                        <GridContainer>
                                            <ItemGrid xs={12} md={10}>
                                            </ItemGrid>
                                            <ItemGrid xs={12} md={2}>
                                                <ExportToExcel
                                                    key={ListAssessment}
                                                    posts={ListAssessment}
                                                    headings={this.state.headers}
                                                    fileName={"Assessments"} sheetName={"Assessments"}/>

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                </ItemGrid>
                            </GridContainer>
                        }
                    />
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    btnColor:"primary",
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color:liteBlue,
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex"
    },
    ...customSelectStyle
};
const mapStateToProps = state => {
    const {isFailed, msg, msgColor, AssessmentTable, classList, classWiseList, isRequestSent, deleteSuccess} = state.Assessment;
    return {classList, isFailed, msg, msgColor, AssessmentTable, classWiseList, isRequestSent, deleteSuccess}
};
export default connect(mapStateToProps, {
    CreateAssessment: CreateAssessmentAction, HideMsg,
    ChildList: GetChildList,
    ClassList: DisplayClassList,
    AssessmentList: DisplayAssessmentList,
    AssessmentDelete: DeleteAssessment
})(withStyles(extendedFormsStyle)(Assessments));
