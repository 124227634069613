import schoolAPi from "kidzwapi";
import {
    CREATE_TEACHER_FAIL,
    CREATE_TEACHER_SUCCESS,
    HIDE_INFO_MSG,
    TEACHER_LIST, UPDATE_TEACHER_FAIL,
    UPDATE_TEACHER_SUCCESS,
    DELETE_SUCCESS,DELETE_FAILED
} from "./types";
import {USER_ID, AUTH, SCHOOL_ID} from "../helper/Cookies";
import {runMutation, gqlClient, createTeacherMutation, getTeacher} from "../graphql";


function CreateTeacherSuccess() {
    return {
        type: CREATE_TEACHER_SUCCESS,
        payLoad: "Teacher Created Successfully"
    }
}

function CreateTeacherFailed(msg) {
    return {
        type: CREATE_TEACHER_FAIL,
        payLoad: msg
    }
}

function UpdateTeacherSuccess() {
    return {
        type: UPDATE_TEACHER_SUCCESS,
        payLoad: "Teacher Updated Successfully"
    }
}


function UpdateTeacherFailed(msg) {
    return {
        type: UPDATE_TEACHER_FAIL,
        payLoad: msg
    }
}
function DeleteTeacherSuccess() {
    return {
        type: DELETE_SUCCESS,
        payLoad: "Teacher Deleted Successfully"
    }
}
function DeleteTeacherFailed(msg) {
    return {
        type: DELETE_FAILED,
        payLoad: msg
    }
}

function teacherList(json) {
    return {
        type: TEACHER_LIST,
        payLoad: json
    }
}

function hideMsg() {
    return {
        type: HIDE_INFO_MSG,
        payload: null
    }
}

export function HideMsg() {
    return function (dispatch) {
        dispatch(hideMsg());
    }
}


export function CreateTeacherAction(tUserName, tPassword, tName, multipleClassTeacher, ClassSubjects, tContact, tEmail, attFor) {
    return function (dispatch) {

        if (tName !== null && tContact !== null) {
            // return schoolAPi.createTeacher(parseInt(SCHOOL_ID()), tUserName, tPassword, tName, multipleClassTeacher, ClassSubjects, tContact, tEmail, attFor, USER_ID(), AUTH())
            //     .then(resp => {
            //         if (resp.status) {
            //             dispatch(CreateTeacherSuccess());
            //         } else {
            //             dispatch(CreateTeacherFailed(resp.msg));
            //         }
            //     })

            const params = {
                input: {
                    schoolId: parseInt(SCHOOL_ID()),
                    name: tName,
                    classTeacher: multipleClassTeacher,
                    classes: ClassSubjects,
                    attFor: attFor,
                    contact: tContact,
                    emailId: tEmail,
                    uname: tUserName,
                    pwd:tPassword
                }
            };
            const options = {
                mutation: createTeacherMutation,
                variables: params
            };

            gqlClient.mutate(options).then(res => {
                if (res) {

                    dispatch(CreateTeacherSuccess());
                }
            }).catch(err =>  dispatch(CreateTeacherFailed(err))
            );


        } else {
            dispatch(CreateTeacherFailed("Enter All Fields"));

        }

    }
}

export function UpdateTeacherAction(teacherId, tName, multipleClassTeacher, multipleSubjects, multipleTeachingClasses, tContact, tEmail) {
    return function (dispatch) {
        if (tName !== null && teacherId !== null) {
            return schoolAPi.updateTeacher(teacherId, tName, multipleClassTeacher, multipleSubjects, multipleTeachingClasses, tContact, tEmail, USER_ID(), AUTH())
                .then(resp => {
                    if (resp.status) {
                        dispatch(UpdateTeacherSuccess());
                    } else {
                        dispatch(UpdateTeacherFailed(resp.msg));
                    }
                })
        } else {
            dispatch(UpdateTeacherFailed("Enter All Fields"));

        }

    }
}

export function DispalyTeacherList() {
    return function (dispatch) {
        var params = {

        };

        const options = {
            query: getTeacher,
            variables: params
        };
        gqlClient.query(options).then(res => {
            if (res) {
                dispatch(teacherList(res.data.getTeachers));
            }
        }).catch(err =>  console.log(err));
    }
}

export function DeleteTeacher(teacherId) {
    return function (dispatch) {
        return schoolAPi.deleteTeacher(teacherId, USER_ID(), AUTH())
            .then(
                resp => {
                    if (resp.status) {
                        dispatch(DeleteTeacherSuccess());
                    }else{
                        dispatch(DeleteTeacherFailed(resp.msg));
                    }
                }
            )
    }
}
