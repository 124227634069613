import schoolAPi from "kidzwapi";
import {
    CREATE_STUDENT_FAIL,
    CREATE_STUDENT_SUCCESS,
    HIDE_INFO_MSG,
    STUDENT_LIST, UPDATE_STUDENT_FAIL,
    UPDATE_STUDENT_SUCCESS,
    STUDENT_STATUS, CREATE_PASSWORD_SUCCESS, CREATE_PASSWORD_FAILED, CLASSWISE_STUDENT_LIST, CLASS_LSIT
} from "./types";
import {USER_ID, AUTH,SCHOOL_ID} from "../helper/Cookies";

function CreateStudentSuccess(){
    return {
        type:CREATE_STUDENT_SUCCESS,
        payLoad:"Student Created Successfully"
    }
}


function CreateStudentFailed(msg){
    return{
        type:CREATE_STUDENT_FAIL,
        payLoad: msg
    }
}
function UpdateStudentSuccess(){
    return{
        type:UPDATE_STUDENT_SUCCESS,
        payLoad:"Student Updated Successfully"
    }
}
function UpdateStudentFailed(msg){
    return{
        type:UPDATE_STUDENT_FAIL,
        payLoad:msg
    }
}
function ClassList(json){
    return{
        type:CLASS_LSIT,
        payLoad: json
    }
}
function StudentStatus(msg){
    return{
        type:STUDENT_STATUS,
        payLoad:msg
    }
}

function createPasswordSuccess(msg){
    return{
        type:CREATE_PASSWORD_SUCCESS,
        payLoad:msg
    }
}

function createPasswordFailed(msg){
    return{
        type:CREATE_PASSWORD_FAILED,
        payLoad:msg
    }
}

function getStudents(json){
    return{
        type:CLASSWISE_STUDENT_LIST,
        payLoad:json
    }
}
function hideMsg() {
    return {
        type: HIDE_INFO_MSG,
        payload: null
    }
}

function studentList(json){
    return{
        type:STUDENT_LIST,
        payLoad:json
    }
}
export function HideMsg() {
    return function (dispatch) {
        dispatch(hideMsg());
    }
}

export function CreateSubjectAction(admissionCode, name, dob,bloodGroup, motherName, motherMobile, motherEmail,fatherName, fatherMobile,fatherEmail, classId, profilePic) {
    return function (dispatch) {
        if (admissionCode !== null && name !== null && classId !== null && motherName!=null && motherMobile!==null ) {
            return schoolAPi.createChildren(parseInt(SCHOOL_ID()),admissionCode,name,dob,bloodGroup,motherName,motherMobile,
                motherEmail,fatherName,fatherMobile,fatherEmail,USER_ID(),classId,2019,profilePic,AUTH())
                .then(resp => {
                    if (resp.status) {
                        dispatch(CreateStudentSuccess());
                    } else {
                        dispatch(CreateStudentFailed(resp.msg));
                    }
                })
        } else {
            dispatch(CreateStudentFailed("Enter All Mandatory"));
        }
    }
}

export function UpdateStudentAction( childId,admissionCode, name, dob,bloodGroup, motherName, motherMobile, motherEmail,fatherName, fatherMobile,fatherEmail, profilePic,classId) {
    return function (dispatch) {
        if (admissionCode !== null && childId !== null && name !== null && motherName!=null && motherMobile!==null ) {
            return schoolAPi.updateChildren(childId,parseInt(SCHOOL_ID()),admissionCode,name,dob,bloodGroup,motherName,motherMobile,
                motherEmail,fatherName,fatherMobile,fatherEmail,profilePic,classId,USER_ID(),AUTH())
                .then(resp => {
                    if (resp.status) {
                        dispatch(UpdateStudentSuccess());
                    } else {
                        dispatch(UpdateStudentFailed(resp.msg));
                    }
                })
        } else {
            dispatch(UpdateStudentFailed("Enter All Mandatory"));
        }
    }
}

export function DisplayStudentList(){
    return function(dispatch){
        return schoolAPi.listChildren(parseInt(SCHOOL_ID()), USER_ID(), AUTH())
            .then(resp=>{
                    if(resp.status){
                        dispatch(studentList(resp.students));
                    }
                }
            )
    }
}

export function ChangeStudentStatus(childId,status){
    return function(dispatch){
        return schoolAPi.updateStudentStatus(childId,status, USER_ID(), AUTH())
            .then(resp=>{
                    if(resp.status){
                        dispatch(StudentStatus(resp.status));
                    }
                }
            )
    }
}

export function CreateStudentPassword(childId,npass,cpass){
    return function(dispatch){
        if(npass===cpass){
            if(npass!='' && cpass!='' && childId!=null){
        return schoolAPi.updateStudentPassword(childId,npass, USER_ID(), AUTH())
            .then(resp=>{
                    if(resp.status){
                        dispatch(createPasswordSuccess(resp.msg));
                    }else{
                        dispatch(createPasswordFailed(resp.msg));
                    }
                }
            )
            }else{
                dispatch(createPasswordFailed("Something went wrong please try again!"));
            }
        }else{
            dispatch(createPasswordFailed("Password Miss matching please enter correctly!"));
        }
    }
}
export function GetClassWiseChildList(classId){
    return function (dispatch){
        return schoolAPi.classWiseStudents(parseInt(SCHOOL_ID()),classId, USER_ID(), AUTH())
            .then(resp=>{
                if(resp.status){
                    dispatch(getStudents(resp.students));
                }
            })

    }
}

export function DisplayClassList(){
    return function(dispatch){
        return schoolAPi.listClass(parseInt(SCHOOL_ID()), USER_ID(), AUTH())
            .then(resp => {
                if (resp.status) {
                    dispatch(ClassList(resp.classes));
                }
            })
    }
}
