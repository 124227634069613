import React from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

function ExportToExcel({...props}) {
    const {posts,headings,fileName,sheetName} = props;
    return (
        <div>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="export"
                table="table-to-xls"
                filename={fileName}
                sheet={sheetName}
                buttonText="Export To Excel"
            />

            <table id="table-to-xls" style={{display:"none"}} >

                <tr>
                {
                    headings.map(post =>{
                            return(

                                    <th>{post.toString()}</th>
                            )
                        }

                    )
                }
                </tr>
                <tbody>


                {
                    posts.map(post =>{
                            return(
                                <tr>
                                    {
                                        Object.keys(post).map((key, index) => {
                                            const myItem = post[key];
                                            return  <td>{myItem}</td>
                                        })
                                    }
                                  {/*  <td>{post.name}</td>
                                    <td>{post.office}</td>
                                    <td>{post.position}</td>*/}
                                </tr>
                            )
                        }

                    )
                }
                </tbody>
            </table>

        </div>
    );

}


export default ExportToExcel;