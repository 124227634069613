import {USER_ID, AUTH, SCHOOL_ID} from "../helper/Cookies";
import {
    GET_FEE_DATA,CREATE_RECEIPT_SUCCESS,CREATE_RECEIPT_FAILED,HIDE_INFO_MSG,GET_FEE_RECEIPTS_DATA
} from "./types";
import {runMutation,getFeeReceipt, gqlClient,createFeeReceiptMutation,getFee} from "../graphql";



function CreateFeeReceiptSuccess() {
    return {
        type: CREATE_RECEIPT_SUCCESS,
        payLoad: "Fee Receipt Created Successfully"
    }
}


function CreateFeeReceiptFailed(msg) {
    return {
        type: CREATE_RECEIPT_FAILED,
        payLoad: msg
    }
}
 function GetFeeReceipts(data){
    return{
        type:GET_FEE_RECEIPTS_DATA,
        payLoad:data
    }
 }
//
// function DeleteFeeFailed(){
//     return{
//         type:DELETE_SUCCESS,
//         payLoad:"Something went Wrong"
//     }
// }

function getFeeList(data){
    return{
        type:GET_FEE_DATA,
        payLoad:data
    }
}

function hideMsg() {
    return {
        type: HIDE_INFO_MSG,
        payload: null
    }
}

export function HideMsg() {
    return function (dispatch) {
        dispatch(hideMsg());
    }

}





export function CreateFeeReceipt(childId, term, termName, amount, items) {
    return function (dispatch) {
        if (childId !== null && term !== null && items.length > 0 && amount > 0 ) {
            const params = {
                input: {
                    schoolId: parseInt(SCHOOL_ID()),
                    childId: childId,
                    term: term,
                    termName: termName,
                    items: items,
                    amount: amount
                }
            };
            const options = {
                mutation: createFeeReceiptMutation,
                variables: params
            };
            // client.request(createFeeMutation, params).then(res => console.log(res)).catch(err => console.log(err));
                    gqlClient.mutate(options).then(res => {
                        if (res) {
                            dispatch(CreateFeeReceiptSuccess());
                        }
                    }).catch(err =>  dispatch(CreateFeeReceiptSuccess(err))
                    );
        } else {
            dispatch(CreateFeeReceiptSuccess("Enter All Fields"))
        }

    }
}

export function GetFees(classId) {
    return function (dispatch) {
        if(classId===undefined || classId===""){
            var params = {
                schoolId: parseInt(SCHOOL_ID())
            };
        }else{
            var params = {
                schoolId: parseInt(SCHOOL_ID()),
                classId: classId
            };
        }

        const options = {
            query: getFee,
            variables: params
        };
        gqlClient.query(options).then(res => {
            if (res) {
                dispatch(getFeeList(res.data.fees));
            }
        }).catch(err =>  console.log(err));
    }
}


export function GetReceipts(childId) {
    return function (dispatch) {
        // if(classId===undefined || childId===""){
        //     var params = {
        //         schoolId: parseInt(SCHOOL_ID())
        //     };
        // }else{
        if(childId!=="" && childId!==undefined ){
            var params = {
                schoolId: parseInt(SCHOOL_ID()),
                childId: childId
            };
        }else{
            var params = {
                schoolId: parseInt(SCHOOL_ID())
            };
        }
        console.log("parms",params);

         // }

        const options = {
            query: getFeeReceipt,
            variables: params
        };
        gqlClient.query(options).then(res => {
            if (res) {
                dispatch(GetFeeReceipts(res.data.getReceipts));
            }
        }).catch(err =>  console.log(err));
    }
}

