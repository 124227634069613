import {
    CLASS_LSIT, CREATE_STUDENT_FAIL,
    CREATE_STUDENT_SUCCESS, STUDENT_LIST,
    UPDATE_STUDENT_FAIL, UPDATE_STUDENT_SUCCESS,
    STUDENT_STATUS, CREATE_PASSWORD_SUCCESS, CREATE_PASSWORD_FAILED, CLASSWISE_STUDENT_LIST
} from "../actions/types";
const INIT_STATE = {isFailed: false, msg: null, msgColor: "danger", dataTable: [],tableList:[],StatusStudent:false,classWiseList:[]};
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CREATE_STUDENT_SUCCESS:
            return {...state,isFailed: true,msg: action.payLoad, msgColor: "success"};
        case CREATE_STUDENT_FAIL:
            return {...state, isFailed: true, msg: action.payLoad};
        case UPDATE_STUDENT_SUCCESS:
            return {...state,isFailed: true,msg: action.payLoad, msgColor: "success"};
        case UPDATE_STUDENT_FAIL:
            return {...state, isFailed: true, msg: action.payLoad};
        case CLASS_LSIT:
            return {...state, dataTable: action.payLoad};
        case STUDENT_LIST:
            return{...state,tableList:action.payLoad};
        case STUDENT_STATUS:
            return{...state,StatusStudent:true};
        case CREATE_PASSWORD_SUCCESS:
            return {...state,isFailed: true,msg: action.payLoad, msgColor: "success"};
        case CREATE_PASSWORD_FAILED:
            return {...state, isFailed: true, msg: action.payLoad};
        case CLASSWISE_STUDENT_LIST:
            return{...state,tableList:action.payLoad};
        default:
            return INIT_STATE;

    }
}
