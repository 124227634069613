import schoolAPi from "kidzwapi";
import { FORGET_PASSWORD_SUCCESS,FORGET_PASSWORD_FAIL, HIDE_INFO_MSG} from "./types";
import {AUTH, SCHOOL_ID, USER_ID} from "../helper/Cookies";

function ForgetPasswordSuccess(msg){
    return{
        type:FORGET_PASSWORD_SUCCESS,
        payLoad:msg
    }
}

function ForgetPasswordFailed(msg){
    return{
        type:FORGET_PASSWORD_FAIL,
        payLoad:msg
    }
}

function hideMsg(){
    return{
        type:HIDE_INFO_MSG,
        payLoad:null
    }
}

export function HideMsg() {
    return function(dispatch){
        dispatch(hideMsg());
    }

}

export function ForgetPasswordAction(emailId){
    return function (dispatch){
        if(emailId!=''){
            return schoolAPi.forgotPassword(emailId)
            .then(resp=>{
                if(resp.status){
                    dispatch(ForgetPasswordSuccess(resp.msg));
                }else{
                    dispatch(ForgetPasswordFailed(resp.msg));
                }
            })
    }else{
            dispatch(ForgetPasswordFailed("please enter Email Id."));
        }
    }
}
