import {
    CLASS_LSIT,
    CREATE_TEACHER_FAIL,
    CREATE_TEACHER_SUCCESS, DELETE_FAILED, DELETE_SUCCESS, SUBJECT_LIST,
    TEACHER_LIST,
    UPDATE_TEACHER_FAIL,
    UPDATE_TEACHER_SUCCESS
} from "../actions/types";
const INIT_STATE = {isFailed: false, msg: null, msgColor: "danger", dataTable: [],classList:[],subjectList:[],deleteTeacher:false};
export default (state = INIT_STATE, action)=>{
    switch (action.type) {
        case CLASS_LSIT:
            return{...state,classList:action.payLoad};
        case SUBJECT_LIST:
            return{...state,subjectList:action.payLoad}
        case CREATE_TEACHER_SUCCESS:
            return {...state,isFailed: true,msg:action.payLoad,msgColor: "success"};
        case CREATE_TEACHER_FAIL:
            return{...state,isFailed:true,msg:action.payLoad};
        case UPDATE_TEACHER_SUCCESS:
            return {...state,isFailed: true,msg:action.payLoad,msgColor: "success"};
        case UPDATE_TEACHER_FAIL:
            return{...state,isFailed:true,msg:action.payLoad};
        case TEACHER_LIST:
            return {...state, dataTable: action.payLoad};
        case DELETE_SUCCESS:
            return {...state, isFailed: true,deleteTeacher:true,msg: action.payLoad,msgColor: "success"};
        case DELETE_FAILED:
            return {...state, isFailed: true,deleteTeacher:true,msg: action.payLoad};
        default:
            return INIT_STATE;
    }
}
