import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";


// @material-ui/icons
import Close from "@material-ui/icons/Close";

import AttendanceIcon from "@material-ui/icons/Assignment";
import tempIcon from "../../assets/icons/thermometer1.png"
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import {dataTable} from "variables/general.jsx";
import {AttendanceType, ChildDropLIst} from "../../variables/menus";
import Button from "../../components/CustomButtons/Button";
import withStyles from "material-ui/styles/withStyles";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "material-ui/Select";
import MenuItem from "material-ui/Menu/MenuItem";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import ExportToExcel from "../../components/ExportToExcel/ExportToExcel";
import {connect} from "react-redux";
import {DisplayClassList} from "../../actions/ClassRoomAction";
import {GetChildList} from "../../actions/AssessmentAction";
import {SaveAttendanceAction, HideMsg, DisplayListAttendance, DeleteAttendance} from "../../actions/AttendanceAction";
import Snackbar from "../../components/Snackbar/Snackbar";
import Edit from "@material-ui/icons/Edit";
import {USER_TYPE} from "../../helper/Cookies";
import SelectTemp from 'react-select';
import moment from "moment";
import Tooltip from "material-ui/Tooltip";
import {liteBlue} from "../../assets/jss/material-dashboard-pro-react";

class Attendance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: ["Code", "Class", "Date", "Subject", "Homework"],
            alert: null,
            data: dataTable.dataRows.map((prop, key) => {
                return ({
                    id: key,
                    name: prop[0],
                    position: prop[1],
                    office: prop[2],
                    age: prop[3],
                    actions: (
                        // we've added some custom button actions
                        <div className="actions-right">

                            <IconButton
                                onClick={() => {
                                    this.showTemperatures();
                                    /* var data = this.state.data;
                                     data.find((o,i) => {
                                         if(o.id === key){
                                             // here you should add some custom code so you can delete the data
                                             // from this component and from your server as well
                                             data.splice(i,1);
                                             return true;
                                         }
                                         return false;
                                     });
                                     this.setState({data: data});*/
                                }}
                                color="blackNoBackground"
                                customClass="remove">
                                <img src={tempIcon} alt="..."/>
                            </IconButton>
                        </div>

                    )
                })
            }),
            selectClass: 0,
            attendDate: null,
            attendSelDate: moment().format('YYYY-MM-DD'),
            listChildren: false,
            selectAttendance: '',
            isDisabled: false,
            selectDropBy: "",
            selectPickupBy: "",
            isAbsent: 0,
            pickBy: null,
            dropBy: null,
            inTime: null,
            outTime: null,
            attendance: [],
            fileName: "No file Chosen",
            file: null,
            ToDate: "",
            // child_droping: ["Mother", "Father", "Grand Mother", "Grand Father","Helper","School Bus","Driver"],
            // child_list: ["Child A", "Child B", "Child C", "Child D","Child E","Child F","Child G" ],
            // classes_list: ["1st Standard", "2nd Standard", "3rd Standard", "4th Standard","5th Standard","6th Standard","7th Standard" ],
            OlyShowTemp: false,
            showList: false
        };
        // this.handleFileChange = this.handleFileChange.bind(this);
        // this.handleFileClick = this.handleFileClick.bind(this);
        this.hideAlert = this.hideAlert.bind(this)
    }

    handleCreate = () => {
        //alert();
        this.setState({
            isCreated: !this.state.isCreated,
            selectClass: 0,
            listChildren: false
        });
    };
    handleSimple = (key, value) => {
        if (typeof value === 'object') {
            this.setState({[key]: value.format('hh:mm')});
        } else {
            this.setState({[key]: value});
        }
    };
    saveAttendance = () => {
        const setAttendance = [];
        const {classWiseList} = this.props;
        classWiseList.forEach(h => {
            setAttendance.push({
                childId: h.childId,
                isAbsent: (this.state["selectAttendance" + h.childId] === 0 || this.state["selectAttendance" + h.childId] === undefined) ? false : true,
                inTime: this.state["inTime" + h.childId] === undefined ? null : this.state["inTime" + h.childId],
                outTime: this.state["outTime" + h.childId] === undefined ? null : this.state["outTime" + h.childId],
                dropBy: this.state["selectDropBy" + h.childId] === undefined ? null : this.state["selectDropBy" + h.childId],
                pickBy: this.state["selectPickupBy" + h.childId] === undefined ? null : this.state["selectPickupBy" + h.childId],
            });
            this.setState({attendance: setAttendance, showList: false});

        });

        const {attendDate, selectClass} = this.state;
        const {CreateAttendance} = this.props;
        CreateAttendance(selectClass, attendDate, setAttendance);
    };

    hideAlert() {
        this.setState({
            alert: null
        });
    }

    dateChanged = (dateObj) => {
        if (typeof dateObj === 'object')
            this.setState({attendDate: dateObj.format('DD-MM-YYYY')});
    };

    dateSelChanged = (dateObj) => {
        if (typeof dateObj === 'object')
            this.setState({attendSelDate: dateObj.format('YYYY-MM-DD')});
    };
    selectToDAte = (dateObj) => {
        if (typeof dateObj === 'object')
            this.setState({ToDate: dateObj.format('YYYY-MM-DD')});
    };

    handleClass(value) {
        this.setState({selectClass: value.value});
        const {ChildList} = this.props;
        let GetChildArray = [];
        GetChildArray.push(value.value);
        ChildList(GetChildArray);
    };

    getAttendanceList = () => {
        const {attendSelDate, selectClass, ToDate} = this.state;
        const {AttendanceList} = this.props;
        AttendanceList(attendSelDate, selectClass, ToDate);
        this.setState({showList: true});
    };
    timeInfo = () => {
        const {HideMsg, ClassList, deleteSuccess} = this.props;
        setTimeout(() => {
            HideMsg();
            ClassList(true);
            if (deleteSuccess !== true) {
                this.handleCreate();

            }

        }, 3000)
    };
    showChild = () => {
        this.setState({listChildren: true});
    };

    deleteAttendance(schoolId, classId, studentId, attDate) {
        const {AttendanceDelete} = this.props;
        AttendanceDelete(schoolId, classId, studentId, attDate);
    }

    componentDidMount() {
        const {ClassList} = this.props;
        ClassList(true);
    }
    getTheme=(theme) => ({
        ...theme,
        borderRadius: '4px',
        height: "20px",
        colors: {
            ...theme.colors,
            //  primary25: '#00ABDC',
            primary:liteBlue,
            //   primary50:"#00ABDC"
        },
    });


    render() {
        const {classes, classList, classWiseList, isFailed, msg, msgColor, HideMsg, attendanceTable} = this.props;
        if (isFailed === true && msg !== null) {
            if (msgColor === "success") {
                this.timeInfo();
            } else {
                setTimeout(() => {
                    HideMsg();
                }, 2000)
            }
        }

        let options = [];

        if (classList !== null) {
            classList.forEach(r => {
                const {name, classId} = r;
                options.push({
                    value: classId,
                    label: name
                })
            })
        }

        let ListAttendance = [];
        let columns = [];
        const userType = parseInt(USER_TYPE());
        // if(this.state.showList === true) {
        //     if (attendanceTable.length > 0) {
        if (userType === 2 || userType === 5) {
            columns = [

                {
                    Header: "Child Name",
                    accessor: "name",
                    minWidth:100,
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Attendance",
                    accessor: "isAbsent",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Date",
                    accessor: "attDate",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "InTime",
                    accessor: "inTime",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "DropBy",
                    accessor: "dropBy",
                    minWidth:100,
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "PickUpBy",
                    accessor: "pickBy",
                    minWidth:100,
                    style: {color: "#000", textAlign: "left"}

                },

                {
                    Header: "OutTime",
                    accessor: "outTime",
                    style: {color: "#000", textAlign: "left"}

                }
            ]
        } else {
            columns = [

                {
                    Header: "Child Name",
                    accessor: "name",
                    minWidth:100,
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Attendance",
                    accessor: "isAbsent",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Date",
                    accessor: "attDate",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "InTime",
                    accessor: "inTime",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "DropBy",
                    accessor: "dropBy",
                    minWidth:100,
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "PickUpBy",
                    accessor: "pickBy",
                    minWidth:100,
                    style: {color: "#000", textAlign: "left"}

                },

                {
                    Header: "OutTime",
                    accessor: "outTime",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                }
            ]
        }
        attendanceTable.forEach(r => {
            const {name, inTime, outTime, dropBy, pickBy, isAbsent, attDate, classId, studentId, schoolId} = r;
            ListAttendance.push({
                schoolId: schoolId,
                studentId: studentId,
                classId: classId,
                name: name,
                inTime: inTime,
                outTime: outTime,
                dropBy: dropBy,
                pickBy: pickBy,
                attDate: attDate,
                isAbsent: (isAbsent === true) ? "Absent" : "Present",
                actions: (
                    <div className="actions-right">
                        <Tooltip title="Delete">
                            <IconButton
                                onClick={() => {
                                    {
                                        if (window.confirm('Are you sure you want to Delete this Record?')) {
                                            this.deleteAttendance(schoolId, classId, studentId, attDate)
                                        }
                                        ;
                                    }
                                }}
                                color="dangerNoBackground"
                                customClass="remove">
                                <Close/>
                            </IconButton>
                        </Tooltip>
                    </div>
                )
            })
        })
        //     }
        // }
        return (
            <GridContainer justify="center">
                <GridContainer>
                    <ItemGrid xs={12} sm={6} md={4}>
                        <Snackbar
                            open={isFailed}
                            place={"tc"}
                            color={msgColor}
                            message={msg}
                        />
                    </ItemGrid>
                </GridContainer> {this.state.isCreated ?
                <IconCard
                    icon={AttendanceIcon}
                    title="Create Attendance"
                  //  iconColor="rose"
                    content={
                        <form>
                            {this.state.alert}
                            <GridContainer justify="center">

                                <ItemGrid xs={12} sm={3}>
                                    <InputLabel className={classes.label}>
                                        Date *
                                    </InputLabel>
                                    <FormControl fullWidth>
                                        <Datetime
                                            timeFormat={false}
                                            dateFormat="DD-MM-YYYY"
                                            onChange={this.dateChanged}
                                            inputProps={{
                                                id: "attendDate",
                                                placeholder: "Select Date",
                                                // value: cDate
                                                // onChange: event => this.handleChangeValues(event, "interviewDate", "length", 0),
                                                // value: this.state.tDate
                                            }}
                                        />
                                    </FormControl>
                                </ItemGrid>

                                <ItemGrid xs={12} sm={6}>
                                    <InputLabel className={classes.label}>
                                        Class *
                                    </InputLabel>


                                    <SelectTemp
                                        className="basic-single"
                                        classNamePrefix="select"
                                        // defaultValue={options[0]}
                                        name="selectClass"
                                        options={options}
                                        onChange={this.handleClass.bind(this)}
                                        theme={(theme) => this.getTheme(theme)}
                                    />
                                </ItemGrid>
                                <ItemGrid xs={12} sm={3}>
                                    <Button color="primary" onClick={this.showChild}> Go </Button>
                                </ItemGrid>
                            </GridContainer>
                            <br/>
                            {this.state.listChildren === true ?
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={1}>
                                            <InputLabel className={classes.label}>
                                                Roll No/Admission Code
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <InputLabel className={classes.label}>
                                                Child Name
                                            </InputLabel>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={9}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={2}>
                                                    <InputLabel className={classes.label}>
                                                        Attendance *
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={2}>
                                                    <InputLabel className={classes.label}>
                                                        In Time
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={3}>
                                                    <InputLabel className={classes.label}>
                                                        Drop By
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={2}>
                                                    <InputLabel className={classes.label}>
                                                        Out Time
                                                    </InputLabel>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={3}>
                                                    <InputLabel className={classes.label}>
                                                        Pickup By
                                                    </InputLabel>
                                                </ItemGrid>

                                                {/*<ItemGrid  xs={12} sm={2}>*/}
                                                {/*<InputLabel className={classes.label}>*/}
                                                {/*Temperature*/}
                                                {/*</InputLabel>*/}
                                                {/*</ItemGrid>*/}

                                            </GridContainer>
                                        </ItemGrid>

                                    <br/>
                                    {classWiseList.map((data) => {
                                        var childtype=""
                                        return (
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={1}>
                                                    <br/>
                                                    {data.rollNo !== undefined ?
                                                        <legend style={{fontSize: "16px"}}>
                                                            {data.rollNo}
                                                        </legend>
                                                        : <legend style={{fontSize: "16px"}}>
                                                            {data.admissionCode}
                                                        </legend>}
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={2}>
                                                    <br/>
                                                    <legend style={{fontSize: "16px"}}>
                                                        {data.name}
                                                    </legend>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={9}>
                                                    <GridContainer>

                                                        <ItemGrid xs={12} sm={2}>
                                                            <SelectTemp
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                // defaultValue={options[0]}
                                                                name={"selectAttendance"+ data.childId}
                                                                options={AttendanceType}
                                                                onChange={(obj)=>{
                                                                    this.setState({
                                                                        ["selectAttendance"+ data.childId]:obj.value
                                                                    })
                                                                }}
                                                                theme={(theme) => this.getTheme(theme)}
                                                            />


  {/*
                                                            <FormControl
                                                                fullWidth
                                                                className={classes.selectFormControl}
                                                            >
                                                                <Select
                                                                    MenuProps={{
                                                                        className: classes.selectMenu
                                                                    }}
                                                                    classes={{
                                                                        select: classes.select
                                                                    }}
                                                                    //value={this.state.selectAttendance}
                                                                    value={this.state["selectAttendance" + data.childId] || 0}
                                                                    onChange={event => this.handleSimple("selectAttendance" + data.childId, event.target.value)}

                                                                    inputProps={{
                                                                        name: "selectAttendance",
                                                                        id: "selectAttendance",
                                                                        placeholder: "Select",

                                                                    }}
                                                                >
                                                                    <MenuItem
                                                                        disabled
                                                                        classes={{
                                                                            root: classes.selectMenuItem
                                                                        }}
                                                                    >
                                                                        Select
                                                                    </MenuItem>
                                                                    {AttendanceType.map((data) => {
                                                                        return (
                                                                            <MenuItem
                                                                                key={data.Id}
                                                                                classes={{
                                                                                    root: classes.selectMenuItem,
                                                                                    selected: classes.selectMenuItemSelected
                                                                                }}
                                                                                value={data.isAbsent}
                                                                            >
                                                                                {data.name}
                                                                            </MenuItem>
                                                                        )
                                                                    })
                                                                    }
                                                                </Select>
                                                            </FormControl>
*/}


                                                        </ItemGrid>

                                                        <ItemGrid xs={12} sm={2}>
                                                            <Datetime
                                                                dateFormat={false}
                                                                id={"inTime" + data.childId}
                                                                onChange={this.handleSimple.bind(this, "inTime" + data.childId)}
                                                                inputProps={{
                                                                    placeholder: "Select Time",
                                                                    disabled: (this.state["selectAttendance" + data.childId] === 1 ? true : false)
                                                                }}
                                                            />
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={3}>
                                                            <SelectTemp
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                // defaultValue={options[0]}
                                                                name={"selectDropBy"+ data.childId}
                                                                options={ChildDropLIst}
                                                                onChange={(obj)=>{
                                                                    this.setState({
                                                                        ["selectDropBy"+ data.childId]:obj.value
                                                                    })
                                                                }}
                                                                theme={(theme) => this.getTheme(theme)}
                                                            />

{/*
                                                            <FormControl
                                                                fullWidth
                                                                className={classes.selectFormControl}
                                                            >
                                                                <Select
                                                                    MenuProps={{
                                                                        className: classes.selectMenu
                                                                    }}
                                                                    classes={{
                                                                        select: classes.select
                                                                    }}
                                                                    value={this.state["selectDropBy" + data.childId] || -1}
                                                                    id={"selectDropBy" + data.childId}
                                                                    onChange={event => this.handleSimple("selectDropBy" + data.childId, event.target.value)}
                                                                    inputProps={{
                                                                        name: "selectDropBy",
                                                                        id: "selectDropBy",
                                                                        placeholder: "Select",
                                                                        disabled: (this.state["selectAttendance" + data.childId] === 1 ? true : false)

                                                                    }}
                                                                >
                                                                    <MenuItem
                                                                        disabled
                                                                        classes={{
                                                                            root: classes.selectMenuItem
                                                                        }}
                                                                    >
                                                                        Select
                                                                    </MenuItem>
                                                                    {ChildDropLIst.map((data) => {
                                                                        return (
                                                                            <MenuItem
                                                                                key={data.Id}
                                                                                classes={{
                                                                                    root: classes.selectMenuItem,
                                                                                    selected: classes.selectMenuItemSelected
                                                                                }}
                                                                                value={data.value}
                                                                            >
                                                                                {data.name}
                                                                            </MenuItem>

                                                                        )
                                                                    })
                                                                    }
                                                                </Select>
                                                            </FormControl>
*/}


                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={2}>
                                                            <FormControl fullWidth>
                                                                <Datetime
                                                                    dateFormat={false}
                                                                    id={"outTime" + data.childId}
                                                                    onChange={this.handleSimple.bind(this, "outTime" + data.childId)}
                                                                    inputProps={{
                                                                        placeholder: "Select Time",
                                                                        disabled: (this.state["selectAttendance" + data.childId] === 1 ? true : false)
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={3}>
                                                            <SelectTemp
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                // defaultValue={options[0]}
                                                                name={"selectPickupBy"+ data.childId}
                                                                options={ChildDropLIst}
                                                                onChange={(obj)=>{
                                                                    this.setState({
                                                                        ["selectPickupBy"+ data.childId]:obj.value
                                                                    })
                                                                }}
                                                                theme={(theme) => this.getTheme(theme)}
                                                            />

{/*
                                                            <FormControl
                                                                fullWidth
                                                                className={classes.selectFormControl}
                                                            >
                                                                <Select
                                                                    MenuProps={{
                                                                        className: classes.selectMenu
                                                                    }}
                                                                    classes={{
                                                                        select: classes.select
                                                                    }}
                                                                    value={this.state["selectPickupBy" + data.childId] || -1}
                                                                    id={"selectPickupBy" + data.childId}
                                                                    onChange={event => this.handleSimple("selectPickupBy" + data.childId, event.target.value)}
                                                                    inputProps={{
                                                                        name: "selectPickupBy",
                                                                        id: "selectPickupBy",
                                                                        placeholder: "Select",
                                                                        disabled: (this.state["selectAttendance" + data.childId] === 1 ? true : false)
                                                                    }}
                                                                >
                                                                    <MenuItem
                                                                        disabled
                                                                        classes={{
                                                                            root: classes.selectMenuItem
                                                                        }}
                                                                    >
                                                                        Select
                                                                    </MenuItem>
                                                                    {ChildDropLIst.map((data) => {
                                                                        return (
                                                                            <MenuItem
                                                                                key={data.Id}
                                                                                classes={{
                                                                                    root: classes.selectMenuItem,
                                                                                    selected: classes.selectMenuItemSelected
                                                                                }}
                                                                                value={data.value}
                                                                            >
                                                                                {data.name}
                                                                            </MenuItem>
                                                                        )
                                                                    })
                                                                    }
                                                                </Select>
                                                            </FormControl>
*/}


                                                        </ItemGrid>
                                                        <ItemGrid/>
                                                    </GridContainer>
                                                </ItemGrid>
                                            </GridContainer>
                                        )
                                    })
                                    }

                                    <GridContainer justify="center">

                                        <ItemGrid>
                                            <br/>
                                            <Button color="primary" onClick={this.saveAttendance}>Save</Button>
                                            <br/>
                                        </ItemGrid>
                                        <ItemGrid>
                                            <br/>
                                            <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                            <br/>
                                        </ItemGrid>

                                    </GridContainer>
                                </GridContainer>

                                : null}
                        </form>

                    }
                />
                :
                <IconCard
                    icon={AttendanceIcon}
                  //  iconColor={"rose"}
                    title={
                        <div>
                            Attendance
                            {userType !== 5 ?
                                <Button
                                    onClick={this.handleCreate}
                                    // round color="white"
                                    color={"primary"} right={true}> Add Attendance
                                </Button>
                                : null}
                        </div>
                    }
                    content={
                        <GridContainer>
                            <ItemGrid xs={12} sm={12}>
                                {this.state.alert}
                                <GridContainer>
                                    <ItemGrid xs={12} sm={2} style={{marginTop: "-11px"}}>
                                        <InputLabel className={classes.label}>
                                            From Date
                                        </InputLabel>
                                        <FormControl fullWidth>
                                            <Datetime
                                                timeFormat={false}
                                                dateFormat="DD-MM-YYYY"
                                                onChange={this.dateSelChanged}
                                                inputProps={{
                                                    id: "attenSelDate",
                                                    placeholder: "Select Date",
                                                    // value: cDate
                                                    // onChange: event => this.handleChangeValues(event, "interviewDate", "length", 0),
                                                    // value: this.state.tDate
                                                }}
                                            />
                                        </FormControl>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={2} style={{marginTop: "-11px"}}>
                                        <InputLabel className={classes.label}>
                                            To Date
                                        </InputLabel>
                                        <FormControl fullWidth>
                                            <Datetime
                                                timeFormat={false}
                                                dateFormat="DD-MM-YYYY"
                                                onChange={this.selectToDAte}
                                                inputProps={{
                                                    id: "ToDate",
                                                    placeholder: "Select Date",
                                                    // value: cDate
                                                    // onChange: event => this.handleChangeValues(event, "interviewDate", "length", 0),
                                                    // value: this.state.tDate
                                                }}
                                            />
                                        </FormControl>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={3} style={{marginTop: "-11px"}}>
                                        <InputLabel className={classes.label}>
                                            Class
                                        </InputLabel>
                                        <SelectTemp
                                            className="basic-single"
                                            classNamePrefix="select"
                                            // defaultValue={options[0]}
                                            name="selectClass"
                                            options={options}
                                            onChange={this.handleClass.bind(this)}
                                            theme={(theme) => this.getTheme(theme)}
                                        />
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={3}>
                                        <Button color="primary" onClick={this.getAttendanceList}> Search </Button>
                                    </ItemGrid>
                                </GridContainer>
                            </ItemGrid>
                            <ItemGrid xs={12}>
                                <ItemGrid xs={12}
                                          style={{height: 1, backgroundColor: "#00acc1", marginTop: "15px"}}/>
                                <ReactTable
                                    data={ListAttendance}
                                    filterable
                                    style={{color: "#00acc1"}}
                                    columns={columns}
                                    defaultPageSize={5}
                                    showPaginationBottom={true}
                                    className="-striped -highlight"
                                >
                                </ReactTable>
                                <ItemGrid xs={12}>
                                    <GridContainer>
                                        <ItemGrid xs={12} md={10}>
                                        </ItemGrid>
                                        <ItemGrid xs={12} md={2}>
                                            <ExportToExcel
                                                posts={this.state.data}
                                                headings={this.state.headers}
                                                fileName={"Fee Invoices"} sheetName={"Fee Invoice"}/>
                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>

                            </ItemGrid>
                            {/*:null}*/}
                        </GridContainer>
                    }
                />
            }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    btnColor:"primary",
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color:liteBlue,
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    ...customSelectStyle
};
const mapStateToProps = state => {
    const {isFailed, msg, msgColor, classList, classWiseList, attendanceTable, deleteSuccess} = state.Attendance;
    return {classList, isFailed, msg, msgColor, classWiseList, attendanceTable, deleteSuccess}
}
export default connect(mapStateToProps, {
    CreateAttendance: SaveAttendanceAction, HideMsg,
    ChildList: GetChildList,
    ClassList: DisplayClassList,
    AttendanceList: DisplayListAttendance,
    AttendanceDelete: DeleteAttendance
})(withStyles(extendedFormsStyle)(Attendance));
