import React, {isValidElement} from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";


import {Check,School} from "@material-ui/icons";
import {dashRoutes} from "../../routes/dashboard";
import CustomInput from "../../components/CustomInput/CustomInput";
import InputLabel from "material-ui/Input/InputLabel";
import Button from "../../components/CustomButtons/Button";
import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import Checkbox from "material-ui/Checkbox/Checkbox";
import {connect} from "react-redux";
import withStyles from "material-ui/styles/withStyles"
import {createSchoolAction,HideMsg,DisplaySchoolList,updateSchoolAction} from "../../actions/SchoolAction";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import Snackbar from "../../components/Snackbar/Snackbar";
import { USER_TYPE} from "../../helper/Cookies";
import Select from "material-ui/Select";
import MenuItem from "material-ui/Menu/MenuItem";
import {schoolCatagory, schoolCatagoryNew} from "../../variables/menus";
import FormControl from "material-ui/Form/FormControl";
import SelectTemp from "react-select";
import {liteBlue} from "../../assets/jss/material-dashboard-pro-react";

class SubSchools extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            tittle: "Create School",
            hideButton: true,
            schoolName:null,
            schoolCode:null,
            schoolMobile:null,
            schoolLandline:null,
            schoolEmail:null,
            facebookUrl:null,
            twitterUrl:null,
            schoolAddress:null,
            schoolCity:null,
            schoolCountry:"India",
            schoolWebsite:null,
            isDisabled:false,
            schoolContactPerson:null,
            menus:[],
            cType:'',
            file: null,
            fileName:"No file Chosen",
            imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage

        };
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
    }

    onChangeValue = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        })
    };
    handleSimple = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    schoolCreate = () => {
        const{CreateSchool}=this.props;
        const{schoolName, schoolCode, schoolAddress, schoolLandline,schoolCity, schoolCountry, schoolMobile,
            schoolEmail, schoolWebsite, menus, file,facebookUrl,twitterUrl,cType,schoolContactPerson}=this.state;
        const socialLinks=[
            {
                name:"Facebook",
                url:facebookUrl
            },
            {
                name:"Twitter",
                url:twitterUrl
            }
        ];

        CreateSchool(schoolName, schoolCode, schoolAddress, schoolLandline,schoolCity, schoolCountry, schoolMobile,
            schoolEmail, schoolWebsite, socialLinks, menus, file,cType,schoolContactPerson);

    };


    handleUpdateSchool = (schoolId,name,code,address,city,country,contact,phone,email,website,socialLinks,logoUrl,menu) => {

        this.setState({
            tittle: "Update School",
            buttonType: "Update",
            hideButton: false,
            schoolId:schoolId,
            schoolName:name,
            schoolCode:code,
            schoolAddress:address,
            schoolLandline:phone,
            schoolCity:city,
            schoolCountry:country,
            schoolMobile:contact,
            schoolEmail:email,
            menus:menu,
            schoolWebsite:website,
            isDisabled:true,
            isCreated: !this.state.createId,
            listId: 0
        });
        if(socialLinks !==null && socialLinks!==undefined){
            if (socialLinks.length > 0){

                this.setState({  facebookUrl:socialLinks[0].url});
            }else{
                this.setState({  facebookUrl:null});
            }
            if (socialLinks.length > 1){

                this.setState({  twitterUrl:socialLinks[1].url});
            }else{
                this.setState({  twitterUrl:null});
            }
        }else{
            this.setState({
                facebookUrl:null,
                twitterUrl:null
            });
        }

        const {SchoolList} = this.props;
        SchoolList();
    };
    schoolUpdate = () => {
        const {UpdateSchool} = this.props;
        const {schoolId,schoolName, schoolCode, schoolAddress,schoolCity, schoolCountry, schoolMobile,
            schoolEmail, schoolWebsite, facebookUrl,twitterUrl,menus, file} = this.state;
        const socialLinks=[
            {
                name:"Facebook",
                url:facebookUrl
            },
            {
                name:"Twitter",
                url:twitterUrl
            }
        ];
        UpdateSchool(schoolId,schoolName, schoolCode, schoolAddress,schoolCity, schoolCountry, schoolMobile,
            schoolEmail, schoolWebsite, socialLinks, menus, file);
    };
    handleCreate=()=>{
        //alert();
        this.setState({
            tittle: "Create School",
            hideButton: true,
            schoolName:null,
            schoolCode:null,
            schoolMobile:null,
            schoolLandline:null,
            schoolEmail:null,
            facebookUrl:null,
            twitterUrl:null,
            schoolAddress:null,
            schoolCity:null,
            schoolCountry:"India",
            schoolWebsite:null,
            menus:[],
            file: null,
            cType:"",
            fileName:"No file Chosen",
            imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
            isCreated: !this.state.isCreated,
        });
    };
    handleFileClick() {
        var input = document.createElement("input");
        input.type = "file";
        input.onchange = this.handleImageChange;
        input.click();
    }
    handleImageChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                fileName:file.name,
                imagePreviewUrl: reader.result
            });
        };
        reader.readAsDataURL(file);
    }


    handleToggle(value) {
        const { menus} = this.state;
        const currentIndex = menus.indexOf(value);
        const newChecked = [...menus];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            menus: newChecked
        });
    }
    componentDidMount() {
        const {SchoolList} = this.props;
        SchoolList();
    }
    timeInfo = () => {
        const {HideMsg,SchoolList} = this.props;
        setTimeout(() => {
            HideMsg();
            SchoolList();
            this.handleCreate();
        }, 3000)
    }
    getTheme=(theme) => ({
        ...theme,
        borderRadius: '4px',
        height: "20px",
        colors: {
            ...theme.colors,
            //  primary25: '#00ABDC',
            primary:liteBlue,
            //   primary50:"#00ABDC"
        },
    });

    render(){
        const {schoolName, schoolCode, schoolAddress, schoolLandline,schoolCity, schoolMobile,
            schoolEmail, schoolWebsite,facebookUrl,twitterUrl,menus,isDisabled}=this.state;
        const { classes,dataTable,isFailed,msgColor ,msg,HideMsg} = this.props;
        if (isFailed === true && msg !== null) {
            if(msgColor==="success"){
                this.timeInfo();
            }else{
                setTimeout(() => {
                    HideMsg();
                }, 2000)
            }
        }
        let schoolList = [];
        if (dataTable.length > 0) {
            dataTable.forEach(r => {
                const {schoolId,name,code,address,city,country,contact,phone,email,website,socialLinks,logoUrl,menus} = r;
                schoolList.push({
                    schoolId:schoolId,
                        name:name,
                    code:code,
                    address:address+'\n'+city,
                    contactDetails:contact!==null?contact:""+phone!==null?phone+'\n':"",
                    websiteDetails:website!==null?website:""+email!==null?email+'\n':"",
                    city:city,
                    country:country,
                    contact:contact,
                    phone:phone,
                    email:email,
                    website:website,
                    socialLinks:socialLinks,
                    logoUrl:logoUrl,
                    menus:menus,
                    actions: (
                        <div className="actions-right">
                            <IconButton
                                onClick={() => {
                                    this.handleUpdateSchool(schoolId,name,code,address,city,country,contact,phone,email,website,socialLinks,logoUrl,menus);
                                }}
                                color="primaryNoBackground"
                                customClass="edit">
                                <Edit/>
                            </IconButton>{" "}
                            <IconButton
                                onClick={() => {

                                }}
                                color="dangerNoBackground"
                                customClass="remove">
                                <Close/>
                            </IconButton>
                        </div>
                    )
                })
            })
        }
        return (
            <GridContainer justify={"center"}>
                <GridContainer>
                    <ItemGrid xs={12} sm={6} md={4}>
                        <Snackbar
                            open={isFailed}
                            place={"tc"}
                            color={msgColor}
                            message={msg}
                        />
                    </ItemGrid>
                </GridContainer>
                {this.state.isCreated ?
                    <ItemGrid xs={12} sm={12}>
                        <IconCard
                            icon={School}
                            title={this.state.tittle}
                          //  iconColor="rose"
                            content={
                                <form>
                                    <CustomInput
                                        labelText="School Name"
                                        id="schoolName"
                                        isMandatory={true}
                                        onChange={this.onChangeValue}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value:schoolName
                                        }}
                                    />
                                    <GridContainer justify="center">

                                        <ItemGrid xs={12} sm={6}>
                                    <CustomInput
                                        labelText="School Code"
                                        id="schoolCode"
                                        isMandatory={true}
                                        onChange={this.onChangeValue}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            disabled:isDisabled,
                                            value:schoolCode
                                        }}
                                    />
                                        </ItemGrid>

                                        <ItemGrid xs={12} sm={6}>
                                            <CustomInput
                                                labelText="Contact Person"
                                                id="schoolContactPerson"
                                                onChange={this.onChangeValue}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    // value:schoolCode
                                                }}
                                            />
                                        </ItemGrid>
                                    </GridContainer>
                                    <GridContainer justify="center">

                                        <ItemGrid xs={12} sm={6}>
                                            <CustomInput
                                                labelText="Mobile No"
                                                id="schoolMobile"
                                                isMandatory={true}
                                                onChange={this.onChangeValue}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    value:schoolMobile
                                                }}
                                            />


                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={6}>
                                            <CustomInput
                                                labelText="LandLine No"
                                                id="schoolLandline"
                                                onChange={this.onChangeValue}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    value:schoolLandline
                                                }}
                                            />

                                        </ItemGrid>

                                    </GridContainer>
                                    <GridContainer justify="center">

                                        <ItemGrid xs={12} sm={6}>
                                    <CustomInput
                                        labelText="Email Id"
                                        id="schoolEmail"
                                        onChange={this.onChangeValue}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value:schoolEmail
                                        }}
                                    />
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={6}>
                                            <InputLabel
                                                htmlFor="simple-select"
                                                className={classes.label}
                                            >
                                                Select Catagory
                                            </InputLabel>
                                            <SelectTemp
                                                className="basic-single"
                                                classNamePrefix="select"
                                                // defaultValue={options[0]}
                                                name="cType"
                                                options={schoolCatagoryNew}
                                                onChange={(option)=>{
                                                    this.setState({cType:option.value})
                                                }}
                                                theme={(theme) =>this.getTheme(theme)}
                                            />

{/*
                                            <FormControl
                                                fullWidth
                                                className={classes.selectFormControl}
                                            >
                                                <InputLabel
                                                    htmlFor="simple-select"
                                                    className={classes.selectLabel}
                                                >
                                                    Select Catagory
                                                </InputLabel>
                                                <Select
                                                    MenuProps={{
                                                        className: classes.selectMenu
                                                    }}
                                                    classes={{
                                                        select: classes.select
                                                    }}
                                                    value={this.state.cType}
                                                    onChange={this.handleSimple}
                                                    inputProps={{
                                                        name: "cType",
                                                        id: "cType"
                                                    }}
                                                >
                                                    <MenuItem
                                                        disabled
                                                        classes={{
                                                            root: classes.selectMenuItem
                                                        }}
                                                    >
                                                        Select Catagory
                                                    </MenuItem>
                                                    {schoolCatagory !== null ?
                                                        schoolCatagory.map((data) =>
                                                            <MenuItem
                                                                key={data.catagoryId}
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                    selected: classes.selectMenuItemSelected
                                                                }}
                                                                value={data.catagoryName}
                                                            >
                                                                {data.catagoryName}
                                                            </MenuItem>
                                                        )
                                                        : null}
                                                </Select>
                                            </FormControl>
*/}
                                        </ItemGrid>
                                    </GridContainer>
                                    <CustomInput
                                        labelText="Website"
                                        id="schoolWebsite"
                                        onChange={this.onChangeValue}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value:schoolWebsite
                                        }}
                                    />

                                    <GridContainer>
                                        <ItemGrid xs={12} sm={6}>
                                            <CustomInput
                                                labelText="Facebook URL"
                                                id="facebookUrl"
                                                name="facebook"
                                                onChange={this.onChangeValue}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    value:facebookUrl
                                                }}
                                            />

                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={6}>
                                            <CustomInput
                                                labelText="Twitter URL"
                                                id="twitterUrl"
                                                name="Twitter"
                                                onChange={this.onChangeValue}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    value:twitterUrl
                                                }}
                                            />

                                        </ItemGrid>

                                    </GridContainer>

                                    <CustomInput
                                        labelText="Address On Invoice"
                                        id="schoolAddress"
                                        isMandatory={true}
                                        onChange={this.onChangeValue}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value:schoolAddress
                                        }}
                                    />
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={6}>
                                            <CustomInput
                                                labelText="City"
                                                id="schoolCity"
                                                onChange={this.onChangeValue}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    value:schoolCity
                                                }}
                                            />

                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={6}>
                                            <CustomInput
                                                labelText="Country"
                                                id="schoolCountry"
                                                onChange={this.onChangeValue}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    disable:true,
                                                    value:"India"
                                                }}
                                            />


                                        </ItemGrid>

                                    </GridContainer>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={6}>
                                            <CustomInput
                                                labelText="School Logo"
                                                id="schoolLogo"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    disabled: true,
                                                    value:""+this.state.fileName
                                                }}
                                            />
                                        </ItemGrid>
                                        <ItemGrid>
                                            <Button  onClick={this.handleFileClick}>Choose File</Button>
                                        </ItemGrid>


                                    </GridContainer>
                                    <InputLabel className={classes.label}>
                                        Modules :<span style={{color:"red" ,fontSize:"13px"}}>*</span>
                                    </InputLabel>
                                    <GridContainer>
                                        {dashRoutes.map((prop, key) => {
                                                return (
                                                    <ItemGrid xs={12} sm={12} md={3} key={key}>
                                                        {prop.isHide===false?
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={menus.indexOf(prop.menuId) > -1 ? true : false}
                                                                    tabIndex={-1}
                                                                    onClick={() => this.handleToggle(prop.menuId)}
                                                                    // checkedIcon={
                                                                    //     <Check className={classes.checkedIcon}/>
                                                                    // }
                                                                    // icon={<Check className={classes.uncheckedIcon}/>}
                                                                    classes={{
                                                                        checked: classes.checked
                                                                    }}
                                                                />
                                                            }
                                                            classes={{
                                                                label: classes.label
                                                            }}
                                                            label={prop.name}
                                                        />
                                                            :null}
                                                    </ItemGrid>
                                                )
                                        })
                                        }
                                    </GridContainer>
                                    <GridContainer justify="center">
                                        {this.state.hideButton === true ?
                                            <ItemGrid>
                                                <br/>
                                                <Button color="primary" onClick={this.schoolCreate}>Save</Button>
                                                <br/>
                                            </ItemGrid>
                                            :
                                            < ItemGrid>
                                                < br/>
                                                < Button color="primary" onClick={this.schoolUpdate}>Update</Button>
                                                <br/>
                                            </ItemGrid>
                                        }
                                        <ItemGrid>
                                            <br/>
                                            <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                            <br/>
                                        </ItemGrid>

                                    </GridContainer>
                                </form>

                            }
                        />
                    </ItemGrid> :
                    <ItemGrid xs={12}>
                        <IconCard
                            icon={School}
                            //iconColor={"rose"}
                            title={
                                <div>
                                    Schools
                                    {USER_TYPE()==3 ?
                                    <Button
                                        onClick={this.handleCreate}
                                        // round color="white"
                                        color="primary" right={true}> Add School
                                    </Button>
                                        :null}
                                </div>
                            }
                            content={
                                <GridContainer >

                                    <ItemGrid xs={12}>
                                <ReactTable
                                    data={schoolList}
                                    filterable
                                    style={{color: "#00acc1", textAlign: "center"}}
                                    columns={[

                                        {
                                            Header: "School Name",
                                            accessor: "name",
                                            minWidth:100,
                                            style: {color: "#000", textAlign: "left"}

                                        },
                                        {
                                            Header: "School Code",
                                            accessor: "code",
                                            minWidth:100,
                                            style: {color: "#000", textAlign: "left"}

                                        },
                                        {
                                            Header: "Contact Details",
                                            accessor: "contactDetails",
                                            style: {color: "#000", textAlign: "left"}

                                        },
                                        {
                                            Header: "Address",
                                            accessor: "address",
                                            style: {color: "#000", textAlign: "left"}

                                        }, {
                                            Header: "Web Details",
                                            accessor: "websiteDetails",
                                            style: {color: "#000", textAlign: "left"}

                                        },
                                        {
                                            Header: "Actions",
                                            accessor: "actions",
                                            sortable: false,
                                            filterable: false,
                                        }
                                    ]}
                                    defaultPageSize={5}
                                    showPaginationBottom={true}
                                    className="-striped -highlight"
                                />
                                    </ItemGrid>
                                </GridContainer>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
}

const Style = {
    ...customCheckboxRadioSwitch,
};
const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: liteBlue,
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex"
    },
    ...customSelectStyle
};
const mapStateToProps= state => {
    const {isFailed,msg,msgColor,dataTable}=state.School;
    return{isFailed,msg,msgColor,dataTable};
}

export default connect(mapStateToProps,{
    CreateSchool:createSchoolAction,HideMsg,
    SchoolList:DisplaySchoolList,
    UpdateSchool:updateSchoolAction,
})( withStyles(extendedFormsStyle) (SubSchools));
