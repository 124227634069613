import schoolAPi from "kidzwapi";
import {GET_SCHOOL_DATA_SUCCESS,
    GET_SCHOOL_DATA_FAIL,
    GET_DEVICE_DATA_SUCCESS,
    GET_DEVICE_DATA_FAIL,
GET_DASHBOARD_CIRCULARS_SUCCESS,
    GET_DASHBOARD_CIRCULARS_FAIL,
    GET_DASHBOARD_ASSESSMENTS_SUCCESS,
    GET_DASHBOARD_ASSESSMENTS_FAIL,
    GET_DASHBOARD_HOMEWORK_SUCCESS,GET_DASHBOARD_HOMEWORK_FAIL,
} from "./types";
import {AUTH, SCHOOL_ID, USER_ID} from "../helper/Cookies";

function getSchoolDataSuccess(msg)
{
    return{
        type:GET_SCHOOL_DATA_SUCCESS,
        payLoad:msg
    }
}

function getSchoolDataFailed(msg)
{
    return{
        type:GET_SCHOOL_DATA_FAIL,
        payLoad:msg
    }
}

function getDeviceDataSuccess(msg)
{
    return{
        type:GET_DEVICE_DATA_SUCCESS,
        payLoad:msg
    }
}

function getDeviceDataFailed(msg)
{
    return{
        type:GET_DEVICE_DATA_FAIL,
        payLoad:msg
    }
}

function getcircularSuccess(msg)
{
    return{
        type:GET_DASHBOARD_CIRCULARS_SUCCESS,
        payLoad:msg
    }
}
function getcircularfailed(msg)
{
    return{
        type:GET_DASHBOARD_CIRCULARS_FAIL,
        payLoad:msg
    }
}

function getAssessmentsSuccess(msg)
{
    return{
        type:GET_DASHBOARD_ASSESSMENTS_SUCCESS,
        payLoad:msg
    }
}
function getAssessmentsfailed(msg)
{
    return{
        type:GET_DASHBOARD_ASSESSMENTS_FAIL,
        payLoad:msg
    }
}

function getHomeWorksSuccess(msg)
{
    return{
        type:GET_DASHBOARD_HOMEWORK_SUCCESS,
        payLoad:msg
    }
}
function getHomeWorksFailed(msg)
{
    return{
        type:GET_DASHBOARD_HOMEWORK_FAIL,
        payLoad:msg
    }
}


export function GetDashboardData(){
    return function (dispatch){
        return schoolAPi.getWidget(parseInt(SCHOOL_ID()), USER_ID(), AUTH())
            .then(resp=>{
                if(resp.status){
                    dispatch(getSchoolDataSuccess(resp));
                }else{
                    dispatch(getSchoolDataFailed("Something went wrong!"));
                }
            })

    }
}

export function GetMobileRegisterData(){
    return function (dispatch){
        return schoolAPi.getDeviceRegs(parseInt(SCHOOL_ID()), USER_ID(), AUTH())
            .then(resp=>{
                if(resp.status){
                    dispatch(getDeviceDataSuccess(resp));
                }else{
                    dispatch(getDeviceDataFailed("Something went wrong!"));
                }
            })

    }
}

export function GetCirularData(){
    return function (dispatch){
        return schoolAPi.getWidgetCircular(parseInt(SCHOOL_ID()), USER_ID(), AUTH())
            .then(resp=>{
                if(resp.status){
                    dispatch(getcircularSuccess(resp.circulars));
                }else{
                    dispatch(getcircularfailed("Something went wrong!"));
                }
            })

    }
}

export function GetAssessmentsData(){
    return function (dispatch){
        return schoolAPi.getWidgetAssessments(parseInt(SCHOOL_ID()), USER_ID(), AUTH())
            .then(resp=>{
                if(resp.status){
                    console.log("reepppppppppppp",resp);
                    dispatch(getAssessmentsSuccess(resp.assess));
                }else{
                    dispatch(getAssessmentsfailed("Something went wrong!"));
                }
            })

    }
}

export function GetHomeWorksData(currentDate){
    return function (dispatch){
        return schoolAPi.getWidgetHomework(parseInt(SCHOOL_ID()),currentDate, USER_ID(), AUTH())
            .then(resp=>{
                if(resp.status){
                    dispatch(getHomeWorksSuccess(resp.homeworks));
                }else{
                    dispatch(getHomeWorksFailed("Something went wrong!"));
                }
            })

    }
}
