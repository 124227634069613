import schoolAPi from "kidzwapi";
import {CREATE_HOMEWORK_FAIL, CREATE_HOMEWORK_SUCCESS, DELETE_FAILED, HIDE_INFO_MSG, LIST_HOMEWORK,DELETE_SUCCESS} from "./types";
import {AUTH, SCHOOL_ID, USER_ID} from "../helper/Cookies";


function CreateHomeWorkSuccess(){
    return{
        type:CREATE_HOMEWORK_SUCCESS,
        payLoad:"HomeWork Created Successfully"
    }
}
function CreateHomeWorkFailed(msg){
    return{
        type:CREATE_HOMEWORK_FAIL,
        payLoad:msg
    }
}

function DeleteHomeWorkSuccess(){
    return{
        type:DELETE_SUCCESS,
        payLoad:"HomeWork Deleted Successfully"
    }
}
function DeleteHomeWorkFailed(msg){
    return{
        type:DELETE_FAILED,
        payLoad:msg
    }
}
function hideMsg(){
    return{
        type:HIDE_INFO_MSG,
        payLoad:null
    }
}
function HomeworkList(json){
    return{
        type:LIST_HOMEWORK,
        payLoad:json
    }
}

export function HideMsg(){
    return function (dispatch){
        dispatch(hideMsg());
    }
}

export function CreateHomeWorkAction(classId,homeworkDate,homeworks){
    return function(dispatch){
        console.log("HomeWork Request",parseInt(SCHOOL_ID()),classId,homeworkDate,homeworks,USER_ID(),AUTH());
        if(classId!==null && homeworks!==null){
            return schoolAPi.createHomeWorks(parseInt(SCHOOL_ID()),classId,homeworkDate,homeworks,USER_ID(),AUTH())
                .then(resp=>{
                    console.log("Homework Response",resp);
                    if(resp.status){
                        dispatch(CreateHomeWorkSuccess());
                    }else{
                        dispatch(CreateHomeWorkFailed(resp.msg));
                    }
                })
        }else{
            dispatch(CreateHomeWorkFailed("Enter All Fields"));
        }
    }
}


export function DisplayHomeWorkList(classId,selectedHMDate){
    return function (dispatch){
        if(classId!==null && selectedHMDate!==null ){
            return schoolAPi.listHomeWorks(parseInt(SCHOOL_ID()),classId,selectedHMDate,USER_ID(),AUTH())
                .then(resp=>{
                    if(resp.status){
                        dispatch(HomeworkList(resp.homeworks));
                    }
                })
        }
    }
}


export function DeleteHomeworks( classId,subjectId,homeworkDate){
    return function (dispatch){
            return schoolAPi.deleteHomework(parseInt(SCHOOL_ID()), classId, subjectId, homeworkDate,USER_ID(),AUTH())
                .then(resp=>{
                    if(resp.status){
                        dispatch(DeleteHomeWorkSuccess());
                    }else{
                        dispatch(DeleteHomeWorkFailed(resp.msg))
                    }
                })
    }
}
