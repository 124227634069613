import schoolAPi from "kidzwapi";
import {USER_ID, AUTH, SCHOOL_ID, USER_TYPE, Classes, getAttClasses} from "../helper/Cookies";
import {
    CREATE_CLASS_FAIL,
    CREATE_CLASS_SUCCESS,
    HIDE_INFO_MSG,
    UPDATE_CLASS_SUCESS,
    UPDATE_CLASS_FAIL,
    CLASS_LSIT
} from "./types";

function CreateClassSuccess() {
    return {
        type: CREATE_CLASS_SUCCESS,
        payLoad: "Class Created Successfully"
    }
}
function UpdateClassSuccess() {
    return {
        type: UPDATE_CLASS_SUCESS,
        payLoad: "Class Updated Successfully"
    }
}

function hideMsg() {
    return {
        type: HIDE_INFO_MSG,
        payload: null
    }
}

function CreateClassFailed(msg) {
    return {
        type: CREATE_CLASS_FAIL,
        payLoad: msg
    }
}

function UpdateClassFailed(msg) {
    return {
        type: UPDATE_CLASS_FAIL,
        payLoad: msg
    }
}

function ClassList(json) {
    return {
        type: CLASS_LSIT,
        payLoad: json
    }
}

export function HideMsg() {
    return function (dispatch) {
        dispatch(hideMsg());
    }
}

export function ClassRoomAction(className, orderId) {
    return function (dispatch) {
        if (className !== null && orderId !== null) {
            return schoolAPi.createClass(parseInt(SCHOOL_ID()), className, orderId, USER_ID(), AUTH())
                .then(resp => {
                    if (resp.status) {
                        dispatch(CreateClassSuccess());
                    } else {
                        dispatch(CreateClassFailed(resp.msg));
                    }
                })
        } else {
            dispatch(CreateClassFailed("Enter All Fields"));
        }
    }
}


export function UpdateClassRoomAction(className, classId, orderId) {
    return function (dispatch) {
        if (classId !== null && className !== null) {
            return schoolAPi.updateClass(classId, parseInt(SCHOOL_ID()), className, orderId, USER_ID(), AUTH())
                .then(resp => {
                    if (resp.status) {
                        dispatch(UpdateClassSuccess());
                    } else {
                        dispatch(UpdateClassFailed(resp.msg));
                    }
                })
        } else {
            dispatch(UpdateClassFailed("Enter All Fields"));
        }
    }
}

export function DisplayClassList(isAttendance = false) {
    return function (dispatch) {
        if (parseInt(USER_TYPE()) !== 2) {
            return schoolAPi.listClass(parseInt(SCHOOL_ID()), USER_ID(), AUTH())
                .then(resp => {
                    if (resp.status) {
                        dispatch(ClassList(resp.classes));
                    }
                })
        } else {
            var TeacherClass = isAttendance ? getAttClasses() : JSON.parse(Classes());
            let options = [];
            TeacherClass.forEach(r => {
                const {_id, name} = r;
                options.push({
                    classId: _id,
                    name: name
                })
            });
            if (options.length > 0) {
                dispatch(ClassList(options));
            }

        }
    }
}
