import Dashboard from "views/Dashboard/Dashboard.jsx";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import Place from "@material-ui/icons/Place";
import Image from "@material-ui/icons/Image";
import AttendanceIcon from "@material-ui/icons/Assignment";
import Amount from "@material-ui/icons/AccountBalance";
import DateRange from "@material-ui/icons/DateRange";
import ChatIcon from "@material-ui/icons/Chat";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ContentCopy from "@material-ui/icons/ContentCopy";
import CircularIcon from "@material-ui/icons/BlurCircular";

import Calendar from "../views/Calendar/Calendar";
import UserProfile from "../views/Profile/UserProfile";
import ClassRooms from "../views/HeaderPages/ClassRooms";
import SubSchools from "../views/Profile/SubSchools";
import Subjects from "../views/HeaderPages/Subjects";
import Students from "../views/HeaderPages/Students";
import Teachers from "../views/HeaderPages/Teachers";
import Users from "../views/Profile/Users";
import Configurations from "../views/Profile/Configurations";
import Circulars from "../views/Circulars/Circulars";
import Assessments from "../views/Assessments/Assessments";
import Homework from "../views/HomeWorks/Homework";
import Attendance from "../views/Attendance/Attendance";
import Gallery from "../views/Gallery/Gallery";
import GalleryImages from "../views/Gallery/GalleryImages";
import FeeCreate from "../views/Fee/FeeCreate";
import FeeReceiptsList from "../views/Fee/ReceiptsList";

import FeeType from "../views/Fee/FeeType";
import FeeScheme from "../views/Fee/FeeStructure";
import FeeInvoice from "../views/Fee/FeeInvoice";
import FeeReceipt from "../views/Fee/FeeReceipt";
import RouteMaster from "../views/Transport/RouteMaster";
import ChildAllocation from "../views/Transport/ChildAllocation";
import Chats from "../views/Chat/Chats";
import ChatConversation from "../views/Chat/ChatConversation";
import Message from "../views/Message/Message";
import Marks from "../views/Marks/Mark";
import {USER_TYPE} from "../helper/Cookies";
var dashRoutes=[];
if(parseInt(USER_TYPE())===2){
     dashRoutes = [


        {
            menuId:1,
            path: "/Circulars",
            name: "Circulars",
            state: "openCirculars",
            icon:CircularIcon,
            isHide:false,
            component:Circulars
        },

        {
            menuId:2,
            path: "/Assessments",
            name: "Assessments",
            state: "openAssessments",
            icon: AssessmentIcon,
            isHide:false,
            component:Assessments
        },
        {
            menuId:3,
            path: "/Home_Works",
            name: "HomeWorks",
            state: "openHomeWork",
            icon: ContentCopy,
            isHide:false,
            component:Homework
        },
        {
            menuId:4,
            path: "/Attendance",
            name: "Attendance",
            state: "openAttendance",
            icon: AttendanceIcon,
            isHide:false,
            component:Attendance
        },
        {
            menuId:5,
            path: "/Gallery",
            name: "Gallery",
            state: "openGallery",
            icon: Image,
            isHide:false,
            component:Gallery,
        },

        {
            menuId:6,
            path: "/Calendar",
            name: "Calendar",
            state: "openCalendar",
            icon: DateRange,
            isHide:false,
            component:Calendar
        },
        // {
        //     menuId:7,
        //     path: "/Chats",
        //     name: "Chats",
        //     state: "openChat",
        //     icon: ChatIcon,
        //     isHide:false,
        //     component:Chats
        // },
        // {
        //     menuId:8,
        //     path: "/Message",
        //     name: "Message",
        //     state: "openMessage",
        //     icon: ChatIcon,
        //     isHide:false,
        //     component:Message
        // },
        // {
        //     menuId:9,
        //     path: "/Mark",
        //     name: "Marks",
        //     state: "openMark",
        //     icon: CircularIcon,
        //     isHide:false,
        //     component:Marks
        // },

        // {
        //     menuId:10,
        //     collapse:true,
        //     path: "/Fee",
        //     name: "Fee",
        //     state: "openFee",
        //     icon: Amount,
        //     isHide:false,
        //    // component:''
        //     views: [
        //         {
        //             path: "/Fee/FeeType",
        //             name: "Fee Type",
        //             mini: "FT",
        //             component: FeeType
        //         },
        //         {
        //             path: "/Fee/FeeScheme",
        //             name: "Fee Structure",
        //             mini: "FS",
        //             component: FeeScheme
        //         },
        //         {
        //             path: "/Fee/FeeInvoice",
        //             name: "Fee Invoice",
        //             mini: "FI",
        //             component: FeeInvoice
        //         },
        //         { path: "/Fee/FeeReceipt", name: "Fee Receipt", mini: "FR", component: FeeReceipt }
        //     ]
        //
        // },
        // {
        //     menuId:11,
        //     collapse:true,
        //     path: "/Transport",
        //     name: "Transport",
        //     state: "openTransport",
        //     isHide:false,
        //     icon: Place,
        //    // component:''
        //     views: [
        //         {
        //             path: "/Transport/RouteMaster",
        //             name: "Route Master",
        //             mini: "RM",
        //             component: RouteMaster
        //         },
        //         {
        //             path: "/Transport/ChildAllocation",
        //             name: "Child Allocation",
        //             mini: "CA",
        //             component: ChildAllocation
        //         }
        //     ]
        //
        // },
        {
            path: "/GalleryImages",
            name: "Images",
            state: "",
            icon: Image,
            isHide:true,
            component: GalleryImages
        },
        {
            path: "/ChatConversation",
            name: "ChatConversation",
            state: "",
            icon: Image,
            isHide:true,
            component: ChatConversation
        },
        {
            path: "/Dashboard",
            name: "DashBoard",
            icon: DashboardIcon,
            component: Dashboard,
            state:"openDashboard",
            isHide:true
        },
        { redirect: true, path: "/", pathTo: "/Login", name: "Dashboard" }

    ];
}else{
     dashRoutes = [


        {
            menuId:1,
            path: "/Circulars",
            name: "Circulars",
            state: "openCirculars",
            icon:CircularIcon,
            isHide:false,
            component:Circulars
        },

        {
            menuId:2,
            path: "/Assessments",
            name: "Assessments",
            state: "openAssessments",
            icon: AssessmentIcon,
            isHide:false,
            component:Assessments
        },
        {
            menuId:3,
            path: "/Home_Works",
            name: "HomeWorks",
            state: "openHomeWork",
            icon: ContentCopy,
            isHide:false,
            component:Homework
        },
        {
            menuId:4,
            path: "/Attendance",
            name: "Attendance",
            state: "openAttendance",
            icon: AttendanceIcon,
            isHide:false,
            component:Attendance
        },
        {
            menuId:5,
            path: "/Gallery",
            name: "Gallery",
            state: "openGallery",
            icon: Image,
            isHide:false,
            component:Gallery,
        },

        {
            menuId:6,
            path: "/Calendar",
            name: "Calendar",
            state: "openCalendar",
            icon: DateRange,
            isHide:false,
            component:Calendar
        },
        // {
        //     menuId:7,
        //     path: "/Chats",
        //     name: "Chats",
        //     state: "openChat",
        //     icon: ChatIcon,
        //     isHide:false,
        //     component:Chats
        // },
        {
            menuId:8,
            path: "/Message",
            name: "Message",
            state: "openMessage",
            icon: ChatIcon,
            isHide:false,
            component:Message
        },
        {
            menuId:9,
            path: "/Mark",
            name: "Marks",
            state: "openMark",
            icon: CircularIcon,
            isHide:false,
            component:Marks
        },
         // {
         //     menuId:10,
         //     path: "/FeeCreate",
         //     name: "Fee",
         //     state: "openFee",
         //     icon: Amount,
         //     isHide:false,
         //     component:FeeCreate
         // },
        {
            menuId:10,
            collapse:true,
            path: "/Fee",
            name: "Fee",
           // state: "openFee",
            icon: Amount,
            isHide:false,
           // component:''
            views: [
                {
                    path: "/FeeCreate",
                    name: "Fee Create",
                    mini: "FC",
                    component: FeeCreate
                },
                {
                    path: "/ReceiptsList",
                    name: "Receipts List",
                    mini: "RL",
                    component: FeeReceiptsList
                },
                // {
                //     path: "/Fee/FeeInvoice",
                //     name: "Fee Invoice",
                //     mini: "FI",
                //     component: FeeInvoice
                // },
                {
                    path: "/FeeReceipt",
                    name: "Fee Receipt",
                    mini: "FR",
                    component: FeeReceipt
                }
            ]

        },
        // {
        //     menuId:11,
        //     collapse:true,
        //     path: "/Transport",
        //     name: "Transport",
        //     state: "openTransport",
        //     isHide:false,
        //     icon: Place,
        //    // component:''
        //     views: [
        //         {
        //             path: "/Transport/RouteMaster",
        //             name: "Route Master",
        //             mini: "RM",
        //             component: RouteMaster
        //         },
        //         {
        //             path: "/Transport/ChildAllocation",
        //             name: "Child Allocation",
        //             mini: "CA",
        //             component: ChildAllocation
        //         }
        //     ]
        //
        // },
        {
            path: "/GalleryImages",
            name: "Images",
            state: "",
            icon: Image,
            isHide:true,
            component: GalleryImages
        },
        {
            path: "/ChatConversation",
            name: "ChatConversation",
            state: "",
            icon: Image,
            isHide:true,
            component: ChatConversation
        },
        {
            path: "/Dashboard",
            name: "DashBoard",
            icon: DashboardIcon,
            component: Dashboard,
            state:"openDashboard",
            isHide:true
        },
        { redirect: true, path: "/", pathTo: "/Login", name: "Dashboard" }

    ];
}


 var profileRoutes=[];

 if(parseInt(USER_TYPE())===2){
     profileRoutes = [
         {
             path: "/Dashboard/MyProfile",
             name: "MyProfile",
             component: UserProfile,
             state:"openMyProfile"
             //  isHide:true
         },
     ];
 }else if(parseInt(USER_TYPE())===3){

     console.log("userType",parseInt(USER_TYPE()));
     profileRoutes = [
         {
             path: "/Dashboard/MyProfile",
             name: "MyProfile",
             component: UserProfile,
             state:"openMyProfile"
             //  isHide:true
         },
         {
             path: "/Dashboard/Users",
             name: "Users",
             state: "openUser",
             component:Users
         },

         {
             path: "/Dashboard/Schools",
             name: "Schools",
             state: "openSchools",
             component:SubSchools
         },
         // {
         //     path: "/Dashboard/Configurations",
         //     name: "Configurations",
         //     state: "openConfigurations",
         //     component:Configurations
         // },
         /*  { redirect: true, path: "/", pathTo: "/Dashboard", name: "Dashboard" }*/

     ];
 }else{
     profileRoutes = [
         {
             path: "/Dashboard/MyProfile",
             name: "MyProfile",
             component: UserProfile,
             state:"openMyProfile"
             //  isHide:true
         },
         {
             path: "/Dashboard/Users",
             name: "Users",
             state: "openUser",
             component:Users
         },
         // {
         //     path: "/Dashboard/Configurations",
         //     name: "Configurations",
         //     state: "openConfigurations",
         //     component:Configurations
         // },
         /*  { redirect: true, path: "/", pathTo: "/Dashboard", name: "Dashboard" }*/

     ];
 }

var headerRoutes=[];
if(parseInt(USER_TYPE())===2){
    headerRoutes = [
    {
        path: "/Dashboard/Students",
            name: "Students",
        state: "openStudents",
        component:Students
    }
        ];
}else{
     headerRoutes = [
        {
            path: "/Dashboard/ClassRooms",
            name: "ClassRooms",
            component: ClassRooms,
            state:"openMyClassrooms"
            //  isHide:true
        },
        {
            path: "/Dashboard/Subjects",
            name: "Subjects",
            state: "openSubjects",
            component:Subjects
        },

        {
            path: "/Dashboard/Students",
            name: "Students",
            state: "openStudents",
            component:Students
        },
        {
            path: "/Dashboard/Teachers",
            name: "Teachers",
            state: "openTeachers",
            component:Teachers
        },
        /* { redirect: true, path: "/", pathTo: "/Dashboard", name: "Dashboard" }*/

    ];
}
export {headerRoutes,profileRoutes,dashRoutes};



