import schoolAPi from "kidzwapi";
import {
    CIRCULAR_LIST,
    CREATE_CIRCULAR_FAIL,
    CREATE_CIRCULAR_SUCCESS,
    DELETE_FAILED,
    DELETE_SUCCESS,
    HIDE_INFO_MSG
} from "./types";
import {AUTH, SCHOOL_ID, USER_ID} from "../helper/Cookies";

function CreateCircularSuccess() {
    return {
        type: CREATE_CIRCULAR_SUCCESS,
        payLoad: "Circular Posted Successfully"
    }
}

function CreateCircularFailled(msg) {
    return {
        type: CREATE_CIRCULAR_FAIL,
        payLoad: msg
    }
}
function DeleteCircularSuccess() {
    return {
        type: DELETE_SUCCESS,
        payLoad: "Circular Deleted Successfully"
    }
}
function DeleteCircularFailed(msg) {
    return {
        type: DELETE_FAILED,
        payLoad: msg
    }
}

function hideMsg() {
    return {
        type: HIDE_INFO_MSG,
        payLoad: null
    }
}

function circularList(json) {
    return {
        type: CIRCULAR_LIST,
        payLoad: json
    }
}

export function HideMsg() {
    return function (dispatch) {
        dispatch(hideMsg());
    }
}

export function CreateCircularAction(childId, circular, cDate, file, fileType, multipleClassSelection, ctitle) {
    return function (dispatch) {
        if (circular !== null || multipleClassSelection.length > 0) {
            if (fileType === "application/pdf" || file === null || fileType === "image/jpeg" || fileType === "image/jpg" || fileType === "image/png") {
                return schoolAPi.createCircular(parseInt(SCHOOL_ID()), multipleClassSelection, childId, ctitle, circular, cDate, file, USER_ID(), AUTH())
                    .then(resp => {
                        if (resp.status) {
                            dispatch(CreateCircularSuccess());
                        } else {
                            dispatch(CreateCircularFailled(resp.msg));
                        }
                    })
            } else {
                dispatch(CreateCircularFailled("Please select Valid file"));
            }

        } else {
            dispatch(CreateCircularFailled("Enter All Fields"));
        }
    }
}

export function DispalyCircularList(fromDate, ToDate, classId) {
    return function (dispatch) {
        return schoolAPi.listCirculars(parseInt(SCHOOL_ID()), classId, fromDate, ToDate, USER_ID(), AUTH())
            .then(resp => {
                if (resp.status) {
                    dispatch(circularList(resp.circulars))
                }
            })
    }
}

export function DeleteCircular(circularId) {
    return function (dispatch) {
        return schoolAPi.deleteCircular( circularId,USER_ID(), AUTH())
            .then(resp => {
                if (resp.status) {
                    dispatch(DeleteCircularSuccess());
                }else{
                    dispatch(DeleteCircularFailed(resp.msg));

                }
            })
    }
}
