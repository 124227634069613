var lineColor = [
    '#94BA33','#00ABDC','#003F66',
];


var linetheme = {
    color: lineColor,
    line: {
        smooth : false,
        symbol: 'emptyCircle',
        symbolSize: 5
    },
};

export const barColor = [
    '#fec646','#1984c1','#16afc3','#333737','#1e4646','#f37538','#42b54b'
];


export const bartheme = {
    color: barColor,
    line: {
        smooth : true,
        symbol: 'emptyCircle',
        symbolSize: 5
    },
};

const pieColor = [
    '#94BA33','#00ABDC','#003F66'
];


export const pietheme = {
    color: pieColor,
    line: {
        smooth : true,
        symbol: 'emptyCircle',
        symbolSize: 5
    },


};


export default linetheme;