import {
    CLASS_LSIT,
    CLASSWISE_STUDENT_LIST,
    CREATE_ATTENDANCE_SUCCESS,
    CREATE_ATTENDENCE_FAIL, DELETE_FAILED, DELETE_SUCCESS,
    LIST_ATTENDANCE
} from "../actions/types";


const INIT_STATE = {isFailed: false, msg: null, msgColor: "danger",classList:[],attendanceTable:[],classWiseList:[],deleteSuccess:false};

export default (state=INIT_STATE,action)=>{
    switch(action.type){
        case CLASS_LSIT:
            return{...state,classList:action.payLoad};
        case CLASSWISE_STUDENT_LIST:
            return{...state,classWiseList:action.payLoad};
        case CREATE_ATTENDANCE_SUCCESS:
            return {...state,isFailed: true,msg: action.payLoad, msgColor: "success"};
        case CREATE_ATTENDENCE_FAIL:
            return {...state, isFailed: true, msg: action.payLoad};
        case LIST_ATTENDANCE:
            return{...state,attendanceTable:action.payLoad};
        case DELETE_SUCCESS:
            return {...state,isFailed:true,msg:action.payLoad,deleteSuccess:true,msgColor: "success"};
        case DELETE_FAILED:
            return {...state,isFailed:true,msg:action.payLoad,deleteSuccess:true};
        default:
            return INIT_STATE;
    }

}
