import schoolAPi from "kidzwapi";
import {
    CREATE_CALENDER_FAIL,
    CREATE_CALENDER_SUCCESS,
    DELETE_FAILED,
    DELETE_SUCCESS,
    HIDE_INFO_MSG,
    LIST_CALENDER
} from "./types";
import {SCHOOL_ID,USER_ID,AUTH} from "../helper/Cookies";

function CreateCalenderSuccess(){
    return{
        type:CREATE_CALENDER_SUCCESS,
        payLoad:"Holiday or Event created successfully"
    }
}

function  CreateCalenderFailed(msg){
    return{
        type:CREATE_CALENDER_FAIL,
        payLoad:msg
    }
}
function DeleteCalenderSuccess(){
    return{
        type:DELETE_SUCCESS,
        payLoad:"Holiday or Event Deleted successfully"
    }
}
function DeleteCalenderFailed(msg){
    return{
        type:DELETE_FAILED,
        payLoad:msg
    }
}

function hideMsg() {
    return {
        type: HIDE_INFO_MSG,
        payload: null
    }
}

function CalenderList(json){
    return{
        type:LIST_CALENDER,
        payLoad:json
    }
}
export function HideMsg(){
    return function(dispatch){
        dispatch(hideMsg());
    }
}

export function CreateCalenderAction(classId, academicYear, HorE, desc, subject, startTime, endTime){
    return function(dispatch){
        console.log("request",parseInt(SCHOOL_ID()),classId, academicYear, HorE, desc, subject, startTime, endTime,USER_ID(),AUTH())
        if(classId!== null && HorE!== null && desc!== null,subject!== null && startTime!==null){
            return schoolAPi.createEvent(parseInt(SCHOOL_ID()),classId, academicYear, HorE, desc, subject, startTime, endTime,USER_ID(),AUTH())
                .then(resp=>{
                    console.log("resp",resp)
                    if(resp.status){
                        dispatch(CreateCalenderSuccess());
                    }else{
                        dispatch(CreateCalenderFailed(resp.msg));
                    }
                })
        }else{
            dispatch(CreateCalenderFailed("Enter All Fields"));
        }
    }
}

export function DisplayCalenderList(classId){
    return function(dispatch){
        return schoolAPi.listEvent(parseInt(SCHOOL_ID()),classId,USER_ID(),AUTH())
            .then(resp=>{
                if(resp.status){
                    dispatch(CalenderList(resp.events));
                }
            })

    }
}

export function DeleteCalender(eventId){
    return function(dispatch){
        return schoolAPi.deleteCalendar(eventId,USER_ID(),AUTH())
            .then(resp=>{
                if(resp.status){
                    dispatch(DeleteCalenderSuccess());
                }else{
                    dispatch(DeleteCalenderFailed(resp.msg))
                }
            })

    }
}
