import React from "react";

//import defaultImage from "assets/img/default-avatar.png";
import avatar from "assets/img/faces/avatar.jpg";

class PictureUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,

      imagePreviewUrl: avatar
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
    handleImageChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result
            });
        };
        reader.readAsDataURL(file);
    }
  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  render() {

    const { btnTitle ,imgUrl,disable} = this.props;

    if(imgUrl!==''){
        var Image=imgUrl;

    }else{
        var Image=this.state.imagePreviewUrl;
      }
    return (
      <div className="picture-container">
        <div className="picture">
          <img
            src={Image}
            className="picture-src"
            alt="..."
        />
          <input type="file" onChange={e => this.handleImageChange(e)} disabled={disable} />
        </div>
        <h6 className="description">{btnTitle}</h6>
      </div>
    );
  }
}

export default PictureUpload;
