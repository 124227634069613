import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Amount from "@material-ui/icons/AccountBalance";
import AttachmentIcon from "@material-ui/icons/AttachFile";
import InputLabel from "material-ui/Input/InputLabel";
import Tooltip from 'material-ui/Tooltip';
import Edit from "@material-ui/icons/Edit";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import withStyles from "material-ui/styles/withStyles";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import ExportToExcel from "../../components/ExportToExcel/ExportToExcel";
import {connect} from "react-redux";
import {DisplayClassList} from "../../actions/ClassRoomAction";
import {UploadMarksAction, HideMsg, DisplayMarks, DeleteMarks} from "../../actions/MarksAction";
import {SCHOOL_ID, USER_TYPE} from "../../helper/Cookies";
import Snackbar from "../../components/Snackbar/Snackbar";
import SelectTemp from 'react-select';
import {liteBlue} from "../../assets/jss/material-dashboard-pro-react";

class Marks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: ["Code", "Class", "Date", "Subject", "Homework"],
            examName: "",
            examMaxMarks: "",
            examMinMarks: "",
            selectClass: -1,
            selectChild: "",
            fileName: "No file Chosen",
            file: null,
            showFields: false,
            showList: false,

        };
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
    }

    handleCreate = () => {
        //alert();
        console.log(this.state);
        if(this.state.updateView){
            this.setState({
                examName:null, examMaxMarks:null,
                examMinMarks:null, uploadedUrl:null,examId:null,
                updateView:false,
            })
        }
        this.setState({
            isCreated: !this.state.isCreated,
            showFields: false,
            fileName: "No file Chosen",
            file: null,
            selectClass: -1,
        });
    };
    handleSimple = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleClass(value) {
        this.setState({selectClass: value.value});
    };

    onChangeValue = event => {
        this.setState({[event.target.id]: event.target.value});
    };

    handleFileClick() {
        var input = document.createElement("input");
        input.type = "file";
        input.onchange = this.handleFileChange;
        input.click();
    }

    handleFileChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                fileName: file.name,
                fileType: file.type
            });
        };
        reader.readAsDataURL(file);
    }

    getUploadFile() {
        if (this.state.selectClass !== 0 && this.state.selectClass !== undefined && this.state.selectClass !== null) {

            window.location.assign("https://kidzlog.gnblabs.com/template/" + parseInt(SCHOOL_ID()) + "/" + this.state.selectClass);
            //  window.location.hash(attachmentUrl);
            this.setState({showFields: true})
        }
    }

    handleAttachment(attachmentUrl) {
        if (attachmentUrl !== null && attachmentUrl !== undefined)
            window.location.assign("https://kidzlog.gnblabs.com/template/download?d=" + encodeURI(attachmentUrl));
        //  window.location.hash(attachmentUrl);
    }

    UploadMarks = () => {
        const {MarksUpload} = this.props;
        const {selectClass, examName, examMaxMarks, examMinMarks, file, fileType} = this.state;
        MarksUpload(selectClass, examName, examMaxMarks, examMinMarks, file, fileType);
    };
    getExamMarks = () => {
        const {ListExamMarks, ClassList} = this.props;
        const {selectClass} = this.state;
        ListExamMarks(selectClass);
        ClassList();
        this.setState({showList: true})
    };

    deleteExam(examId, marksId) {
        const {ExamDelete} = this.props;
        ExamDelete(examId, marksId);
    }

    componentDidMount() {
        const {ClassList} = this.props;
        ClassList();
    }

    timeInfo = () => {
        const {HideMsg, ClassList, deleteSuccess} = this.props;
        setTimeout(() => {
            HideMsg();
            if (deleteSuccess !== true) {
                this.handleCreate();
            }
            ClassList();
        }, 3000)
    };
    getTheme=(theme) => ({
        ...theme,
        borderRadius: '4px',
        height: "20px",
        colors: {
            ...theme.colors,
            //  primary25: '#00ABDC',
            primary:liteBlue,
            //   primary50:"#00ABDC"
        },
    });

    render() {
        const {classes, classList, isFailed, msg, msgColor, HideMsg, examTable} = this.props;
        if (isFailed === true && msg !== null) {
            if (msgColor === "success") {
                this.timeInfo();
            } else {
                setTimeout(() => {
                    HideMsg();
                }, 2000)
            }
        }
        let options = [];
        if (classList !== null) {
            classList.forEach(r => {
                const {name, classId} = r;
                options.push({
                    value: classId,
                    label: name
                })
            })
        }
        let ExamList = [];
        let columns = [];
        const userType = parseInt(USER_TYPE());
        // if(this.state.showList === true) {
        //     if (examTable.length > 0) {
        if (userType === 2 || userType === 5) {
            columns = [
                {
                    Header: "Class Name",
                    accessor: "className",
                    style: {color: "#000", textAlign: "left"}

                },

                {
                    Header: "Exam Tittle",
                    accessor: "examTitle",
                    minWidth:200,
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Max Marks",
                    accessor: "max",
                    style: {color: "#000", textAlign: "right"}

                },
                {
                    Header: "Min Marks",
                    accessor: "min",
                    style: {color: "#000", textAlign: "right"}

                },
                {
                    Header: "Students",
                    accessor: "students",
                    style: {color: "#000", textAlign: "right"}

                },
                {
                    Header: "Attachment",
                    accessor: "attachment",
                    sortable: false,
                    filterable: false,
                }
            ]
        } else {
            columns = [
                {
                    Header: "Class Name",
                    accessor: "className",
                    style: {color: "#000", textAlign: "left"}

                },

                {
                    Header: "Exam Tittle",
                    accessor: "examTitle",
                    minWidth:200,
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Max Marks",
                    accessor: "max",
                    style: {color: "#000", textAlign: "right"}

                },
                {
                    Header: "Min Marks",
                    accessor: "min",
                    style: {color: "#000", textAlign: "right"}

                },
                {
                    Header: "Students",
                    accessor: "students",
                    style: {color: "#000", textAlign: "right"}

                },
                {
                    Header: "Attachment",
                    accessor: "attachment",
                    sortable: false,
                    filterable: false,
                },
                {
                    Header: "Actions",
                    accessor: "actions",

                    sortable: false,
                    filterable: false,
                }
            ]
        }
        console.log("examTable",examTable);
        examTable.forEach((r,i) => {
            const {classId, className, examTitle, min, max, students, uploadedUrl, marksId, examId} = r;
            ExamList.push({
                examId: examId,
                marksId: marksId,
                classId: classId,
                className: className,
                examTitle: examTitle,
                min: min,
                max: max,
                students: students,
                uploadedUrl: uploadedUrl,
                attachment: (
                    <div>
                        <IconButton
                            onClick={() => {
                                this.handleAttachment(uploadedUrl);
                            }}
                            color="blackNoBackground"
                            customClass="edit">
                            <AttachmentIcon/>
                        </IconButton>{" "}
                    </div>
                ),
                actions: (
                    <div className="actions-right">
                        <Tooltip title="View">
                            <IconButton
                                onClick={() => {
                                    this.setState({
                                        selectClass:ExamList[i].classId, examName:ExamList[i].examTitle, examMaxMarks:ExamList[i].max,
                                        examMinMarks:ExamList[i].min, uploadedUrl:ExamList[i].uploadedUrl,examId:ExamList[i].examId,
                                        updateView:true,
                                        showFields:true,
                                        isCreated:true
                                    })
                                }}
                                color="primaryNoBackground"
                                customClass="edit">
                                <Edit/>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete">
                            <IconButton
                                onClick={() => {
                                    {
                                        if (window.confirm('Are you sure you want to Delete this Record?')) {
                                            this.deleteExam(examId, marksId)
                                        }
                                        ;
                                    }
                                }}
                                color="dangerNoBackground"
                                customClass="remove">
                                <Close/>
                            </IconButton>
                        </Tooltip>

                    </div>
                )
            })
        });
        var selectClass;
        options.forEach(r=>{
            if(r.value===this.state.selectClass){
                selectClass=r
            }
        });

        //     }
        // }
        return (
            <GridContainer justify="center">
                <GridContainer>
                    <ItemGrid xs={12} sm={6} md={4}>
                        <Snackbar
                            open={isFailed}
                            place={"tc"}
                            color={msgColor}
                            message={msg}
                        />
                    </ItemGrid>
                </GridContainer>
                {this.state.isCreated ?
                    <IconCard
                        icon={Amount}
                        title="Exam Marks"
                      //  iconColor="rose"
                        content={
                            <form>

                                <GridContainer justify="center">
                                    <ItemGrid xs={6} sm={6}>
                                        <InputLabel className={classes.label}>
                                            Select Class *
                                        </InputLabel>
                                        <SelectTemp
                                            className="basic-single"
                                            classNamePrefix="select"
                                            // defaultValue={options[0]}
                                            name="selectClass"
                                            options={options}
                                            value={selectClass}
                                            onChange={this.handleClass.bind(this)}
                                            theme={(theme) =>this.getTheme(theme)}
                                        />
                                    </ItemGrid>
                                    {this.state.updateView?
                                        <ItemGrid xs={6} sm={6}>
                                            <InputLabel className={classes.label}>
                                                Click On Attachment Icon To Get Marks
                                            </InputLabel>
                                        <IconButton
                                            onClick={() => {
                                                this.handleAttachment(this.state.uploadedUrl);
                                            }}
                                            color="blackNoBackground"
                                            customClass="edit">
                                            <AttachmentIcon/>
                                        </IconButton>
                                        </ItemGrid>:null}
                                    {!this.state.updateView? <ItemGrid xs={6} sm={6}>
                                        {<InputLabel className={classes.label}>
                                            Please Select Class and Click on Attachment Icon to Upload Marks
                                        </InputLabel>}
                                        <IconButton
                                            onClick={() => {
                                                this.getUploadFile();
                                            }}
                                            color="blackNoBackground"
                                            customClass="edit">
                                            <AttachmentIcon/>
                                        </IconButton>{" "}
                                    </ItemGrid>:null}

                                    {this.state.showFields === true ?
                                        <ItemGrid xs={12} sm={12}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={12}>
                                                    {/* <InputLabel className={classes.label}>
                                                Fee Structure Name
                                            </InputLabel>*/}
                                                    <CustomInput
                                                        labelText="Exam Name"
                                                        id="examName"
                                                        onChange={this.onChangeValue}
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        value={this.state.examName}
                                                    />
                                                </ItemGrid>

                                                <ItemGrid xs={6} sm={6}>
                                                    {/*<InputLabel className={classes.label}>*/}
                                                    {/*Fee Structure Name*/}
                                                    {/*</InputLabel>*/}
                                                    <CustomInput
                                                        labelText="Exam Maximum Marks"
                                                        id="examMaxMarks"
                                                        onChange={this.onChangeValue}
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        value={this.state.examMaxMarks}
                                                    />
                                                </ItemGrid>

                                                <ItemGrid xs={6} sm={6}>
                                                    {/*<InputLabel className={classes.label}>*/}
                                                    {/*Fee Structure Name*/}
                                                    {/*</InputLabel>*/}
                                                    <CustomInput
                                                        labelText="  Exam Minimum Marks"
                                                        id="examMinMarks"
                                                        onChange={this.onChangeValue}
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        value={this.state.examMinMarks}
                                                    />

                                                </ItemGrid>
                                            </GridContainer>

                                            {!this.state.updateView?
                                                <GridContainer>
                                                <ItemGrid xs={12} sm={6}>
                                                    <CustomInput
                                                        //  labelText="At"
                                                        id="cAttachment"

                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            accept: "application/pdf",
                                                            disabled: true,
                                                            value: "" + this.state.fileName
                                                        }}
                                                    />
                                                </ItemGrid>
                                                <ItemGrid>
                                                    <Button onClick={this.handleFileClick}>Choose File</Button>
                                                </ItemGrid>


                                            </GridContainer>:null}

                                            <GridContainer justify="center">
                                                {!this.state.updateView? <ItemGrid>
                                                    <br/>
                                                    <Button color="primary" onClick={this.UploadMarks}>Save</Button>
                                                    <br/>
                                                </ItemGrid>:null}
                                                <ItemGrid>
                                                    <br/>
                                                    <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                    <br/>
                                                </ItemGrid>
                                            </GridContainer>

                                        </ItemGrid>
                                        : null}

                                </GridContainer>
                            </form>

                        }
                    />
                    :
                    <IconCard
                        icon={Amount}
                      //  iconColor={"rose"}
                        title={
                            <div>
                                Exam Marks
                                {(userType === 3 || userType === 5) ?
                                    <Button
                                        onClick={this.handleCreate}
                                        // round color="white"
                                        color="primary" right={true}> Add Exam Marks
                                    </Button>
                                    : null}
                            </div>
                        }
                        content={
                            <GridContainer>
                                <ItemGrid xs={12} sm={3} style={{marginTop: "-11px"}}>
                                    <InputLabel className={classes.label}>
                                        Class
                                    </InputLabel>
                                    <SelectTemp
                                        className="basic-single"
                                        classNamePrefix="select"
                                        // defaultValue={options[0]}
                                        name="selectClass"
                                        options={options}
                                        onChange={this.handleClass.bind(this)}
                                        theme={(theme) =>this.getTheme(theme)}
                                    />
                                </ItemGrid>
                                <ItemGrid xs={2} sm={2}>
                                    <Button color="primary" onClick={this.getExamMarks}> Go </Button>
                                </ItemGrid>

                                {/*{this.state.showList===true?*/}
                                <ItemGrid xs={12}>
                                    <ItemGrid xs={12}>
                                        <ReactTable
                                            data={ExamList}
                                            filterable
                                            style={{color: "#00acc1"}}
                                            columns={columns}
                                            defaultPageSize={5}
                                            showPaginationBottom={true}
                                            className="-striped -highlight"
                                        />

                                    </ItemGrid>
                                    <ItemGrid xs={12}>
                                        <GridContainer>
                                            <ItemGrid xs={12} md={10}>
                                            </ItemGrid>
                                            <ItemGrid xs={12} md={2}>
                                                <ExportToExcel
                                                    posts={ExamList}
                                                    headings={this.state.headers}
                                                    fileName={"Exam Marks"} sheetName={"Exam Marks"}/>
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                </ItemGrid>
                                {/*:null}*/}
                            </GridContainer>
                        }
                    />
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = theme => ({
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#AAAAAA",
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    ...customSelectStyle
});
const mapStateToProps = state => {
    const {isFailed, msg, msgColor, classList, examTable, deleteSuccess} = state.Mark;
    return {classList, isFailed, msg, msgColor, examTable, deleteSuccess};
};
export default connect(mapStateToProps, {
    MarksUpload: UploadMarksAction, HideMsg,
    ClassList: DisplayClassList,
    ListExamMarks: DisplayMarks,
    ExamDelete: DeleteMarks
})(withStyles(extendedFormsStyle)(Marks));
