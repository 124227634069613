import React from "react";
import PropTypes from "prop-types";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import InputAdornment from "material-ui/Input/InputAdornment";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import LoginCard from "../../components/Cards/LoginCard.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import Snackbar from "../../components/Snackbar/Snackbar";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux'
import {Auth, hideInfo} from '../../actions'

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            cardAnimaton: "cardHidden",
            uname: "",
            pwd: "",
            showError: false,
            isLoading: false,
            errorMsg: "Enter username and password"
        };

        this.onChangeValues = this.onChangeValues.bind(this);
    }

    onChangeValues(event) {
        this.setState({
            [event.target.id]: event.target.value
        });

        this.setState({
            isError: false
        });
    }


    setErrorState = (isError, ErrorMsg) => {
        this.setState({
            showError: isError,
            errorMsg: ErrorMsg,
            isLoading: false
        });
        this.timeOut();
    };

    handleKeyPress = event => {
        if (event.key === 'Enter') {
            this.onLogin();
        }
    };
    onLogin = () => {
        // this.props.history.push('/Dashboard');
        const {uname, pwd} = this.state;
        const {Auth} = this.props;
        Auth(uname, pwd);
    };

    timeOut = () => {
        const {hideInfo} = this.props;
        setTimeout(hideInfo, 3000)
    };

    goToHome = () => {
        const {history} = this.props;
        history.push("/Dashboard");
    };

    componentDidMount() {
        // we add a hidden class to the card and after 700 ms we delete it and the transition appears
        setTimeout(
            function () {
                this.setState({cardAnimaton: ""});
            }.bind(this),
            700
        );
    }

    render() {
        // console.log(this.props);
        const {classes, isLoggedIn, isFailed, failMsg} = this.props;
        const {uname, pwd} = this.state;
        if (isLoggedIn) {
            this.goToHome();
        }
        if (isFailed) {
            this.timeOut();
        }
        return (
            <div className={classes.content}>
                <div className={classes.container}>
                    <GridContainer>
                        <ItemGrid xs={12} sm={6} md={4}>
                            <Snackbar
                                open={isFailed}
                                place={"tc"}
                                color={"danger"}
                                message={failMsg}
                            />
                        </ItemGrid>
                    </GridContainer>
                    <GridContainer justify="center">
                        <ItemGrid xs={12} sm={6} md={4}>
                            <form>
                                <LoginCard
                                    customCardClass={classes[this.state.cardAnimaton]}
                                    headerColor="liteBlue"
                                    cardTitle="Login"
                                    cardSubtitle="Or Be Classical"
                                    footerAlign="center"
                                    footer={
                                        <div>
                                            <Button color="blueNoBackground" wd size="lg" onClick={this.onLogin}>
                                                Let's Go
                                            </Button>
                                            <legend style={{fontSize: "14px"}}>Don't have an account click on
                                                <Link to="/Register" className="btn btn-link">
                                                    <span style={{color: "#00acc1"}}>  Register</span></Link>
                                            </legend>
                                        </div>
                                    }
                                    content={
                                        <div>
                                            <CustomInput
                                                labelText="User Name.."
                                                id="uname"
                                                onChange={this.onChangeValues}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Face className={classes.inputAdornmentIcon}/>
                                                        </InputAdornment>
                                                    ),
                                                    value: uname
                                                }}
                                            />
                                            <CustomInput
                                                labelText="Password"
                                                id="pwd"
                                                iType={"password"}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                onChange={this.onChangeValues}
                                                inputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LockOutline
                                                                className={classes.inputAdornmentIcon}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                    value: pwd
                                                }}
                                            />
                                            <Link to="/ForgotPassword" className="btn btn-link">
                                                <span style={{color: "#00acc1"}}>Forgot Password?</span></Link>

                                        </div>
                                    }
                                />
                            </form>
                        </ItemGrid>
                    </GridContainer>
                </div>
            </div>
        );
    }
}

LoginPage.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    const {isFailed, failMsg, isLoggedIn} = state.LoginAuth;
    // console.log(state);
    return {isFailed, failMsg, isLoggedIn};
};

export default connect(mapStateToProps, {Auth, hideInfo})(withStyles(loginPageStyle)(LoginPage));
