import {USER_ID, AUTH, SCHOOL_ID} from "../helper/Cookies";
import {
    CREATE_FEE_FAILED, CREATE_FEE_SUCCESS, HIDE_INFO_MSG,GET_FEE_DATA,DELETE_FAILED,DELETE_SUCCESS
} from "./types";
import {runMutation,getFee, createFeeMutation, gqlClient,deleteFeeMutation} from "../graphql";
import {useMutation} from "@apollo/react-hooks";
import {undefinedFieldMessage} from "graphql/validation/rules/FieldsOnCorrectType";


function CreateFeeSuccess() {
    return {
        type: CREATE_FEE_SUCCESS,
        payLoad: "Fee Created Successfully"
    }
}


function CreateFeeFailed(msg) {
    return {
        type: CREATE_FEE_FAILED,
        payLoad: msg
    }
}
 function DeleteFeeSuccess(){
    return{
        type:DELETE_SUCCESS,
        payLoad:"Fee Deleted Successfully"
    }
 }

function DeleteFeeFailed(){
    return{
        type:DELETE_SUCCESS,
        payLoad:"Something went Wrong"
    }
}

function getFeeList(data){
    return{
        type:GET_FEE_DATA,
        payLoad:data
    }
}

function hideMsg() {
    return {
        type: HIDE_INFO_MSG,
        payload: null
    }
}

export function HideMsg() {
    return function (dispatch) {
        dispatch(hideMsg());
    }

}

export function CreateFeeM(classId, fee, validFrom, validTo, lastDates, terms) {
    console.log("create feee", parseInt(SCHOOL_ID()), classId, fee, validFrom, validTo, USER_ID(), AUTH(), lastDates, terms);
    if (validFrom !== null && validTo !== null && classId.length > 0 && fee.length > 0) {
        // const [createFee, {data}] = useMutation(createFeeMutation);
        // const params = {
        //     variables: {
        //         schoolId: SCHOOL_ID(),
        //         classId: classId[0],
        //         fee: fee,
        //         terms: terms,
        //         lastDates: lastDates,
        //         validFrom: validFrom,
        //         validTo: validTo,
        //         createdBy: USER_ID()
        //     }
        // };
        // createFee(params);
        // console.log(data);
    }
}

export function CreateFee(classId, fee, validFrom, validTo, lastDates, terms) {
    return function (dispatch) {
        // console.log("create feee", parseInt(SCHOOL_ID()), classId, fee, validFrom, validTo, USER_ID(), AUTH(), lastDates, terms);
        if (validFrom !== null && validTo !== null && classId.length > 0 && fee.length > 0) {
            classId.forEach(r => {

            const params = {
                input: {
                    schoolId: parseInt(SCHOOL_ID()),
                    classId: r,
                    fee: fee,
                    terms: parseInt(terms),
                    lastDates: lastDates,
                    validFrom: validFrom,
                    validTo: validTo,
                    createdBy: USER_ID()
                }
            };
            const options = {
                mutation: createFeeMutation,
                variables: params
            };
            // client.request(createFeeMutation, params).then(res => console.log(res)).catch(err => console.log(err));
                    gqlClient.mutate(options).then(res => {
                        if (res) {
                            dispatch(CreateFeeSuccess());
                        }
                    }).catch(err =>  dispatch(CreateFeeSuccess(err))
                    );
            })
        } else {
            dispatch(CreateFeeFailed("Enter All Fields"))
        }

    }
}

export function GetFees(classId) {
    return function (dispatch) {
        if(classId===undefined || classId===""){
            var params = {
                schoolId: parseInt(SCHOOL_ID())
            };
        }else{
            var params = {
                schoolId: parseInt(SCHOOL_ID()),
                classId: classId
            };
        }

        const options = {
            query: getFee,
            variables: params
        };
        gqlClient.query(options).then(res => {
            if (res) {
                dispatch(getFeeList(res.data.fees));
            }
        }).catch(err =>  console.log(err));
    }
}

export function DeleteFee(feeId) {
    return function (dispatch) {
        const params = {
            _id: feeId
        };
        const options = {
            mutation: deleteFeeMutation,
            variables: params
        };
        gqlClient.mutate(options).then(res => {
            if (res) {
                dispatch(DeleteFeeSuccess());
            }
        }).catch(err => DeleteFeeFailed());

    }
}
