import schoolAPi from "kidzwapi";
import {
    CREATE_MYPROFILE_PASSWORD_SUCCESS,
    CREATE_MYPROFILE_PASSWORD_FAILED,
    GET_MYPROFILE_SUCCESS,
    GET_MYPROFILE_FAILED,
    HIDE_INFO_MSG,
} from "./types";
import {AUTH, SCHOOL_ID, USER_ID} from "../helper/Cookies";

function createPasswordSuccess(msg){
    return{
        type:CREATE_MYPROFILE_PASSWORD_SUCCESS,
        payLoad:msg
    }
}

function createPasswordFailed(msg){
    return{
        type:CREATE_MYPROFILE_PASSWORD_FAILED,
        payLoad:msg
    }
}

function get_MyProfile_success(msg){
    return{
        type:GET_MYPROFILE_SUCCESS,
        payLoad:msg
    }
}

function get_MyProfile_failed(msg){
    return{
        type:GET_MYPROFILE_FAILED,
        payLoad:msg
    }
}

function hideMsg() {
    return {
        type: HIDE_INFO_MSG,
        payload: null
    }
}

export function HideMsg() {
    return function (dispatch) {
        dispatch(hideMsg());
    }
}


export function DisplayMyProfile(){
    return function(dispatch){
        return schoolAPi.schoolProfile(parseInt(SCHOOL_ID()), USER_ID(), AUTH())
            .then(resp=>{
                    if(resp.status===0){
                        dispatch(get_MyProfile_success(resp));
                    }else{
                        dispatch(get_MyProfile_failed("Something Went Wrong! Please try again."));

                    }
                }
            )
    }
}


export function CreateMyProfilePassword(npass,cpass){
    return function(dispatch){
        if(npass===cpass){
            console.log("request",npass, USER_ID(), AUTH());
            if(npass!='' && cpass!='' && USER_ID()!=null){
                return schoolAPi.changeSchoolPwd(npass, USER_ID(), AUTH())
                    .then(resp=>{
                            console.log("actionResp",resp);
                            if(resp.status){
                                dispatch(createPasswordSuccess(resp.msg));
                            }else{
                                dispatch(createPasswordFailed(resp.msg));
                            }
                        }
                    )
            }else{
                dispatch(createPasswordFailed("Something went wrong please try again!"));
            }
        }else{
            dispatch(createPasswordFailed("Password Miss matching please enter correctly!"));
        }
    }
}
