import {
    CLASS_LSIT,
    DELETE_FAILED,
    DELETE_SUCCESS,
    LIST_MARKS,
    UPLOAD_MARKS_FAIL,
    UPLOAD_MARKS_SUCCESS
} from "../actions/types";

const INIT_STATE = {isFailed: false, msg: null, msgColor: "danger", classList: [],examTable:[],deleteSuccess:false};

export default (state=INIT_STATE,action)=>{
    switch(action.type){
        case CLASS_LSIT:
            return{...state,classList:action.payLoad};
        case UPLOAD_MARKS_SUCCESS:
            return{...state,isFailed:true,msg:action.payLoad,msgColor:"success"};
        case UPLOAD_MARKS_FAIL:
            return{...state,isFailed:true,msg:action.payLoad};
        case LIST_MARKS:
            return{...state,examTable:action.payLoad};
        case DELETE_SUCCESS:
            return{...state,isFailed:true,msg:action.payLoad,msgColor:"success",deleteSuccess:true};
        case DELETE_FAILED:
            return{...state,isFailed:true,msg:action.payLoad};
        default:
            return INIT_STATE;
    }
}
