import {RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    HIDE_INFO_MSG,
    VALIDATE_TOKEN_FAIL,
    VALIDATE_TOKEN_SUCCESS} from "./types";
import schoolAPi from "kidzwapi";

function ResetPasswordSuccess(json) {
    return {
        type: RESET_PASSWORD_SUCCESS,
        payLoad: json
    }
}


function ResetPasswordFailed(json) {
    return {
        type: RESET_PASSWORD_FAIL,
        payLoad: json
    }
}

function ValidateTokenSuccess(json) {
    return {
        type: VALIDATE_TOKEN_SUCCESS,
        payLoad: json
    }
}
function ValidateTokenFailed(json) {
    return {
        type: VALIDATE_TOKEN_FAIL,
        payLoad: json
    }
}


function hideMsg() {
    return {
        type: HIDE_INFO_MSG,
        payLoad: null
    }
}

export function HideMsg() {
    return function (dispatch) {
        dispatch(hideMsg());
    }
}


export function ValidateTokenAction(token){
    return function (dispatch){
        if(token!=''){
            return schoolAPi.validateToken(token)
                .then(resp=>{
                    if(resp.status){
                        dispatch(ValidateTokenSuccess(resp.userId));
                    }else{
                        dispatch(ValidateTokenFailed(resp.msg));
                    }
                })
        }else{
            dispatch(ValidateTokenFailed("Something wrong please try again!!"));
        }
    }
}


export function ChangePasswordAction(userId,npwd,cpwd,token){
    return function (dispatch){
        if(npwd===cpwd){
            if(token!='' && userId!='' && npwd!='' && cpwd!=''  ){
                return schoolAPi.changePasswd(userId,npwd,token)
                    .then(resp=>{
                        if(resp.status){
                            dispatch(ResetPasswordSuccess(resp.msg));
                        }else{
                            dispatch(ResetPasswordFailed(resp.msg));
                        }
                    })
            }else{
                dispatch(ResetPasswordFailed("Something went wrong please try again!"));
            }
        }else{
            dispatch(ResetPasswordFailed("Password Miss matching please enter correctly!"));

        }
    }
}
