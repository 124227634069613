import {combineReducers} from 'redux';
import LoginAuth from './LoginAuth';
import ClassRoom from './ClassRoomReducer';
import Subject from './SubjectReducer';
import Sutdents from './StudentReducer';
import Teacher from './TeacherReducer';
import School from './SchoolReducer';
import User from './UserReducer';
import Circular from './CircularReducer';
import Assessment from './AssessmentReducer';
import HomeWork from './HomeWorkReducers';
import Calender from './CalenderReducer';
import GalleryAlbum from './GalleryAlbumReducer';
import Messages from './MessageReducer';
import Attendance from './AttendanceReducer';
import Mark from  './MarksReducer';
import ForgetPsd from './ForgetPasswordReducer';
import ResetPwd from './ResetPasswordReducer';
import RegSchool from './RegisterSchoolReducer';
import Dashboard from './DashboardReducer';
import MyProfile from './MyProfileReducer';
import Fee from './FeeReducer';
import FeeReceipt from './FeeReceiptReducer'

export default combineReducers({
    LoginAuth,ClassRoom,Subject,Sutdents,Teacher,School,
    User,Circular,Assessment,HomeWork,Calender,GalleryAlbum,
    Messages,Attendance,Mark,ForgetPsd,ResetPwd,RegSchool,Dashboard,MyProfile,Fee,FeeReceipt
});
