import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
import moment from "moment";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

import ContentCopy from "@material-ui/icons/ContentCopy";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import withStyles from "material-ui/styles/withStyles";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import ExportToExcel from "../../components/ExportToExcel/ExportToExcel";
import {connect} from "react-redux";
import {DisplayClassList} from "../../actions/ClassRoomAction";
import {DisplaySubjectList} from "../../actions/SubjectAction";
import {CreateHomeWorkAction, HideMsg, DisplayHomeWorkList, DeleteHomeworks} from "../../actions/HomeWorkAction";
import Snackbar from "../../components/Snackbar/Snackbar";
import {USER_TYPE} from "../../helper/Cookies";
import SelectTemp from 'react-select';
import Tooltip from "material-ui/Tooltip";
import {liteBlue} from "../../assets/jss/material-dashboard-pro-react";


class Homework extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: ["Class", "SubjectId", "Homework", "Date", "Subject"],
            selectClass: 0,
            selectSubject: 0,
            subjectName: "",
            homeWork: "",
            homeWorks: [],
            multipleClassSelection: -1,
            homeworkDate: moment().format('DD-MM-YYYY'),
            fileName: "No file Chosen",
            file: null,
            showList: false,
            showSubjects: false
        };
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
    }

    handleCreate = () => {
        //alert();
        this.setState({
            isCreated: !this.state.isCreated,
            multipleClassSelection: -1,
            showSubjects: false,
            homeWorks:[],
            homework:""

        });
    };

    handleMultipleClassTeacher(value) {
        const {SubjectList} = this.props;
        SubjectList(value.value);
        this.setState({multipleClassSelection: [value.value], showSubjects: true});
    }

    handleSimple = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleClass(value) {
        this.setState({selectClass: value.value});
    };

    dateChanged = (dateObj) => {
        if (typeof dateObj === 'object')
            this.setState({homeworkDate: dateObj.format('DD-MM-YYYY')});
    };
    selectHwDate = (dateObj) => {
        if (typeof dateObj === 'object')
            this.setState({shwDate: dateObj.format('DD-MM-YYYY')});
    };

    onChangeValue = (event, subjectId, subjectName) => {
        const temp = [...this.state.homeWorks];
        const setHomeWorks = [];
        let isFound = false;
        temp.forEach(h => {
            if (h.subjectId === subjectId) {
                h.homework = event.target.value;
                if (event.target.value.toString().length > 0) {
                    setHomeWorks.push(h);
                }
                isFound = true;
            } else {
                setHomeWorks.push(h);
            }
        });
        if (!isFound) {
            setHomeWorks.push({
                homework: event.target.value,
                subjectId: subjectId,
                subjectName: subjectName
            });
        }
        this.setState({homeWorks: setHomeWorks});


    };
    HomeWorkCreate = () => {
        const {CreateHomeWork} = this.props;
        const {multipleClassSelection, homeworkDate, homeWorks} = this.state;
        CreateHomeWork(multipleClassSelection, homeworkDate, homeWorks);
    };
    getHomeWorks = () => {
        // this.setState({showList:true});
        const {ListHomeWorks} = this.props;
        const {selectClass, shwDate} = this.state;
        ListHomeWorks(selectClass, shwDate);
    };

    handleFileClick() {
        var input = document.createElement("input");
        input.type = "file";
        input.onchange = this.handleFileChange;
        input.click();
    }

    handleFileChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                fileName: file.name
            });
        };
        reader.readAsDataURL(file);
    }

    deleteHomework(classId, subjectId, homeworkDate) {
        // console.log("home",classId,subjectId,homeworkDate)
        const {HomeworkDelete} = this.props;
        HomeworkDelete(classId, subjectId, homeworkDate);
    }


    componentDidMount() {
        const {ClassList} = this.props;
        ClassList();
        // SubjectList();
    }

    timeInfo = () => {
        const {HideMsg, ClassList, deleteSuccess} = this.props;
        setTimeout(() => {
            HideMsg();
            if (deleteSuccess !== true) {
                this.handleCreate();
            }
            ClassList();
            // SubjectList();
        }, 3000)
    };
    getTheme=(theme) => ({
        ...theme,
        borderRadius: '4px',
        height: "20px",
        colors: {
            ...theme.colors,
            //  primary25: '#00ABDC',
            primary:liteBlue,
            //   primary50:"#00ABDC"
        },
    });

    render() {
        const {ClassList, classes, classList, subjectList, msg, isFailed, msgColor, HideMsg, homeWorkTable} = this.props;
        if (isFailed === true && msg !== null) {
            if (msgColor === "success") {

                this.timeInfo();
            } else {
                setTimeout(() => {
                    HideMsg();
                    ClassList();
                    // SubjectList();
                }, 2000)
            }
        }

        let options = [];

        if (classList !== null || classList !== undefined) {
            classList.forEach(r => {
                const {name, classId} = r;
                options.push({
                    value: classId,
                    label: name
                })
            })
        }

        let ListHomework = [];
        let columns = [];
        const userType = parseInt(USER_TYPE());
        if (userType === 2 || userType === 5) {
            columns = [
                {
                    Header: "Class",
                    accessor: "className",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Date",
                    accessor: "homeworkDate",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Subject",
                    accessor: "subjectName",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Homework",
                    accessor: "homework",
                    style: {color: "#000", textAlign: "left"}

                }
            ]
        } else {
            columns = [
                {
                    Header: "Class",
                    accessor: "className",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Date",
                    accessor: "homeworkDate",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Subject",
                    accessor: "subjectName",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Homework",
                    accessor: "homework",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                }
            ]
        }
        homeWorkTable.forEach(r => {
            const {classId, subjectId, homework, homeworkDate, subjectName, className} = r;
            ListHomework.push({
                classId: classId,
                className: className,
                subjectId: subjectId,
                homework: homework,
                homeworkDate: homeworkDate,
                subjectName: subjectName,
                actions: (
                    <div className="actions-right">
                        <Tooltip title="Delete">
                            <IconButton
                                onClick={() => {
                                    {
                                        if (window.confirm('Are you sure you want to Delete this Record?')) {
                                            this.deleteHomework(classId, subjectId, homeworkDate)
                                        }
                                    }
                                }}
                                color="dangerNoBackground"
                                customClass="remove">
                                <Close/>
                            </IconButton>
                        </Tooltip>
                    </div>
                )
            })
        });
        return (
            <GridContainer justify="center">
                <GridContainer>
                    <ItemGrid xs={12} sm={6} md={4}>
                        <Snackbar
                            open={isFailed}
                            place={"tc"}
                            color={msgColor}
                            message={msg}
                        />
                    </ItemGrid>
                </GridContainer>
                {this.state.isCreated ?
                    <IconCard
                        icon={ContentCopy}
                        title="Create Homework"
                      //  iconColor="rose"
                        content={
                            <form>

                                <GridContainer justify="center">
                                    <ItemGrid xs={12} sm={3}>
                                        <InputLabel className={classes.label}>
                                            Date
                                        </InputLabel>
                                        <FormControl fullWidth>
                                            <Datetime
                                                timeFormat={false}
                                                dateFormat="DD-MM-YYYY"
                                                onChange={this.dateChanged}
                                                inputProps={{
                                                    id: "homeworkDate",
                                                    placeholder: "Select Date",
                                                    // value: cDate
                                                    // onChange: event => this.handleChangeValues(event, "interviewDate", "length", 0),
                                                    // value: this.state.tDate
                                                }}
                                            />
                                        </FormControl>
                                    </ItemGrid>

                                    <ItemGrid xs={12} sm={9}>
                                        <InputLabel className={classes.label}>
                                            Classes :
                                        </InputLabel>
                                        <SelectTemp
                                            isMulti={false}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            // defaultValue={options[0]}
                                            name="multipleClassSelection"
                                            options={options}
                                            onChange={this.handleMultipleClassTeacher.bind(this)}
                                            theme={(theme) => this.getTheme(theme)}
                                        />
                                    </ItemGrid>
                                </GridContainer>
                                {this.state.showSubjects === true ?
                                    <GridContainer justify="center">
                                        <ItemGrid xs={12} sm={3}>
                                            <br/>
                                            <InputLabel className={classes.label}>
                                                Subject
                                            </InputLabel>

                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={9}>
                                            <br/>
                                            <InputLabel className={classes.label}>
                                                Description
                                            </InputLabel>

                                        </ItemGrid>

                                        {subjectList.map((data) => {
                                            return (
                                                <GridContainer key={data.subjectId}>
                                                    <ItemGrid xs={12} sm={3}>
                                                        <br/>
                                                        <legend style={{fontSize: "14px", marginTop: "50px"}}>
                                                            {data.name}
                                                        </legend>
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={9}>
                                                        <CustomInput
                                                            // labelText="Description"
                                                            id={"homeWork" + data.subjectId}
                                                            onChange={event => this.onChangeValue(event, data.subjectId, data.name)}
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                multiline: true,
                                                                rows: 3,
                                                                // placeholder: "Type Description Here"
                                                            }}
                                                        />

                                                    </ItemGrid>
                                                </GridContainer>
                                            )
                                        })
                                        }
                                    </GridContainer>
                                    : null}
                                {this.state.showSubjects === true ?

                                    <GridContainer justify="center">

                                        <ItemGrid>
                                            <br/>
                                            <Button color="primary" onClick={this.HomeWorkCreate}>Save</Button>
                                            <br/>
                                        </ItemGrid>
                                        <ItemGrid>
                                            <br/>
                                            <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                            <br/>
                                        </ItemGrid>

                                    </GridContainer>
                                    : null}
                            </form>
                        }
                    />
                    :
                    <IconCard
                        icon={ContentCopy}
                    //    iconColor={"rose"}
                        title={
                            <div>
                                Home Works
                                {userType !== 5 ?
                                    <Button
                                        onClick={this.handleCreate}
                                        // round color="white"
                                        color="primary" right={true}> Add Homework
                                    </Button>
                                    : null}
                            </div>
                        }
                        content={
                            <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={2} style={{marginTop: "-11px"}}>
                                            <InputLabel className={classes.label}>
                                                From Date
                                            </InputLabel>
                                            <FormControl fullWidth>
                                                <Datetime
                                                    timeFormat={false}
                                                    dateFormat="DD-MM-YYYY"
                                                    onChange={this.selectHwDate}
                                                    inputProps={{
                                                        id: "shwDate",
                                                        placeholder: "Select Date",
                                                        // value: cDate
                                                        // onChange: event => this.handleChangeValues(event, "interviewDate", "length", 0),
                                                        // value: this.state.tDate
                                                    }}
                                                />
                                            </FormControl>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={3} style={{marginTop: "-11px"}}>
                                            <InputLabel className={classes.label}>
                                                Class
                                            </InputLabel>
                                            <SelectTemp
                                                className="basic-single"
                                                classNamePrefix="select"
                                                // defaultValue={options[0]}
                                                name="selectClass"
                                                options={options}
                                                onChange={this.handleClass.bind(this)}
                                                theme={(theme) =>this.getTheme(theme)}
                                            />
                                        </ItemGrid>

                                        <ItemGrid xs={12} sm={2}>
                                            <Button color="primary" onClick={this.getHomeWorks}> Search </Button>
                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
                                <ItemGrid xs={12}>
                                    <ItemGrid xs={12}
                                              style={{height: 1, backgroundColor: "#00acc1", marginTop: "15px"}}/>
                                    <ReactTable
                                        data={ListHomework}
                                        filterable
                                        style={{color: "#00acc1"}}
                                        columns={columns}
                                        defaultPageSize={5}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                    >
                                    </ReactTable>
                                    <ItemGrid xs={12}>
                                        <GridContainer>
                                            <ItemGrid xs={12} md={10}>
                                            </ItemGrid>
                                            <ItemGrid xs={12} md={2}>
                                                <ExportToExcel
                                                    posts={ListHomework}
                                                    headings={this.state.headers}
                                                    fileName={"HomeWorks"} sheetName={"HomeWork"}/>
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                </ItemGrid>
                            </GridContainer>
                        }
                    />
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color:liteBlue,
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    ...customSelectStyle
};
const mapStateToProps = state => {
    const {isFailed, msg, msgColor, classList, subjectList, homeWorkTable, deleteSuccess} = state.HomeWork;
    return {classList, isFailed, msg, msgColor, subjectList, homeWorkTable, deleteSuccess}
};
export default connect(mapStateToProps, {
    ClassList: DisplayClassList,
    SubjectList: DisplaySubjectList,
    CreateHomeWork: CreateHomeWorkAction, HideMsg,
    ListHomeWorks: DisplayHomeWorkList,
    HomeworkDelete: DeleteHomeworks
})(withStyles(extendedFormsStyle)(Homework));
