import Cookies from 'universal-cookie'
import cons, { serverConfig } from '../helper/Consts';
import axios from 'axios';

const ck = new Cookies();

export function loginRequest(taskCode, data, callback) {
    var request = {
        taskCode: taskCode,
        payloadSize: Object.keys(data).length,
        data: data,
    };
    console.log("request--->" + JSON.stringify(request));

    fetch(serverConfig.SERVER_URL + 'vowjute/portalApi', {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Origin': 'http://localhost:3030',
            'Content-Type': 'application/json'
        },

    }).then((response) => response.json())
        .then(json => {
            console.log('Success:', JSON.stringify(json));
            callback(json);
        })
        .catch((error) => {
            console.log(error);
            // console.error('Error:', error);
        });
}
export function request(taskCode, data, callback) {
    var request = {
        taskCode: taskCode,
        payloadSize: Object.keys(data).length,
        data: data,
        userId: ck.get(cons.USERID),
        cipher: ck.get(cons.CIPHER)
    };
    console.log("logout request---> " + JSON.stringify(request));

    fetch(serverConfig.SERVER_URL + 'vowjute/portalApi', {
        method: 'POST',
        //  mode: 'no-cors',
        body: JSON.stringify(request),
        headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Origin': 'http://localhost:3030',
            //           'Content-Type': 'application/json'
            'Content-Type': 'application/json'
        },

    }).then((response) => response.json())
        .then(json => {
            console.log('Success:', JSON.stringify(json));
            callback(json);
        })
        .catch((error) => {
            console.log(error);
        });
}
/*function getDataFromUrl(url,callback) {
    return fetch(serverConfig.SERVER_URL +url)
        .then((response) => response.json())
        .then((responseJson) => {
            console.log('Success:', JSON.stringify(responseJson));

            callback(responseJson);
        })
        .catch((error) => {
            console.error(error);
        });
}*/

export const mediaUpload = (uId, file, type, cb) => {
    const url = serverConfig.SERVER_URL + 'media/uploadMedia';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('userId', uId);
    formData.append('imgType', type);
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    };
    axios.post(url, formData, config).then(
        function (response) {
            cb(response.data);
        }
    ).catch(function (err) {
        console.log(err);
    })
};

export const postMessage = (userId, distId, pDesc, imgType, file) => {
    const url = serverConfig.SERVER_URL + 'media/postMessage';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('userId', userId);
    formData.append('distId', distId);
    formData.append('pDesc', pDesc);
    formData.append('imgType', imgType);
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    };
    return axios.post(url, formData, config);
};
