
import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/icons
import Dvr from "@material-ui/icons/Dvr";
import Close from "@material-ui/icons/Close";
import Amount from "@material-ui/icons/AccountBalance";
import Edit from "@material-ui/icons/Edit";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import { dataTable } from "variables/general.jsx";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import withStyles from "material-ui/styles/withStyles";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import ExportToExcel from "../../components/ExportToExcel/ExportToExcel";
import TextField from "@material-ui/core/TextField/TextField";
class FeeStructure extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            headers:["Code","Class","Date","Subject","Homework"],

            data: dataTable.dataRows.map((prop,key) => {
                return ({
                    id: key,
                    name: prop[0],
                    position: prop[1],
                    office: prop[2],
                    age: prop[3],
                    actions: (
                        // we've added some custom button actions
                        <div className="actions-right">
                            <IconButton
                                onClick={() => {
                                   // let obj = this.state.data.find(o => o.id === key);
                                    this.handleCreate();
                                    /*
                                                                        alert(
                                                                "You've clicked EDIT button on \n{ \nName: " + obj.name
                                                                            + ", \nposition: " + obj.position
                                                                            + ", \noffice: " + obj.office
                                                                            + ", \nage: " + obj.age + "\n}.");
                                    */
                                }}
                                color="warningNoBackground"
                                customClass="edit">
                                <Dvr />
                            </IconButton>{" "}
                            <IconButton
                                onClick={() => {
                                  //  let obj = this.state.data.find(o => o.id === key);
                                    this.handleCreate();
                                    /*
                                                                        alert(
                                                                            "You've clicked EDIT button on \n{ \nName: " + obj.name
                                                                            + ", \nposition: " + obj.position
                                                                            + ", \noffice: " + obj.office
                                                                            + ", \nage: " + obj.age + "\n}.");
                                    */
                                }}
                                color="infoNoBackground"
                                customClass="edit">
                                <Edit />
                            </IconButton>{" "}
                            <IconButton
                                onClick={() => {
                                    var data = this.state.data;
                                    data.find((o,i) => {
                                        if(o.id === key){
                                            // here you should add some custom code so you can delete the data
                                            // from this component and from your server as well
                                            data.splice(i,1);
                                            return true;
                                        }
                                        return false;
                                    });
                                    this.setState({data: data});
                                }}
                                color="dangerNoBackground"
                                customClass="remove">
                                <Close />
                            </IconButton>
                        </div>

                    )
                })
            }),
            selectClass: "",
            selectChild:"",
            fileName:"No file Chosen",
            file:null,
            child_list: ["Child A", "Child B", "Child V", "Child C","Child D","Child E","Child F" ],
            classes_list: ["1st Standard", "2nd Standard", "3rd Standard", "4th Standard","5th Standard","6th Standard","7th Standard" ],

        };
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
    }
    handleCreate=()=>{
        //alert();
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
        });
    };
    handleSimple = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    handleFileClick() {
        var input = document.createElement("input");
        input.type = "file";
        input.onchange = this.handleFileChange;
        input.click();
    }
    handleFileChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                fileName:file.name
            });
        };
        reader.readAsDataURL(file);
    }

    render(){
      /*  const { classes } = this.props;*/
        return (
            <GridContainer justify="center">
                {this.state.isCreated ?
                    <ItemGrid xs={12} sm={12} >
                        <IconCard
                            icon={Amount}
                            title="Create Fee Structure"
                            iconColor="rose"
                            content={
                                <form>

                                    <GridContainer justify="center">

                                        <ItemGrid xs={12} sm={12}>
                                           {/* <InputLabel className={classes.label}>
                                                Fee Structure Name
                                            </InputLabel>*/}
                                            <CustomInput
                                                 labelText=" Fee Structure Name"
                                                id="circular"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    //  multiline: true,
                                                    // rows: 3,
                                                    // placeholder: "Type Description Here"
                                                }}
                                            />
                                        </ItemGrid>

                                        <ItemGrid xs={12} sm={6}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={4}>
                                                    <legend style={{fontSize:"16px",marginTop:"20px"}}>Admission Fee </legend>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={7}>
                                                    <TextField
                                                        id="outlined"
                                                        margin="normal"
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        style={{ height:40}}
                                                    />

                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={4}>
                                                    <legend style={{fontSize:"16px",marginTop:"20px"}}>Tution Fee </legend>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={7}>
                                                    <TextField
                                                        id="outlined"
                                                        margin="normal"
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        style={{ height:40}}
                                                    />

                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={4}>
                                                    <legend style={{fontSize:"16px",marginTop:"20px"}}>Term Fee </legend>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={7}>
                                                    <TextField
                                                        id="outlined"
                                                        margin="normal"
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        style={{ height:40}}
                                                    />

                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={4}>
                                                    <legend style={{fontSize:"16px",marginTop:"20px"}}>Books Fee </legend>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={7}>
                                                    <TextField
                                                        id="outlined"
                                                        margin="normal"
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        style={{ height:40}}
                                                    />

                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={4}>
                                                    <legend style={{fontSize:"16px",marginTop:"20px",fontFamily:"bold"}}>Total </legend>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={7}>
                                                    <TextField
                                                        id="outlined"
                                                        margin="normal"
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        style={{ height:40}}
                                                    />

                                                </ItemGrid>
                                            </GridContainer>
                                        </ItemGrid>

                                        <ItemGrid xs={12} sm={6}>
                                            <GridContainer>
                                                <ItemGrid xs={12} sm={5}>
                                                    <legend style={{fontSize:"16px",marginTop:"20px"}}>Uniform Fee </legend>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={7}>
                                                    <TextField
                                                        id="outlined"
                                                        margin="normal"
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        style={{ height:40}}
                                                    />

                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={5}>
                                                    <legend style={{fontSize:"16px",marginTop:"20px"}}>Annual Insurance </legend>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={7}>
                                                    <TextField
                                                        id="outlined"
                                                        margin="normal"
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        style={{ height:40}}
                                                    />

                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={5}>
                                                    <legend style={{fontSize:"16px",marginTop:"20px"}}>Hostal Fee </legend>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={7}>
                                                    <TextField
                                                        id="outlined"
                                                        margin="normal"
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        style={{ height:40}}
                                                    />

                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={5}>
                                                    <legend style={{fontSize:"16px",marginTop:"20px"}}>Registration Fee </legend>
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={7}>
                                                    <TextField
                                                        id="outlined"
                                                        margin="normal"
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        style={{ height:40}}
                                                    />

                                                </ItemGrid>

                                            </GridContainer>

                                        </ItemGrid>
                                    </GridContainer>



                                    <GridContainer justify="center">

                                        <ItemGrid>
                                            <br/>
                                            <Button color="success" onClick={this.handleCreate}>Save</Button>
                                            <br/>
                                        </ItemGrid>
                                        <ItemGrid>
                                            <br/>
                                            <Button color="rose" onClick={this.handleCreate}  >Cancel</Button>
                                            <br/>
                                        </ItemGrid>

                                    </GridContainer>
                                </form>

                            }
                        />
                    </ItemGrid> :
                    <ItemGrid xs={12}>

                        <IconCard
                            icon={Amount}
                            iconColor={"rose"}
                            title={
                                <div>
                                    Fee Structure
                                    <Button
                                        onClick={this.handleCreate}
                                        // round color="white"
                                        color="rose" right={true}> Add Structure
                                    </Button>
                                </div>
                            }
                            content={
                                <GridContainer >
{/*
                                    <ItemGrid xs={12}>
                                        <GridContainer >
                                            <ItemGrid xs={11} sm={11}>

                                                <legend>   </legend>
                                            </ItemGrid>
                                            <ItemGrid xs={1} sm={1}>

                                                <IconButton
                                                    onClick={this.handleCreate}
                                                    // round color="white"
                                                    color="rose">
                                                    <AddIcon />
                                                </IconButton>
                                            </ItemGrid>

                                        </GridContainer>
                                    </ItemGrid >
*/}

                                    <ItemGrid xs={12}>
                                        <ReactTable
                                            data={this.state.data}
                                            filterable
                                            style={{color:"#00acc1"}}
                                            columns={[
                                                {
                                                    Header: "Code",
                                                    accessor: "name",
                                                    style:{color:"#000",textAlign:"left"}

                                                },

                                                {
                                                    Header: "Structure Name",
                                                    accessor: "office",
                                                    style:{color:"#000",textAlign:"left"}

                                                },
                                                {
                                                    Header: "Created On",
                                                    accessor: "age",
                                                    style:{color:"#000",textAlign:"left"}

                                                },
                                                {
                                                    Header: "Amount",
                                                    accessor: "age",
                                                    style:{color:"#000",textAlign:"left"}

                                                },
                                                {
                                                    Header: "Actions",
                                                    accessor: "actions",

                                                    sortable: false,
                                                    filterable: false,
                                                }
                                            ]}
                                            defaultPageSize={5}
                                            showPaginationBottom={true}
                                            className="-striped -highlight"
                                        />

                                    </ItemGrid>
                                    <ItemGrid xs={12}>
                                        <GridContainer>
                                            <ItemGrid xs={12} md={10}>
                                            </ItemGrid>
                                            <ItemGrid xs={12} md={2}>
                                                <ExportToExcel
                                                    posts={this.state.data}
                                                    headings={this.state.headers}
                                                    fileName={"Fee Structures"} sheetName={"Fee Structure"}/>

                                            </ItemGrid>



                                        </GridContainer>
                                    </ItemGrid>

                                </GridContainer>

                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
}
const extendedFormsStyle=theme =>( {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#AAAAAA",
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    ...customSelectStyle
});
export default withStyles(extendedFormsStyle)(FeeStructure);
