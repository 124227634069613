import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import moment from "moment";

// @material-ui/icons
import CircularIcon from "@material-ui/icons/BlurCircular";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "../../components/Cards/IconCard.jsx";

import Button from "../../components/CustomButtons/Button";
import withStyles from "material-ui/styles/withStyles";
import InputLabel from "material-ui/Input/InputLabel";
import {connect} from "react-redux";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import {DisplayClassList} from "../../actions/ClassRoomAction";
import SelectTemp from 'react-select';
import {liteBlue} from "../../assets/jss/material-dashboard-pro-react";
import { GetReceipts} from "../../actions/FeeReceipt";
import {GetChildList} from "../../actions/AssessmentAction";

class ReceiptsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectClass: "",
            selectChild: "",

        };
    }

    handleCreate = () => {
        //alert();
        this.setState({
            isCreated: !this.state.isCreated,
            // multipleClassSelection: "",
        });
    };


    FeeReceipts=()=>{
       const{selectClass,selectChild}=this.state;
        const {getFeeReceipts} = this.props;
        getFeeReceipts(selectChild);

    }

    getTheme = (theme) => ({
        ...theme,
        borderRadius: '4px',
        height: "20px",
        colors: {
            ...theme.colors,
            //  primary25: '#00ABDC',
            primary: liteBlue,
            //   primary50:"#00ABDC"
        },
    });
    timeInfo = () => {
        const {HideMsg, ClassList} = this.props;

        setTimeout(() => {
            HideMsg();
            ClassList();
                this.handleCreate();
          //  this.getData();
        }, 3000)
    };

    handleChange(value) {
        this.setState({selectClass: value.value});
        const {ChildList} = this.props;
        let GetChildArray = [];
        GetChildArray.push(value.value);
        ChildList(GetChildArray);
    }
    componentDidMount() {
        const {ClassList,getFeeReceipts} = this.props;
        ClassList();
        getFeeReceipts();
    }



    render() {
        const {classes, classList, classWiseList, FeeReceiptData, msg, msgColor, isFailed} = this.props;
        if (isFailed === true && msg !== null) {
            if (msgColor === "success") {
                this.timeInfo();
            } else {
                setTimeout(() => {
                    this.getData();
                }, 2000)
            }
        }

        let ClassNames = [{value:"",label:"select..."}], childNames = [{value:"",label:"select..."}];

        if (classList !== null) {
            classList.forEach(r => {
                const {name, classId} = r;
                ClassNames.push({
                    value: classId,
                    label: name
                })
            })
        }
        if (classWiseList !== null) {
            classWiseList.forEach(r => {
                const {name, childId} = r;
                childNames.push({
                    value: childId,
                    label: name
                })
            })

        }
        let FeeReceiptsList = [];
      let columns = [
            {
                Header: "Child Name",
                accessor: "name",
                filterable: false,
                style: {color: "#000", textAlign: "left"}
            },
            {
                Header: "Term Name",
                accessor: "termName",
                filterable: false,
                style: {color: "#000", textAlign: "left"}


            },
            {
                Header: "Paid Amount",
                accessor: "amount",
                filterable: false,
                headerStyle:{textAlign: "left"},
                style: {color: "#000"}

            },
          {
              Header: "Paid Date",
              accessor: "paidDate",
              filterable: false,
              headerStyle:{textAlign: "left"},
              style: {color: "#000"}

          }
        ];
       if(FeeReceiptData.length>0)
        FeeReceiptData.forEach(f=>{
            FeeReceiptsList.push({
                amount:f.amount,
                termName:f.termName,
                name:f.child.name,
               // paidDate:moment(f.createdAt).format("MM-DD-YYYY")
                paidDate:f.createdAt

            })
        })

        // }
        return (
            <GridContainer justify="center">

                    <IconCard
                        icon={CircularIcon}
                        //iconColor="primary"
                        title={"Fee Receipts List"}
                        content={
                            <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                    <GridContainer>
                                            <ItemGrid xs={12} sm={6}>
                                                <GridContainer>
                                                    <ItemGrid xs={12} sm={6}>
                                                        <InputLabel className={classes.label}>
                                                            Select Class *
                                                        </InputLabel>
                                                        <SelectTemp
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            // value={this.state.selectClass}
                                                            // defaultValue={options[0]}
                                                            name="selectClass"
                                                            options={ClassNames}
                                                            onChange={this.handleChange.bind(this)}
                                                            theme={(theme) => this.getTheme(theme)}
                                                        />
                                                    </ItemGrid>
                                                    <ItemGrid xs={12} sm={6}>
                                                        <InputLabel className={classes.label}>
                                                            Select Child
                                                        </InputLabel>
                                                        <SelectTemp
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            // defaultValue={options[0]}
                                                            name="selectChild"
                                                            options={childNames}
                                                           // onChange={this.handleStudentChange.bind(this)}
                                                            onChange={(option)=>{
                                                                this.setState({selectChild:option.value})
                                                            }}
                                                            theme={(theme) => this.getTheme(theme)}
                                                        />
                                                    </ItemGrid>
                                                </GridContainer>
                                            </ItemGrid>


                                        <ItemGrid xs={12} sm={2}>
                                            <Button color="primary" onClick={this.FeeReceipts}> Search </Button>
                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
                                {this.state.alert}
                                <ItemGrid xs={12}>
                                    <ItemGrid xs={12}
                                              style={{height: 1, backgroundColor: "#00acc1", marginTop: "15px"}}/>
                                    <ReactTable
                                        data={FeeReceiptsList}
                                        filterable
                                        style={{color: "#00acc1"}}
                                        columns={columns}
                                        defaultPageSize={5}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                    />

                                </ItemGrid>
                            </GridContainer>

                        }
                    />
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color:liteBlue,
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex"
    },
    ...customSelectStyle
};
const mapStateToProps = state => {
    const {msg, msgColor, isFailed, classList, classWiseList, FeeList, FeeReceiptData} = state.FeeReceipt;
    return {msg, msgColor, isFailed, classList, classWiseList, FeeList, FeeReceiptData}
};

export default connect(mapStateToProps, {
    ChildList: GetChildList,
    ClassList: DisplayClassList,
    getFeeReceipts: GetReceipts
})(withStyles(extendedFormsStyle)(ReceiptsList));
