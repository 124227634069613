import React from "react";
//import classNames from "classnames";
import PropTypes from "prop-types";
//import {Manager, Target, Popper} from "react-popper";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import IconButton from "material-ui/IconButton";
import Hidden from "material-ui/Hidden";

// @material-ui/icons
//import ExitToApp from "@material-ui/icons/ExitToApp";
import ExitToApp from "../../assets/icons/logout.png";
import Tooltip from "material-ui/Tooltip";

import headerLinksStyle from "assets/jss/material-dashboard-pro-react/components/headerLinksStyle";

import {connect} from 'react-redux';
import {exitToApp} from '../../actions';

class HeaderLinks extends React.Component {
    state = {
        open: false,
        userName: "Profile Name",
        //imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    };
    handleClick = () => {
        this.setState({open: !this.state.open});
    };
    handleClose = () => {
        this.setState({open: false});
    };

    render() {
        const {classes, rtlActive, exitToApp} = this.props;
        return (
            <div>
                <Tooltip title="Logout" placement="bottom" classes={{tooltip: classes.tooltip}}>
                    <IconButton
                        onClick={()=>{
                            const r = window.confirm("Do you really want to LogOut?");
                            if(r === true){
                                exitToApp();
                            }

                        }}
                        color="inherit"
                        aria-label="Person"
                        className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
                        classes={{
                            label: rtlActive ? classes.labelRTL : ""
                        }}

                    >
                        <img src={ExitToApp} height={40} width={40} className={rtlActive ? classes.links + " " + classes.linksRTL : classes.links}/>
{/*
                        <ExitToApp className={rtlActive ? classes.links + " " + classes.linksRTL : classes.links}/>
*/}
                        <Hidden mdUp>
                            <p className={classes.linkText}>
                                {rtlActive ? "الملف الشخصي" : "Logout"}
                            </p>
                        </Hidden>
                    </IconButton>
                </Tooltip>

            </div>
        );
    }
}

HeaderLinks.propTypes = {
    classes: PropTypes.object.isRequired,
    rtlActive: PropTypes.bool
};


export default connect(null, {exitToApp})(withStyles(headerLinksStyle)(HeaderLinks));
