import {gql} from "apollo-boost";

// export const getFeeQuery = gql ``;
export const getTeacher = gql `query getTeachers{
  getTeachers{
   userId
    schoolId
    name
    classTeacher
    classes{
      classId
      subjectId
    }
    contact
    emailId
    activeStatus
    attFor
  }
}`;
