import schoolApi from "kidzwapi";
import {
    CREATE_SCHOOL_FAIL,
    CREATE_SCHOOL_SUCCESS,
    HIDE_INFO_MSG, SCHOOL_LIST,
    UPDATE_SCHOOL_FAIL,
    UPDATE_SCHOOL_SUCCESS
} from "./types";

import {SCHOOL_ID,USER_ID,AUTH} from "../helper/Cookies";


function CreateSchoolSuccess(){
    return {
        type:CREATE_SCHOOL_SUCCESS,
        payLoad:"School Created Successfully"
    }
}

function CreateSchoolFailed(msg){
    return{
        type:CREATE_SCHOOL_FAIL,
        payLoad:msg
    }
}

function hideMsg(){
    return{
        type:HIDE_INFO_MSG,
        payLoad:null
    }
}

function UpdateSchoolSuccess(){
    return{
        type:UPDATE_SCHOOL_SUCCESS,
        payLoad:"School Updated Successfully"
    }
}
function UpdatedSchoolFailed(msg){
    return{
        type:UPDATE_SCHOOL_FAIL,
        payLoad:msg
    }
}

function SchoolList(json){
    return{
        type:SCHOOL_LIST,
        payLoad:json
    }
}

export function HideMsg(){
    return function(dispatch){
        dispatch(hideMsg());
    }
}



export function createSchoolAction(schoolName, schoolCode, schoolAddress, schoolLandline,schoolCity, schoolCountry,
                                   schoolMobile, schoolEmail, schoolWebsite, socialLinks, menus, file,studentSize,schoolContactPerson){
    return function (dispatch){
        if(schoolName !== null && schoolCode !==null && schoolAddress!=null && schoolMobile!==null && menus!=null){
        return schoolApi.createSchool(schoolName,schoolCode,schoolAddress, schoolLandline,schoolCity,schoolCountry,
            schoolMobile, schoolEmail,schoolWebsite, socialLinks,file,menus,parseInt(SCHOOL_ID()),studentSize,schoolContactPerson, USER_ID(),AUTH())
            .then(resp=>{
                if(resp.status){
                    dispatch(CreateSchoolSuccess());
                }
                else{
                    dispatch(CreateSchoolFailed(resp.msg))
                }
            })
        }else{
            dispatch(CreateSchoolFailed("Enter All Fileds"))
        }
    }
}


export function updateSchoolAction(schoolId,schoolName, schoolCode, schoolAddress,schoolCity, schoolCountry,
                                   schoolMobile, schoolEmail, schoolWebsite, socialLinks, menus, file){
    return function (dispatch){
        if(schoolId!==null && schoolName !==null && schoolAddress !==null){
            return schoolApi.updateSchool(parseInt(schoolId),schoolName, schoolCode, schoolAddress,schoolCity, schoolCountry,
                schoolMobile, schoolEmail, schoolWebsite, socialLinks,file, menus,USER_ID(),AUTH())
                .then(resp=>{
                    console.log("response",resp)
                    if(resp.status){
                        dispatch(UpdateSchoolSuccess());
                    }
                    else{
                        dispatch(UpdatedSchoolFailed(resp.msg))
                    }
                })
        }else{
            dispatch(UpdatedSchoolFailed("Enter All Fields"))
        }
    }
}

export function DisplaySchoolList(){
    return function (dispatch){
        return schoolApi.listSchool(parseInt(SCHOOL_ID()), USER_ID(), AUTH())
            .then(resp=>{
                if(resp.status){
                        dispatch(SchoolList(resp.schools));
                    }
                }
            )

    }
}
