import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Assessment from "@material-ui/icons/Assessment";
import AttachmentIcon from "@material-ui/icons/AttachFile";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import IconButton from "../../components/CustomButtons/IconButton.jsx";

import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import withStyles from "material-ui/styles/withStyles";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "material-ui/Select";
import MenuItem from "material-ui/Menu/MenuItem";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import ExportToExcel from "../../components/ExportToExcel/ExportToExcel";
import {DisplayClassList} from "../../actions/ClassRoomAction";
import {GetChildList} from "../../actions/AssessmentAction";
import {SendMessageAction, HideMsg, DisplayMessages, DeleteMessages} from "../../actions/MessageAction";
import {connect} from "react-redux";
import Snackbar from "../../components/Snackbar/Snackbar";
import {USER_TYPE} from "../../helper/Cookies";
import SelectTemp from 'react-select';
import Tooltip from "material-ui/Tooltip";
import {Input, MessageBox, MessageList,Popup} from "react-chat-elements";
import SendIcon from "@material-ui/icons/Send";
import {liteBlue, primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import moment from "moment";


class Message extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: ["Name", "Message", "AssessmentUrl"],
            message: "",
            selectAll: 0,
            selectClass: 0,
            selectChild: [],
            multipleClassSelection: -1,
            fileName: "No file Chosen",
            file: null,
            fileType: "NA",
            showList: false
        };
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
    }

    handleCreate = () => {
        //alert();
        this.setState({
            // selectClass: 0,
            //  selectChild: [],
            //  multipleClassSelection: -1,
            // fileName: "No file Chosen",
            isCreated: !this.state.isCreated,
        });

    };
    onChangeValue = event => {
        this.setState({[event.target.id]: event.target.value});
    };

    handleClass(value) {
        this.setState({selectClass: value.value});
        const {ChildList} = this.props;
        ChildList([value.value]);
    };

    handleChild = event => {
        if (event!==null) {
            const {classWiseList} = this.props;
            let ChildList = [];
            event.forEach(r => {
                const {value} = r;
                ChildList.push(value)
            });
            this.setState({selectChild: ChildList});
        } else {
            this.setState({selectChild:[]});
        }
    };

    handleMultipleClassTeacher(value) {
        let ClassArray = [];
        if (value !== null) {
            value.map((data) =>
                ClassArray.push(data.value)
            )
        }
        const {ChildList} = this.props;
        ChildList(ClassArray);
        this.setState({multipleClassSelection: ClassArray});
    }

    MessageSend = () => {
        const {SendMessage} = this.props;
        const {selectChild, multipleClassSelection, message, file, fileType} = this.state;
        SendMessage(selectChild, multipleClassSelection, message, file, fileType);
    };

    handleFileClick() {
        var input = document.createElement("input");
        input.type = "file";
        input.onchange = this.handleFileChange;
        input.click();
    }

    handleFileChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                fileName: file.name,
                fileType: file.type,
            });
        };
        reader.readAsDataURL(file);
    }

    componentDidMount() {
        const {ClassList} = this.props;
        ClassList();
    };

    handleAttachment(attachmentUrl) {
        if (attachmentUrl !== null && attachmentUrl !== undefined)
            window.open(attachmentUrl, "_blank");
        window.location.assign(attachmentUrl);
        //  window.location.hash(attachmentUrl);
    }

    deleteMessage(messageId) {
        const {MessageDelete} = this.props;
        MessageDelete(messageId);
    }

    getMessages = () => {
        this.setState({showList: true});
        const {ListMessages} = this.props;
        const {multipleClassSelection} = this.state;
        ListMessages(multipleClassSelection);
    };
    timeInfo = () => {
        const {HideMsg, ClassList, deleteSuccess} = this.props;
        setTimeout(() => {
            HideMsg();
           // ClassList();
            if (deleteSuccess !== true) {
                this.refs.message.clear();
                this.setState({fileName:"",file:null,fileType:'NA'})
            }
            this.getMessages();
        }, 3000)
    };
    getTheme=(theme) => ({
        ...theme,
        borderRadius: '4px',
        height: "20px",
        colors: {
            ...theme.colors,
            //  primary25: '#00ABDC',
            primary:liteBlue,
            //   primary50:"#00ABDC"
        },
    });

    render() {

        const {classes, classList, classWiseList, msg, isFailed, msgColor, HideMsg, MessageTable} = this.props;
        if (isFailed === true && msg !== null) {
            if (msgColor === "success") {
                this.timeInfo();
            } else {
                setTimeout(() => {
                    HideMsg();
                }, 2000)
            }
        }

        // let options=[{value:0,label:'Select All'}];

        let options = [],childNames=[];

        if (classList !== null || classList !== undefined) {
            classList.forEach(r => {
                const {name, classId} = r;
                options.push({
                    value: classId,
                    label: name
                })
            })
        }
         console.log("selectChild",this.state.selectChild);
        if(classWiseList!==null){
            childNames.push({label:"Select All",value:0});
            classWiseList.forEach(r => {
                const {name, childId} = r;
                childNames.push({
                    value: childId,
                    label: name
                })
            })

        }

        let ListMessages = [],messagesList=[];
        let columns = [];
        const userType = parseInt(USER_TYPE());
        // if (MessageTable.length > 0) {
        if (userType === 2 || userType === 5) {
            columns = [
                {
                    Header: "Name",
                    accessor: "students",
                    style: {color: "#000", textAlign: "left"}
                },
                {
                    Header: "Message",
                    accessor: "message",
                    style: {color: "#000", textAlign: "left"}
                },
                {
                    Header: "Attachment",
                    accessor: "attachment",
                    sortable: false,
                    filterable: false,
                }
            ]
        } else {
            columns = [
                {
                    Header: "Name",
                    accessor: "students",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Message",
                    accessor: "message",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Attachment",
                    accessor: "attachment",
                    sortable: false,
                    filterable: false,
                },
                {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                }
            ]
        }
        // console.log("MessageTable",MessageTable);
        MessageTable.forEach(r => {
            const {message, attachmentUrl, students, messageId,createdDateTime,attachmentType} = r;
            messagesList.push({
                //title: 'Tania Andrew',
                position: 'right',
                type:attachmentUrl!==null?'photo': 'text',
                text: message,
                messageId: messageId,
                // date:new Date(createdDateTime),
                dateString:  moment(new Date(createdDateTime)).format('DD/MM/YYYY h:mm A'),
                data: {
                    uri:attachmentUrl,
                    /*status: {
                        click: false,
                        loading: 0,
                    }*/
                }

            });
            ListMessages.push({
                messageId: messageId,
                message: message,
                students: students.length,
                attachmentUrl: attachmentUrl,
                attachment: (
                    <div>
                        <IconButton
                            onClick={() => {
                                this.handleAttachment(attachmentUrl);
                            }}
                            color="primaryNoBackground"
                            customClass="edit">
                            <AttachmentIcon/>
                        </IconButton>{" "}
                    </div>
                ),
                actions: (
                    <div className="actions-right">
                        <Tooltip title="Delete">
                            <IconButton
                                onClick={() => {
                                    {
                                        if (window.confirm('Are you sure you want to Delete this Record?')) {
                                            this.deleteMessage(messageId)
                                        }
                                        ;
                                    }
                                }}
                                color="dangerNoBackground"
                                customClass="remove">
                                <Close/>
                            </IconButton>
                        </Tooltip>
                    </div>
                )
            })
        })
        // }
        return (
            <GridContainer justify="center">
                <GridContainer>
                    <ItemGrid xs={12} sm={6} md={4}>
                        <Snackbar
                            open={isFailed}
                            place={"tc"}
                            color={msgColor}
                            message={msg}
                        />
                    </ItemGrid>
                </GridContainer>
                {this.state.isCreated ?
                    <IconCard
                        icon={Assessment}
                        title="Send Messsage"
                        iconColor="rose"
                        content={
                            <form>
                                <CustomInput
                                    labelText="Message"
                                    id="message"
                                    onChange={this.onChangeValue}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                                <GridContainer justify="center">

                                    <ItemGrid xs={12} sm={6}>
                                        <InputLabel style={{color: "#AAAAAA"}}>
                                            Select Class
                                        </InputLabel>
                                        <SelectTemp
                                            isMulti
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            // defaultValue={options[0]}
                                            name="multipleClassSelection"
                                            options={options}
                                            onChange={this.handleMultipleClassTeacher.bind(this)}
                                            theme={(theme) =>this.getTheme(theme)}
                                        />
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6}>

                                        <FormControl
                                            fullWidth
                                            className={classes.selectFormControl}
                                        >
                                            <InputLabel
                                                htmlFor="multiple-select"
                                                className={classes.selectLabel}
                                            >
                                                Select Child
                                            </InputLabel>
                                            <Select
                                                multiple
                                                MenuProps={{
                                                    className: classes.selectMenu
                                                }}
                                                classes={{
                                                    select: classes.select
                                                }}
                                                value={this.state.selectChild}
                                                onChange={this.handleChild}
                                                inputProps={{
                                                    name: "selectChild",
                                                    id: "multiple-select"
                                                }}
                                            >
                                                <MenuItem
                                                    disabled
                                                    classes={{
                                                        root: classes.selectMenuItem
                                                    }}
                                                >
                                                    Select Child
                                                </MenuItem>
                                                <MenuItem
                                                    value={this.state.selectAll}
                                                    classes={{
                                                        root: classes.selectMenuItem
                                                    }}
                                                >
                                                    Select All
                                                </MenuItem>
                                                {classWiseList !== null ?
                                                    classWiseList.map((data) =>
                                                        <MenuItem
                                                            key={data.childId}
                                                            classes={{
                                                                root: classes.selectMenuItem,
                                                                selected: classes.selectMenuItemSelected
                                                            }}
                                                            value={data.childId}
                                                        >
                                                            {data.name}
                                                        </MenuItem>
                                                    )
                                                    : null}


                                            </Select>
                                        </FormControl>


                                    </ItemGrid>

                                </GridContainer>
                                {/* <InputLabel className={classes.label}>
                                    Attachment
                                    </InputLabel>*/}

                                <GridContainer>

                                    <ItemGrid xs={12} sm={6}>
                                        <CustomInput
                                            labelText=" Attachment"
                                            id="attachment"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                disabled: true,
                                                value: "" + this.state.fileName
                                            }}
                                        />
                                    </ItemGrid>
                                    <ItemGrid>
                                        <Button onClick={this.handleFileClick}>Choose File</Button>
                                    </ItemGrid>

                                </GridContainer>

                                <GridContainer justify="center">

                                    <ItemGrid>
                                        <br/>
                                        <Button color="success" onClick={this.MessageSend}>Send</Button>
                                        <br/>
                                    </ItemGrid>
                                    <ItemGrid>
                                        <br/>
                                        <Button color="rose" onClick={this.handleCreate}>Cancel</Button>
                                        <br/>
                                    </ItemGrid>

                                </GridContainer>
                            </form>

                        }
                    />
                    :
                    <IconCard
                        icon={Assessment}
                        //iconColor={"rose"}
                        title={
                            <div>
                                Messages

{/*
                                {userType !== 5 ?
                                    <Button
                                        onClick={this.handleCreate}
                                        // round color="white"
                                        color="rose" right={true}> Add Message
                                    </Button>
                                    : null}
*/}

                            </div>
                        }
                        content={
                            <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={3} style={{marginTop: "-11px"}}>
                                            <InputLabel className={classes.label}>
                                                Class
                                            </InputLabel>
                                            <SelectTemp
                                                isMulti
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                // defaultValue={options[0]}
                                                name="multipleClassSelection"
                                                options={options}
                                                onChange={this.handleMultipleClassTeacher.bind(this)}
                                                theme={(theme) =>this.getTheme(theme)}
                                            />
                                        </ItemGrid>

                                        <ItemGrid xs={12} sm={3} style={{marginTop: "-11px"}}>
                                            <InputLabel className={classes.label}>
                                                Select Child
                                            </InputLabel>
                                            <SelectTemp
                                                isMulti
                                                className="basic-single"
                                                classNamePrefix="select"
                                                // defaultValue={options[0]}
                                                name="selectChild"
                                                options={childNames}
                                                onChange={this.handleChild}
                                                theme={(theme) =>this.getTheme(theme)}
                                            />
                                        </ItemGrid>

                                        <ItemGrid xs={12} sm={2}>
                                            <Button color="primary" onClick={this.getMessages}> Search </Button>
                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
                                {/*{this.state.showList === true ?*/}
                                <ItemGrid xs={12}>
                                    <div>
                                        <GridContainer style={{
                                            height: 400,
                                            overflowY: 'auto',
                                            overflowScrolling: "touch",
                                            backgroundColor: "#eee",
                                            paddingTop: "10px"
                                        }}>
{/*
                                            <Popup
                                                show={this.state.show}
                                                header='Lorem ipsum dolor sit amet.'
                                                headerButtons={[{
                                                    type: 'transparent',
                                                    color:'black',
                                                    text: 'close',
                                                    onClick: () => {
                                                        this.setState({show: false})
                                                    }
                                                }]}
                                                renderContent={()=>{
                                                    return (<GridContainer>
                                                        <Input
                                                            placeholder="Type here..."
                                                            multiline={true}
                                                            minHeight={50}
                                                            ref={'message'}
                                                            onChange={(event)=>{
                                                                this.setState({message:event.target.value})
                                                            }}
                                                            value={this.state.message}
                                                            rightButtons={
                                                                <div>
                                                                    <img width={200} height={200} src={this.state.file}/>
                                                                    <IconButton
                                                                        onClick={this.handleFileClick}
                                                                        color="blackNoBackground"
                                                                        customClass="edit">
                                                                        <AttachmentIcon/>
                                                                    </IconButton>

                                                                    <IconButton
                                                                        color='primaryNoBackground' onClick={this.MessageSend}>
                                                                        <SendIcon/>
                                                                    </IconButton>
                                                                </div>


                                                            }/>
                                                    </GridContainer>)
                                                }}/>
*/}

                                            <ItemGrid xs={12}>
                                                <GridContainer>
                                                    {messagesList.map((prop,i)=>{
                                                        return (
                                                            <ItemGrid xs={12}>
                                                                <IconButton
                                                                    color='dangerNoBackground' right={true} onClick={()=>{
                                                                    if (window.confirm('Are you sure you want to Delete this Record?')) {
                                                                        this.deleteMessage(prop.messageId)
                                                                    }

                                                                }}>
                                                                    <Close/>
                                                                </IconButton>

                                                                <MessageBox
                                                                    // title={}
                                                                    position={prop.position}
                                                                    type={prop.type}
                                                                    // forwarded={true}
                                                                    dateString={prop.dateString}
                                                                    data={prop.data}
                                                                    text={prop.text}/>

                                                            </ItemGrid>
                                                        )
                                                    })
                                                    }
                                                </GridContainer>
                                                {/*
                                                <MessageList
                                                    className='message-list'
                                                    lockable={true}
                                                    toBottomHeight={'100%'}
                                                    dataSource={messagesList}/>
*/}
                                                {/*
                                                <MessageBox
                                                   // title={}
                                                    position={'right'}
                                                    type={'text'}
                                                    forwarded={true}
                                                    dateString={'2:50 PM'}
                                                    text={'I am fine . What about you?'}/>
*/}
                                            </ItemGrid>
                                        </GridContainer>
                                        <GridContainer>
                                            <ItemGrid xs={12}>
                                                <Input
                                                    placeholder="Type here..."
                                                    multiline={true}
                                                    minHeight={50}
                                                    ref={'message'}
                                                    onChange={(event)=>{
                                                        this.setState({message:event.target.value})
                                                    }}
                                                    value={this.state.message}
                                                    rightButtons={
                                                        <div>
                                                            <InputLabel className={classes.label}>
                                                                {this.state.fileName}
                                                            </InputLabel>

                                                            <IconButton
                                                                onClick={this.handleFileClick}
                                                                color="blu"
                                                                customClass="edit">
                                                                <AttachmentIcon/>
                                                            </IconButton>

                                                            {userType !== 5 ?<IconButton
                                                                color='primaryNoBackground' onClick={this.MessageSend}>
                                                                <SendIcon/>
                                                            </IconButton>:null}
                                                        </div>


                                                    }/>
                                                <ItemGrid xs={12} className={classes.line}/>
                                            </ItemGrid>
                                        </GridContainer>
                                    </div>

                                    {/*  <ReactTable
                                        data={ListMessages}
                                        filterable
                                        style={{color: "#00acc1"}}
                                        columns={columns}
                                        defaultPageSize={5}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                    />
                                    <ItemGrid xs={12}>
                                        <GridContainer>
                                            <ItemGrid xs={12} md={10}>
                                            </ItemGrid>
                                            <ItemGrid xs={12} md={2}>
                                                <ExportToExcel
                                                    key={ListMessages}
                                                    posts={ListMessages}
                                                    headings={this.state.headers}
                                                    fileName={"Messages"} sheetName={"Messages"}/>
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>*/}

                                </ItemGrid>
                                {/*:null}*/}
                            </GridContainer>

                        }
                    />
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color:liteBlue,
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex"
    },
    line:{height: 2, backgroundColor:liteBlue},
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    ...customSelectStyle
};
const mapStateToProps = state => {
    const {isFailed, msg, msgColor, MessageTable, classList, classWiseList, deleteSuccess} = state.Messages;
    return {classList, isFailed, msg, msgColor, MessageTable, classWiseList, deleteSuccess}
};
export default connect(mapStateToProps, {
    ChildList: GetChildList,
    ClassList: DisplayClassList,
    SendMessage: SendMessageAction, HideMsg,
    ListMessages: DisplayMessages,
    MessageDelete: DeleteMessages
})(withStyles(extendedFormsStyle)(Message));

