import {gql} from "apollo-boost";

export const createFeeMutation = gql `mutation createFee($input: CreateFeeInput!){
  createFee(iFee: $input){
    _id
    schoolId
    classId
    terms
    lastDates
    fee{
      head
      termName
      value
      total
    }
    validTo
    validFrom
    createdAt
  }
}`;

export const deleteFeeMutation=gql `mutation deleteFee($_id: String!){
 deleteFee(_id: $_id){
    _id
  }
}`;

