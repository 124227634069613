import {
    GET_DEVICE_DATA_SUCCESS,
    GET_DEVICE_DATA_FAIL,
    GET_SCHOOL_DATA_FAIL,
    GET_SCHOOL_DATA_SUCCESS,
    GET_DASHBOARD_CIRCULARS_SUCCESS,
    GET_DASHBOARD_CIRCULARS_FAIL,
    GET_DASHBOARD_ASSESSMENTS_SUCCESS,
    GET_DASHBOARD_ASSESSMENTS_FAIL,
    GET_DASHBOARD_HOMEWORK_SUCCESS, GET_DASHBOARD_HOMEWORK_FAIL
} from "../actions/types";
const INIT_STATE = {isFailed: false, msg: null, schoolData: [],DeviceData:[],Circulars:[],Assessments:[],HomeWorks:[]};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SCHOOL_DATA_SUCCESS:
            return {...state,isFailed: true,schoolData: action.payLoad};
        case GET_SCHOOL_DATA_FAIL:
            return {...state, isFailed: true, msg: action.payLoad};
        case GET_DEVICE_DATA_SUCCESS:
            return {...state,isFailed: true,DeviceData: action.payLoad};
        case GET_DEVICE_DATA_FAIL:
            return {...state, isFailed: true, msg: action.payLoad};
        case GET_DASHBOARD_CIRCULARS_SUCCESS:
            return {...state,isFailed: true,Circulars: action.payLoad};
        case GET_DASHBOARD_CIRCULARS_FAIL:
            return {...state, isFailed: true, msg: action.payLoad};
        case GET_DASHBOARD_ASSESSMENTS_SUCCESS:
            return {...state,isFailed: true,Assessments: action.payLoad};
        case GET_DASHBOARD_ASSESSMENTS_FAIL:
            return {...state, isFailed: true, msg: action.payLoad};
        case GET_DASHBOARD_HOMEWORK_SUCCESS:
            return {...state,isFailed: true,HomeWorks: action.payLoad};
        case GET_DASHBOARD_HOMEWORK_FAIL:
            return {...state, isFailed: true, msg: action.payLoad};
        default:
            return INIT_STATE;
    }
}
