export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const UNAME_CHANGED = "UNAME_CHANGED";
export const PWD_CHANGED = "PWD_CHANGED";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const HIDE_INFO_MSG = "HIDE_INFO";
export const LOGGED_OUT = "LOGGED_OUT";
export const LOGOUT_FAILED = "LOGOUT_FAILED";
export const CREATE_CLASS_SUCCESS = "CREATE_CLASS_SUCCESS";
export const CREATE_CLASS_FAIL = "CREATE_CLASS_FAIL";
export const UPDATE_CLASS_SUCESS = "UPDATE_CLASS_SUCESS";
export const UPDATE_CLASS_FAIL = "UPDATE_CLASS_FAIL";
export const CLASS_LSIT = "CLASS_LIST";
export const CREATE_SUBJECT_SUCCESS = "CREATE_SUBJECT_SUCCESS";
export const CREATE_SUBJECT_FAIL = "CREATE_SUBJECT_FAIL";
export const UPDATE_SUBJECT_SUCCESS = "UPDATE_SUBJECT_SUCCESS";
export const UPDATE_SUBJECT_FAIL = "UPDATE_SUBJECT_FAIL";
export const SUBJECT_LIST = "SUBJECT_LIST";
export const CREATE_STUDENT_SUCCESS="CREATE_STUDENT_SUCCESS";
export const CREATE_STUDENT_FAIL ="CREATE_STUDENT_FAIL";
export const UPDATE_STUDENT_SUCCESS="UPDATE_STUDENT_SUCCESS";
export const UPDATE_STUDENT_FAIL="UPDATE_STUDENT_FAIL";
export const STUDENT_LIST="STUDENT_LIST";
export const CREATE_TEACHER_SUCCESS="CREATE_TEACHER_SUCCESS";
export const CREATE_TEACHER_FAIL="CREATE_TEACHER_FAIL";
export const UPDATE_TEACHER_SUCCESS="UPDATE_TEACHER_SUCCESS";
export const UPDATE_TEACHER_FAIL="UPDATE_TEACHER_FAIL";
export const TEACHER_LIST="TEACHER_LIST";
export const CREATE_SCHOOL_SUCCESS="CREATE_SCHOOL_SUCCESS";
export const CREATE_SCHOOL_FAIL="CREATE_SCHOOL_FAIL";
export const UPDATE_SCHOOL_SUCCESS="UPDATE_SCHOOL_SUCCESS";
export const UPDATE_SCHOOL_FAIL="UPDATE_SCHOOL_FAIL";
export const SCHOOL_LIST="SCHOOL_LIST";
export const CREATE_USER_SUCCESS="CREATE_USER";
export const CREATE_USER_FAIL="CREATE_USER_FAIL";
export const USERS_LIST="USERS_LIST";
export const CREATE_CIRCULAR_SUCCESS="CREATE_CIRCULAR_SUCCESS";
export const CREATE_CIRCULAR_FAIL="CREATE_CIRCULAR_FAIL";
export const CIRCULAR_LIST="CIRCULAR_LIST";
export const CREATE_ASSESSMENT_SUCCESS="CREATE_ASSESSMENT_SUCCESS";
export const CREATE_ASSESSMENT_FAIL="CREATE_ASSESSMENT_FAIL";
export const ASSESSMENT_LIST="ASSESSMENT_LIST";
export const CLASSWISE_STUDENT_LIST="CLASSWISE_STUDENT_LIST";
export const CREATE_HOMEWORK_SUCCESS="CREATE_HOMEWORK_SUCCESS";
export const CREATE_HOMEWORK_FAIL="CREATE_HOMEWORK_FAIL";
export const LIST_HOMEWORK="LIST_HOMEWORK";
export const CREATE_CALENDER_SUCCESS="CREATE_CALENDER_SUCCESS";
export const CREATE_CALENDER_FAIL="CREATE_CALENDER_FAIL";
export const LIST_CALENDER="LIST_CALENDER";
export const CREATE_ALBUM_SUCCESS="CREATE_ALBUM_SUCCESS";
export const CREATE_ALBUM_FAIL="CREATE_ALBUM_FAIL";
export const LIST_ALBUMS="LIST_ALBUMS";
export const UPLOAD_IMAGE_SUCCESS="UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAIL="UPLOAD_IMAGE_FAIL";
export const SEND_MESSAGE_SUCCESS="SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAIL="SEND_MESSAGE_FAIL";
export const LIST_MESSAGES="LIST_MESSAGES";
export const CREATE_ATTENDANCE_SUCCESS="CREATE_ATTENDANCE_SUCCESS";
export const CREATE_ATTENDENCE_FAIL="CREATE_ATTENDANCE_FAIL";
export const LIST_ATTENDANCE="LIST_ATTENDANCE";
export const UPLOAD_MARKS_SUCCESS="UPLOAD_MARKS_SUCCESS";
export const UPLOAD_MARKS_FAIL="UPLOAD_MARKS_FAIL";
export const LIST_MARKS="LIST_MARKS";
export const FORGET_PASSWORD_SUCCESS="FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAIL="FORGET_PASSWORD_FAIL";
export const RESET_PASSWORD_SUCCESS="RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL="RESET_PASSWORD_FAIL";
export const VALIDATE_TOKEN_SUCCESS="VALIDATE_TOKEN_SUCCESS";
export const VALIDATE_TOKEN_FAIL="VALIDATE_TOKEN_FAIL";
export const REGISTER_SCHOOL_SUCCESS="REGISTER_SCHOOL_SUCCESS";
export const REGISTER_SCHOOL_FAIL="REGISTER_SCHOOL_FAIL";
export const GET_SCHOOL_DATA_SUCCESS="GET_SCHOOL_DATA_SUCCESS";
export const GET_SCHOOL_DATA_FAIL="GET_SCHOOL_DATA_FAIL";
export const GET_DEVICE_DATA_SUCCESS="GER_DEVICE_DATA_SUCCESS";
export const GET_DEVICE_DATA_FAIL="GET_DEVICE_DATA_FAIL";
export const GET_DASHBOARD_CIRCULARS_SUCCESS="GET_DASHBOARD_CIRCULARS_SUCCESS";
export const GET_DASHBOARD_CIRCULARS_FAIL="GET_DASHBOARD_CIRCULARS_FAIL";
export const GET_DASHBOARD_ASSESSMENTS_SUCCESS="GET_DASHBOARD_ASSESSMENTS_SUCCESS";
export const GET_DASHBOARD_ASSESSMENTS_FAIL="GET_DASHBOARD_ASSESSMENTS_FAIL";
export const GET_DASHBOARD_HOMEWORK_SUCCESS="GET_DASHBOARD_HOMEWORK_SUCCESS";
export const GET_DASHBOARD_HOMEWORK_FAIL="GET_DASHBOARD_HOMEWORK_FAIL";
export const  STUDENT_STATUS="STUDENT_STATUS";
export const CREATE_PASSWORD_SUCCESS="CREATE_PASSWORD_SUCCESS";
export const CREATE_PASSWORD_FAILED="CREATE_PASSWORD_FAILED";
export const CREATE_MYPROFILE_PASSWORD_SUCCESS="CREATE_MYPROFILE_PASSWORD_SUCCESS";
export const CREATE_MYPROFILE_PASSWORD_FAILED="CREATE_MYPROFILE_PASSWORD_FAILED";
export const GET_MYPROFILE_SUCCESS="GET_MYPROFILE_SUCCESS";
export const GET_MYPROFILE_FAILED="GET_MYPROFILE_FAILED";
export const DELETE_SUCCESS="DELETE_SUCCESS";
export const DELETE_FAILED="DELETE_FAILED";
export const CREATE_FEE_SUCCESS="CREATE_FEE_SUCCESS";
export const CREATE_FEE_FAILED="CREATE_FEE_FAILED";
export const GET_FEE_DATA="GET_FEE_DATA";
export const CREATE_RECEIPT_SUCCESS="CREATE_RECEIPT_SUCCESS";
export const CREATE_RECEIPT_FAILED="CREATE_RECEIPT_FAILED";
export const GET_FEE_RECEIPTS_DATA="GET_FEE_RECEIPTS_DATA"

