import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// import Print from "@material-ui/icons/Print";
import Dvr from "@material-ui/icons/Dvr";
import Amount from "@material-ui/icons/AccountBalance";
import Icon from '@material-ui/core/Icon';
import DeleteIcon from '@material-ui/icons/Delete';
// import Check from '@material-ui/icons/Check';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

// import {dataTable} from "variables/general.jsx";
import Button from "../../components/CustomButtons/Button";
import withStyles from "material-ui/styles/withStyles";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
// import MenuItem from "material-ui/Menu/MenuItem";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
// import TextField from "material-ui/TextField/TextField";
// import ExportToExcel from "../../components/ExportToExcel/ExportToExcel";
// import moment from "moment";
import SelectTemp from 'react-select';
import {DisplayClassList} from "../../actions/ClassRoomAction";
import {CreateFee, HideMsg, GetFees, DeleteFee} from "../../actions/FeeAction";
import {connect} from "react-redux";
import CustomInput from "../../components/CustomInput/CustomInput";
import {createFeeMutation} from "../../graphql/mutations";
import {client, gqlClient} from "../../graphql/service";
import {SCHOOL_ID, USER_ID, USER_TYPE} from "../../helper/Cookies";
import Snackbar from "../../components/Snackbar/Snackbar";
import Tooltip from "material-ui/Tooltip";
import Table from "components/Table/Table.jsx";
import Popup from "../../components/PopUp/Popup";
// import {FormControlLabel} from "@material-ui/core";
import FormControlLabel from "material-ui/Form/FormControlLabel";
import  Checkbox from "material-ui/Checkbox";
import { Add } from "@material-ui/icons";




// const createFeeMut = (multipleClassSelection, cFee, FromDate, ToDate, lastDates, terms) => {
//     // const [createFee, {data}] = useMutation(createFeeMutation);
//     const params = {
//         input: {
//             schoolId: parseInt(SCHOOL_ID()),
//             classId: multipleClassSelection[0],
//             fee: cFee,
//             terms: terms,
//             lastDates: lastDates,
//             validFrom: FromDate,
//             validTo: ToDate,
//             createdBy: USER_ID(),
//             FeeData: []
//         }
//     };
//     const options = {
//         mutation: createFeeMutation,
//         variables: params
//     };
//     console.log(options);
//
//     // client.request(createFeeMutation, params).then(res => console.log(res)).catch(err => console.log(err));
//     gqlClient.mutate(options).then(res => {
//         console.log(res);
//     }).catch(err => console.log(err));
//
//     // createFee(params);
//     // console.log(data);
// };


class FeeCreate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            multipleClassSelection: "",
            FromDate: null,
            ToDate: null,
            selectClass: -1,
            fee: [{key: "", value: [], total: 0}],
            lastDates: [],
            terms: 1,
            rows: 1,
            Header: [],
            FeeData: [],
            lastDateHeader:[],
            LastDate:[],
            ValidFrom: "",
            ValidTo: "",
            ClassName: "",
            showDetails: false
        }

    }

    handleCreate = () => {
        //alert();
        this.setState({
            multipleClassSelection: [],
            FromDate: "",
            ToDate: "",
            selectClass: -1,
            fee: [{key: "", value: [], total: 0}],
            lastDates: [],
            terms: 1,
            rows: 1,
            isCreated: !this.state.isCreated,
        });
    };

    onChangeValue = (val, row, col, name) => {
            const feeArray = [...this.state.fee];
            const fee = feeArray[row];

            if (feeArray.length > 0 && fee) {
                if (name === 'key') {
                    fee.key = val;
                } else {
                    fee.value[col] = val.toString().length > 0 ? parseFloat(val) : 0;
                }
                this.setState({
                    fee: feeArray
                });
            } else {
                if (col > 0) {
                    feeArray.push({
                        isOptional: false,
                        key: val,
                        value: [],
                        total: 0
                    });
                } else {
                    const obj = {
                        isOptional: false,
                        key: '',
                        value: [],
                        total: val
                    };
                    obj.value[col] = val;
                    feeArray.push(obj);
                }
            }

            if (fee) {
                const total = fee.value.length > 0 ? fee.value.reduce((total, v) => total + v) : 0;
                fee.total = total;
            }
            this.setState({fee: feeArray})
    };

    onChangeOptional = (row) => {
        const temFee = [...this.state.fee];
        const f = temFee[row];

        if (temFee.length > 0 && f) {
            f.isOptional = !f.isOptional;
        } else {
            temFee.push({
                isOptional: true,
                key: '',
                value: [],
                total: 0
            });
        }
        this.setState({fee: temFee});
        // console.log(temFee);
    };

    handleMultipleClassTeacher(value) {
        let ClassArray = [];
        if (value !== null) {
            value.map((data) =>
                ClassArray.push(data.value)
            )
        }
        this.setState({multipleClassSelection: ClassArray});
    };

    onChangeTerms = (terms) => {
        this.setState({terms});
    };

    FeeCreate = () => {
        const {multipleClassSelection, FromDate, ToDate, fee, lastDates, terms, rows} = this.state;
        const cFee = [];
        fee.forEach(f => {
            cFee.push({
                head: f.key,
                termName: "Term Fee",
                value: f.value,
                total: f.total
            })
        });
        // CreateFeeM(multipleClassSelection, cFee, FromDate, ToDate, lastDates, terms);
        this.props.CreateClassFee(multipleClassSelection, cFee, FromDate, ToDate, lastDates, terms);
    };

    selectFromDate = (dateObj) => {
        if (typeof dateObj === 'object')
            this.setState({FromDate: dateObj.format('YYYY-MM-DD')});
    };
    selectToDate = (dateObj) => {
        if (typeof dateObj === 'object')
            this.setState({ToDate: dateObj.format('YYYY-MM-DD')});
    };

    selectedTermDate = (date, index) => {
        const tempDate = [...this.state.lastDates];
        tempDate[index] = date.format('YYYY-MM-DD');
        console.log(tempDate);
        this.setState({lastDates: tempDate});
    };

    createUI() {
        const {classes} = this.props;
        const heads = Array.from({length: 1}).map((el, i) => {
            return (
                <div key={"headPop"} style={{marginTop: 30}}>
                    <GridContainer style={{fontWeight: 'bold'}}>
                        <ItemGrid style={{width: 50}}>
                            Optional
                        </ItemGrid>
                        <ItemGrid xs={12} sm={1}/>
                        {
                            Array.from({length: this.state.terms}).map((el, index) => {
                                return (
                                    <ItemGrid style={{width: 95, fontSize: 11}}>
                                        {"Term " + (index + 1)}
                                    </ItemGrid>
                                )
                            })
                        }
                        <ItemGrid xs={12} sm={1}>
                            Total
                        </ItemGrid>
                        <ItemGrid xs={12} sm={1}/>
                    </GridContainer>
                </div>
            )
        });
        const datePop = Array.from({length: 1}).map((el, i) => {
            return (
                <div key={"datePop"}>
                    <GridContainer>
                        <ItemGrid style={{width: 50}}/>
                        <ItemGrid xs={12} sm={1} style={{marginTop: 15, fontWeight: 'bold', fontSize: 11}}>
                            Last Dates
                        </ItemGrid>
                        {
                            Array.from({length: this.state.terms}).map((el, index) => {
                                const pHolder = "Date";
                                const date = this.state.lastDates[index];
                                return (
                                    <ItemGrid style={{width: 95, fontSize: 11}}>
                                        <FormControl>
                                            <Datetime
                                                timeFormat={false}
                                                dateFormat="DD-MM-YYYY"
                                                onChange={(date) => this.selectedTermDate(date, index)}
                                                fon
                                                inputProps={{
                                                    id: "FromDate",
                                                    placeholder: pHolder,
                                                    value: date && date,
                                                    autoComplete: 'off',
                                                    fontSize: '8px'
                                                }}
                                            />
                                        </FormControl>
                                    </ItemGrid>
                                )
                            })
                        }
                        <ItemGrid xs={12} sm={1} style={{marginTop: 15}}/>
                    </GridContainer>
                </div>
            )
        });
        const uiPop = Array.from({length: this.state.rows}).map((el, i) => {
            const feeObj = this.state.fee[i];
            return (
                <div key={i}>
                    <GridContainer>
                        <ItemGrid style={{width: 50}}>
                        {/* <Checkbox
                                        tabIndex={-1}
                                        onClick={() => this.onChangeOptional(i)}
                                        // checkedIcon={<Checkbox className={classes.checkedIcon}/>}
                                        // icon={<Check className={classes.uncheckedIcon}/>}
                                        classes={{
                                            checked: classes.checked
                                        }}
                                    /> */}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        tabIndex={-1}
                                        onClick={() => this.onChangeOptional(i)}
                                        // checkedIcon={<Checkbox className={classes.checkedIcon}/>}
                                        // icon={<Check className={classes.uncheckedIcon}/>}
                                        classes={{
                                            checked: classes.checked
                                        }}
                                    />
                                }
                                classes={{label: classes.label}}
                            />
                        </ItemGrid>
                        <ItemGrid xs={12} sm={1}>
                            <CustomInput
                                labelText="Title"
                                id="Feetitle"
                                onChange={(event) => {
                                    this.onChangeValue(event.target.value, i, -1, 'key')
                                }}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    value: feeObj && feeObj.key
                                }}
                            />
                        </ItemGrid>
                        {
                            Array.from({length: this.state.terms}).map((el, index) => (
                                <ItemGrid style={{width: 95}}>
                                    <CustomInput
                                        labelText={"Amount"}
                                        id="feeValue"
                                        onChange={(event) => {
                                            this.onChangeValue(event.target.value, i, index, 'value')
                                        }}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: feeObj && feeObj.value[index] && feeObj.value[index]
                                        }}
                                    />
                                </ItemGrid>
                            ))
                        }
                        <ItemGrid xs={12} sm={1} style={{marginTop: 30}}>
                            {feeObj && feeObj.total}
                        </ItemGrid>
                        {i > 0 ?
                            <ItemGrid xs={12} sm={1}>
                                <DeleteIcon color="#ff9800" onClick={this.removeClick.bind(this, i)}
                                            style={{marginTop: "25px"}}/>
                            </ItemGrid>
                            : null}
                    </GridContainer>
                </div>
            )
        });
        const totPop = Array.from({length: 1}).map((el, i) => {
            let fTot = 0;
            return (
                <div key={"totPop"}>
                    <GridContainer>
                        <ItemGrid style={{width: 50}}/>
                        <ItemGrid xs={12} sm={1}>
                            Totals
                        </ItemGrid>
                        {
                            Array.from({length: this.state.terms}).map((el, index) => {
                                const fees = this.state.fee.map(f => f.value[index]);
                                const tFee = fees.reduce((t, v) => {
                                    const tot = t > 0 ? t : 0;
                                    return tot + v;
                                });
                                fTot += tFee > 0 ? tFee : 0;
                                return (
                                    <ItemGrid style={{width: 95}}>
                                        {tFee > 0 ? tFee : 0}
                                    </ItemGrid>
                                )
                            })
                        }
                        <ItemGrid xs={12} sm={1}>
                            {fTot > 0 ? fTot : 0}
                        </ItemGrid>
                        {i > 0 ?
                            <ItemGrid xs={12} sm={1}>
                                <DeleteIcon color="#ff9800" onClick={this.removeClick.bind(this, i)}
                                            style={{marginTop: "25px"}}/>
                            </ItemGrid>
                            : null}
                    </GridContainer>
                </div>
            )
        });
        return [...heads, ...datePop, ...uiPop, ...totPop];
    }

    addClick() {
        this.setState({rows: this.state.rows + 1});
    }

    removeClick(i) {
        const tempfee = [...this.state.fee];
        tempfee.splice(i, 1);
        this.setState({rows: this.state.rows - 1, fee: tempfee});
    }

    timeInfo = () => {
        const {HideMsg, ClassList,getFeeData} = this.props;
        setTimeout(() => {
            this.handleCreate();
            HideMsg();
            ClassList();
            getFeeData();
        }, 3000)
    };

    handleChange(value) {
        this.setState({selectClass: value.value});
        this.props.getFeeData(value.value);

    }

    deleteFee(feeId) {
        this.props.FeeDelete(feeId);
    }

    showFeeDetails = (feeId, FeeList) => {
        let ClasswiseFee = [];
        let DataHerader = ["Title"];
        let DateHeader=[];
        const Feedetails = FeeList.find(a => a._id === feeId);
        let ld=Feedetails.lastDates;
        let Total=["Total"];
        let gt=0;
        if((Feedetails.lastDates).length>=0){
            ld.forEach((l,d) => {
                DateHeader.push('Term ' +(d+1) +' Last Date')
            });
        }
        Array.from({length: Feedetails.terms}).map((w,q)=>{
            const fd = Feedetails.fee.map(f => f.value[q]);
            let tt=0;
            fd.forEach(val=>{
                tt=tt+parseInt(val);
            })
            Total.push(tt);
            // fd.reduce((t,s)=>{
            //     console.log('dddddddddd',t,s);
            //     tt=parseInt(t)+parseInt(s);
            //     Total.push(tt);
            //
            // });

        });
        (Feedetails.fee).forEach((p, k) => {
            let data = [];
            data.push(p.head + "");
            (p.value).forEach((v, i) => {
                if (k === 0)
                    DataHerader.push('Term ' + (i + 1));
                data.push(v);
            });
            data.push(p.total);
            ClasswiseFee.push(data)
        });
        (Feedetails.fee).forEach((g) => {
            gt+=g.total;
        })
        Total.push(gt);
        ClasswiseFee.push(Total)
        DataHerader.push('Total');
        this.setState({
            ClassName: Feedetails.class.name + "  Fee Details",
            terms: Feedetails.terms,
            ValidFrom: Feedetails.validFrom,
            ValidTo: Feedetails.validTo,
            Header: DataHerader,
            FeeData: ClasswiseFee,
            LastDateHeaders:DateHeader,
             LastDate:[Feedetails.lastDates],
            showDetails: true
        })
    };

    componentDidMount() {
        const {ClassList, getFeeData} = this.props;
        ClassList();
        getFeeData();
    }

    // shouldComponentUpdate(nextProps, nextState, nextContext) {
    //     const {getFeeData,FeeList} = nextProps;
    //     if(FeeList.length<=0){
    //         getFeeData();
    //     }
    //     // var ss= FeeList[0];
    //     // if( FeeList.length>0){
    //     //     if(this.state.dontcall===true && ss!==undefined){
    //     //         this.showFeeDetails(ss._id,FeeList)
    //     //     }
    //     // }
    //     return true;
    //
    // }

    render() {
        const {classes, classList, msg, isFailed, msgColor, HideMsg, FeeList} = this.props;

        if (isFailed === true && msg !== null) {
            if (msgColor === "success") {
                this.timeInfo();
            } else {
                setTimeout(() => {
                    HideMsg();
                }, 2000)
            }
        }

        let options = [];
        if (classList !== null) {
            classList.forEach(r => {
                const {name, classId} = r;
                options.push({
                    value: classId,
                    label: name
                })
            })
        }
        let columns = [];
        const userType = parseInt(USER_TYPE());
        if (userType === 3) {
            columns = [
                {
                    Header: "Classs Name",
                    accessor: "className",
                    sortable: false,
                    filterable: false,
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Fee",
                    accessor: "totalFee",
                    sortable: false,
                    filterable: false,
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Terms",
                    accessor: "terms",
                    sortable: false,
                    filterable: false,
                    style: {color: "#000", textAlign: "left"}

                },
                // {
                //     Header: "Valid From",
                //     accessor: "validFrom",
                //     sortable: false,
                //     filterable: false,
                //     style: {color: "#000", textAlign: "left"}
                //
                // },
                // {
                //     Header: "Valid To",
                //     accessor: "validTo",
                //     sortable: false,
                //     filterable: false,
                //     style: {color: "#000", textAlign: "left"}
                //
                // },
                {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                }

            ]
        }
        let classWiseFee = [];
        FeeList.forEach(r => {
            const {classId, fee, terms, _id, validFrom, validTo} = r;
            var tf = 0;
            fee.forEach(s => {
                tf += s.total
            });
            classWiseFee.push({
                className: r.class.name,
                _id: _id,
                classId: classId,
                terms: terms,
                validFrom: validFrom,
                validTo: validTo,
                totalFee: tf,
                actions: (
                    <div className="actions-right">
                        <Tooltip title="View Fee Details">
                            <IconButton
                                onClick={() => {
                                    this.showFeeDetails(_id, FeeList)
                                }}
                                color="infoNoBackground"
                                customClass="edit">
                                <Dvr/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton
                                onClick={() => {
                                    {
                                        if (window.confirm('Are you sure you want to Delete this Record?')) {
                                            this.deleteFee(_id)
                                        }
                                        ;
                                    }
                                }}
                                color="dangerNoBackground"
                                customClass="remove">
                                <Close/>
                            </IconButton>

                        </Tooltip>
                    </div>
                )

            })
        });

        return (
            <GridContainer justify=" center">
                <GridContainer>
                    <ItemGrid xs={12} sm={6} md={4}>
                        <Snackbar
                            open={isFailed}
                            place={"tc"}
                            color={msgColor}
                            message={msg}
                        />
                    </ItemGrid>
                </GridContainer>
                <GridContainer>
                    <ItemGrid xs={12} sm={12} md={12}>
                        <IconCard
                            icon={Amount}
                            iconColor={" rose"}
                            title={" Create Fee"}
                            content={
                                <ItemGrid>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={6} lg={3} md={3}>
                                            <InputLabel style={{color: "#AAAAAA"}}>
                                                Classes :
                                            </InputLabel>
                                            <SelectTemp
                                                isMulti
                                                className=" basic-multi-select"
                                                classNamePrefix=" select"
                                                // defaultValue={options[0]}
                                                name=" multipleClassSelection"
                                                options={options}
                                                onChange={this.handleMultipleClassTeacher.bind(this)}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: '4px',
                                                    height: "20px",
                                                    colors: {
                                                        ...theme.colors,
                                                        //  primary25: '#00ABDC',
                                                        primary: '#e91e63',
                                                        //   primary50:"#00ABDC"
                                                    },
                                                })}
                                            />
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={5} lg={3} md={3}>
                                            <CustomInput
                                                labelText=" No Of Terms"
                                                id=" noofterms"
                                                value="1"
                                                onChange={(event) => {
                                                    this.onChangeTerms(event.target.value);
                                                }}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    value: this.state.terms
                                                }}
                                            />
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={6} lg={3} md={3}>
                                            <InputLabel className={classes.label} style={{paddingRight: 15}}>
                                                Valid From Date
                                            </InputLabel>
                                            <FormControl>
                                                <Datetime
                                                    timeFormat={false}
                                                    dateFormat="DD-MM-YYYY"
                                                    onChange={this.selectFromDate}
                                                    inputProps={{
                                                        id: "FromDate",
                                                        placeholder: "Select Date",
                                                        value: this.state.FromDate,
                                                        autoComplete: 'off'
                                                    }}
                                                />
                                            </FormControl>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={6} lg={3} md={3}>
                                            <InputLabel className={classes.label} style={{paddingRight: 15}}>
                                                Valid To Date
                                            </InputLabel>
                                            <FormControl>
                                                <Datetime
                                                    timeFormat={false}
                                                    dateFormat="DD-MM-YYYY"
                                                    onChange={this.selectToDate}
                                                    inputProps={{
                                                        id: "ToDate",
                                                        placeholder: "Select Date",
                                                        value: this.state.ToDate,
                                                        autoComplete: 'off'
                                                    }}
                                                />
                                            </FormControl>
                                        </ItemGrid>
                                    </GridContainer>
                                    {this.createUI()}
                                    {/* <Icon style={{color: "#ff9800"}} fontSize={"large"}
                                          onClick={this.addClick.bind(this)}>add_circle</Icon> */}
                                    <IconButton
                                        onClick={this.addClick.bind(this)}
                                        // color="#ff9800"
                                        style={{color: "#ff9800",backgroundColor:"white"}}
                                    >
                                        <Add />
                                    </IconButton>
                                    <GridContainer>
                                        <ItemGrid>
                                            <br/>
                                            <Button color="success" onClick={this.FeeCreate}>Save</Button>
                                            <br/>
                                        </ItemGrid>
                                        <ItemGrid>
                                            <br/>
                                            <Button color="rose" onClick={this.handleCreate}>Cancel</Button>
                                            <br/>
                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
                            }
                        />
                    </ItemGrid>
                    <ItemGrid xs={12}>
                        <IconCard
                            icon={Amount}
                            title={""}
                            iconColor=""
                            content={
                                <GridContainer>
                                    <ItemGrid xs={12} sm={12}>
                                        <ItemGrid xs={12} sm={4}>
                                            <InputLabel style={{color: "#AAAAAA"}}>
                                                Classes :
                                            </InputLabel>
                                            <SelectTemp
                                                className="basic-singlet"
                                                classNamePrefix=" select"
                                                // defaultValue={options[0]}
                                                name="selectClass"
                                                options={options}
                                                onChange={this.handleChange.bind(this)}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: '4px',
                                                    height: "20px",
                                                    colors: {
                                                        ...theme.colors,
                                                        //  primary25: '#00ABDC',
                                                        primary: '#e91e63',
                                                        //   primary50:"#00ABDC"
                                                    },
                                                })}
                                            />
                                        </ItemGrid>
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={12}>
                                        <ReactTable
                                            data={classWiseFee}
                                            filterable
                                            style={{color: "#00acc1"}}
                                            columns={columns}
                                            defaultPageSize={5}
                                            showPaginationBottom={true}
                                            className="-striped -highlight"
                                        />
                                    </ItemGrid>
                                </GridContainer>
                            }
                        />
                        {
                            //get indent alert
                            this.state.showDetails ?
                                <Popup
                                    closePopup={() => {
                                        this.setState({
                                            showDetails: false
                                        })
                                    }}
                                    title=""
                                    isShowSave={false}
                                    isShowCancel={false}
                                    // save_btn_txt={"OK"}
                                    // cancel_button_text={"Cancel"}
                                    // juteType={selectedJuteType}
                                    content={
                                        <form>
                                            <ItemGrid xs={12}>
                                                <strong>{this.state.ClassName}</strong>

                                                <ItemGrid style={{marginTop: "10px"}}>
                                                    <span
                                                        style={{paddingRight: "45px"}}>No: Terms: {this.state.terms}</span>
                                                    <span
                                                        style={{paddingRight: "45px"}}>Valid From: {this.state.ValidFrom}</span>
                                                    <span>Valid To: {this.state.ValidTo}</span>
                                                    <Table
                                                        tableHead={this.state.LastDateHeaders}
                                                        tableData={this.state.LastDate}
                                                    />
                                                    <Table
                                                        striped
                                                        tableHead={this.state.Header}
                                                        tableData={this.state.FeeData}
                                                    />
                                                </ItemGrid>

                                            </ItemGrid>


                                        </form>

                                    }
                                >
                                </Popup> : null
                        }

                    </ItemGrid>
                    {/*<ItemGrid xs={12} sm={12} md={7}>*/}

                    {/*    <IconCard*/}
                    {/*        icon={Amount}*/}
                    {/*        title={ this.state.ClassName }*/}
                    {/*        iconColor=""*/}
                    {/*        content={*/}
                    {/*            <ItemGrid xs={12}>*/}
                    {/*                {this.state.showDetails===true?*/}
                    {/*                    <ItemGrid>*/}
                    {/*                        <span style={{paddingRight: "45px"}}>No: Terms: {this.state.terms}</span>*/}
                    {/*                        <span style={{paddingRight: "45px"}}>Valid From: {this.state.ValidFrom}</span>*/}
                    {/*                        <span>Valid To: {this.state.ValidTo}</span>*/}
                    {/*                            <Table*/}
                    {/*                                striped*/}
                    {/*                                tableHead={this.state.Header}*/}
                    {/*                                tableData={this.state.FeeData}*/}
                    {/*                            />*/}
                    {/*                    </ItemGrid>*/}

                    {/*                    :null}*/}
                    {/*            </ItemGrid>*/}
                    {/*        }*/}
                    {/*    />*/}
                    {/*</ItemGrid>*/}
                </GridContainer>
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#AAAAAA",
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    ...customSelectStyle
};
const mapStateToProps = state => {
    const {isFailed, msg, msgColor, classList, FeeList} = state.Fee;
    return {isFailed, msg, msgColor, classList, FeeList}
};

export default connect(mapStateToProps, {
    ClassList: DisplayClassList,
    CreateClassFee: CreateFee, HideMsg,
    getFeeData: GetFees,
    FeeDelete: DeleteFee

})(withStyles(extendedFormsStyle)(FeeCreate));
