import React from "react";
import IconCard from "../../components/Cards/IconCard";
import alertIcon from "@material-ui/icons/AddAlert";
import Button from "../../components/CustomButtons/Button";
import GridContainer from "../../components/Grid/GridContainer";
import ItemGrid from "../../components/Grid/ItemGrid";
import IconButton from "components/CustomButtons/IconButton.jsx";

import PropTypes from "prop-types";
import withStyles from "material-ui/styles/withStyles";
import RegularCard from "../Cards/RegularCard";
import Close from "@material-ui/icons/Close";
import {liteBlue, primaryColor} from "../../assets/jss/material-dashboard-pro-react";

class Popup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const {content, title, closePopup, onClickSave, cancel_button_text, isShowSave, isShowCancel, save_btn_txt} = this.props;
        return (
            <div style={{
                position: "fixed",
                width: "100%",
                height: "100%",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                margin: "auto",
                zIndex: 5000,
                backgroundColor: "rgba(0,0,0, 0.5)"
            }}>
                <div style={{
                    position: "absolute", display: "relative",
                    top: "15%",
                    left: "35%",
                    right: "15%",
                    bottom: "50%",
                    margin: "auto",
                    backGround: "white",

                    // zIndex: 5500
                }}>
                    <GridContainer justify="center"   style={{ backgroundColor: primaryColor}}>
                        <ItemGrid xs={12}>
                            <RegularCard
                                content={
                                    <GridContainer justify={"center"}  style={{maxHeight:400, overflow:"auto"}}>
                                        <ItemGrid xs={12}>
                                        <IconButton
                                            onClick={closePopup}
                                            color="dangerNoBackground"
                                            right={true}
                                            customClass="remove">
                                            <Close/>
                                        </IconButton>
                                        </ItemGrid>
                                        <ItemGrid xs={12}>
                                            {content}
                                        </ItemGrid>

                                        <ItemGrid xs={12}>
                                            <br/>
                                            <GridContainer justify="center">
                                                <ItemGrid>
                                                    {isShowSave ?
                                                        <Button style={{width:100}} color="primary"
                                                                onClick={onClickSave}>{save_btn_txt}</Button>
                                                        :null

                                                    }
                                                </ItemGrid>
                                                <ItemGrid>
                                                    {isShowCancel ?
                                                        <Button color="danger" style={{width:100}}
                                                                onClick={closePopup}>{cancel_button_text}</Button>
                                                        : null
                                                    }
                                                </ItemGrid>
                                            </GridContainer>

                                        </ItemGrid>
                                    </GridContainer>


                                }
                            />
                        </ItemGrid>
                    </GridContainer>
                </div>
            </div>
        );

    }
}

const extendedCalendarStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#AAAAAA",
        fontSize: "12px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    button:{
        width:100
    }
};

export default withStyles(extendedCalendarStyle)(Popup);
