import schoolAPi from "kidzwapi";
import {
    DELETE_FAILED,
    DELETE_SUCCESS,
    HIDE_INFO_MSG,
    LIST_MESSAGES,
    SEND_MESSAGE_FAIL,
    SEND_MESSAGE_SUCCESS
} from "./types";
import {AUTH, SCHOOL_ID, USER_ID} from "../helper/Cookies";

function SendMessageSuccess(){
    return{
        type:SEND_MESSAGE_SUCCESS,
        payLoad:"Message Sent Successfully"
    }
}
function DeleteMessageSuccess(){
    return{
        type:DELETE_SUCCESS,
        payLoad:"Message Deleted Successfully"
    }
}

function DeleteMessageFailed(msg){
    return{
        type:DELETE_FAILED,
        payLoad:msg
    }
}

function SendMessageFailed(msg){
    return{
        type:SEND_MESSAGE_FAIL,
        payLoad: msg
    }
}
function MessageList(json){
    return{
        type:LIST_MESSAGES,
        payLoad:json
    }
}
function hideMsg(){
    return{
        type:HIDE_INFO_MSG,
        payLoad:null
    }
}

export function HideMsg(){
    return function (dispatch){
        dispatch(hideMsg());
    }
}
export function SendMessageAction( childId,classId, messageTxt, attachment,attachmentType){
    return function (dispatch){
        console.log("Message Request",parseInt(SCHOOL_ID()),classId, childId, messageTxt, attachmentType,attachment,USER_ID(),AUTH());
        if( parseInt(SCHOOL_ID())!==null && classId !==0 && childId!==null&&messageTxt!==''){
            if(attachmentType==='NA' || attachment === null || attachmentType==="application/pdf" || attachmentType==="image/jpeg" || attachmentType==="image/jpg"){
                return schoolAPi.createMessage(parseInt(SCHOOL_ID()),classId, childId, messageTxt, attachmentType,attachment,USER_ID(),AUTH())
                    .then(resp=>{
                        console.log("Message resp",resp);
                        if(resp.status){
                            dispatch(SendMessageSuccess());
                        }else{
                            dispatch(SendMessageFailed(resp.msg));
                        }
                    })
            }else{
             dispatch(SendMessageFailed("Please select Pdf or Jpeg file"));
            }

        }else{
            dispatch(SendMessageFailed("Enter All Fileds"));
        }

    }
}
export function DisplayMessages(classId){
    return function (dispatch){
        if(classId!==0){
            return schoolAPi.listMessages(parseInt(SCHOOL_ID()),classId,USER_ID(),AUTH())
                .then(resp=>{
                    console.log("messa",resp);
                    if(resp.status){
                        dispatch(MessageList(resp.messages));
                    }
                })
        }

    }
}

export function DeleteMessages(messageId){
    return function (dispatch){
        return schoolAPi.deleteMessage(messageId,USER_ID(),AUTH())
            .then(resp=>{
                if(resp.status){
                    dispatch(DeleteMessageSuccess());
                }else{
                    dispatch(DeleteMessageFailed(resp.msg));
                }
            })
    }
}
