import {
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAIL,
    } from "../actions/types";

const INIT_STATE = {isFailed: false, msg: null, msgColor: "danger"};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FORGET_PASSWORD_SUCCESS:
            return {...state,isFailed: true,msg: action.payLoad, msgColor: "success"};
        case FORGET_PASSWORD_FAIL:
            return {...state, isFailed: true, msg: action.payLoad};
        default:
            return INIT_STATE;
    }
}

