const cons = {
    AUTH: "auth",
    USERID: "userId",
    PURL: "profilePicUrl",
    USER_NAME: "userName",
    SCHOOLID: "schoolId",
    LOGGED_IN: "loggedIn",
    USERTYPE:"userType",
    ALBUMIMAGES:"albumImages",
    ALBUMID:"albumId",
    SESSION_OK: 0,
    SESSION_EXP: 1,
    TEACHCLASS:"teachclass",
    ATTEN_CLASSES: "attClasses"
};

export const WEBSITE_URL = "http://jaagustocks.com";


let PROD_PATH = "https://kidzlogapi.spiolabs.com/";

let DEV_PATH = "https://kidzlogapi.spiolabs.com/";
export const serverConfig = {
    SERVER_URL: (process.env.NODE_ENV === "development") ? DEV_PATH : PROD_PATH//put your local or server ip addr
};


// export function displayAction(utype){
// console.log("uuuuuuuuuuu",utype);
//     switch(utype){
//         case utype === 2 :
//             return{
//                 Header: "Actions",
//                 accessor: "actions",
//                 sortable: false,
//                 filterable: false,
//             }
//         default:
//             return null;
//
//     }
//
// }

export default cons;
