import {
     CLASSWISE_STUDENT_LIST,
    CLASS_LSIT, GET_FEE_DATA, GET_FEE_RECEIPTS_DATA, CREATE_RECEIPT_SUCCESS, CREATE_RECEIPT_FAILED
} from "../actions/types";


const INIT_STATE = {isFailed: false, msg: null, msgColor: "danger",classList:[],FeeList:[],classWiseList:[],FeeReceiptData:[]};
export default (state = INIT_STATE, action) => {
    switch(action.type) {
        case CREATE_RECEIPT_SUCCESS:
            return{...state,isFailed: true,msg:action.payLoad,msgColor: "success"};
        case CREATE_RECEIPT_FAILED:
            return {...state, isFailed: true, msg: action.payLoad};
        case CLASSWISE_STUDENT_LIST:
            return{...state,classWiseList:action.payLoad};
        case CLASS_LSIT:
            return{...state,classList:action.payLoad};
        case GET_FEE_DATA:
            return{...state,FeeList:action.payLoad};
        case GET_FEE_RECEIPTS_DATA:
            return{...state,FeeReceiptData:action.payLoad};
        default:
            return INIT_STATE;
    }

}
