import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
import Loader from 'react-loader-spinner';
import moment from "moment";
import ExifOrientationImg from 'react-fix-image-orientation'

// @material-ui/icons
import Close from "@material-ui/icons/Close";

import Edit from "@material-ui/icons/Edit";
import People from "@material-ui/icons/People";
import Check from "@material-ui/icons/Check"
import Person from "@material-ui/icons/Person"
import {NavLink} from "react-router-dom";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import withStyles from "material-ui/styles/withStyles";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import {connect} from "react-redux";
import {DisplayClassList} from "../../actions/ClassRoomAction";
import {
    CreateSubjectAction,
    HideMsg,
    DisplayStudentList,
    UpdateStudentAction,
    ChangeStudentStatus,
    CreateStudentPassword,
    GetClassWiseChildList
} from "../../actions/StudentAction";
import Snackbar from "../../components/Snackbar/Snackbar";
import {USER_TYPE} from "../../helper/Cookies";
import {BloodGroup} from "../../variables/menus";
import PictureUpload from "../../components/CustomUpload/PictureUpload";
import Tooltip from "material-ui/Tooltip";
import Popup from "../HeaderPages/PopUp";
import SelectTemp from 'react-select';
import {liteBlue} from "../../assets/jss/material-dashboard-pro-react";

class Students extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tittle: "Create Student",
            hideButton: true,
            selectAll: 0,
            rollNo: "",
            childId: null,
            classSelect: "",
            dob: moment().format('YYYY-MM-DD'),
            admissionNo: null,
            childName: null,
            mName: null,
            mContact: null,
            mEmail: null,
            fName: null,
            fContact: null,
            fEmail: null,
            bGroup: "",
            npass: "",
            cpass: "",
            file: null,
            fileName: "No file Chosen",
            imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
            isCreated: false,


        };
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
        // this.childLogin = this.childLogin.bind(this);
    }

    handleCreate = () => {
        //alert();

        this.setState({
            tittle: "Create Student",
            hideButton: true,
            childId: null,
            classSelect: "",
            dob: moment().format('YYYY-MM-DD'),
            admissionNo: null,
            childName: null,
            mName: null,
            mContact: null,
            mEmail: null,
            fName: null,
            fContact: null,
            fEmail: null,
            bGroup: "",
            StudentStaus: false,
            BtnColor: "danger",
            file: null,
            fileName: "No file Chosen",
            imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
            isCreated: !this.state.isCreated,
            showPopup: false,
            loader: false,
            showList: false,

        });
    };
    handleSimple = event => {
        this.setState({[event.target.name]: event.target.value});
        console.log(this.state);
    };

    handleChangebGroup(value) {
        this.setState({bGroup: value.value});
    };

    handleChange(value) {
        if (value.value === 0) {
            const {StudentList} = this.props;
            StudentList();
        }
        this.setState({classSelect: value.value});
    }

    handleFileClick() {
        var input = document.createElement("input");
        input.type = "file";
        input.onchange = this.handleImageChange;
        input.click();
    }

    handleImageChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                fileName: file.name,
                imagePreviewUrl: reader.result
            });
        };
        reader.readAsDataURL(file);
    }

    studentCreate = () => {
        const {CreateStudent} = this.props;
        const {
            classSelect, dob, admissionNo, childName, mName, mContact, mEmail,
            fName, fContact, fEmail, bGroup, file, rollNo
        } = this.state;
        CreateStudent(admissionNo, childName, dob, bGroup, mName, mContact, mEmail,
            fName, fContact, fEmail, classSelect, file, rollNo);
    };
    handleUpdateStudent = (childId, classId, admissionCode, name, dob, bloodGroup, motherEmail, motherMobile, motherName, fatherEmail, fatherMobile, fatherName, picUrl, rollNo) => {
        this.setState({
            tittle: "Update student",
            buttonType: "Update",
            hideButton: false,
            childName: name,
            classSelect: classId,
            admissionNo: admissionCode,
            dob: dob,
            bGroup: bloodGroup === undefined ? null : bloodGroup,
            childId: childId,
            mName: motherName,
            mContact: motherMobile,
            mEmail: motherEmail === undefined ? null : motherEmail,
            fName: fatherName,
            fContact: fatherMobile,
            fEmail: fatherEmail === undefined ? null : fatherEmail,
            rollNo: rollNo === undefined ? null : rollNo,
            imagePreviewUrl: picUrl,
            isCreated: !this.state.createId,
            showPopup: false,
            listId: 0
        });
        const {ClassList} = this.props;
        ClassList();
    };

    UpdateStudentStatus = (childId, status) => {
        const {UpdateStatus, StatusStudent, StudentList} = this.props;
        UpdateStatus(childId, status);
        this.setState({loader: true});
        if (StatusStudent === true) {
            StudentList();
        }

    };

    studentUpdate = () => {
        const {UpdateStudent} = this.props;
        const {
            childId, admissionNo, childName, dob, bGroup, mName, mContact, mEmail,
            fName, fContact, fEmail, file, classSelect, rollNo
        } = this.state;
        UpdateStudent(childId, admissionNo, childName, dob, bGroup, mName, mContact, mEmail,
            fName, fContact, fEmail, file, classSelect, rollNo);
    };

    dateChanged = (dateObj) => {
        if (typeof dateObj === 'object')
            this.setState({dob: dateObj.format('YYYY-MM-DD')});
    };
    onChangeValue = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        })
    };
    getClassList = () => {
        // this.setState({showList:true})
        let ClassArray = [];
        ClassArray.push(this.state.classSelect);
        const {ClassWiseChild} = this.props;
        // const {classSelect} = this.state;
        ClassWiseChild(ClassArray);
    };
    timeInfo = () => {
        const {HideMsg, StudentList, ClassList} = this.props;
        setTimeout(() => {
            HideMsg();
            StudentList();
            ClassList();
            this.handleCreate();
        }, 3000)
    };

    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup
        });
    }

    CreatePassword() {

        const {npass, cpass, childId} = this.state;
        const {StudentPassword} = this.props;
        StudentPassword(childId, npass, cpass);
    }

    componentDidMount() {
        const {ClassList, StudentList} = this.props;
        StudentList();
        ClassList();
    }

    openChildPortal = (uname, password) => {
        window.open("https://kidzlogparent.spiolabs.com/AutoLogin/" + uname + "/" + password, "_blank");
    };
    getTheme=(theme) => ({
        ...theme,
        borderRadius: '4px',
        height: "20px",
        colors: {
            ...theme.colors,
            //  primary25: '#00ABDC',
            primary:liteBlue,
            //   primary50:"#00ABDC"
        },
    });

    render() {

        const {ClassList, admissionNo, childName, dob, mName, mContact, mEmail, fName, fContact, fEmail, childId} = this.state;
        const {classes, dataTable, isFailed, msgColor, msg, tableList, HideMsg} = this.props;
        if (isFailed === true && msg !== null) {
            if (msgColor === "success") {
                this.timeInfo();
            } else {
                setTimeout(() => {
                    HideMsg();
                    ClassList();
                }, 2000)
            }
        }
        let options = [];
        if (dataTable !== null) {
            dataTable.forEach(r => {
                const {name, classId} = r;
                options.push({
                    value: classId,
                    label: name
                })
            })
        }
        let childList = [];
        let columns = [];
        const userType = parseInt(USER_TYPE());


        if (userType === 5 || userType === 2) {
            columns = [
                {
                    Header: "Student Details",
                    accessor: "studentDetails",
                    minWidth:150,
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Other Details",
                    accessor: "otherDetails",
                    minWidth:100,
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Mother Details",
                    accessor: "motherDetails",
                    minWidth:150,
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Father Details",
                    accessor: "fatherDetails",
                    minWidth:150,
                    style: {color: "#000", textAlign: "left"}

                }
            ]
        } else {
            columns = [
                {
                    Header: "Student Details",
                    accessor: "studentDetails",
                    minWidth:150,
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Other Details",
                    accessor: "otherDetails",
                    minWidth:100,
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Mother Details",
                    accessor: "motherDetails",
                    minWidth:150,
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Father Details",
                    accessor: "fatherDetails",
                    minWidth:150,
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                    minWidth: 100,
                    headerStyle: {textAlign: "center"},
                    style: {color: "#000", textAlign: "left"}
                }
            ]
        }
        let cRow = 0;
        tableList.forEach(r => {
            const {className, childId, classId, admissionCode, name, dob, bloodGroup, motherEmail, motherMobile, motherName, fatherEmail, fatherMobile, fatherName, picUrl, activeStatus, pswd, roll_no} = r;
            childList.push({
                childId: childId,
                classId: classId,
                studentDetails:"Code: "+admissionCode+"\nName: "+name+"\nClass: "+className,
                otherDetails:'DOB: ' +dob+"\nBG: "+bloodGroup+'\nPSWD: '+pswd,
                motherDetails:motherName+"\n"+motherMobile+"\n"+motherEmail,
                fatherDetails:fatherName+"\n"+fatherMobile+"\n"+fatherEmail,
                picUrl: picUrl,
                activeStatus: activeStatus,
                roll_no: roll_no,
                // motherDetails:motherName+split('\n')+motherEmail+splitLine('\n')+motherEmail,
                actions: (
                    <div className="actions-right">
                        <Tooltip title="Child Portal login" placement="bottom" classes={{tooltip: classes.tooltip}}>
                            <IconButton
                                onClick={() => this.openChildPortal(motherMobile, pswd)}
                                color="successNoBackground"
                                customClass="success"
                            >
                                <Person/>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Edit" placement="bottom" classes={{tooltip: classes.tooltip}}>

                            <IconButton
                                onClick={() => {
                                    this.handleUpdateStudent(childId, classId, admissionCode, name, dob, bloodGroup, motherEmail, motherMobile, motherName, fatherEmail, fatherMobile, fatherName, picUrl);
                                }}
                                color="infoNoBackground"
                                customClass="edit">
                                <Edit/>
                            </IconButton>
                        </Tooltip>{" "}

                        {activeStatus === 0 ?
                            <Tooltip title="Deactive" placement="bottom" classes={{tooltip: classes.tooltip}}>
                                <IconButton
                                    onClick={() => {
                                        this.UpdateStudentStatus(childId, 1)
                                    }}
                                    color="dangerNoBackground"
                                    customClass="remove">

                                    <Close/>

                                </IconButton>
                            </Tooltip>
                            :
                            <Tooltip title="Active" placement="bottom" classes={{tooltip: classes.tooltip}}>
                                <IconButton
                                    onClick={() => {
                                        this.UpdateStudentStatus(childId, 0)
                                    }}
                                    color="dangerNoBackground"
                                    customClass="remove">
                                    <Check/>
                                </IconButton>
                            </Tooltip>
                        }


                    </div>
                )
            })
            cRow++;
        });
        return (
            <GridContainer justify="center">
                <GridContainer>
                    <ItemGrid xs={12} sm={6} md={4}>
                        <Snackbar
                            open={isFailed}
                            place={"tc"}
                            color={msgColor}
                            message={msg}
                        />
                    </ItemGrid>
                </GridContainer>

                {this.state.isCreated ?
                    <ItemGrid xs={12} sm={12}>
                        {this.state.showPopup ?
                            <Popup
                                closePopup={this.togglePopup.bind(this)}
                                onClickCreate={this.CreatePassword.bind(this)}
                                handleInput={this.onChangeValue}
                            />

                            : null
                        }
                        <IconCard
                            icon={People}
                            // title={this.state.tittle}
                            title={

                                <div>
                                    {this.state.loader === true ?
                                        <Loader
                                            type="Oval"
                                            color="#00BFFF"
                                            height="100"
                                            width="100"
                                        />
                                        : null}
                                    {this.state.tittle}
                                    {/*{console.log("childdddddd",childId)}*/}
                                    {(childId !== null) ?
                                        <Button
                                            onClick={this.togglePopup.bind(this)}
                                            // round color="white"
                                            color="primary" right={true}> Change Password
                                        </Button>
                                        : null}
                                </div>
                            }
                           // iconColor="rose"
                            content={
                                <div>
                                    <form>
                                        <GridContainer>
                                                <ItemGrid xs={12} sm={6}>
                                                    <CustomInput
                                                        labelText="Admission Code *"
                                                        id="admissionNo"
                                                        onChange={this.onChangeValue}
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            value: admissionNo
                                                        }}
                                                    />
                                                </ItemGrid>
                                                <ItemGrid xs={12} sm={6}>
                                                    <CustomInput
                                                        labelText="Roll No"
                                                        id="rollNo"
                                                        onChange={this.onChangeValue}
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            // value: roll_no
                                                        }}
                                                    />

                                                </ItemGrid>
                                            <ItemGrid xs={12} sm={12} md={12}>
                                                <CustomInput
                                                    labelText="Child Name *"
                                                    id="childName"
                                                    onChange={this.onChangeValue}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        value: childName
                                                    }}
                                                />
                                            </ItemGrid>

                                        </GridContainer>
                                        <GridContainer justify="center">
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Blood Group
                                                </InputLabel>
                                                <SelectTemp
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    // defaultValue={options[0]}
                                                    name="bGroup"
                                                    options={BloodGroup}
                                                    onChange={this.handleChangebGroup.bind(this)}
                                                    theme={(theme) =>this.getTheme(theme)}
                                                />

                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Date Of Birth
                                                </InputLabel>
                                                <FormControl fullWidth>

                                                    <Datetime
                                                        timeFormat={false}
                                                        dateFormat="DD-MM-YYYY"
                                                        onChange={this.dateChanged}
                                                        inputProps={{
                                                            id: "dob",
                                                            placeholder: "Select Date",
                                                            value: dob
                                                            // onChange: event => this.handleChangeValues(event, "interviewDate", "length", 0),
                                                            // value: this.state.tDate
                                                        }}
                                                    />

                                                </FormControl>

                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={4}>
                                                <InputLabel className={classes.label}>
                                                    Select Class *
                                                </InputLabel>
                                                <SelectTemp
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    // defaultValue={options[0]}
                                                    name="classSelect"
                                                    options={options}
                                                    onChange={this.handleChange.bind(this)}
                                                    theme={(theme) =>this.getTheme(theme)}
                                                />
                                            </ItemGrid>
                                        </GridContainer>
                                        <CustomInput
                                            labelText="Mother Name *"
                                            id="mName"
                                            onChange={this.onChangeValue}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                value: mName
                                            }}
                                        />
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={6}>
                                                <CustomInput
                                                    labelText="Mother Contact No *"
                                                    id="mContact"
                                                    onChange={this.onChangeValue}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        value: mContact
                                                    }}
                                                />
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>
                                                <CustomInput
                                                    labelText="Mother Email Id"
                                                    id="mEmail"
                                                    onChange={this.onChangeValue}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        type: "email",
                                                        value: mEmail
                                                    }}
                                                />

                                            </ItemGrid>

                                        </GridContainer>
                                        <CustomInput
                                            labelText="Father Name *"
                                            id="fName"
                                            onChange={this.onChangeValue}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                value: fName
                                            }}
                                        />
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={6}>
                                                <CustomInput
                                                    labelText="Father Contact No *"
                                                    id="fContact"
                                                    onChange={this.onChangeValue}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        value: fContact
                                                    }}
                                                />

                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={6}>
                                                <CustomInput
                                                    labelText="Father Email Id"
                                                    id="fEmail"
                                                    onChange={this.onChangeValue}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        type: "email",
                                                        value: fEmail
                                                    }}
                                                />

                                            </ItemGrid>

                                        </GridContainer>

                                        <GridContainer>
                                            <ItemGrid xs={12} md={3}>
                                                {/*<ExifOrientationImg src={this.state.imagePreviewUrl} disable={true} style={{width: '100px', height: '100px'}}/>*/}
                                                <PictureUpload imgUrl={this.state.imagePreviewUrl} disable={true}/>
                                            </ItemGrid>

                                            <ItemGrid xs={12} sm={6}>
                                                <CustomInput
                                                    labelText="Profile Pic"
                                                    id="profile_pic"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        disabled: true,
                                                        value: "" + this.state.fileName
                                                    }}
                                                />
                                            </ItemGrid>
                                            <ItemGrid>
                                                <Button onClick={this.handleFileClick}>Choose File</Button>
                                            </ItemGrid>
                                        </GridContainer>

                                        <GridContainer justify="center">

                                            {this.state.hideButton === true ?
                                                <ItemGrid>
                                                    <br/>
                                                    <Button color="primary" onClick={this.studentCreate}>Save</Button>
                                                    <br/>
                                                </ItemGrid>
                                                :
                                                < ItemGrid>
                                                    < br/>
                                                    < Button color="primary"
                                                             onClick={this.studentUpdate}>Update</Button>
                                                    <br/>
                                                </ItemGrid>
                                            }
                                            <ItemGrid>
                                                <br/>
                                                <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                                <br/>
                                            </ItemGrid>

                                        </GridContainer>
                                    </form>
                                </div>
                            }
                        />
                    </ItemGrid> :
                    <ItemGrid xs={12}>
                        <IconCard
                            icon={People}
                          //  iconColor={"rose"}
                            title={
                                <div>
                                    Students
                                    {(userType === 3 || userType === 5) ?
                                        <Button
                                            onClick={this.handleCreate}
                                            // round color="white"
                                            color="primary" right={true}> Add Student
                                        </Button>
                                        : null}
                                </div>
                            }
                            content={
                                <GridContainer>
                                    <ItemGrid xs={12} sm={12}>
                                        <GridContainer justify="left">
                                            <ItemGrid xs={12} sm={3} style={{marginTop: "-11px"}}>
                                                <InputLabel className={classes.label}>
                                                    Select Class
                                                </InputLabel>
                                                <SelectTemp
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    // defaultValue={options[0]}
                                                    name="classSelect"
                                                    options={options}
                                                    onChange={this.handleChange.bind(this)}
                                                    theme={(theme) =>this.getTheme(theme)}
                                                />
                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={2}>
                                                <Button color="primary" onClick={this.getClassList}> Search </Button>
                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>
                                    <ItemGrid xs={12}>
                                        <ReactTable
                                            data={childList}
                                            filterable
                                            style={{color: "#00acc1"}}
                                            columns={columns}
                                            defaultPageSize={5}
                                            showPaginationBottom={true}
                                            className="-striped -highlight"
                                        />
                                    </ItemGrid>
                                </GridContainer>
                            }
                        />
                    </ItemGrid>
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#AAAAAA",
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex"
    },
    ...customSelectStyle
};

const mapStateToProps = state => {
    // const{dataTable} = state.ClassRoom;
    const {isFailed, msg, msgColor, tableList, StatusStudent, dataTable} = state.Sutdents;
    return {isFailed, msg, msgColor, tableList, dataTable, StatusStudent}
};
export default connect(mapStateToProps, {
    CreateStudent: CreateSubjectAction, HideMsg,
    ClassList: DisplayClassList,
    StudentList: DisplayStudentList,
    UpdateStudent: UpdateStudentAction,
    UpdateStatus: ChangeStudentStatus,
    StudentPassword: CreateStudentPassword,
    ClassWiseChild: GetClassWiseChildList,
})(withStyles(extendedFormsStyle)(Students));
