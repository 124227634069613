import {gql} from "apollo-boost";

export const createFeeReceiptMutation = gql `mutation createReceipt($input: CreateReceiptInput!){
  createReceipt(receipt: $input){
    _id
    schoolId
    childId
    term
    termName
    items{
      name
      amount
    }
    amount
    createdAt
  }
}`;

// export const deleteFeeMutation=gql `mutation deleteFee($_id: String!){
//  deleteFee(_id: $_id){
//     _id
//   }
// }`;

