
import {
    CLASS_LSIT,
    CREATE_CALENDER_FAIL,
    CREATE_CALENDER_SUCCESS, DELETE_FAILED,
    DELETE_SUCCESS,
    LIST_CALENDER
} from "../actions/types";

const INIT_STATE = {isFailed: false, msg: null, msgColor: "danger",classList:[],HolidaysEvents:[],isRequestSent:false,deleteSuccess:false};
export default (state = INIT_STATE, action) => {
    switch(action.type){
        case CLASS_LSIT:
            return{...state,classList:action.payLoad};
        case CREATE_CALENDER_SUCCESS:
            return {...state,isFailed: true,msg: action.payLoad, msgColor: "success"};
        case CREATE_CALENDER_FAIL:
            return {...state, isFailed: true, msg: action.payLoad};
        case LIST_CALENDER:
            return{...state,HolidaysEvents:action.payLoad,isRequestSent:true};
        case DELETE_SUCCESS:
            return {...state,isFailed: true,msg: action.payLoad, msgColor: "success",deleteSuccess:true};
        case DELETE_FAILED:
            return {...state,isFailed: true,msg: action.payLoad,deleteSuccess:true};

        default:
            return INIT_STATE;
    }

}

