import {
    CLASS_LSIT,
    CLASSWISE_STUDENT_LIST,
    CREATE_ALBUM_FAIL,
    CREATE_ALBUM_SUCCESS, DELETE_FAILED, DELETE_SUCCESS,
    LIST_ALBUMS, UPLOAD_IMAGE_FAIL, UPLOAD_IMAGE_SUCCESS
} from "../actions/types";


const INIT_STATE = {isFailed: false, msg: null, msgColor: "danger", albumTable: [],classList:[],classWiseList:[],deleteSuccess:false};

export default (state=INIT_STATE,action)=>{
    switch (action.type) {
        case CLASS_LSIT:
            return{...state,classList:action.payLoad};
        case CLASSWISE_STUDENT_LIST:
            return{...state,classWiseList:action.payLoad};
        case CREATE_ALBUM_SUCCESS:
            return {...state,isFailed: true,msg: action.payLoad, msgColor: "success"};
        case CREATE_ALBUM_FAIL:
            return {...state, isFailed: true, msg: action.payLoad};
        case UPLOAD_IMAGE_SUCCESS:
            return {...state,isFailed: true,msg: action.payLoad, msgColor: "success"};
        case UPLOAD_IMAGE_FAIL:
            return {...state, isFailed: true, msg: action.payLoad};
        case LIST_ALBUMS:
            return{...state,albumTable:action.payLoad};
        case DELETE_SUCCESS:
            return {...state,isFailed: true,msg: action.payLoad, msgColor: "success",deleteSuccess:true};
        case DELETE_FAILED:
            return {...state,isFailed: true,msg: action.payLoad, deleteSuccess:true};
        default:
            return INIT_STATE;
    }
}
