import schoolApi from "kidzwapi";
import {REGISTER_SCHOOL_SUCCESS,
    REGISTER_SCHOOL_FAIL,
    HIDE_INFO_MSG
} from "./types";


function RegisterSchoolSuccess(msg)
{
    return{
        type:REGISTER_SCHOOL_SUCCESS,
        payLoad:msg
    }
}

function RegisterSchoolFailed(msg)
{
    return{
        type:REGISTER_SCHOOL_FAIL,
        payLoad:msg
    }
}

function hideMsg(){
    return{
        type:HIDE_INFO_MSG,
        payLoad:null
    }
}

export function HideMsg() {
    return function(dispatch){
        dispatch(hideMsg());
    }

}
export function RegisterSchoolAction(schoolName, schoolCode,schoolEmail, schoolMobile,schoolContactName, menus, referalCode, studentSize){
    return function (dispatch){
        if(schoolName !== null && schoolCode !==null && schoolEmail!=null && schoolMobile!==null && menus!=null && studentSize!='' && schoolContactName!=null){
            return schoolApi.registerSchool(schoolName,schoolCode,schoolEmail, schoolMobile,menus,referalCode, studentSize)
                .then(resp=>{
                    if(resp.status){
                        dispatch(RegisterSchoolSuccess(resp.msg));
                    }
                    else{
                        dispatch(RegisterSchoolFailed(resp.msg))
                    }
                })
        }else{
            dispatch(RegisterSchoolFailed("Enter All Fileds"))
        }
    }
}
