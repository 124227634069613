import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Datetime from "react-datetime";
import moment from "moment";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import withStyles from "material-ui/styles/withStyles";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import Select from "material-ui/Select";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import alertIcon from "@material-ui/icons/PermContactCalendar";
import List from "@material-ui/icons/List";
import PermContactCalendar from "@material-ui/icons/PermContactCalendar";
import {connect} from "react-redux";
import {HolidayType, HolidayType1} from "../../variables/menus";
import {DisplayClassList} from "../../actions/ClassRoomAction";
import {CreateCalenderAction, HideMsg, DisplayCalenderList, DeleteCalender} from "../../actions/CalenderAction";
import Snackbar from "../../components/Snackbar/Snackbar";
 import Edit from "@material-ui/icons/Edit";
import {getCookie, USER_TYPE} from "../../helper/Cookies";
import MenuItem from "material-ui/Menu/MenuItem";
import SelectTemp from 'react-select';
import Tooltip from "material-ui/Tooltip";
import BigCalendar from "react-big-calendar";
import Popup from "../../components/PopUp/Popup";
import Add from '@material-ui/icons/Add';
import {getDataFromUrl} from "../../server/server";
import cons, {serverApi} from "../../helper/Consts";
import {liteBlue} from "../../assets/jss/material-dashboard-pro-react";
import ExportToExcel from "../../components/ExportToExcel/ExportToExcel";

BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));
class Calendar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectClass: -1,
            multipleClassSelection: [],
            startTime: moment().format('DD-MM-YYYY HH:mm'),
            endTime: null,
            academicYear: moment().year(),
            alert: null,
            eventType: "H",
            subject: "",
            description: "",
            headers: ["Type", "Subject", "Description", "Start Time", "End Time"],
            selectHead: "",
            selectMode: "",
            fileName: "No file Chosen",
            file: null,

        };
        // this.handleFileChange = this.handleFileChange.bind(this);
        // this.handleFileClick = this.handleFileClick.bind(this);
    }

    handleCreate = () => {
        //alert();
        console.log(this.state);
        this.setState({
            isCreated: !this.state.isCreated,
            multipleClassSelection: [],

        });
    };
    handleSimple = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleSDate = (dateObj) => {
        if (typeof dateObj === 'object')
            this.setState({startTime: dateObj.format('DD-MM-YYYY HH:mm')});
    };
    handleEDate = (dateObj) => {
        if (typeof dateObj === 'object')
            this.setState({endTime: dateObj.format('DD-MM-YYYY HH:mm')});
    };
    handleClass = event => {
        this.setState({selectClass: event.target.value});
    };

    handleMultipleClassTeacher(value) {
        let ClassArray = [];
        if (value !== null) {
            value.map((data) =>
                ClassArray.push(data.value)
            )
        }
        this.setState({multipleClassSelection: ClassArray});
    }

    handleType = event => {
        this.setState({eventType: event.value});
    };
    onChangeValue = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        })
    };
    CalenderCreate = () => {
        const {CreateCalender} = this.props;
        const {multipleClassSelection, startTime, eventType, endTime, subject, description, academicYear} = this.state;
        CreateCalender(multipleClassSelection, academicYear, eventType, description, subject, startTime, endTime);
    };

    deleteCalender(eventId) {
        const {CalenderDelete} = this.props;
        CalenderDelete(eventId)
    }

    componentDidMount() {
        const {ClassList} = this.props;
        ClassList();
        // CalenderList();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {CalenderList, classList, HolidaysEvents, isRequestSent} = nextProps;
        if (HolidaysEvents.length <= 0) {
            let ClassArrays = [];
            if (this.state.selectClass === -1) {
                // console.log("sadik", classList);
                classList.forEach(r => {
                    const {classId} = r;
                    ClassArrays.push(classId)
                });
                this.setState({selectClass: ClassArrays});
            }

            if (isRequestSent === false && ClassArrays.length > 0) {
                CalenderList(ClassArrays);
            }
        }
        return true;
    }
    eventColors(event, start, end, isSelected) {
        var backgroundColor = "event-";
        event.color
            ? (backgroundColor = backgroundColor + event.color)
            : (backgroundColor = backgroundColor + "default");
        var style = {
            textAlign:"center"
          /*  borderRadius: '0px',
            opacity: 0.8,
            color: 'black',
            border: '0px',
            display: 'block'*/
        }
        return {
            className: backgroundColor,
            style:style
        };
    }

    timeInfo = () => {
        const {HideMsg, CalenderList, ClassList, deleteSuccess} = this.props;
        setTimeout(() => {
            HideMsg();
            ClassList();
            if (deleteSuccess !== true) {
                this.togglePopup();
            }else {
                this.setState({
                    showPopup: false,
                    isLoading:false,
                    description:"",
                    startTime:"",
                    updateEvent:false,
                    addEvent:false
                })
            }
            CalenderList(this.state.selectClass);
        }, 3000)
    };
    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup,
            isLoading:false,
            description:"",
            startTime:"",
            updateEvent:false,
            addEvent:false
        });
    }

    getTheme=(theme) => ({
        ...theme,
        borderRadius: '4px',
        height: "20px",
        colors: {
            ...theme.colors,
            //  primary25: '#00ABDC',
            primary:liteBlue,
            //   primary50:"#00ABDC"
        },
    });

    render() {

        const {classes, classList, isFailed, msg, msgColor, HolidaysEvents, HideMsg, isRequestSent} = this.props;
        if (isFailed === true && msg !== null) {
            if (msgColor === "success") {

                this.timeInfo();
            } else {
                setTimeout(() => {
                    HideMsg();
                }, 2000)
            }
        }
        let options = [];

        if (classList !== null || classList !== undefined) {
            classList.forEach(r => {
                const {name, classId} = r;
                options.push({
                    value: classId,
                    label: name
                })
            })
        }
        let ListEvents = [];


        let events = [];
        let columns = [];

        const userType = parseInt(USER_TYPE());
        // if (HolidaysEvents.length > 0) {
        if (userType === 2 || userType === 5) {
            columns = [
                // {
                //     Header: "Class",
                //     accessor: "classId",
                //     style: {color: "#000", textAlign: "left"}
                //
                // },
                {
                    Header: "Type",
                    accessor: "holidayOrEvent",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Subject",
                    accessor: "subject",
                    style: {color: "#000", textAlign: "left"}

                },

                {
                    Header: "Description",
                    accessor: "description",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Start Time",
                    accessor: "startTime",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "End Time",
                    accessor: "endTime",
                    style: {color: "#000", textAlign: "left"}

                }
            ]
        } else {
            columns = [
                // {
                //     Header: "Class",
                //     accessor: "classId",
                //     style: {color: "#000", textAlign: "left"}
                //
                // },
                {
                    Header: "Type",
                    accessor: "holidayOrEvent",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Subject",
                    accessor: "subject",
                    style: {color: "#000", textAlign: "left"}

                },

                {
                    Header: "Description",
                    accessor: "description",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Start Time",
                    accessor: "startTime",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "End Time",
                    accessor: "endTime",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                }
            ]
        }
        console.log("HolidaysEvents",HolidaysEvents);
        HolidaysEvents.forEach(r => {
            const {classId, academicYear, holidayOrEvent, description, subject, startTime, endTime, _id} = r;
            var date = String(startTime).split(' ');
            var days = String(date[0]).split('-');
            var hours = String(date[1]).split(':');

            var d=days[0].replace(/^0+/, '')
            var day=parseInt(d),m=parseInt(days[1]),y=parseInt(days[2]),h=parseInt(hours[0]) , min=parseInt(hours[1]);

            if(endTime!==null){
                var date1 = String(endTime).split(' ');
                var days1 = String(date1[0]).split('-');
                var hours1 = String(date1[1]).split(':');

                var d1=days1[0].replace(/^0+/, '');
                var day1=parseInt(d1),m1=parseInt(days1[1]),y1=parseInt(days1[2]),h1=parseInt(hours1[0]) , min1=parseInt(hours1[1]);
            }

            events.push({
                id: _id,
                classId: classId,
                academicYear: academicYear,
                holidayOrEvent: (holidayOrEvent === "E") ? "Event" : "Holiday",
                title:subject,
                allDay: true,
                start: new Date(y, m-1, day, h,min),
                end:(endTime!==null)? new Date(y1, m1-1, day1, h1,min1): new Date(y, m-1, day, h,min),
                color: (holidayOrEvent === "E")?"green":"red",
                date:startTime,
                startTime:date[0],
                endTime:(endTime!==null)?date1[0]:date[0],
                description:description
            })

            ListEvents.push({
                holidayOrEvent: (holidayOrEvent === "E") ? "Event" : "Holiday",
                subject: subject,
                description: description,
                startTime: startTime,
                endTime: endTime,
                _id: _id,
                classId: classId,
                academicYear: academicYear,
                actions: (
                    <div className="actions-right">
                        <Tooltip title="Delete">
                            <IconButton
                                onClick={() => {
                                    {
                                        if (window.confirm('Are you sure you want to Delete this Record?')) {
                                            this.deleteCalender(_id)
                                        }
                                        ;
                                    }
                                }}
                                color="dangerNoBackground"
                                customClass="remove">
                                <Close/>
                            </IconButton>
                        </Tooltip>
                    </div>
                )
            })
        });
        // }

        return (
            <GridContainer justify="center">
{/*
                <GridContainer>
                    <ItemGrid xs={12} sm={6} md={4}>
                        <Snackbar
                            open={isFailed}
                            place={"tc"}
                            color={msgColor}
                            message={msg}
                        />
                    </ItemGrid>
                </GridContainer>
*/}
                {this.state.isCreated ?
                    <IconCard
                        icon={alertIcon}
                        //iconColor={"rose"}
                        title={
                            <div>
                                 Events/Holidays
                                {<IconButton
                                        onClick={this.handleCreate}
                                        // round color="white"
                                        color="primary" right={true}>
                                    <PermContactCalendar/>
                                    </IconButton>
                                }
                            </div>
                        }
                        content={
                            <GridContainer>
                                <ItemGrid xs={12} sm={6} md={4}>
                                    <Snackbar
                                        open={isFailed}
                                        place={"tc"}
                                        color={msgColor}
                                        message={msg}
                                    />
                                </ItemGrid>
                                <ItemGrid xs={12}>
                                    {/*  <ExportToExcel posts={this.state.data}/>*/}
                                    <ReactTable
                                        data={ListEvents}
                                        filterable
                                        style={{color: liteBlue}}
                                        columns={columns}
                                        defaultPageSize={5}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                    >

                                    </ReactTable>
                                </ItemGrid>
                                <ItemGrid xs={12}>
                                    <GridContainer>
                                        <ItemGrid xs={12} md={10}>
                                        </ItemGrid>
                                        <ItemGrid xs={12} md={2}>
                                            <ExportToExcel
                                                key={ListEvents}
                                                posts={ListEvents}
                                                headings={this.state.headers}
                                                fileName={"Holidays"} sheetName={"Holidays"}/>

                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>

                            </GridContainer>

                        }
                    />

                    :

                    <IconCard
                        icon={alertIcon}
                    //    iconColor={"rose"}
                        title={
                            <div>
                                Calender Events

                                { <IconButton
                                        onClick={this.handleCreate}
                                        // round color="white"
                                        color="primary" right={true}>
                                        <List/>
                                    </IconButton>}

                            </div>
                        }
                        content={
                            <GridContainer>
                                <ItemGrid xs={12}>
                                    <BigCalendar
                                        selectable
                                        events={events}
                                        defaultView="month"
                                        scrollToTime={new Date(1970, 1, 1, 6)}
                                        defaultDate={new Date()}
                                        onSelectEvent={event =>{
                                            alert(event.title+"\n"+event.description);
                                        }}
                                        onSelectSlot={slotInfo =>  {
                                            var selectedEvents=[];
                                            var startDate = moment(slotInfo.start.toLocaleString()).format("DD-MM-YYYY");
                                            var endDate = moment(slotInfo.end.toLocaleString()).format("DD-MM-YYYY");
                                            console.log("toggle is true",startDate);

                                            events.forEach((o,i)=>{
                                                console.log("start time",o.startTime);
                                                if(o.startTime===startDate||o.endTime===endDate){
                                                    selectedEvents.push(ListEvents[i])
                                                }
                                            });
                                             startDate = moment(slotInfo.start.toLocaleString()).format("DD-MM-YYYY HH:mm");

                                            this.setState({
                                                startTime:startDate,
                                                showPopup: !this.state.showPopup,
                                                selectedEvents:selectedEvents,
                                            });
                                        }}
                                        eventPropGetter={this.eventColors}
                                        //  dayPropGetter={this.dayColors.bind(this)}
                                    />
                                    {this.state.showPopup ?
                                        <Popup closePopup={this.togglePopup.bind(this)}
                                               onClickSave={() => this.CalenderCreate()}
                                               isShowSave={this.state.selectedEvents.length===0||this.state.updateEvent||this.state.addEvent}
                                               isShowCancel={false}
                                               save_btn_txt={this.state.updateEvent ? "update" : "Add"}
                                               cancel_button_text={"Cancel"}
                                               content={
                                                   <form>
                                                       <GridContainer>
                                                           <ItemGrid xs={12} sm={6} md={4}>
                                                               <Snackbar
                                                                   open={isFailed}
                                                                   place={"tc"}
                                                                   color={msgColor}
                                                                   message={msg}
                                                               />
                                                           </ItemGrid>
                                                       </GridContainer>

                                                       {(this.state.selectedEvents.length===0||this.state.updateEvent||this.state.addEvent)?
                                                           <GridContainer>

                                                               <ItemGrid xs={12} sm={6}>
                                                                   <InputLabel className={classes.label}>
                                                                       Select Class
                                                                   </InputLabel>
                                                                   <SelectTemp
                                                                       isMulti
                                                                       className="basic-multi-select"
                                                                       classNamePrefix="select"
                                                                       // defaultValue={options[0]}
                                                                       name="multipleClassSelection"
                                                                       options={options}
                                                                       onChange={this.handleMultipleClassTeacher.bind(this)}
                                                                       theme={(theme) => this.getTheme(theme)}
                                                                       styles={{
                                                                           control: (base) => ({
                                                                               ...base,
                                                                               // height: '30px',
                                                                               // 'min-height': '30px',
                                                                               marginTop: "25px"
                                                                           }),

                                                                       }}
                                                                   />
                                                               </ItemGrid>

                                                               <ItemGrid xs={12} sm={6}>
                                                                   <InputLabel className={classes.label}>
                                                                       Type
                                                                   </InputLabel>
                                                                   <SelectTemp
                                                                       className="basic-multi-select"
                                                                       classNamePrefix="select"
                                                                       // defaultValue={options[0]}
                                                                       name="eventType"
                                                                       options={HolidayType1}
                                                                       onChange={this.handleType}
                                                                       theme={(theme) => this.getTheme(theme)}
                                                                       styles={{
                                                                           control: (base) => ({
                                                                               ...base,
                                                                               // height: '30px',
                                                                               // 'min-height': '30px',
                                                                               marginTop: "25px"
                                                                           }),

                                                                       }}
                                                                   />

                                                               </ItemGrid>

                                                               <ItemGrid xs={12} sm={6}>
                                                                   <InputLabel className={classes.label}>
                                                                      Subject
                                                                   </InputLabel>
                                                                   <CustomInput
                                                                       //  labelText="At"
                                                                       id="subject"
                                                                       onChange={this.onChangeValue}
                                                                       formControlProps={{
                                                                           fullWidth: true
                                                                       }}
                                                                       inputProps={{}}
                                                                   />
                                                               </ItemGrid>
                                                               <ItemGrid xs={12} sm={6}>
                                                                   <InputLabel className={classes.label}>
                                                                       Description
                                                                   </InputLabel>
                                                                   <CustomInput
                                                                       //  labelText="At"
                                                                       id="description"
                                                                       onChange={this.onChangeValue}
                                                                       formControlProps={{
                                                                           fullWidth: true
                                                                       }}
                                                                       inputProps={{}}
                                                                   />
                                                               </ItemGrid>
                                                               <ItemGrid xs={12} sm={6}>
                                                                   <InputLabel className={classes.label}>
                                                                       start Date
                                                                   </InputLabel>
                                                                   <FormControl fullWidth>
                                                                       <Datetime
                                                                           closeOnSelect={true}
                                                                           timeFormat="HH:mm"
                                                                           dateFormat="DD-MM-YYYY "
                                                                           onChange={this.handleSDate}
                                                                           inputProps={{
                                                                               id: "startTime",
                                                                               placeholder: "select Date",
                                                                                value: this.state.startTime
                                                                               // onChange: event => this.handleChangeValues(event, "interviewDate", "length", 0),
                                                                               // value: this.state.tDate
                                                                           }}
                                                                       />
                                                                   </FormControl>
                                                               </ItemGrid>
                                                               {this.state.eventType === "E" ?
                                                                   <ItemGrid xs={12} sm={6}>
                                                                       <InputLabel className={classes.label}>
                                                                           End Date
                                                                       </InputLabel>
                                                                       <FormControl fullWidth>
                                                                           <Datetime
                                                                               closeOnSelect={true}
                                                                               timeFormat="HH:mm"
                                                                               dateFormat="DD-MM-YYYY "
                                                                               onChange={this.handleEDate}
                                                                               inputProps={{
                                                                                   id: "endTime",
                                                                                   placeholder: "select Date",
                                                                               }}
                                                                           />
                                                                       </FormControl>
                                                                   </ItemGrid>
                                                                   : null}

                                                               {/*<ItemGrid xs={12}>*/}
                                                               {/*<FormControlLabel*/}
                                                               {/*control={*/}

                                                           </GridContainer>
:
                                                           <GridContainer>
                                                               <ItemGrid xs={12} sm={12} style={{marginTop:"10px"}}>
                                                                   <InputLabel className={classes.label}>
                                                                       Holiday Date : {this.state.startTime}
                                                                   </InputLabel>
                                                                   {  (userType === 3 || userType === 4)?  <IconButton
                                                                       onClick={() => {
                                                                           this.setState({
                                                                               addEvent:true,
                                                                           })

                                                                       }}
                                                                       color="primary" right={true}>
                                                                       <Add/>
                                                                   </IconButton>:null}

                                                               </ItemGrid>
                                                               <ItemGrid xs={12}>
                                                                   <ReactTable
                                                                       data={this.state.selectedEvents}
                                                                       filterable={false}
                                                                       style={{color: "#00acc1"}}
                                                                       columns={columns}
                                                                       defaultPageSize={5}
                                                                       showPaginationBottom={false}
                                                                       sortable={false}
                                                                       className="-striped -highlight"
                                                                   />
                                                               </ItemGrid>

                                                           </GridContainer>}


                                                   </form>

                                               }

                                        />

                                        : null
                                    }


                                    {/*  <ExportToExcel posts={this.state.data}/>*/}
{/*
                                    <ReactTable
                                        data={ListEvents}
                                        filterable
                                        style={{color: "#00acc1"}}
                                        columns={columns}
                                        defaultPageSize={5}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                    >

                                    </ReactTable>
*/}
                                </ItemGrid>
                            </GridContainer>

                        }
                    />
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: liteBlue,
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    ...customSelectStyle
};
const mapStateToProps = state => {
    const {isFailed, msg, msgColor, classList, HolidaysEvents, isRequestSent, deleteSuccess} = state.Calender;
    return {classList, isFailed, msg, msgColor, HolidaysEvents, isRequestSent, deleteSuccess}
};
export default connect(mapStateToProps, {
    ClassList: DisplayClassList,
    CalenderList: DisplayCalenderList,
    CreateCalender: CreateCalenderAction, HideMsg,
    CalenderDelete: DeleteCalender
})(withStyles(extendedFormsStyle)(Calendar));
