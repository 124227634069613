import React from "react";
//import 'react-chat-elements/dist/main.css';
import '../../assets/scss/material-dashboard-pro-react/chat-elements.css'
// react component for creating dynamic tables

// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import Image from "@material-ui/icons/Image";
// import {dataTable} from "variables/general.jsx";
import avatar from "assets/img/faces/avatar.jpg";
// import {ChatItem} from 'react-chat-elements';
import {ChatList} from 'react-chat-elements'

//import withStyles from "material-ui/styles/withStyles"

class Chats extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            imagePreviewUrl: avatar,

        };

    }

    onClickItem = () => {
        this.props.history.push('/ChatConversation');
    };

    render() {
        return (
            <GridContainer justify={"center"}>

                <ItemGrid xs={12}>
                    <IconCard
                        icon={Image}
                        iconColor={"rose"}
                        title={
                            <div>
                                Chats
                                {/* <Button
                                        onClick={this.handleCreate}
                                        // round color="white"
                                        color="rose" right={true}> Add New Group
                                    </Button>*/}
                            </div>
                        }
                        content={
                            <GridContainer>
                                <ItemGrid xs={12}>
                                    <ChatList
                                        className='chat-list'
                                        onClick={this.onClickItem}
                                        dataSource={[
                                            {
                                                avatar: avatar,
                                                alt: 'Reactjs',
                                                title: 'Tania Andrew',
                                                subtitle: 'You : What are you doing?',
                                                dateString: '1:45PM',
                                                unread: 0,
                                                onClick: this.onClickItem
                                            },
                                            {
                                                avatar: 'https://www.gettyimages.ie/gi-resources/images/Homepage/Hero/UK/CMS_Creative_164657191_Kingfisher.jpg',
                                                alt: 'Reactjs',
                                                title: 'Pupilned Group',
                                                subtitle: 'You :  Hi everyone?',
                                                dateString: '1:35 PM',
                                                unread: 1,
                                                onClick: this.onClickItem
                                            },

                                        ]}/>


                                    {/*
                                        <GridContainer>
                                            <ItemGrid xs={12} sm={3} >
                                                <div className="picture-container">
                                                    <div className="picture">
                                                        <img
                                                            src={avatar}
                                                            className="picture-src"
                                                            alt="..."

                                                        />
                                                    </div>
                                                </div>

                                            </ItemGrid>
                                            <ItemGrid xs={12} sm={9}  >

                                            </ItemGrid>

                                        </GridContainer>
*/}
                                </ItemGrid>
                            </GridContainer>
                        }
                    />
                </ItemGrid>

            </GridContainer>
        );
    }
}


export default Chats;
