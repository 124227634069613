import schoolAPi from "kidzwapi";
import {
    CREATE_ALBUM_FAIL,
    CREATE_ALBUM_SUCCESS, DELETE_SUCCESS,
    HIDE_INFO_MSG,
    LIST_ALBUMS,
    UPLOAD_IMAGE_FAIL,
    UPLOAD_IMAGE_SUCCESS
} from "./types";
import {AUTH, SCHOOL_ID, USER_ID} from "../helper/Cookies";


function CreateAlbumSuccess() {
    return {
        type: CREATE_ALBUM_SUCCESS,
        payLoad: "Album Created Successfully"
    }
}

function CreateAlbumFailed(msg) {
    return {
        type: CREATE_ALBUM_FAIL,
        payLoad: msg
    }
}
function DeleteAlbumSuccess() {
    return {
        type: DELETE_SUCCESS,
        payLoad: "Album Deleted Successfully"
    }
}

function DeleteAlbumFailed(msg) {
    return {
        type: DELETE_SUCCESS,
        payLoad: msg
    }
}
function UploadImageSuccess() {
    return {
        type: UPLOAD_IMAGE_SUCCESS,
        payLoad: "Image Upload Successfully"
    }
}

function UploadImageFailed(msg) {
    return {
        type: UPLOAD_IMAGE_FAIL,
        payLoad: msg
    }
}

function hideMsg() {
    return {
        type: HIDE_INFO_MSG,
        payLoad: null
    }
}

function AlbumList(json) {
    return {
        type: LIST_ALBUMS,
        payLoad: json
    }
}

export function HideMsg() {
    return function (dispatch) {
        dispatch(hideMsg());
    }
}

export function CreateAlbumAction(classId, childId, albumName, coverPic, fileType) {
    return function (dispatch) {
        console.log("Gallery request",parseInt(SCHOOL_ID()), classId, childId, albumName, coverPic, USER_ID(), AUTH());
        if (childId !== 0 && classId !== 0 && coverPic !== null) {
            if (fileType === "image/jpeg" || fileType=== "image/jpg" || fileType=== "image/png") {
                return schoolAPi.createAlbum(parseInt(SCHOOL_ID()), classId, childId, albumName, coverPic, USER_ID(), AUTH())
                    .then(resp => {
                        console.log("Gallery Resp",resp);
                        if (resp.status) {
                            dispatch(CreateAlbumSuccess());
                        } else {
                            dispatch(CreateAlbumFailed(resp.msg));
                        }
                    })
            } else {
                dispatch(CreateAlbumFailed("Please Upload jpeg file"))
            }
        } else {
            dispatch(CreateAlbumFailed("Enter All Fields"))
        }
    }
}


export function UploadImageAction(albumId, url, fileType) {
    return function (dispatch) {
        if (albumId !== null) {
            if (fileType === "image/jpeg" || fileType=== "image/jpg" || fileType=== "image/png") {
                console.log("request",albumId, url,parseInt(SCHOOL_ID()), USER_ID(), AUTH())
                return schoolAPi.addImageToAlbum(albumId, url,parseInt(SCHOOL_ID()), USER_ID(), AUTH())
                    .then(resp => {
                        console.log("resp",resp);
                        if (resp.status) {
                            dispatch(UploadImageSuccess());
                        }
                    })
            } else {
                dispatch(UploadImageFailed("Please Upload jpeg Image"));
            }
        } else {
            dispatch(UploadImageFailed("Please Upload Image"));
        }
    }
}

export function DisplayAlbum() {
    return function (dispatch) {
        return schoolAPi.listAlbums(parseInt(SCHOOL_ID()), USER_ID(), AUTH())
            .then(resp => {
                if (resp.status) {
                    dispatch(AlbumList(resp.albums))
                }

            })
    }
}

export function DeleteAlbum(albumId) {
    return function (dispatch) {
        return schoolAPi.deleteGallery(albumId, USER_ID(), AUTH())
            .then(resp => {
                if (resp.status) {
                    dispatch(DeleteAlbumSuccess());
                }else{
                    dispatch(DeleteAlbumFailed(resp.msg));
                }

            })
    }
}
