import {
    CREATE_SCHOOL_FAIL, CREATE_SCHOOL_SUCCESS, SCHOOL_LIST, UPDATE_SCHOOL_FAIL, UPDATE_SCHOOL_SUCCESS
} from "../actions/types";

const INIT_STATE = {isFailed: false, msg: null, msgColor: "danger", dataTable: []};

export default (state=INIT_STATE ,action)=>{
    switch(action.type){
        case CREATE_SCHOOL_SUCCESS:
            return{...state,isFailed: true,msg:action.payLoad,msgColor: "success"};
        case CREATE_SCHOOL_FAIL:
            return{...state,isFailed:true,msg:action.payLoad};
        case UPDATE_SCHOOL_SUCCESS:
            return {...state,isFailed:true,msg:action.payLoad,msgColor:"success"};
        case UPDATE_SCHOOL_FAIL:
            return {...state,isFailed:true,msg:action.payLoad};
        case SCHOOL_LIST:
                return {...state,dataTable:action.payLoad};
        default:
            return INIT_STATE;
    }
}
