import {HIDE_INFO_MSG, LOGIN_FAILED, LOGIN_SUCCESS, LOGOUT_FAILED, LOGGED_OUT} from '../actions/types';
import {
    AUTH,
    dispName,
    setAuthKey,
    setDispName,
    setLoggedIn,
    setUserId,
    USER_ID,
    isLoggedIn,
    removeCookies,
    setSchoolId,
    setUserType,
    setTeachClass, setAttClasses,
} from '../helper/Cookies';

const INIT_STATE = {
    isFailed: false,
    failMsg: "Login failed.",
    isLoggedIn: isLoggedIn(),
    auth: AUTH(),
    name: dispName(),
    userId: USER_ID(),
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            const {_id, auth, name, menus,schoolId,type,classes, attClasses} = action.payload;
            setAuthKey(auth);
            setUserId(_id);
            setDispName(name);
            setLoggedIn();
            setSchoolId(schoolId);
            setUserType(type);
            console.log("login",action.payload);
            setTeachClass(JSON.stringify(classes));
            setAttClasses(attClasses);
            return {...state, userId: _id, name: name, auth: auth, menus, schoolId: schoolId,type:type, isLoggedIn: true};
        case LOGIN_FAILED:
            return {...state, isFailed: true, failMsg: action.payload, isLoggedIn: false};
        case HIDE_INFO_MSG:
            console.log("enterrrrrr2222222")
            return {...state,isFailed: false, failMsg: "Login failed.",};
           // return INIT_STATE;
        case LOGGED_OUT:
            removeCookies();
            return {...state, isLoggedIn: false};
            // return INIT_STATE;
        case LOGOUT_FAILED:
            return {...state, failMsg: action.payload};
        default:
            return state;
    }
}
