import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/icons

import Edit from "@material-ui/icons/Edit";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";

import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import withStyles from "material-ui/styles/withStyles";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import {connect} from "react-redux";
import {SubjectAction, HideMsg, DisplaySubjectList, UpdateSubject} from "../../actions/SubjectAction";
import Snackbar from "../../components/Snackbar/Snackbar";
import {USER_TYPE} from "../../helper/Cookies";

import SubjectIcon from "@material-ui/icons/Subject";
import Tooltip from "material-ui/Tooltip";

class Subjects extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tittle: "Create Subject",
            buttonType: "Save",
            subjectId: '',
            hideButton: true,
            subject_code: null,
            subject_name: null,
            isCreated: false,
            listId: 1
        }
    }

    handleCreate = () => {
        //alert();
        this.setState({
            tittle: "Create Subject",
            hideButton: true,
            subject_code: null,
            subject_name: null,
            isCreated: !this.state.isCreated,
            listId: 0
        });
    }

    handleUpdateSubject = (subjectId, name, code) => {
        this.setState({
            tittle: "Update Subject",
            buttonType: "Update",
            hideButton: false,
            subjectId: subjectId,
            subject_name: name,
            subject_code: code,
            isCreated: true,
            listId: 0
        });
    };

    onChangeValue = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        })
    };
    saveSubject = () => {
        const {CreateSubject} = this.props;
        const {subject_code, subject_name} = this.state;
        CreateSubject(subject_code, subject_name);
    }
    updateSubject = () => {
        const {UpdateSubject} = this.props;
        const {subjectId, subject_code, subject_name} = this.state;
        UpdateSubject(subjectId, subject_name, subject_code);
    };
    timeInfo = () => {
        const {HideMsg, SubjectList} = this.props;
        setTimeout(() => {
            HideMsg();
            SubjectList();
            this.handleCreate();
        }, 3000)
    }

    componentDidMount() {
        const {SubjectList} = this.props;
        SubjectList();
    }

    render() {
        const {subject_code, subject_name} = this.state;
        const {isFailed, msg, msgColor, dataTable, HideMsg} = this.props;
        // console.log("msg",isFailed,msgColor,msg);
        if (isFailed === true && msg !== null) {
            if (msgColor === "success") {
                this.timeInfo();
            } else {
                setTimeout(() => {
                    HideMsg();
                }, 2000)
            }
        }

        let subjectList = [];
        let columns = [];
        const userType = parseInt(USER_TYPE());
        // if (dataTable.length > 0) {
        if (userType === 5) {
            columns = [

                {
                    Header: "Subject Name",
                    accessor: "name",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Subject Code",
                    accessor: "code",
                    style: {color: "#000", textAlign: "left"}

                }
            ]
        } else {
            columns = [

                {
                    Header: "Subject Name",
                    accessor: "name",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Subject Code",
                    accessor: "code",
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                }
            ]
        }
        dataTable.forEach(r => {
            const {subjectId, name, code} = r;
            subjectList.push({
                code: code,
                name: name,
                subjectId: subjectId,
                actions: (
                    <div className="actions-right">
                        <Tooltip title="Edit">
                            <IconButton
                                onClick={() => {
                                    this.handleUpdateSubject(subjectId, name, code);
                                }}
                                color="infoNoBackground"
                                customClass="edit">
                                <Edit/>
                            </IconButton>
                        </Tooltip>{" "}
                    </div>
                )
            })
        })
        // }
        return (
            <GridContainer justify="center">
                <GridContainer>
                    <ItemGrid xs={12} sm={6} md={4}>
                        <Snackbar
                            open={isFailed}
                            place={"tc"}
                            color={msgColor}
                            message={msg}
                        />
                    </ItemGrid>
                </GridContainer>
                {this.state.isCreated ?
                    <IconCard
                        icon={SubjectIcon}
                        title={this.state.tittle}
                     //   iconColor="rose"
                        content={
                            <form>
                                <CustomInput
                                    labelText="Subject Code"
                                    id="subject_code"
                                    onChange={this.onChangeValue}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        value: subject_code
                                    }}
                                />
                                <CustomInput
                                    labelText="Subject Name"
                                    id="subject_name"
                                    onChange={this.onChangeValue}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        value: subject_name
                                    }}
                                />


                                <GridContainer justify="center">

                                    {this.state.hideButton === true ?
                                        <ItemGrid>
                                            <br/>
                                            <Button color="primary" onClick={this.saveSubject}>Save</Button>
                                            <br/>
                                        </ItemGrid>
                                        :
                                        < ItemGrid>
                                            < br/>
                                            < Button color="primary" onClick={this.updateSubject}>Update</Button>
                                            <br/>
                                        </ItemGrid>
                                    }
                                    <ItemGrid>
                                        <br/>
                                        <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                        <br/>
                                    </ItemGrid>

                                </GridContainer>
                            </form>

                        }
                    />
                    :
                    <IconCard
                        onClick={this.handleCreate}
                        icon={SubjectIcon}
                      //  iconColor={"rose"}
                        title={
                            <div>
                                Subjects
                                {userType !== 5 ?
                                    <Button
                                        onClick={this.handleCreate}
                                        // round color="white"
                                        color="primary" right={true}> Add Subject
                                    </Button>
                                    : null}
                            </div>
                        }
                        content={
                            <GridContainer>
                                <ItemGrid xs={12}>
                                    <ReactTable
                                        data={subjectList}
                                        filterable
                                        style={{color: "#00acc1"}}
                                        columns={columns}
                                        defaultPageSize={5}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                    />
                                </ItemGrid>
                            </GridContainer>
                        }
                    />
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#AAAAAA",
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex"
    },
    ...customSelectStyle
};

const mapStateToProps = state => {
    const {isFailed, msg, msgColor, dataTable} = state.Subject;
    return {isFailed, msg, msgColor, dataTable}
}
export default connect(mapStateToProps, {
    CreateSubject: SubjectAction, HideMsg,
    UpdateSubject: UpdateSubject,
    SubjectList: DisplaySubjectList
})(withStyles(extendedFormsStyle)(Subjects));
