import React from "react";
// react component for creating dynamic tables

// @material-ui/icons
import Close from "@material-ui/icons/Delete";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import IconButton from "components/CustomButtons/IconButton.jsx";
import Image from "@material-ui/icons/Image";
import CustomInput from "../../components/CustomInput/CustomInput";
import InputLabel from "material-ui/Input/InputLabel";
import FormControl from "material-ui/Form/FormControl";
import Select from "material-ui/Select/Select";
import MenuItem from "material-ui/Menu/MenuItem";
import Button from "../../components/CustomButtons/Button";
import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import {connect} from "react-redux";
import {DisplayClassList} from "../../actions/ClassRoomAction";
import {GetChildList} from "../../actions/AssessmentAction";
import {CreateAlbumAction, HideMsg, DisplayAlbum, DeleteAlbum} from "../../actions/GalleryAlbumAction";
import withStyles from "material-ui/styles/withStyles"
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import Snackbar from "../../components/Snackbar/Snackbar";
import {setImages, setAlbumId, USER_TYPE} from "../../helper/Cookies";
import SelectTemp from 'react-select';
import Tooltip from "material-ui/Tooltip";
import {liteBlue} from "../../assets/jss/material-dashboard-pro-react";


class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            albumName: "",
            selectAll: 0,
            selectClass: 0,
            selectChild: [],
            file: null,
            multipleClassSelection: -1,
            fileName: "No file Chosen",
            imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
            child_list: ["Child A", "Child B", "Child V", "Child C", "Child D", "Child E", "Child F"],
            classes_list: ["1st Standard", "2nd Standard", "3rd Standard", "4th Standard", "5th Standard", "6th Standard", "7th Standard"],
        };
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
    }

    handleSimple = event => {
        this.setState({[event.target.name]: event.target.value});
    };
    handleChild = event => {
        let ChildList = [];
        if (event !== null) {
            var hasall=false;
            event.map((data) =>{
                if(data.value===0){
                    hasall=true;
                  const  {classWiseList}=this.props;
                    ChildList = [];
                    classWiseList.forEach(r => {
                        const {name, childId} = r;
                        ChildList.push(childId)
                    })

                }else{
                    if(!hasall)
                    ChildList.push(data.value)

                }
                }
            )
        }
        this.setState({selectChild: ChildList});
    };

    handleMultipleClassTeacher(value) {
        let ClassArray = [];
        if (value !== null) {
            value.map((data) =>
                ClassArray.push(data.value)
            )
        }
        const {ChildList} = this.props;
        ChildList(ClassArray);
        this.setState({multipleClassSelection: ClassArray});
    }

    onChangeValue = event => {
        this.setState({[event.target.id]: event.target.value});
    };
    handleCreate = () => {
        //alert();
        console.log(this.state);
        this.setState({
            albumName: "",
            selectClass: 0,
            selectChild: [],
            multipleClassSelection: -1,
            file: null,
            fileName: "No file Chosen",
            isCreated: !this.state.isCreated,
        });
    };
    handleClass = event => {
        this.setState({selectClass: event.target.value});
        const {ChildList} = this.props;
        ChildList(event.target.value);
    };


    handleFileClick() {
        var input = document.createElement("input");
        input.type = "file";
        input.onchange = this.handleImageChange;
        input.click();
    }

    handleImageChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                fileName: file.name,
                fileType: file.type
            });
        };
        reader.readAsDataURL(file);
    }

    AlbumCreate = () => {
        const {CreateAlbum} = this.props;
        const {multipleClassSelection, selectChild, albumName, file, fileType} = this.state;
        CreateAlbum(multipleClassSelection, selectChild, albumName, file, fileType);
    };

    onClickAlbum = (albumId, albumImages) => {
        var images = JSON.stringify(albumImages);
        setImages(images);
        setAlbumId(albumId);
        this.props.history.push('/GalleryImages');
    };

    deleteAlbum(albumId) {
        const {AlbumDelete} = this.props;
        AlbumDelete(albumId);
    }

    componentDidMount() {
        const {ClassList, AlbumList} = this.props;
        ClassList();
        AlbumList();
    }

    timeInfo = () => {
        const {HideMsg, deleteSuccess, AlbumList} = this.props;
        setTimeout(() => {
            HideMsg();
            if (deleteSuccess !== true) {
                this.handleCreate();
            }
            AlbumList();
        }, 3000)
    };
    getTheme=(theme) => ({
        ...theme,
        borderRadius: '4px',
        height: "20px",
        colors: {
            ...theme.colors,
            //  primary25: '#00ABDC',
            primary:liteBlue,
            //   primary50:"#00ABDC"
        },
    });


    render() {
        const {classes, classList, classWiseList, isFailed, msg, msgColor, HideMsg, albumTable} = this.props;
        if (isFailed === true && msg !== null) {
            if (msgColor === "success") {
                this.timeInfo();
            } else {
                setTimeout(() => {
                    HideMsg();
                }, 2000)
            }
        }
        console.log("childlist",this.state.selectChild);
        let options = [],childNames=[];

        if (classList !== null || classList !== undefined) {
            classList.forEach(r => {
                const {name, classId} = r;
                options.push({
                    value: classId,
                    label: name
                })
            })
        }
        if(classWiseList!==null){
            classWiseList.forEach((r,i) => {
                const {name, childId} = r;
                if(i===0)
                childNames.push({value:0,label:"Select All"});
                childNames.push({
                    value: childId,
                    label: name
                })
            })

        }



        let ListAlbums = [];
        const userType = parseInt(USER_TYPE());
        if (albumTable.length > 0) {
            albumTable.forEach(r => {
                const {title, coverUrl, albumId, mediaFiles} = r;
                ListAlbums.push({
                    title: title,
                    coverUrl: coverUrl,
                    albumId: albumId,
                    mediaFiles: mediaFiles,
                })
            })
        }
        return (
            <GridContainer justify={"center"}>
                <GridContainer>
                    <ItemGrid xs={12} sm={6} md={4}>
                        <Snackbar
                            open={isFailed}
                            place={"tc"}
                            color={msgColor}
                            message={msg}
                        />
                    </ItemGrid>
                </GridContainer>
                {this.state.isCreated ?
                    <IconCard
                        icon={Image}
                        title="Create Album"
                      //  iconColor="rose"
                        content={
                            <form>
                                {/*<InputLabel className={classes.label}>
                                        Album Name
                                    </InputLabel>*/}
                                <CustomInput
                                    labelText="Album Name"
                                    id="albumName"
                                    onChange={this.onChangeValue}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                                <GridContainer>
                                    <ItemGrid xs={12} sm={6}>
                                        <InputLabel style={{color: "#AAAAAA"}}>
                                            Select Class
                                        </InputLabel>
                                        <SelectTemp
                                            isMulti
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            // defaultValue={options[0]}
                                            name="multipleClassSelection"
                                            options={options}
                                            onChange={this.handleMultipleClassTeacher.bind(this)}
                                            theme={(theme) => this.getTheme(theme)}
                                        />
                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6}>
                                        <InputLabel className={classes.label}>
                                            Select Child
                                        </InputLabel>

                                        <SelectTemp
                                            isMulti
                                            className="basic-single"
                                            classNamePrefix="select"
                                            // defaultValue={options[0]}
                                            name="selectChild"
                                            options={childNames}
                                            value={()=>{
                                                this.state.selectChild.forEach(r=>{
                                                    childNames.forEach(data=>{
                                                        if(r===data.value){
                                                            return data;
                                                        }
                                                    })
                                                })
                                            }}
                                            onChange={this.handleChild.bind(this)}
                                            theme={(theme) =>this.getTheme(theme)}
                                        />
                                    </ItemGrid>

{/*
                                    <ItemGrid xs={12} sm={6}>

                                        <FormControl
                                            fullWidth
                                            className={classes.selectFormControl}
                                        >
                                            <InputLabel
                                                htmlFor="multiple-select"
                                                className={classes.selectLabel}
                                            >
                                                Select Child
                                            </InputLabel>
                                            <Select
                                                multiple
                                                MenuProps={{
                                                    className: classes.selectMenu
                                                }}
                                                classes={{
                                                    select: classes.select
                                                }}
                                                value={this.state.selectChild}
                                                onChange={this.handleChild}
                                                inputProps={{
                                                    name: "selectChild",
                                                    id: "multiple-select"
                                                }}
                                            >
                                                <MenuItem
                                                    value={this.state.selectAll}
                                                    classes={{
                                                        root: classes.selectMenuItem
                                                    }}
                                                >
                                                    Select All
                                                </MenuItem>
                                                {classWiseList !== null ?
                                                    classWiseList.map((data) =>
                                                        <MenuItem
                                                            key={data.childId}
                                                            classes={{
                                                                root: classes.selectMenuItem,
                                                                selected: classes.selectMenuItemSelected
                                                            }}
                                                            value={data.childId}
                                                        >
                                                            {data.name}
                                                        </MenuItem>
                                                    )
                                                    : null}
                                            </Select>
                                        </FormControl>
                                    </ItemGrid>
*/}
                                </GridContainer>
                                <GridContainer>
                                    <ItemGrid xs={12} sm={6}>
                                        <CustomInput
                                            labelText="Album Pic"
                                            id="profile_pic"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                disabled: true,
                                                value: "" + this.state.fileName
                                            }}
                                        />
                                    </ItemGrid>
                                    <ItemGrid>
                                        <Button onClick={this.handleFileClick}>Choose File</Button>
                                    </ItemGrid>


                                </GridContainer>
                                <GridContainer justify="center">

                                    <ItemGrid>
                                        <br/>
                                        <Button color="primary" onClick={this.AlbumCreate}>Save</Button>
                                        <br/>
                                    </ItemGrid>
                                    <ItemGrid>
                                        <br/>
                                        <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                        <br/>
                                    </ItemGrid>

                                </GridContainer>
                            </form>

                        }
                    />
                    :
                    <IconCard
                        icon={Image}
                       // iconColor={"rose"}
                        title={
                            <div>
                                Albums
                                {(userType === 3 || userType === 4) ?
                                    <Button
                                        onClick={this.handleCreate}
                                        // round color="white"
                                        color="primary" right={true}> Add Album
                                    </Button>
                                    : null}
                            </div>
                        }
                        content={
                            <GridContainer>
                                <ItemGrid xs={12}>
                                    <GridContainer>
                                        {ListAlbums !== null ?
                                            ListAlbums.map((data) =>
                                                <ItemGrid xs={12} sm={3}>
                                                    <GridContainer justify={"center"}>
                                                        <ItemGrid xs={12} sm={12} style={{marginTop: "30px"}}>
                                                            <div className={"thumbnail"}>
                                                                <img alt="..." width={220} height={220}
                                                                     onClick={() => this.onClickAlbum(data.albumId, data.mediaFiles)}
                                                                     src={data.coverUrl}/>
                                                            </div>
                                                        </ItemGrid>
                                                        <ItemGrid xs={12} sm={12}>
                                                            <legend style={{
                                                                fontSize: "18px",
                                                                color: "#e91e63",
                                                                fontFamily: "italic"
                                                            }}>
                                                                    <span style={{
                                                                        marginRight: "10px",
                                                                        marginTop: "10px",
                                                                        marginLeft: "10px"
                                                                    }}>{data.title}</span>
                                                                {(userType === 3 || userType === 4) ?
                                                                    <Tooltip title="Delete">
                                                                        <IconButton
                                                                            onClick={() => {
                                                                                {
                                                                                    if (window.confirm('Are you sure you want to Delete this Album?')) {
                                                                                        this.deleteAlbum(data.albumId)
                                                                                    }
                                                                                    ;
                                                                                }
                                                                            }}
                                                                            color="dangerNoBackground"
                                                                            customClass="remove">
                                                                            <Close/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    : null}

                                                            </legend>
                                                        </ItemGrid>
                                                    </GridContainer>
                                                </ItemGrid>
                                            )
                                            : null}
                                    </GridContainer>
                                </ItemGrid>
                            </GridContainer>
                        }
                    />
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#AAAAAA",
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        // display: "inline-flex"
    },
    imageBackground: {
        hover: {outline: '2px', solid: '#06befa'}
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 5,
    },
    menu: {
        width: 200,
    },
    card: {
        maxWidth: 400,
        backgroundColor: "#AAAAAA"
    },
    cardHeader: {
        backgroundColor: "#AAAAAA"
    },
    media: {
        margin: 3,

        height: 1, paddingTop: '86.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    title: {
        fontSize: '14px'
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    ...customSelectStyle
};
const mapStateToProps = state => {
    const {isFailed, msg, msgColor, classList, classWiseList, albumTable, deleteSuccess} = state.GalleryAlbum;
    return {classList, isFailed, msg, msgColor, classWiseList, albumTable, deleteSuccess}
};
export default connect(mapStateToProps, {
    CreateAlbum: CreateAlbumAction, HideMsg,
    ChildList: GetChildList,
    ClassList: DisplayClassList,
    AlbumList: DisplayAlbum,
    AlbumDelete: DeleteAlbum
})(withStyles(extendedFormsStyle)(Gallery));
