import {VALIDATE_TOKEN_SUCCESS,
    VALIDATE_TOKEN_FAIL,
    RESET_PASSWORD_FAIL,
    RESET_PASSWORD_SUCCESS,
} from "../actions/types";

const INIT_STATE = {isFailed: false, msg: null, msgColor: "danger",display:"block",userId:null,isRedirect:false};
export default (state = INIT_STATE, action) => {
    switch(action.type){
        case VALIDATE_TOKEN_SUCCESS:
            return {...state,isFailed: true,userId: action.payLoad,display:"block",};
        case VALIDATE_TOKEN_FAIL:
            return {...state, isFailed: true, msg: action.payLoad ,display:"none"};
        case RESET_PASSWORD_SUCCESS:
            return {...state,isFailed: true,msg: action.payLoad, msgColor: "success",isRedirect:true};
        case RESET_PASSWORD_FAIL:
            return {...state, isFailed: true, msg: action.payLoad};
        default:
            return INIT_STATE;
    }
}
