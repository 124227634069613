import { LOGIN_SUCCESS, LOGIN_FAILED, HIDE_INFO_MSG, LOGGED_OUT, LOGOUT_FAILED } from "./types";

import { login, logout } from 'kidzwapi';
import { AUTH } from "../helper/Cookies";

function loginSuccess(json) {
    return {
        type: LOGIN_SUCCESS,
        payload: json
    }
}

function loginFailed(msg) {
    return {
        type: LOGIN_FAILED,
        payload: msg
    }
}

function logoutFailed(msg) {
    return {
        type: LOGOUT_FAILED,
        payload: msg
    }
}

function hideMsg() {
    return {
        type: HIDE_INFO_MSG,
        payload: null
    }
}

function loggedOut() {
    return {
        type: LOGGED_OUT,
        payload: null
    }
}

function sessionExperied() {
    return {
        type: LOGGED_OUT,
        payload: null
    }
}

export function sessionExp() {
    return function (dispatch) {
        return dispatch(sessionExperied());
    }
}

export function exitToApp() {
    return function (dispatch) {
        return logout(AUTH())
            .then((resp) => {
                if (resp.status) {
                    dispatch(loggedOut());
                } else {
                    dispatch(loggedOut());
                }
            });
    }
}

export function hideInfo() {
    return function (dispatch) {
        return dispatch(hideMsg());
    }
}


export function Auth(uname, pwd) {
    return function (dispatch) {
        if (uname.toString().trim().length > 0 && pwd.toString().trim().length > 0) {
            return login(uname, pwd).then(json => {
                if (json.status) {
                    console.log("Login Resp", json);
                    dispatch(loginSuccess(json))
                } else {
                    console.log(json)
                    dispatch(loginFailed(json.msg));
                }
            }, err => {
                // console.log(err);
                dispatch(loginFailed("Invalid Username or password"));
            })
        } else {
            dispatch(loginFailed("Enter username and password."));
        }
    }
}
