import LoginPage from "../views/login/LoginPage.jsx";

// @material-ui/icons
import Fingerprint from "@material-ui/icons/Fingerprint";
import ForgotPassword from "../views/login/ForgotPassword";

const pagesRoutes = [
    {
        path: "/Login",
        name: "Login Page",
        short: "Login",
        mini: "LP",
        icon: Fingerprint,
        component: LoginPage
    },

    {
        path: "/ForgotPassword",
        name: "ForgotPassword",
        mini: "FP",
        icon: Fingerprint,
        component: ForgotPassword
    },

   /* {redirect: true, path: "/", pathTo: "/Login", name: "Login"},*/

]

export default pagesRoutes;
