import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import moment from "moment";
import Datetime from "react-datetime";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/icons

import Close from "@material-ui/icons/Close";

import Edit from "@material-ui/icons/Edit";
import CircularIcon from "@material-ui/icons/BlurCircular";
import AttachmentIcon from "@material-ui/icons/AttachFile";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "../../components/Cards/IconCard.jsx";
import IconButton from "../../components/CustomButtons/IconButton.jsx";

import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import withStyles from "material-ui/styles/withStyles";
import FormControl from "material-ui/Form/FormControl";
import InputLabel from "material-ui/Input/InputLabel";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import {connect} from "react-redux";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import ExportToExcel from "../../components/ExportToExcel/ExportToExcel";
import {DisplayClassList} from "../../actions/ClassRoomAction";
import {CreateCircularAction, HideMsg, DispalyCircularList, DeleteCircular} from "../../actions/CircularAction";
import Snackbar from "../../components/Snackbar/Snackbar";
import {USER_TYPE} from "../../helper/Cookies";
import SelectTemp from 'react-select';
import Tooltip from "material-ui/Tooltip";
import {liteBlue} from "../../assets/jss/material-dashboard-pro-react";

class Circulars extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            ctitle: null,
            childId: null,
            cAttachment: null,
            selectAll: 0,
            circular: null,
            cDate: moment().format('DD-MM-YYYY'),
            multipleClassSelection: "",
            headers: ["CircularId", "Circular", "Date", "Attachment"],
            fileName: "No file Chosen",
            fileType: "",
            file: null,
            selectClass: -1,
            FromDate: moment().subtract(30, 'd').format('YYYY-MM-DD'),
            ToDate: moment().format('YYYY-MM-DD'),
            tDate: moment().format('YYYY-MM-DD'),
        };
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
    }

    handleCreate = () => {
        //alert();
        this.setState({
            isCreated: !this.state.isCreated,
            // multipleClassSelection: "",
        });
    };
    // handleMultipleClassTeacher = event => {
    //     this.setState({ multipleClassSelection: event.target.value });
    // };
    handleMultipleClassTeacher(value) {
        let ClassArrays = [];
        if (value !== null) {
            value.map((data) => {
                    if (data.value === -1) {
                        const {classList} = this.props;
                        classList.forEach(r => {
                            const {classId} = r;
                            ClassArrays.push(classId)
                        })
                    } else {
                        ClassArrays.push(data.value);
                    }
                }
            );
            this.setState({multipleClassSelection: ClassArrays});
        } else {
            this.setState({multipleClassSelection: value.value});
        }

    }

    handleClass(value) {
        const {classList} = this.props;
        let ClassArrays = [];
        if (value.value === -1) {
            classList.forEach(r => {
                const {classId} = r;
                ClassArrays.push(classId)
            });
            this.setState({selectClass: ClassArrays});
        } else {
            this.setState({selectClass: value.value});
        }
        if (this.state.FromDate === "") {
            var oneWeekAgo = moment(this.state.cDate).subtract(30, 'days');
            if (typeof oneWeekAgo === 'object') {
                this.setState({FromDate: oneWeekAgo.format("YYYY-MM-DD")});
            }
        }
        if (this.state.ToDate === "") {
            this.setState({ToDate: this.state.cDate});
        }
    };

    circularCreate = () => {
        const {CreateCirular, ClassList, CircularList} = this.props;
        const {childId, circular, cDate, file, fileType, multipleClassSelection, ctitle} = this.state;
        CreateCirular(childId, circular, cDate, file, fileType, multipleClassSelection, ctitle);
        ClassList();
    };
    CircularsGet = () => {
        const {CircularList, ClassList} = this.props;
        const {FromDate, ToDate, selectClass} = this.state;
        CircularList(FromDate, ToDate, selectClass);
        ClassList();

    }

    basicAlert(msg) {
        this.setState({
            alert: (
                <SweetAlert
                    style={{display: "block", marginTop: "-100px", fontSize: "8px"}}
                    title={msg}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                />
            )
        });
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }

    handleToggle(value) {
        const {checked} = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checked: newChecked
        });
    }

    dateChanged = (dateObj) => {
        if (typeof dateObj === 'object')
            this.setState({cDate: dateObj.format('DD-MM-YYYY')});
    };
    onChangeValue = event => {
        this.setState({[event.target.id]: event.target.value});
    };
    selectFromDate = (dateObj) => {
        if (typeof dateObj === 'object')
            this.setState({FromDate: dateObj.format('YYYY-MM-DD')});
    };
    selectToDate = (dateObj) => {
        if (typeof dateObj === 'object')
            this.setState({ToDate: dateObj.format('YYYY-MM-DD')});
    };

    handleFileClick() {
        var input = document.createElement("input");
        input.type = "file";
        input.onchange = this.handleFileChange;
        input.click();
    }

    handleFileChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            if (file.type === "application/pdf" || file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png") {
                this.setState({
                    file: file,
                    fileName: file.name,
                    fileType: file.type
                });
            } else {
                this.setState({
                    file: '',
                    fileName: ''
                });
            }

        };
        reader.readAsDataURL(file);

    }

    handleSimple = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    getData = () => {
        const {CircularList} = this.props;
        // var oneMonthAgo = moment(this.state.tDate).subtract(30, 'days');
        // var oneMonthAgoDate = oneMonthAgo.format("YYYY-MM-DD");
        CircularList(this.state.FromDate, this.state.tDate, this.state.selectClass);
    };

    timeInfo = () => {
        const {HideMsg, CircularList, ClassList, deleteSuccess} = this.props;

        setTimeout(() => {
            HideMsg();
            ClassList();
            if (deleteSuccess !== true) {
                this.handleCreate();
            }
            this.getData();
        }, 3000)
    };


    handleAttachment(attachmentUrl) {
        if (attachmentUrl !== null && attachmentUrl !== undefined)
            window.open(attachmentUrl, "_blank");
        // window.location.assign(attachmentUrl);
        //  window.location.hash(attachmentUrl);
    }

    deleteCircular(circularId) {

        const {CircularDelete} = this.props;
        CircularDelete(circularId);
    }


    componentDidMount() {
        const {ClassList} = this.props;
        ClassList();

        // {this.setState({FromDate:this.state.cDate})}
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {CircularList, classList, circularTable, isRequestSent} = nextProps;
        if (circularTable.length <= 0) {
            let ClassArrays = [];
            if (this.state.selectClass === -1) {
                // console.log("sadik", classList);
                classList.forEach(r => {
                    const {classId} = r;
                    ClassArrays.push(classId)
                });
                this.setState({selectClass: ClassArrays});
            }
            // var oneWeekAgo = moment(this.state.cDate).subtract(30, 'days');
            // var oneWeekAgoDate = oneWeekAgo.format("YYYY-MM-DD");

            if (isRequestSent === false && ClassArrays.length > 0) {
                CircularList(this.state.FromDate, this.state.ToDate, ClassArrays);
            }
        }
        return true;
    }

    render() {
        const {selectClass, multipleClassSelection, FromDate, ToDate} = this.state;
        const {classes, classList, isFailed, msg, msgColor, circularTable, HideMsg, isRequestSent} = this.props;


        if (isFailed === true && msg !== null) {
            if (msgColor === "success") {
                this.timeInfo();
            } else {
                setTimeout(() => {
                    HideMsg();
                    this.getData();
                }, 2000)
            }
        }
        const userType = parseInt(USER_TYPE());

        let options = [{label: "All Classes", value: -1,}];
        if (classList !== null || classList !== undefined) {
            classList.forEach(r => {
                const {name, classId} = r;
                options.push({
                    value: classId,
                    label: name
                })
            })
        }

        let circularsList = [];
        let columns = [];

        // if (circularTable.length > 0) {
        if (userType === 2 || userType === 5) {
            columns = [
                {
                    Header: "Date",
                    accessor: "dateOfCircular",
                    filterable: false,
                    style: {color: "#000", textAlign: "left"}
                },
                {
                    Header: "Circular Title",
                    accessor: "title",
                    filterable: false,
                    minWidth:200,
                    style: {color: "#000", textAlign: "left"}


                },/*
                {
                    Header: "Circular",
                    accessor: "description",
                    filterable: false,
                    style: {color: "#000", textAlign: "left"}


                },*/


                {
                    Header: "Attachment",
                    accessor: "attachment",
                    sortable: false,
                    filterable: false,
                    headerStyle: {textAlign: "left"}
                }

            ]
        } else {
            columns = [
                {
                    Header: "Date",
                    accessor: "dateOfCircular",
                    filterable: false,
                    style: {color: "#000", textAlign: "left"}

                },
                {
                    Header: "Circular Title",
                    accessor: "title",
                    filterable: false,
                    minWidth:200,
                    style: {color: "#000", textAlign: "left"}


                },
/*                {
                    Header: "Circular",
                    accessor: "description",
                    filterable: false,
                    style: {color: "#000", textAlign: "left"}

                },*/


                {
                    Header: "Attachment",
                    accessor: "attachment",
                    sortable: false,
                    filterable: false,
                },
                {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                }
            ]
        }
        console.log("circularTable",circularTable);
        circularTable.forEach(r => {
            const {description, dateOfCircular, attachmentUrl, circularId, title} = r;
            circularsList.push({
                circularId: circularId,
                description: description,
                dateOfCircular: moment(dateOfCircular).format("DD-MM-YYYY"),
                attachmentUrl: attachmentUrl,
                title: title,
                attachment: (
                    <div>
                        <IconButton
                            onClick={() => {
                                this.handleAttachment(attachmentUrl);
                            }}
                            color="blackNoBackground"
                            customClass="edit">
                            <AttachmentIcon/>
                        </IconButton>{" "}
                    </div>
                ),
                actions: (
                    <div className="actions-right">
                        <Tooltip title="Delete">
                            <IconButton
                                onClick={() => {
                                    this.setState({
                                        isCreated:true,
                                        updateView:true,
                                        cDate:moment(dateOfCircular).format("DD-MM-YYYY"),
                                        ctitle:title,
                                        fileName:attachmentUrl,
                                        circular:description,
                                        circularId:circularId
                                    })
                                }}
                                color="primaryNoBackground"
                                customClass="remove">
                                <Edit/>
                            </IconButton>
                        </Tooltip>{" "}


                        <Tooltip title="Delete">
                            <IconButton
                                onClick={() => {
                                    {
                                        if (window.confirm('Are you sure you want to Delete this Record?')) {
                                            this.deleteCircular(circularId)
                                        }
                                        ;
                                    }
                                }}

                                color="dangerNoBackground"
                                customClass="remove">
                                <Close/>
                            </IconButton>
                        </Tooltip>
                    </div>
                )
            })
        })
        // }
        return (
            <GridContainer justify="center">
                <GridContainer>
                    <ItemGrid xs={12} sm={6} md={4}>
                        <Snackbar
                            open={isFailed}
                            place={"tc"}
                            color={msgColor}
                            message={msg}
                        />
                    </ItemGrid>
                </GridContainer>
                {this.state.isCreated ?
                    <IconCard
                        icon={CircularIcon}
                        title="Create Circular"
                        //iconColor="primary"
                        content={
                            <form>
                                <CustomInput
                                    labelText="Circular Title"
                                    id="ctitle"
                                    onChange={this.onChangeValue}
                                    value={this.state.ctitle}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                                {/*<InputLabel style={{color:liteBlue}}>*/}
                                {/*    Classes :*/}
                                {/*</InputLabel>*/}

                                <GridContainer>
                                    <ItemGrid xs={12} sm={6}>
                                        <InputLabel className={classes.label}>
                                            Date:
                                        </InputLabel>
                                        <FormControl fullWidth>
                                            <Datetime
                                                closeOnSelect={true}
                                                timeFormat={false}
                                                dateFormat="DD-MM-YYYY"
                                                onChange={this.dateChanged}
                                                inputProps={{
                                                    id: "cDate",
                                                    placeholder: "Select Date",
                                                    // value: cDate
                                                    // onChange: event => this.handleChangeValues(event, "interviewDate", "length", 0),
                                                     value: this.state.cDate
                                                }}
                                            />

                                        </FormControl>

                                    </ItemGrid>
                                    <ItemGrid xs={12} sm={6}>
                                        <InputLabel className={classes.label}>
                                            Classes :<span style={{color:"red" ,fontSize:"13px"}}>*</span>
                                        </InputLabel>
                                        <SelectTemp
                                            isMulti
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            // defaultValue={options[0]}
                                            name="multipleClassSelection"
                                            options={options}
                                            onChange={this.handleMultipleClassTeacher.bind(this)}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: '4px',
                                                height: "20px",
                                                colors: {
                                                    ...theme.colors,
                                                    //  primary25: '#00ABDC',
                                                    primary: liteBlue,
                                                    //   primary50:"#00ABDC"
                                                },
                                            })}
                                        />
                                    </ItemGrid>
                                </GridContainer>
                                <InputLabel className={classes.label}>
                                    Circular<span style={{color:"red" ,fontSize:"13px"}}>*</span>
                                </InputLabel>
                                <CustomInput
                                    // labelText="Lamborghini Mercy, Your chick she so thirsty, I'm in that two seat Lambo."
                                    id="circular"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    onChange={this.onChangeValue}
                                    value={this.state.circular}
                                    inputProps={{
                                        multiline: true,
                                        rows: 7,
                                        placeholder: "Type Circular Here"
                                    }}
                                />
                                <InputLabel className={classes.label}>
                                    Attachment
                                </InputLabel>

                                <GridContainer>
                                    <ItemGrid xs={12} sm={6}>
                                        <CustomInput
                                            //  labelText="At"
                                            id="cAttachment"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                accept: "application/pdf",
                                                disabled: true,
                                                value: "" + this.state.fileName
                                            }}
                                        />
                                    </ItemGrid>
                                    <ItemGrid>
                                        <Button onClick={this.handleFileClick}>Choose File</Button>
                                    </ItemGrid>


                                </GridContainer>

                                <GridContainer justify="center">

                                    {!this.state.updateView? <ItemGrid>
                                        <br/>
                                        <Button color="primary" onClick={this.circularCreate}>Send</Button>
                                        <br/>
                                    </ItemGrid>:null}
                                    <ItemGrid>
                                        <br/>
                                        <Button color="danger" onClick={this.handleCreate}>Cancel</Button>
                                        <br/>
                                    </ItemGrid>

                                </GridContainer>
                            </form>
                        }
                    /> :
                    <IconCard
                        icon={CircularIcon}
                        //iconColor="primary"
                        title={
                            <div>
                                Circulars
                                <Button
                                    onClick={this.handleCreate}
                                    color="primary" right={true}> Add Circular
                                </Button>
                            </div>
                        }
                        content={
                            <GridContainer>
                                <ItemGrid xs={12} sm={12}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={2} style={{marginTop: "-11px"}}>
                                            <InputLabel className={classes.label}>
                                                From Date
                                            </InputLabel>
                                            <FormControl fullWidth>
                                                <Datetime
                                                    timeFormat={false}
                                                    dateFormat="DD-MM-YYYY"
                                                    onChange={this.selectFromDate}
                                                    inputProps={{
                                                        id: "FromDate",
                                                        placeholder: "Select Date",
                                                        value: this.state.FromDate
                                                    }}
                                                />
                                            </FormControl>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2} style={{marginTop: "-11px"}}>
                                            <InputLabel className={classes.label}>
                                                To Date
                                            </InputLabel>
                                            <FormControl fullWidth>
                                                <Datetime
                                                    timeFormat={false}
                                                    dateFormat="DD-MM-YYYY"
                                                    onChange={this.selectToDate}
                                                    inputProps={{
                                                        id: "ToDate",
                                                        placeholder: "Select Date",
                                                        value: this.state.ToDate
                                                    }}
                                                />
                                            </FormControl>
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={3} style={{marginTop: "-11px"}}>
                                            <InputLabel className={classes.label}>
                                                Class
                                            </InputLabel>
                                            <SelectTemp
                                                className="basic-single"
                                                classNamePrefix="select"
                                                defaultValue={options[0]}
                                                name="selectClass"
                                                options={options}
                                                onChange={this.handleClass.bind(this)}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: '4px',
                                                    height: "20px",
                                                    colors: {
                                                        ...theme.colors,
                                                        //  primary25: '#00ABDC',
                                                        primary:liteBlue,
                                                        //   primary50:"#00ABDC"
                                                    },
                                                })}
                                            />
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={2}>
                                            <Button color="primary" onClick={this.CircularsGet}> Search </Button>
                                        </ItemGrid>
                                    </GridContainer>
                                </ItemGrid>
                                {this.state.alert}
                                <ItemGrid xs={12}>
                                    <ItemGrid xs={12}
                                              style={{height: 1, backgroundColor: "#00acc1", marginTop: "15px"}}/>
                                    <ReactTable
                                        data={circularsList}
                                        filterable
                                        style={{color: "#00acc1"}}
                                        columns={columns}
                                        defaultPageSize={5}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                    />
                                    <ItemGrid xs={12}>
                                        <GridContainer>
                                            <ItemGrid xs={12} md={10}>
                                            </ItemGrid>
                                            <ItemGrid xs={12} md={2}>
                                                <ExportToExcel
                                                    key={circularsList}
                                                    posts={circularsList}
                                                    headings={this.state.headers}
                                                    fileName={"Circulars"} sheetName={"Circulars"}/>

                                            </ItemGrid>
                                        </GridContainer>
                                    </ItemGrid>

                                </ItemGrid>
                            </GridContainer>

                        }
                    />
                }
            </GridContainer>
        );
    }
}

const extendedFormsStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color:liteBlue,
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex"
    },
    ...customSelectStyle
};
const mapStateToProps = state => {
    const {isFailed, msg, msgColor, circularTable, classList, isRequestSent, deleteSuccess} = state.Circular;
    return {isFailed, msg, msgColor, circularTable, classList, isRequestSent, deleteSuccess}
};
export default connect(mapStateToProps, {
    CreateCirular: CreateCircularAction, HideMsg,
    CircularList: DispalyCircularList,
    ClassList: DisplayClassList,
    CircularDelete: DeleteCircular


})(withStyles(extendedFormsStyle)(Circulars));
