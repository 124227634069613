import React from "react";
//import 'react-chat-elements/dist/main.css';
import '../../assets/scss/material-dashboard-pro-react/chat-elements.css'
// react component for creating dynamic tables

// @material-ui/icons
import AttachmentIcon from "@material-ui/icons/Attachment";
import SendIcon from "@material-ui/icons/Send";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
// import IconCard from "components/Cards/IconCard.jsx";
// import Image from "@material-ui/icons/Image";
// import {dataTable} from "variables/general.jsx";
import avatar from "assets/img/faces/avatar.jpg";
import {ChatItem, MessageBox, Input, MessageList} from 'react-chat-elements';
import RegularCard from "../../components/Cards/RegularCard";
import IconButton from "../../components/CustomButtons/IconButton";

//import withStyles from "material-ui/styles/withStyles"

class ChatConversation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            imagePreviewUrl: avatar,

        };

    }


    render() {
        return (
            <GridContainer justify={"center"}>

                <ItemGrid xs={12}>
                    <RegularCard
                        cardTitle={
                            <div>
                                <ChatItem
                                    avatar={avatar}
                                    alt={'Reactjs'}
                                    title={'Tania Andrew'}
                                    //  subtitle={'Last seen today at 2:45 PM'}
                                    //date={new Date()}
                                    dateString={
                                        <div>
                                            <IconButton
                                                onClick={() => {
                                                }}
                                                color="blackNoBackground"
                                                customClass="edit">
                                                <AttachmentIcon/>
                                            </IconButton>
                                        </div>
                                    }
                                />
                            </div>
                        }
                        content={
                            <div>
                                <GridContainer style={{
                                    height: 400,
                                    overflowY: 'auto',
                                    overflowScrolling: "touch",
                                    backgroundColor: "#eee",
                                    paddingTop: "10px"
                                }}>
                                    <ItemGrid xs={12}>
                                        <MessageList
                                            className='message-list'
                                            lockable={true}
                                            toBottomHeight={'100%'}
                                            dataSource={[
                                                {
                                                    title: 'Tania Andrew',
                                                    position: 'left',
                                                    type: 'text',
                                                    text: 'Hi how r u',
                                                    dateString: '2:45 PM',
                                                },
                                                {
                                                    //  title:'Tania Andrew',
                                                    position: 'right',
                                                    type: 'photo',
                                                    text: 'Hi how r u',
                                                    dateString: '2:45 PM',
                                                    data: {
                                                        uri: 'https://www.gettyimages.ie/gi-resources/images/Homepage/Hero/UK/CMS_Creative_164657191_Kingfisher.jpg',
                                                        /*status: {
                                                            click: false,
                                                            loading: 0,
                                                        }*/
                                                    }
                                                },
                                                {
                                                    // title:'Tania Andrew',
                                                    position: 'right',
                                                    type: 'text',
                                                    text: 'I am fine . What about you?',
                                                    dateString: '2:45 PM',
                                                },
                                            ]}/>

                                        <MessageBox
                                            //title={'Tania Andrew'}
                                            position={'right'}
                                            type={'text'}
                                            dateString={'2:50 PM'}
                                            text={'I am fine . What about you?'}/>*/}
                                    </ItemGrid>
                                </GridContainer>
                                <GridContainer>
                                    <ItemGrid xs={12}>
                                        <Input
                                            placeholder="Type here..."
                                            multiline={true}
                                            minHeight={50}
                                            rightButtons={
                                                <IconButton
                                                    color='defaultNoBackground'>
                                                    <SendIcon/>
                                                </IconButton>
                                            }/>
                                        <ItemGrid xs={12}
                                                  style={{height: 1, backgroundColor: "#00acc1", marginTop: "10px"}}/>
                                    </ItemGrid>
                                </GridContainer>
                            </div>

                        }/>

                </ItemGrid>

            </GridContainer>
        );
    }
}


export default ChatConversation;
