import {CREATE_USER_FAIL, CREATE_USER_SUCCESS, USERS_LIST} from "../actions/types";
const INIT_STATE = {isFailed: false, msg: null, msgColor: "danger", userTable: []};

export default (state=INIT_STATE,action)=>{
    switch(action.type){
        case CREATE_USER_SUCCESS:
            return {...state,isFailed: true,msg:action.payLoad,msgColor: "success"};
        case CREATE_USER_FAIL:
            return{...state,isFailed:true,msg:action.payLoad};
        case USERS_LIST:
            return{...state,userTable: action.payLoad}
        default:
            return INIT_STATE;

    }

}
