import cons from "./Consts";
import {reactLocalStorage as storage} from "reactjs-localstorage";

import avatar from "../assets/img/default-avatar.png";


export const setCookie = (key, value) => {
    storage.set(key, value);
};

export const removeCookies = () => {
    storage.clear();
    return true;
};

export const setUserId = (userId) => {
    setCookie(cons.USERID, userId);
};

export const setAuthKey = (auth) => {
    setCookie(cons.AUTH, auth);
};


export const setAttClasses = classes => {
    setCookie(cons.ATTEN_CLASSES, JSON.stringify(classes));
};

export const getAttClasses = () => {
    const classes = storage.get(cons.ATTEN_CLASSES, null);
    return classes ? JSON.parse(classes) : null;
};


export const setLoggedIn = () => {
    setCookie(cons.LOGGED_IN, true);
};

export const setLoggedOut = () => {
    setCookie(cons.LOGGED_IN, false);
};

export const setDispName = (name) => {
    setCookie(cons.USER_NAME, name);
};
export const setSchoolId = (schoolId) => {

    setCookie(cons.SCHOOLID, schoolId);
};

export const dispName = () => {
    return storage.get(cons.USER_NAME, "Defualt");
};
export const SCHOOL_ID = ()=>{
    return storage.get(cons.SCHOOLID,null);

};
export const setUserType=(type)=>{
    setCookie(cons.USERTYPE,type);
};
export const  setImages=(AlbumImages)=>{
    setCookie(cons.ALBUMIMAGES, AlbumImages);
};

export const AlbumImages =()=>{
    return storage.get(cons.ALBUMIMAGES,null)
};

export const setAlbumId =(albumId)=>{
    setCookie(cons.ALBUMID, albumId);
};

export const setTeachClass =(teachClass)=>{
    setCookie(cons.TEACHCLASS, teachClass);
};

export const Classes =()=>{
    return storage.get(cons.TEACHCLASS, null)
}

export const AlbumId =()=>{
    return storage.get(cons.ALBUMID, null)
}
export const USER_TYPE=()=>{
    return storage.get(cons.USERTYPE,null);
}
export const isLoggedIn = () => {
    return storage.get(cons.LOGGED_IN, false);
};

export const AUTH = () => {
    return storage.get(cons.AUTH, "loginAuth");
};

export const PROFILE_PIC = () => {
    var pp = storage.get(cons.PURL, null);
    console.log(pp);
    return ((pp !== null || pp !== undefined) && isLoggedIn()) ? pp : avatar;
};



export const USER_ID = () => {
    if (isLoggedIn()) {
        return storage.get(cons.USERID, -1);
    } else {
        return -1;
    }
};
