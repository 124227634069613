import Pages from "layouts/Pages.jsx";
import Home from "layouts/Dashboard.jsx";
import Logout from "../layouts/Logout";
import ResetPassword from "../views/login/ResetPassword";
import Register from "../views/Register/RegisterPage";
//import Login from "../views/login/LoginPage";

var indexRoutes = [
    {path: "/Logout", name: "Logout", component: Logout},
    {path: "/Login", name: "Login", component: Pages},
    {path: "/ForgotPassword", name: "ForgotPassword", component: Pages},
    {path: "/Dashboard", name: "Dashboard", component: Home},
    {path: "/ucp/:token", name: "ucp", component: ResetPassword},
    {path: "/Register", name: "Register", component: Register},
    {path: "/", name: "Dashboard", component: Home }
];

export default indexRoutes;
