import React from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory,createHashHistory} from "history";
import {Router, Route, Switch} from "react-router-dom";
import {ApolloProvider} from "@apollo/react-hooks";
import {gqlClient} from "./graphql/service";
import {createStore, applyMiddleware} from 'redux'
import {Provider} from 'react-redux';
import thunk from 'redux-thunk'

import indexRoutes from "./routes/index.jsx";
import rootReducers from "./reducers"
import "./assets/scss/material-dashboard-pro-react/material-dashboard-pro-react1.css";

const hist = createHashHistory();
const store = createStore(rootReducers, applyMiddleware(thunk));

ReactDOM.render(
    <Provider store={store}>
        <ApolloProvider client={gqlClient}>
            <Router history={hist}>
                <Switch>
                    {indexRoutes.map((prop, key) => {
                        return <Route path={prop.path} component={prop.component} key={key}/>;
                    })}
                </Switch>
            </Router>
        </ApolloProvider>
    </Provider>,
    document.getElementById("root")
);
