import React from "react";
import Datetime from "react-datetime";

import IconCard from "../../components/Cards/IconCard";
import alertIcon from "@material-ui/icons/AddAlert";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import GridContainer from "../../components/Grid/GridContainer";
import ItemGrid from "../../components/Grid/ItemGrid";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Select from "material-ui/Select";
import PropTypes from "prop-types";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import withStyles from "material-ui/styles/withStyles";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle";
// import FormControlLabel from "material-ui/Form/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox/Checkbox";
// import {Check} from "@material-ui/icons";
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import Input from "@material-ui/core/Input/Input";
import InputLabel from "material-ui/Input/InputLabel";

 function Popup({...props}) {
     const {classes,onClickCreate,closePopup,handleInput} = props;
     return (
         <GridContainer>
             <ItemGrid xs={6}>
            <div style={{
                position: "fixed",display: "relative",
                width: "100%",
                height: "100%",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                margin: "auto",
                zIndex: 5000,
                backgroundColor: "rgba(0,0,0, 0.5)"
            }}>
                <div style={{
                    position: "absolute",
                    display: "relative",
                    width: "50%",
                    height: "50%",
                    top: "20%",
                    left: "15%",
                    right: "15%",
                    bottom: "25%",
                    margin: "auto",
                    backGround: "white",
                   // zIndex: 5500
                }}>

                            <IconCard
                                icon={alertIcon}
                                title="Create New Password"
                              //  iconColor="rose"
                                content={
                                    <GridContainer justify="center" >
                                        <GridContainer>
                                        <ItemGrid  xs={12} sm={6}>

                                                <CustomInput
                                                labelText="New Password"
                                                id="npass"
                                                onChange={handleInput}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{}}
                                            />
                                        </ItemGrid>
                                        <ItemGrid  xs={12} sm={6}>

                                                <CustomInput
                                                    labelText="Confirm Password"
                                                    //  labelText="At"
                                                    id="cpass"
                                                    onChange={handleInput}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{}}
                                                />
                                        </ItemGrid>
                                        </GridContainer>
<GridContainer justify="center">
    <ItemGrid>
        <Button color="primary" onClick={onClickCreate}>Create</Button>

    </ItemGrid>
    <ItemGrid>
        <Button color="danger" onClick={closePopup}>Cancel</Button>

    </ItemGrid>
</GridContainer></GridContainer>}/>
                </div>
            </div>
             </ItemGrid>
         </GridContainer>
        );

}
Popup.propTypes = {
    classes: PropTypes.object.isRequired

};
const extendedCalendarStyle = {
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: "#AAAAAA",
        fontSize: "12px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    ...customSelectStyle,
    ...buttonStyle,
    ...customCheckboxRadioSwitch,
};


export default withStyles(extendedCalendarStyle)(Popup);
